import React, { useState } from "react";
import Backicon from "../Assets/backicon.png";
import Button from "../common/Button";
import axiosInstance from "../utils";
import endpoints from "../utils/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SubAdminPopup = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("GENERAL");

  const clickHandler = () => {
    props.closePopUp();
  };

  const handleSubmit = () => {
    // console.log("Name:", name);
    // console.log("Email:", email);
    postSubAdminData(name, email, role);
  };

  const postSubAdminData = async (name, email, type) => {
    const url = endpoints.baseUrl + endpoints.createSubAdmin;
    // console.log(url)
    const formData = {
      name: name,
      email: email,
      type,
    };
    console.log(formData, "Form data in create subadin");
    try {
      const response = await axiosInstance.post(url, formData);
      console.log(response.data.data.name);
      props.closePopUp();
    } catch (error) {
      // console.log(err)
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  return (
    <>
      <div
        className="px-5 py-3"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #4F81C3",
          paddingTop: "40px",
        }}
      >
        <img
          src={Backicon}
          onClick={clickHandler}
          style={{ cursor: "pointer" }}
        />
        <div className="row">
          <div className="col-12 pt-4">
            <label style={{ fontWeight: 500, fontSize: 16 }}>Name</label>
            <input
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="mt-2 ps-3 py-2"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-12 pt-4">
            <label style={{ fontWeight: 500, fontSize: 16 }}>
              Email address
            </label>
            <input
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="mt-2 ps-3 py-2"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-12 pt-4">
            <label style={{ fontWeight: 500, fontSize: 16 }}>Role</label>
            <select
              onChange={(e) => setRole(e.target.value)}
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
                fontWeight: "600",
                fontSize: "15px",
              }}
              className="ps-2 py-2 mt-1 "
              value={role}
            >
              <option value="" disabled className="d-none">
                Select role
              </option>

              <option
                value={"GENERAL"}
                style={{
                  backgroundColor: "#F3F1F1",
                  borderRadius: "15px",
                  border: "none",
                  display: "block",
                }}
              >
                General
              </option>
              <option
                value={"PRODUCT"}
                style={{
                  backgroundColor: "#F3F1F1",
                  borderRadius: "15px",
                  border: "none",
                  display: "block",
                }}
              >
                Product
              </option>
              <option
                value={"SUPERADMIN"}
                style={{
                  backgroundColor: "#F3F1F1",
                  borderRadius: "15px",
                  border: "none",
                  display: "block",
                }}
              >
                Both
              </option>
            </select>
          </div>
        </div>

        <div className="mt-5 text-center" onClick={handleSubmit}>
          <Button borderRadius={10} name="submit" width="20%" fontSize="15px" />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SubAdminPopup;
