import React from "react";
import Styles from "./Heading.module.css";
export default function (props) {
  const container = {
    height: props.height,
    width: props.height,
    // backgroundColor:props.bgColor || 'yellow',
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    textAlign: props.textAlign,
    paddingLeft: props.paddingLeft,
  };
  const p = {
    // fontSize: props.fontSize || "20px",
    fontWeight: props.fontWeight || "600",
    marginBottom: 0,
    fontFamily: props.fontFamily,
    color: props.color,
    textAlign: props.textAlign,
    textTransform:"capitalize"
  };

  return (
    <div style={container}>
      <p style={p} className={`${Styles.fs_lg}`}>
        {props.name}
      </p>
    </div>
  );
}
