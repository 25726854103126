import React, { useEffect, useState } from "react";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import Heading from "../../common/Heading";
import Sidebar from "../../common/SideBar";
import NavBarEx from "../../common/NavBarEx";
import { DataGrid } from "@mui/x-data-grid";
import redeye from "../../Assets/remove_red_eye.png";
import editicon from "../../Assets/edit.svg";
import styles from "./Shop.module.css";
import colors from "../../utils/helper";
import PreLoader from "../../common/PreLoader";
import Card from "../../common/Card";
import Backicon from "../../Assets/backicon.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import trashicon from "../../Assets/icon_trash.png";
import WarningPop from "../../common/WarningPop";
import { toast } from "react-toastify";
import ShopEditForm from "../../components/Shop/ShopEditForm";

function AllShop() {
  const navigate = useNavigate();
  const [editActive, setEditActive] = useState();
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([
    // { orderId: "11111111", customerId: "1111111111111" },
  ]);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const columns = [
    // { flex: 1, field: "id", headerName: "Sno.", maxWidth: 100 },
    // { flex: 1, field: "_id", headerName: "Shop ID" },
    { flex: 1, field: "name", headerName: "Name" },
    { flex: 1, field: "ownerId", headerName: "Owner ID" },
    { flex: 1, field: "ownerName", headerName: "Owner Name" },
    { flex: 1, field: "totalAgents", headerName: "No. of agent" },
    // { flex: 1, field: "totalCategories", headerName: "No. of category" },
    { field: "totalOrders", headerName: "Total orders" },
    {
      flex: 1,
      field: "totalRevenue",
      headerName: "Total sales",
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log("edit", params.row);
            setEditActive(params?.row?._id);
            setPopupOpen(true);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            {!params?.row?.resolved && (
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={onClick}
              >
                <img src={editicon} style={{ width: "20px" }} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
            navigate("/admin/specificShop/" + params?.row?._id);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            {!params?.row?.resolved && (
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={onClick}
              >
                <img src={redeye} style={{ width: "20px" }} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
            setActive(params?.row?._id);
            setWarning(true);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            {!params?.row?.resolved && (
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={onClick}
              >
                <img src={trashicon} style={{ width: "20px" }} />
              </button>
            )}
          </>
        );
      },
    },
  ];
  const fetchData = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allShops
      );
      console.log("data");
      console.log(response.data.data);
      let data = response.data.data.map((item, index) => {
        return {
          ...item,
          id: index + 1,
          ownerId: item.owner[0] ? item.owner[0]?._id : "N/A",
          ownerName: item.owner[0] ? item.owner[0].name : "N/A",
          totalAgents: item.agents?.length,
          createdAt: moment(item.createdAt),
          // totalCategories: item.categories?.length,
          totalRevenue: item.totalRevenue.toFixed(2),
        };
      });
      setRows(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const deleteShop = async (tempId) => {
    const url = endpoints.baseUrl + "admin/" + endpoints.deleteShop + tempId; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      console.log("Delete response------------------->:", response.data.data);
      toast.success("customer deleted succesfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } catch (err) {
      // console.log("Delete error:", err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      setWarning(false);
      fetchData();
    }
  };

  const PopUpclose = () => {
    setPopupOpen(false);
  };

  const warningHandler = (val) => {
    console.log("hello from warning handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-lg-2 col-md-3 ${styles.left}`}
            style={{ paddingRight: "0px" }}
          >
            <Sidebar active={true} activeTabColor="Shops" />
          </div>

          <div
            className="col-lg-10 col-md-9 right py-3"
            style={{
              backgroundColor: colors.bgcolor,
              overflowY: "auto",
              height: "100vh",
              flexWrap: "wrap",
            }}
          >
            {loading && <PreLoader />}
            <div className="pt-3">
              <NavBarEx
                buttonFlag={false}
                buttonName={"Add new customer"}
                fontSize={"38px"}
                search={true}
              />
            </div>
            <div className="text-start pt-3">
              <img
                src={Backicon}
                alt="Back Icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="row pt-4">
              <div className="col-12">
                {/* <ReccentOrders /> */}
                <Card backgroundColor="#fff">
                  <div className="pt-4 d-flex justify-content-between align-items-center">
                    <Heading name="All Shops" paddingLeft="1%" />
                  </div>
                  <div className="px-4 py-2" style={{ width: "100%" }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      //   checkboxSelection
                      // hideFooter
                      style={{ border: "none", minHeight: 400 }}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupOpen && (
        <div className={styles.popcontainer}>
          <div style={{ position: "relative", zIndex: 20 }}>
            <ShopEditForm
              fetchDataOfAllShops={fetchData}
              editActive={editActive}
              closePopUp={PopUpclose}
            />
          </div>
        </div>
      )}
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "30%",
            top: "30%",
            // zIndex: 10,
          }}
        >
          <WarningPop
            delete={() => deleteShop(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to delete this shop?"
          />
        </div>
      ) : null}
    </div>
  );
}

export default AllShop;
