import React, { useState, useEffect } from "react";
import redeye from "../../Assets/remove_red_eye.png";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
import styles from "../../components/Supllier/StockReport.module.css";
import editicon from "../../Assets/edit.svg";
import dustbin from "../../Assets/icon_trash.png";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import Button from "../../common/Button";
import AddSubCategoryPopup from "./AddSubCategoryPopup";
import { useNavigate } from "react-router";
import Backicon from "../../Assets/backicon.png";
import WarningPop from "../../common/WarningPop";
import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
export default function Subcategory() {
  const navigate = useNavigate();

  const [subcategories, setSubcategories] = useState([]);
  const { id } = useParams();

  const fetchSubcategories = async () => {
    const url =
      endpoints.baseUrl +
      "admin/" +
      endpoints.getAllCategoriesInProduct +
      "?parent=" +
      id;
    // console.log("******", url);
    try {
      const response = await axiosInstance.get(url);
      setSubcategories(response.data.data);
      console.log(response.data.data, "*****************")
    } catch (error) {
      console.error("Error fetching products:", error);

    }
  };
  useEffect(() => {
    fetchSubcategories();
  }, []);
  const border = {
    // borderTop: "12px solid #EAF0FA",
    // borderBottom: "12px solid #EAF0FA",
  };
  const headerdata = [
    {
      header1: "Subcategory",
      header2: "Category",
      header3: "",
      header4: "",
      header5: "",
      header6: "",
      header7: "",
      header8: "Edit",
      header9: "Delete",
    },
  ];
  const [popupOpen, setPopupOpen] = useState(false);
  const [nameToEditForm, setNameToEditForm] = useState()
  const [title, setTitle] = useState("");
  const handleVerifyClick = (e) => {
    setNameToEditForm(e)
    if (!e) {
      setTitle("Subcategory Name")
    } else {
      setTitle("Change Subcategory Name")
    }
    setPopupOpen(true);
  };
  const PopUpclose = () => {
    setPopupOpen(false);
  };
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };
  const deleteSubcategory = async (id) => {

    const url = endpoints.baseUrl + "admin/" + endpoints.deleteCategory + "/" + id; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      fetchSubcategories()
    } catch (error) {
      // console.log("Delete error:", err);
      // toast.error("Internal Server Error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 5000, // Set the duration for how long the popup should be visible
      // });
    }
  }
  const deleteHandler = (index) => {
    deleteSubcategory(subcategories[index]?._id);
    setWarning(0);
  };

  return (
    <div>
      <div className="text-start pb-3">
        <img
          src={Backicon}
          alt="Back Icon"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/agent/home")}
        />
      </div>
      <Card>
        <div className="py-3 row  align-items-center">
          <div className="col-6">
            <Heading
              paddingLeft={"4%"}
              name="Subcategory Table"
              fontSize="24px"
            />
          </div>
          <div className="col-6 text-end pe-4" onClick={() => handleVerifyClick("")}>
            <Button
              name="Add Subcategory"
              width="fit-content"
              borderRadius="12px"
            />
          </div>
        </div>
        <div
          className={`table-responsive ${styles.tablecontainer}`}
          style={{
            overflowX: "auto",
            maxHeight: "320px",
            paddingLeft: "2%",
            paddingRight: "2%",
          }}
        >
          <table className={`table table-borderless ${styles.table}`}>
            <thead>
              {headerdata.map((th, index) => (
                <tr
                  key={index}
                  style={{
                    ...border,
                    // backgroundColor: "#DEE9F8",
                    borderRadius: "12px",
                    borderWidth: "0px",
                  }}
                >
                  <th scope="col">{th.header1}</th>
                  <th scope="col">{th.header2}</th>
                  <th scope="col">{th.header3}</th>
                  <th scope="col">{th.header4}</th>
                  <th scope="col">{th.header5}</th>
                  <th scope="col">{th.header6}</th>
                  <th scope="col">{th.header7}</th>
                  <th scope="col">{th.header8}</th>
                  <th scope="col">{th.header9}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              ))}
            </thead>
            <tbody>
              {subcategories.map((row, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    ...border,
                  }}
                >
                  <td
                    // contenteditable="true"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {row?.name}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {row?.parent?.name}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {""}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {""}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {""}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {""}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {""}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {/* onClick={OpenEditPopup} */}
                    <div onClick={() => { handleVerifyClick(row?._id) }} style={{}}>
                      <img
                        style={{ height: "15px", cursor: "pointer" }}
                        src={editicon}
                        alt="Delete"
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                    onClick={() => {
                      // calculateXandY();
                      //   setActive(index);
                      //   warningHandler();
                    }}
                  >
                    <img
                      onClick={(event) => {
                        setActive(index);
                        warningHandler();
                      }}
                      width={15}
                      height={20}
                      src={dustbin}
                      alt="Trash Icon"
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {""}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {""}
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    }}
                  >
                    {""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
      {/* {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "30%",
            top: "50%",
            zIndex: 1000000000,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to accept the request?"
          />
        </div>
      ) : null} */}
      {popupOpen && (
        <div className={` ${styles.popupcontainer}`}>
          <div style={{ position: "relative", zIndex: "50" }}>
            <AddSubCategoryPopup
              title={title}
              editActive={nameToEditForm}
              categoryId={id}
              closePopUp={PopUpclose}
              getData={fetchSubcategories}
            />
          </div>
        </div>
      )}
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "38%",
            top: "50%",
            // zIndex: 10,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to delete this SubCatagories?"
          />
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
}
