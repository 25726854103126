import React from "react";
import "./terms.css";
function AboutUs() {
    return (
        <div className="container" style={{}}>
            <div className="row">
                <div
                    className="col-lg-6 col-md-6 col-12 p-3 offset-md-3 slip"
                    style={{
                        minHeight: "100vh",
                    }}
                >
                    <h3>About Us</h3>
                    <p style={{ fontWeight: "bold" }}>
                        Effective Date: 1st October, 2023
                    </p>
                    <br />
                    <p>
                        Welcome to Anyp Systems, where fashion meets passion! We are more than just an online clothing store; we are a destination for style enthusiasts who seek trendy, affordable, and high-quality apparel. Our mission is to empower individuals to express their unique personalities through fashion, making every outfit a statement and every customer a trendsetter.
                    </p>
                    <br />
                    <p style={{ fontWeight: "bold" }}>
                        Our Story
                    </p><p>
                        Established in 2017, Anyp Systems was born out of a shared love for fashion and a desire to provide an unparalleled online shopping experience. What started as a small venture has now grown into a thriving hub for the latest styles and fashion-forward pieces. Our journey is a testament to our commitment to curate a diverse collection that caters to various tastes and preferences.
                    </p><br /><p style={{ fontWeight: "bold" }}>
                        About Us
                    </p><p>
                        The Anyp Systems Difference
                    </p><p>
                        Fashion That Speaks Volumes: Our carefully curated collections showcase the latest trends, ensuring that you stay ahead in the style game. From casual wear to sophisticated evening attire, we have something for every occasion, empowering you to express your unique sense of fashion.
                    </p><br /><p>

                        Quality You Can Trust: We understand that fashion is not just about looks but also about quality. That's why we source our clothing from reputable manufacturers, ensuring that each piece is crafted with attention to detail and made to last.
                    </p><br /><p>

                        Affordable Elegance: At Anyp Systems, we believe that everyone deserves to look and feel their best without breaking the bank. That's why we strive to offer affordable yet stylish options, making fashion accessible to all.
                    </p><br /><p style={{ fontWeight: "bold" }}>

                        Our Commitment
                    </p><p>
                        Customer Satisfaction: Your satisfaction is our top priority. Our dedicated customer support team is here to assist you every step of the way, from choosing the perfect outfit to ensuring a seamless shopping experience.
                    </p><br /><p>

                        Environmental Responsibility: We are committed to sustainability. Our efforts include eco-friendly packaging, responsibly sourced materials, and a continuous commitment to reducing our environmental impact.
                    </p><br /><p>

                        Thank you for choosing Anyp Systems as your go-to destination for fashion. We look forward to being part of your style journey and helping you turn heads with every step you take. Happy shopping!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
