// import React from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   responsive: true,
//   maintainAspectRatio: false,
//   plugins: {
//     legend: {
//       display: false,
//       position: 'top',
//     },
//     title: {
//       display: false,
//       // text: 'Chart.js Line Chart',
//     },
//   },
//   scales: {
//     x: {
//       display: false, // Hide the Y-axis labels
//     },
//     y: {
//       // grid: {
//       //   display: true, // Show X-axis grid lines
//       //   color: 'rgba(128, 128, 128, 0.3)',

//       // },
//       border:{dash: [4, 7]}, // for the grid lines
//       grid: {
//         color: 'red', // for the grid lines
//         tickColor: '#000', // for the tick mark
//         tickBorderDash: [2, 3], // also for the tick, if long enough
//         tickLength: 10, // just to see the dotted line
//         tickWidth: 2,
//         offset: true,
//         drawTicks: false, // true is default
//         drawOnChartArea: true // true is default
//     },

//       ticks: {
//         display: false, // Hide X-axis labels
//         stepSize: 10
//       },
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,

//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [60, 20, 70, 40, 50, 90],
//       borderColor: '#EC751F',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },

//   ],
// };

// export function CustomLineChart(props) {
//   return (
//     <div className='pb-5 pt-2'  style={{ width: '92%', height:props.height || '95%', marginLeft: 'auto', marginRight: 'auto' }}>
//       <Line options={options} data={data} width="1490px" height="480px" />
//     </div>
//   )
// }
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true,
      grid: {
        display: false,
      },
      ticks: {
        display: true,
      },
    },
    y: {
      display: true,
      grid: {
        color: "rgba(128, 128, 128, 0.3)",
      },
      ticks: {
        display: true,
        stepSize: 5,
      },
    },
  },
};


const xCoordinates = [1, 2, 3, 4, 5, 6, 7];
const yCoordinates = [60, 20, 70, 40, 50, 90, 30];



export function CustomLineChart(props) {
  const labels =props.amountsForGraph?.x || ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: props.amountsForGraph?.y || yCoordinates,
        borderColor: '#EC751F',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return (
    <div
      className="pb-3 pt-2"
      style={{
        width: "92%",
        height: props.height || "95%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Line options={options} data={data} width="1490px" height="480px" />
    </div>
  );
}
