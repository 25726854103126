import React, { useState, useEffect } from "react";
import NavBarEx from "../../common/NavBarEx";
import Subcategory from "../../components/Supllier/Subcategory";
import SideBar from "../../common/SideBar";
import colors from "../../utils/helper";
import styles from "../../screens/agent/supplier/Product.module.css";
import PreLoader from "../../common/PreLoader";
import { useParams } from "react-router-dom";
export default function () {
  const params=useParams();
  const id =params.id
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      // document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);

  return (
    <div className=" position-relative">
      <div>{/* <NavBar /> */}</div>
      <div className=" container-fluid">
        <div className=" row ">
          <div
            className={` col-lg-2 col-md-3 max-vh-100 pe-0  ${styles.left}`}
            // style={popupOpen || Open == 1 ? { filter: "blur(2px)" } : {}}
          >
            <SideBar flag={true} activeTabColor="Dashboard" />
          </div>
          <div
            className="col-lg-10 col-md-9 col-12  pb-4"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: colors.bgcolor,
            }}
          >
            {loading && <PreLoader />}
            {/* style={popupOpen || Open == 1 ? { filter: "blur(2px)" } : {}} */}
            <div>
              <div className="py-3">
                <NavBarEx
                  buttonName={"Create Notification"}
                  buttonFlag={false}
                  search={true}
                />
              </div>
              <div>
                <Subcategory id={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
