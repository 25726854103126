import React, { useEffect, useState } from "react";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import Heading from "../../common/Heading";
import Sidebar from "../../common/SideBar";
import NavBarEx from "../../common/NavBarEx";
import { DataGrid } from "@mui/x-data-grid";
import redeye from "../../Assets/remove_red_eye.png";
import styles from "./Order.module.css";
import colors from "../../utils/helper";
import PreLoader from "../../common/PreLoader";
import Card from "../../common/Card";
import Backicon from "../../Assets/backicon.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function AllReturns() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([
    // { orderId: "11111111", customerId: "1111111111111" },
  ]);
  const columns = [
    { flex: 1, field: "id", headerName: "Sno." },
    { flex: 1, field: "_id", headerName: "Return ID" },
    { flex: 1, field: "orderId", headerName: "Order ID" },
    { flex: 1, field: "customer", headerName: "Customer ID" },
    { flex: 1, field: "reason", headerName: "Reason" },
    { flex: 1, field: "status", headerName: "Status" },
    { flex: 1, field: "paymentMode", headerName: "Payment mode" },
    { flex: 1, field: "createdAt", headerName: "Order date" },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
            navigate("/admin/returnDescription" + "/" + params.row._id);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            {!params?.row?.resolved && (
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={onClick}
              >
                <img src={redeye} style={{ width: "20px" }} />
              </button>
            )}
          </>
        );
      },
    },
  ];
  const fetchData = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getAllReturns
      );
      console.log(response.data.data);
      setRows(
        response.data.data.map((item, index) => {
          return {
            ...item,
            id: index + 1,
            orderId: item.orderId._id,
            customer: item?.customer?._id,
            createdAt: moment(item.createdAt),
          };
        })
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-lg-2 col-md-3 ${styles.left}`}
            style={{ paddingRight: "0px" }}
          >
            <Sidebar active={true} activeTabColor="Orders" />
          </div>

          <div
            className="col-lg-10 col-md-9 right py-3"
            style={{
              backgroundColor: colors.bgcolor,
              overflowY: "auto",
              height: "100vh",
              flexWrap: "wrap",
            }}
          >
            {loading && <PreLoader />}
            <div className="pt-3">
              <NavBarEx
                buttonFlag={false}
                buttonName={"Add new customer"}
                fontSize={"38px"}
                search={true}
              />
            </div>
            <div className="text-start pt-3">
              <img
                src={Backicon}
                alt="Back Icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="row pt-4">
              <div className="col-12">
                {/* <ReccentOrders /> */}
                <Card backgroundColor="#fff">
                  <div className="pt-4 d-flex justify-content-between align-items-center">
                    <Heading name="All Return Requests" paddingLeft="1%" />
                  </div>
                  <div
                    className="px-4 py-2"
                    style={{ width: "100%" }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      //   checkboxSelection
                      // hideFooter
                      style={{ border: "none", minHeight: 400, }}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllReturns;
