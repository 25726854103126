import React, { useEffect, useState } from "react";
import Sidebar from "../../common/SideBar";
import dustbin from "../../Assets/icon_trash.png";
import Card from "../../common/Card";
import NavBarEx from "../../common/NavBarEx";
import styles from "../../components/Table/TableRow.module.css";
import stylesCoupon from "./Coupon.module.css";
import CreateCouponform from "../../components/CreateCouponform";
import WarningPop from "../../common/WarningPop";
import Info from "../../common/Info";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export default function Coupon() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const handleInfoOpen = (e) => {
    // e.preventDefault();
    setPopupOpen(!popupOpen);
    console.log("info");
  };
  const PopUpclose = () => {
    setPopupOpen(false);
  };
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  const [data, setData] = useState([1, 20, 3, 4, 5, 6, 7]);
  useEffect(() => {}, [data]);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };

  const popcontainer = {
    height: "90%",
    width: "40%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const [getAllCoupon, setgetAllCoupon] = useState([]);
  useEffect(() => {
    getAllCouponApi();
    // deleteCouponOffer();
  }, []);

  const getAllCouponApi = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getAllOffer
      );
      setgetAllCoupon(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  // const [getDataOfMerchant, setGetDataOfMerchant] = useState([]);
  const deleteCouponOffer = async (couponId) => {
    // console.log(couponId,"***************");
    const url =
      endpoints.baseUrl + "admin/" + endpoints.deleteCoupon + couponId; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      console.log("Delete response:", response.data.data);
    } catch (error) {
      console.log("Delete error:", error);
      // toast.error("Internal Server Error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 5000, // Set the duration for how long the popup should be visible
      // });
    }
  };
  const deleteHandler = async (val) => {
    // console.log("from delete handler***********************", val);
    try {
      // Get the _id of the subadmin to be deleted
      const couponId = getAllCoupon[val]._id;

      // Make the delete API call
      console.log(couponId, "***************************************");
      await deleteCouponOffer(couponId);

      // Remove the subadmin from the local state
      const newData = getAllCoupon.filter((item, index) => index !== val);
      setgetAllCoupon(newData);
    } catch (error) {
      console.log("Delete Handler Error:", error);
      // toast.error("Internal Server Error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 5000, // Set the duration for how long the popup should be visible
      // });
    }

    setWarning(0);
  };
  return (
    <div>
      {/* <NavBar buttonName={"Create New Coupon"} buttonFlag={true} popUp={showPopUp} /> */}
      <div className={`container-fluid `}>
        <div className="row">
          {/* giving the grid of 2 to side nav */}
          <div
            className={`col-lg-2 col-md-3 ${stylesCoupon.left} ${stylesCoupon.sidePadding}`}
            style={
              open == 1 || warning == 1
                ? { filter: "blur(2px)", paddingRight: "0px" }
                : {}
            }
          >
            <Sidebar activeTabColor="Create coupons" />
          </div>
          {/* screen-content */}
          <div
            className={`col-lg-10 col-md-9 right pb-4 ${stylesCoupon.bgBlueColor}`}
            style={open == 1 || warning == 1 ? { filter: "blur(2px)" } : {}}
          >
            {loading && <PreLoader />}
            {/* navbar */}
            <div className="py-3">
              <NavBarEx
                search={true}
                buttonName={"Create Coupon"}
                buttonFlag={true}
                popUp={showPopUp}
                // width={"25%"}
                siderBarButton
              />
            </div>
            <div className="row">
              {getAllCoupon.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`col-xl-3 col-lg-4 col-md-6 col-sm-6  my-3 `}
                  >
                    <Card
                      width={"100%"}
                      height={98}
                      borderBottomRightRadius={"0"}
                      borderBottomLeftRadius={"0"}
                      bgColor={"#D9D9D9"}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          padding: 8,
                        }}
                        onClick={(event) => {
                          // calculateXandY(event);
                          setActive(index);
                          warningHandler();
                        }}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          className={styles.iconSize}
                          src={dustbin}
                          alt="dustbin"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        {item?.promoCode}
                      </div>
                    </Card>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderBottomRightRadius: "15px",
                        borderBottomLeftRadius: "15px",
                        height: "auto",
                        minHeight: 160,
                      }}
                    >
                      <div className="px-2 py-2">
                        <div className="d-flex justify-content-between">
                          <p className={stylesCoupon.font}>
                            Product name:
                            <span className={stylesCoupon.color}>
                              &nbsp;
                              {item?.productId?.name
                                ? item?.productId?.name
                                : "N/A"}
                            </span>
                          </p>

                          {/* <div
                            style={{
                              position: "relative",
                              zIndex: "1",
                              cursor: "pointer",
                            }}
                            onClick={handleInfoOpen}
                          >
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.625 5.125H10.375V6.875H8.625V5.125ZM8.625 8.625H10.375V13.875H8.625V8.625ZM9.5 0.75C4.67 0.75 0.75 4.67 0.75 9.5C0.75 14.33 4.67 18.25 9.5 18.25C14.33 18.25 18.25 14.33 18.25 9.5C18.25 4.67 14.33 0.75 9.5 0.75ZM9.5 16.5C5.64125 16.5 2.5 13.3587 2.5 9.5C2.5 5.64125 5.64125 2.5 9.5 2.5C13.3587 2.5 16.5 5.64125 16.5 9.5C16.5 13.3587 13.3587 16.5 9.5 16.5Z"
                                fill="#4F81C3"
                              />
                            </svg>
                            {popupOpen ? (
                              <div
                                className={` ${stylesCoupon.infopopcontainer}`}
                              >
                                <div>
                                  <Info />
                                </div>
                              </div>
                            ) : null}
                          </div> */}
                        </div>

                        <p className={stylesCoupon.font}>
                          Category:
                          <span className={stylesCoupon.color}>
                            &nbsp;
                            {item?.categoryId?.name
                              ? item?.categoryId?.name
                              : "N/A"}
                          </span>
                        </p>
                        {/* <p className={stylesCoupon.font}>
                          Shop:
                          <span className={stylesCoupon.color}>
                            &nbsp;
                            {item?.categoryId?.name
                              ? item?.shopId?.name
                              : "N/A"}
                          </span>
                        </p> */}
                        {/* <p className={stylesCoupon.font}>
                          Promo type:
                          <span className={stylesCoupon.color}>
                            &nbsp;{item?.promoType}
                          </span>
                        </p> */}
                        {item?.promoType === "flat" ? (
                          <p className={stylesCoupon.font}>
                            Discount Amount:
                            <span className={stylesCoupon.color}>
                              &nbsp;{item?.offerAmount}
                            </span>
                          </p>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className={stylesCoupon.font}>
                              Percentage off:
                              <span className={stylesCoupon.color}>
                                &nbsp;{item?.percentageOff}
                              </span>
                            </p>
                            <p className={stylesCoupon.font}>
                              Max Discount:
                              <span className={stylesCoupon.color}>
                                &nbsp;{item?.maxDisc}
                              </span>
                            </p>
                          </div>
                        )}
                        <p className={stylesCoupon.font}>
                          Expires on:
                          <p className={stylesCoupon.color}>
                            {moment(item?.validTill).toString()}
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {warning ? (
          <div
            style={{
              width: "35%",
              height: "50%",
              position: "absolute",
              left: "30%",
              top: "50%",
              // zIndex: 10,
            }}
          >
            <WarningPop
              delete={() => deleteHandler(active)}
              closePopUp={(val) => {
                warningHandler(val);
              }}
              message="Are you sure you want to delete the Promo Code?"
            />
          </div>
        ) : null}

        <div
          hidden={!open ? true : false}
          className={`max-vh-100 overflow-auto ${stylesCoupon.popcontainer}`}
        >
          {open ? (
            <CreateCouponform
              getAllCouponApi={getAllCouponApi}
              closePopUp={closePopUp}
            />
          ) : null}
          <div />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
