import React, { useState, useEffect } from "react";
import NavBarEx from "../../common/NavBarEx";
import Sidebar from "../../common/SideBar";
import Styles from "./SpecificMerchant.module.css";
import AgentOrdereSummary from "../../components/Merchants/AgentOrdereSummary";
import ProfileDetails from "../../components/Merchants/ProfileDetails";
import SalesGraph from "../../components/Merchants/SalesGraph";
import TopOrderTable from "../../components/Merchants/TopOrderTable";
import MerchantForm from "../../components/MerchantForm";
import Button from "../../common/Button";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MerchantEditForm from "../../components/MerchantEditForm";

const SpecificMerchant = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      // document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const params = useParams();
  const id = params.id;
  const [popupOpen, setPopupOpen] = useState(false);
  const showPopUp = (value) => {
    setPopupOpen(value);
  };
  const closePopUp = () => {
    setPopupOpen(0);
  };

  const [merchantAnalyticsApi, setMerchantAnalyticsApi] = useState([]);
  const merchantAnalytics = async (tempId) => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.merchantAnalytics + tempId
      );
      setMerchantAnalyticsApi(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [merchantInfo, setmerchantInfo] = useState([]);
  // const merchantId = dataFromApi[val]._id;
  const dataOfMerchant = async (tempId) => {
    // console.log(endpoints.baseUrl + "admin/" + endpoints.merchantbyId + tempId)
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.merchantbyId + tempId
      );
      setmerchantInfo(response.data.data);
      console.log("????????????????", response.data);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [totalOrdersData, setTotalOrdersData] = useState([]);
  const specificMerchantTotalOrders = async (tempId) => {
    // console.log(
    //   endpoints.baseUrl + "admin/" + endpoints.orderOfMerchantById + tempId + "/all"
    // );
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl +
          "admin/" +
          endpoints.orderOfMerchantById +
          tempId +
          "/all"
      );
      setTotalOrdersData(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async () => {
    // console.log(id)
    setLoading(true);
    await dataOfMerchant(id);
    await merchantAnalytics(id);
    await specificMerchantTotalOrders(id);

    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="container-fluid position-relative">
        <div className="row">
          <div
            className={` col-lg-2 col-md-3 pe-0 ${Styles.left}`}
            style={popupOpen == 1 ? { filter: "blur(2px)" } : {}}
          >
            <Sidebar activeTabColor="Merchants" />
          </div>
          <div
            className="col-lg-10 col-md-9 col-sm-12 py-4"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#EAF0FA",
            }}
          >
            {loading ? (
              <PreLoader />
            ) : (
              <div style={popupOpen == 1 ? { filter: "blur(2px)" } : {}}>
                <div
                // style={{display:"flex"}}
                >
                  {/* <NavBar /> */}
                  <NavBarEx />
                </div>
                {!merchantInfo ? (
                  <div>Partner not found</div>
                ) : (
                  <div className="row py-3">
                    <div className="col-12">
                      <AgentOrdereSummary
                        merchantAnalyticsApi={merchantAnalyticsApi}
                        merchantInfo={merchantInfo}
                      />
                    </div>
                    <div className="col-lg-4 col-md-5 col-sm-12 pt-5 h-100">
                      <ProfileDetails
                        showPopUp={showPopUp}
                        merchantInfo={merchantInfo}
                        fetchData={fetchData}
                      />
                    </div>
                    <div
                      className="col-lg-8 col-md-7 col-sm-12 mt-5 h-100"
                      style={{ overflowY: "auto", maxHeight: "525px" }}
                    >
                      <SalesGraph id={id} />
                      <div className="pt-3">
                        <TopOrderTable totalOrdersData={totalOrdersData} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {popupOpen ? (
        <div className={Styles.popcontainer}>
          <div style={{ position: "relative", zIndex: "20" }}>
            <MerchantEditForm
              editActive={id}
              closePopUp={closePopUp}
              fetchData={fetchData}
              isbuttonVisible
            />
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default SpecificMerchant;
