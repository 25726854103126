import React from "react";
import "./terms.css";
function PrivacyPolicy() {
  return (
    <div className="container" style={{}}>
      <div className="row">
        <div
          className="col-lg-6 col-md-6 col-12 p-3 offset-md-3 slip"
          style={{
            minHeight: "100vh",
          }}
        >
          <h3>Privacy Policy</h3>
          <p style={{ fontWeight: "bold" }}>
            Effective Date: 1st October, 2023
          </p>

          <p>
            My Mega Mall ("we," "us," or "our") respects your privacy and is
            committed to protecting your personal information. This Privacy
            Policy outlines how we collect, use, disclose, and safeguard your
            personal data. By accessing or using our mobile application ("the
            App"), you agree to the practices described in this policy.
          </p>
          <p className="heading">1. Information We Collect:</p>
          <p>We may collect the following types of information:</p>
          <p className="subheading">1.1 Personal Information:</p>
          <p>
            When you use the App, we may collect personal information you
            provide voluntarily, such as your name, email address, postal
            address, phone number, or payment information when making a
            purchase.
          </p>
          <p className="subheading">1.2 Device Information:</p>
          <p>
            We may automatically collect information about your device,
            including its type, model, operating system, and unique device
            identifiers.
          </p>
          <p className="subheading">1.3 Usage Data:</p>
          <p>
            We collect data related to your interactions with the App, including
            your browsing history, the pages you visit, and the actions you take
            within the App.
          </p>
          <p className="heading">2. How We Use Your Information:</p>
          <p>We use your information for the following purposes:</p>
          <p className="subheading">2.1 Providing Services:</p>
          <p>
            To provide, personalise, and improve the App, including processing
            orders, managing accounts, and delivering products or services.
          </p>
          <p className="subheading">2.2 Communication:</p>
          <p>
            To communicate with you regarding your account, orders, and
            important updates. We may also send promotional or marketing
            communications with your consent, which you can opt out of at any
            time.
          </p>
          <p className="subheading">2.3 Analytics:</p>
          <p>
            To analyse App usage, conduct research, and improve our services. We
            use aggregated and anonymized data for these purposes.
          </p>
          <p className="subheading">2.4 Security:</p>
          <p>
            To detect and prevent fraud, unauthorised access, and other illegal
            activities.
          </p>
          <p className="heading">3. Information Sharing:</p>
          <p>We may share your data with the following parties:</p>
          <p className="subheading">
            3.1 Payment Processors and Shipping Companies:
          </p>
          <p>
            We may disclose your information to payment processors and shipping
            companies to facilitate your orders.
          </p>
          <p className="subheading">3.2 Legal Compliance:</p>
          <p>
            We may share your data to comply with legal obligations, respond to
            lawful requests, protect our rights, and enforce our policies.
          </p>
          <p className="heading">4. Data Retention:</p>
          <p>We keep your data until you explicitly request its deletion.</p>
          <p className="heading">5. Data Transfer:</p>
          <p>
            We do not transfer user data to any third party in any other
            country. We only operate in India.
          </p>
          <p className="heading">6. User Rights:</p>
          <p>
            You can update your profile from the profile section. You also have
            the option to delete your account without contacting our support.
            If, however, you want to reach out to us for any reason, please
            contact us at info.mymegamall@gmail.com.
          </p>
          <p className="heading">7. Children's Privacy:</p>
          <p>
            The App is not intended for individuals under 18 years of age, and
            we do not knowingly collect data from children.
          </p>
          <p className="heading">8. Changes to this Privacy Policy:</p>
          <p>
            We may update this policy to reflect changes in our data practices.
            We will notify you of significant changes through the App or other
            means.
          </p>
          <p className="heading">9. Contact Us:</p>
          <p>
            If you have questions or concerns about this Privacy Policy or your
            data, please contact us at the contact information given below.
          </p>
          <p></p>
          <p>
            By using the App, you consent to the practices outlined in this
            Privacy Policy.
          </p>
          <p></p>
          <p>AYNP Systems</p>
          <p>4214, SECOND FLOOR, SADAR BAZAR, DELHI - 110006</p>
          <p>info.mymegamall@gmail.com</p>
          <p>+91-9205603401</p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
