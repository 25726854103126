import React, { useState, useEffect } from "react";
import Heading from "../../common/Heading";
import Sidebar from "../../common/SideBar";
import NavBarEx from "../../common/NavBarEx";
import styles from "./Order.module.css";
import colors from "../../utils/helper";
import ReccentOrders from "../../components/Orders/ReccentOrders";
import ReturnRequest from "../../components/Orders/ReturnRequest";
import PreLoader from "../../common/PreLoader";
import Card from "../../common/Card";
import { DataGrid } from "@mui/x-data-grid";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import moment from "moment";
import { Button } from "@mui/material";
function Support() {
  const [loading, setLoading] = useState(true);
  const columns = [
    { field: "id", headerName: "Sno", flex: 1 },
    {
      field: "problemCategory",
      headerName: "Category",
      flex: 1
    },
    {
      field: "problemDescription",
      headerName: "Problem description",
      flex: 1,
      minWidth: 400,
    },
    { field: "priority", headerName: "Priority", flex: 1 },
    { field: "resolved", headerName: "Resolved", flex: 1, type: "boolean" },
    { field: "userName", headerName: "Customer Name", flex: 1 },
    { field: "userPhone", headerName: "Customer Phone No.", flex: 1 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
            const response = await axiosInstance.patch(
              endpoints.baseUrl +
              "admin/" +
              endpoints.patchSupportTicket +
              "?ticketId=" +
              params.row._id
            );
            console.log(response.data);
            if (response.data.code === 200) {
              getData();
            }
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            {!params?.row?.resolved && (
              <Button onClick={onClick}>Resolve</Button>
            )}
          </>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getSupportTickets
      );
      console.log(response.data.data);
      let data = response.data.data.map((item, index) => {
        return {
          ...item,
          id: index + 1,
          userName: item?.userId?.name || "-",
          userPhone: item?.userId?.mobileNumber,
          createdAt: moment(item.createdAt),
        };
      });

      setRows(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-lg-2 col-md-3 ${styles.left}`}
            style={{ paddingRight: "0px" }}
          >
            <Sidebar activeTabColor="Support" />
          </div>

          <div
            className="col-lg-10 col-md-9 right py-3"
            style={{
              backgroundColor: colors.bgcolor,
              overflowY: "auto",
              height: "100vh",
              flexWrap: "wrap",
            }}
          >
            {loading && <PreLoader />}
            <div className="pt-3">
              <NavBarEx
                buttonFlag={false}
                buttonName={"Add new customer"}
                fontSize={"38px"}
                search={true}
              />
            </div>
            <div className="row pt-4">
              <div className="col-12">
                <Card backgroundColor="#fff">
                  <div className="pt-4 d-flex justify-content-between align-items-center">
                    <Heading name="Support" paddingLeft="1%" />
                  </div>
                  <div
                    className="px-4 py-2"
                    style={{ width: "100%" }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      //   checkboxSelection
                      style={{ border: "none", minHeight: "70vh", }}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
