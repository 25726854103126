import React, { useEffect, useState } from "react";
import Sidebar from "../../common/SideBar";
// import NavBar from "../../common/NavBar";
import styles from "./HappyWall.module.css";
import Card from "../../common/Card";
import dustbin from "../../Assets/icon_trash.png";
import NavBarEx from "../../common/NavBarEx";
import TShirt from "../../Assets/T-Shirt.png";
import colors from "../../utils/helper";
import Heading from "../../common/Heading";
import Newproduct from "../../components/Supllier/NewProduct";
import WarningPop from "../../common/WarningPop";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import placeHolder from "../../Assets/placeholder.png";
import "react-toastify/dist/ReactToastify.css";
const HappyWall = () => {
  const fetchForHappyWall = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.getAllHappywall;
    try {
      const response = await axiosInstance.get(url);
      setHappywall(response.data.data.review);
      // console.log(response.data.data+"************** Happy wall-------->")
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  useEffect(() => {
    fetchForHappyWall();
  }, []);
  const [data, setData] = useState([1, 20, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const warningHandler = (val) => {
    // console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  useEffect(() => {}, [data]);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };

  const deleteElement = (index, ar) => {
    for (let i = 0; i < ar.length; i++) {
      if (i < index) {
        // console.log("delete index ->", i);
        continue;
      }
      ar[i] = ar[i + 1];
    }
    ar.pop();
    // data=[...ar]
    console.log(data);
    return ar;
  };
  const [happywall, setHappywall] = useState([]);
  const deleteHandler = (index) => {
    // console.log("from delete handler product screen", index);
    deleteFromHappyWall(happywall[index]._id);
    setWarning(0);
  };

  const deleteFromHappyWall = async (id) => {
    try {
      // Make the DELETE request to the API
      const response = await axiosInstance.delete(
        endpoints.baseUrl + `admin/${endpoints.deleteFromHappywall}`,
        {
          data: {
            review: id,
          },
        }
      );
      console.log(response.data.message);
      // If successful, remove the deleted category from the categoriesFromApi state
      setHappywall((prevState) =>
        prevState.filter((category) => category._id !== id)
      );
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };
  // shivaam don
  const [popupOpen, setPopupOpen] = useState(false);
  const handleVerifyClick = (e) => {
    // e.preventDefault();
    setPopupOpen(true);
  };
  const PopUpclose = () => {
    setPopupOpen(false);
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  return (
    <div>
      <div className=" container-fluid">
        <div className="row min-vh-100">
          <div className={` col-lg-2 col-md-3 pe-0 ${styles.left}`}>
            <div style={popupOpen ? { filter: "blur(2px)" } : {}}>
              <Sidebar flag={true} activeTabColor="Happy wall" />
            </div>
          </div>
          <div
            className="col-lg-10 col-md-9 col-sm-12 col-12"
            style={{
              overflowY: "auto",
              maxHeight: "100vh",
              backgroundColor: "#EAF0FA",
            }}
          >
            <div style={popupOpen ? { filter: "blur(2px)" } : {}}>
              {loading && <PreLoader />}
              <div className="py-3">
                <NavBarEx
                  buttonName={"Create Notification"}
                  buttonFlag={false}
                  search={true}
                />
              </div>
              <div className="ps-1 pt-3">
                <Heading name="Happy Wall" />
              </div>
              <div className="row pt-3">
                {happywall.length > 0 ? (
                  happywall.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="col-lg-3 col-md-3 col-sm-6 my-3"
                      >
                        <Card bgColor="#fff" width="100%">
                          <div
                            className={`${styles.container}`}
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{
                                height: "350px",
                                // width: "250px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                style={{
                                  cursor: "pointer",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                src={
                                  item?.images[0] ? item.images[0] : placeHolder
                                }
                              />
                              <div
                                style={{
                                  backgroundColor: "#D6E4F7",
                                  opacity: "80%",
                                  position: "absolute",
                                  height: "50px",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                }}
                                className="d-flex justify-content-between align-items-center w-100 px-2"
                              >
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item?.productId?.name || "N/A"}
                                </p>
                                <img
                                  onClick={(event) => {
                                    setActive(index);
                                    warningHandler();
                                  }}
                                  width={15}
                                  height={20}
                                  src={dustbin}
                                  alt="Trash Icon"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ flex: 1, justifyContent: "center" }}>
                    No Reviews Present
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "30%",
            top: "50%",
            // zIndex: 10,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to accept the request?"
          />
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default HappyWall;
