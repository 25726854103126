import React, { useState, useEffect } from "react";
import Backicon from "../Assets/backicon.png";
import Heading from "../common/Heading";
import Button from "../common/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../utils";
import endpoints from "../utils/endpoints";

const MerchantEditForm = (props) => {
  const fetchMerchantDetails = async (merchantId) => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.merchantbyId + merchantId;
    try {
      const response = await axiosInstance.get(url);
      const merchantData = response.data.data;

      setName(merchantData.name);
      setEmail(merchantData.email);
      setMobileNumber(merchantData.mobileNumber);
      setShopIdOption(merchantData.shopId._id);
      setSelectedOptionCategory(merchantData.categoryId._id);
      setType(merchantData.type);
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    }
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [type, setType] = useState("");
  const [shopId, setShopId] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const handleSelectTypeChange = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    if (props.editActive) {
      fetchMerchantDetails(props.editActive);
    }
    fetchCategories();
    fetchDataOfAllShops();
  }, [props.editActive]);

  const clickhandler = async (e) => {
    e.preventDefault();

    console.log("Name:", name);
    console.log("Email", email);
    console.log("mobileNumber", mobileNumber);
    console.log("ShopId", ShopIdOption);
    console.log("CategryId", selectedOptionCategory);
    if (validateForm()) {
      await updateMerchant(selectedOptionCategory, ShopIdOption);
      props.closePopUp();
    }
  };

  const temp = () => {
    props.fetchData();
  };

  const updateMerchant = async (tempCategoryId, tempShopId) => {
    const url =
      endpoints.baseUrl +
      "admin/" +
      endpoints.updateMerchant +
      props.editActive;
    console.log(url);

    try {
      const response = await axiosInstance.patch(url, {
        name: name,
        mobileNumber: mobileNumber,
        email: email,
        categoryId: tempCategoryId,
        shopId: tempShopId,
        type: type,
        // Add more fields as needed for the update
      });

      props.fetchData();

      // Handle successful update response here
      // console.log('Merchant data updated successfully!', response.data.data);
    } catch (error) {
      // Handle error here
      console.error("Error updating merchant data:", error.message);
    }
  };
  const closePopUpHandeler = () => {
    props.closePopUp();
  };
  const options = ["Option 1", "Option 2", "Option 3"];
  const [selectedOptionCategory, setSelectedOptionCategory] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOptionCategory(selectedValue);
    console.log("Category Id:", selectedValue);
  };
  const ShopIdoptions = ["Option 1", "Option 2", "Option 3"];
  const [ShopIdOption, setShopIdOption] = useState("");

  const handleSelectShopIdChange = (event) => {
    const ShopIdValue = event.target.value;
    setShopIdOption(ShopIdValue);
    console.log("ShopId Option:", ShopIdValue);
  };

  const findCategoryIdByName = (name) => {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].name === name) {
        return categories[i]._id;
      }
    }
    return null;
  };
  const findShopIdByName = (shopName) => {
    for (let i = 0; i < dataOfAllShops.length; i++) {
      if (dataOfAllShops[i].name === shopName) {
        return dataOfAllShops[i]._id;
      }
    }
    return null;
  };

  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct;
    // console.log("**", url);
    try {
      const response = await axiosInstance.get(url);
      setCategories(response.data.data);
      // console.log(response.data.data,"***************************");
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [dataOfAllShops, setdataOfAllShops] = useState([]);
  const fetchDataOfAllShops = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allShops
      );
      setdataOfAllShops(response.data.data);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const restrictToRange = (event) => {
    const value = parseInt(event.target.value);

    if (isNaN(value) || value < 1 || value > 9999999999) {
      setMobileNumber("");
    } else {
      setMobileNumber(value.toString());
    }
  };
  const validateForm = () => {
    let valid = true;
    console.log(typeof ShopIdOption, ShopIdOption);
    // Validate Name field
    if (name.trim() === "") {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    // Validate Email field
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      setEmailError("");
    }

    // Validate Contact field
    if (mobileNumber.trim() === "") {
      setMobileError("Contact number is required");
      valid = false;
    } else {
      setMobileError("");
    }

    // Validate Shop Id field
    if (ShopIdOption.trim() === "") {
      setShopIdError("Please select a Shop Id");
      valid = false;
    } else {
      setShopIdError("");
    }

    // Validate Category Id field
    if (selectedOptionCategory.trim() === "") {
      setCategoryIdError("Please select a Category Id");
      valid = false;
    } else {
      setCategoryIdError("");
    }

    return valid;
  };
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [shopIdError, setShopIdError] = useState("");
  const [categoryIdError, setCategoryIdError] = useState("");
  return (
    <div
      className="bg-white"
      style={{
        overflow: "auto",
        height: "100vh",
        border: "1px solid #5082C4 ",
      }}
    >
      <div>
        <div className="px-4 py-3 ">
          <div className="row ">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    className="pe-3"
                    src={Backicon}
                    alt="Back Icon"
                    onClick={closePopUpHandeler}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Heading name={name} fontSize="25px" fontWeight="500" />
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6 col-sm-6 text-start text-sm-end pt-4 pt-sm-0">
              {props.isbuttonVisible ? (
                <Button
                  name="Upgrade as shop owner"
                  width="100%"
                  borderRadius="10px"
                  fontSize="15px"
                />
              ) : null}
            </div> */}
          </div>

          <form>
            <div
              className="row pt-4"
              // style={{ overflowY: "auto", maxHeight: "500px" }}
            >
              {/* <div className="col-6 pt-3">
                <label
                  style={{ fontWeight: 500, fontSize: 15, display: "block" }}
                  className="pb-2"
                >
                  Log Id
                </label>
                <select
                  value={LogIdOption}
                  onChange={handleSelectLogIdChange}
                  style={{
                    display: "block",
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    display: "block",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="ps-2 py-2 mt-1"
                >
                  <option value="" disabled className="d-none">
                    select Log Id
                  </option>
                  {LogIdoptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="col-12 pt-4">
                <label
                  style={{
                    fontWeight: 500,
                    paddingBottom: "10px",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "start",
                  }}
                  className="pb-2"
                >
                  Name
                </label>
                <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className=" ps-3 py-2"
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                />
                {nameError && (
                  <small className="error-text text-danger">{nameError}</small>
                )}
              </div>
              <div className="col-12 pt-4">
                <label
                  style={{
                    fontWeight: 500,
                    paddingBottom: "10px",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  Email ID
                </label>
                <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    display: "block",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="mt-2 ps-3 py-2"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <small className="error-text text-danger">{emailError}</small>
                )}
              </div>
              <div className="col-12 pt-4">
                <label
                  style={{
                    fontWeight: 500,
                    paddingBottom: "10px",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  Contact
                </label>
                <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    display: "block",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="mt-2 ps-3 py-2"
                  type="number"
                  value={mobileNumber}
                  onChange={restrictToRange}
                />
                {mobileError && (
                  <small className="error-text text-danger">
                    {mobileError}
                  </small>
                )}
              </div>
              <div className="col-12 pt-4">
                <label
                  style={{ fontWeight: 500, fontSize: 15, display: "block" }}
                  className="pb-2"
                >
                  Type
                </label>
                <select
                  value={type}
                  onChange={handleSelectTypeChange}
                  style={{
                    display: "block",
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="ps-2 py-2 mt-1"
                >
                  <option value="" disabled className="d-none">
                    Select Type
                  </option>
                  <option value="OWNER">Owner</option>
                  <option value="AGENT">Agent</option>
                  <option value="NULL">NULL</option>
                </select>
              </div>
              <div className="col-12 pt-4">
                <label
                  style={{ fontWeight: 500, fontSize: 15, display: "block" }}
                  className="pb-2"
                >
                  Shop Id
                </label>
                <select
                  value={ShopIdOption}
                  onChange={handleSelectShopIdChange}
                  style={{
                    display: "block",
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="ps-2 py-2 mt-1"
                >
                  <option value="" disabled className="d-none">
                    Select Shop Id
                  </option>
                  {dataOfAllShops.map((option, index) => (
                    <option key={index} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {shopIdError && (
                  <small className="error-text text-danger">
                    {shopIdError}
                  </small>
                )}
              </div>
              <div className="col-12 pt-4">
                <label
                  style={{ fontWeight: 500, fontSize: 15, display: "block" }}
                  className="pb-2"
                >
                  Category Id
                </label>
                <select
                  value={selectedOptionCategory}
                  onChange={handleSelectChange}
                  style={{
                    display: "block",
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="ps-2 py-2 mt-1"
                >
                  <option value="" disabled className="d-none">
                    Select Category Id
                  </option>
                  {categories.map((option, index) => (
                    <option key={index} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {categoryIdError && (
                  <small className="error-text text-danger">
                    {categoryIdError}
                  </small>
                )}
              </div>
            </div>
            <div className="mt-5 text-center" onClick={clickhandler}>
              <Button
                name="Update"
                width="15%"
                borderRadius="10px"
                fontSize="15px"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MerchantEditForm;
