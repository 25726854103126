import React from "react";
import "./terms.css";
function CancellationPolicy() {
  return (
    <div className="container" style={{}}>
      <div className="row">
        <div
          className="col-lg-6 col-md-6 col-12 p-3 offset-md-3 slip"
          style={{
            minHeight: "100vh",
          }}
        >
          <h3>Cancellation and Refund Policy</h3>
          <p style={{ fontWeight: "bold" }}>
            Effective Date: 1st October, 2023
          </p>
          <p className="heading">1. Returns and Exchanges</p>
          <p>
            At MyMegaMall, we strive to provide you with a seamless shopping
            experience. We understand that there may be instances where you need
            to return or exchange a product. The eligibility and terms for
            returns and exchanges are outlined as follows:
          </p>
          <p></p>
          <p>
            a. Return Time: Each product listed on our platform will specify a
            return time frame, which may vary from product to product. Please
            refer to the individual product's description for details on the
            return period.
          </p>
          <p className="heading">2. Eligibility for Refunds</p>
          <p>
            We offer complete refunds for genuine return requests within the
            specified return time frame for the product. To be eligible for a
            refund, the following conditions must be met:
          </p>
          <p></p>
          <p>
            - The product must be in its original condition, unused, and in the
            same packaging as when you received it.
          </p>
          <p>- Any tags, labels, or seals should remain intact.</p>
          <p>
            - The product should not be damaged, soiled, or altered in any way.
          </p>
          <p className="heading">3. Refund Processing Time</p>
          <p>
            Once we receive a valid refund request and confirm that the product
            meets the eligibility criteria, we will initiate the refund process.
            Our standard refund processing time is 7 days from the date of
            initiating the request.
          </p>
          <p className="heading">4. How to Initiate a Return or Exchange</p>
          {/* <p>You can initiate a return or exchange in the following ways:</p> */}
          <p></p>
          {/* <p>
            a. From Your Application: If you wish to return or exchange a
            product, you can do so directly from your MyMegaMall mobile
            application. Simply follow the instructions provided within the app
            to initiate the process.
          </p> */}
          <p>
            To initiate a return, please follow these steps:
            <ol type="1">
              <li>
                Select the order you want to return from order section in the
                app.
              </li>
              <li>
                Click the return order button and follow the instructions.
              </li>
              <li>
                Video Evidence: Record a clear video showcasing the product's
                condition, including any defects or issues. This video is
                essential for processing your return.
              </li>
              <li>
                Email Submission: Send the video to info.mymegamall@gail.com
                with a detailed description of the reason for return.
              </li>
              <li>
                Return Approval: Our team will review the video and respond with
                a return approval status within 3-5 business days.
              </li>
              <li>
                Return Procedure: If approved, we will provide instructions on
                how to proceed with the return.
              </li>
            </ol>
          </p>
          <p>
            Note: Returns will not be accepted without the required video
            evidence. Please ensure the video clearly shows the product's
            condition to avoid any delays or rejections. If you have any
            questions or concerns, feel free to reach out to us at
            info.mymegamall@gail.com.
          </p>
          <p></p>
          {/* <p>
            b. Contact Us: If you encounter any difficulties or have questions
            about the return or exchange process, you can contact our support
            team at info.mymegamall@gmail.com. Our customer support team will
            guide you through the process and provide any necessary assistance.
          </p> */}
          <p className="heading">5. Refunds for Cancelled Orders</p>
          <p>
            In the event that you cancel an order before it has been processed
            and shipped, we will initiate a refund as soon as possible. Our
            standard processing time for such refunds is 7 days.
          </p>
          <p></p>
          <p>
            Please note that the mode of refund will be the same as the mode of
            payment used during the purchase. If, for any reason, the original
            mode of payment is not feasible, alternative methods of refund will
            be discussed with you.
          </p>
          <p className="heading">6. Contact Information</p>
          <p>
            If you have any questions or concerns regarding our Cancellation and
            Refund Policy, please contact us at:
          </p>
          <p></p>
          <p>Address:</p>
          <p>AYNP SYSTEMS</p>
          <p>4214, SECOND FLOOR, SADAR BAZAR, DELHI - 110006</p>
          <p></p>
          <p>Email: mmm.prabandhak@gmail.com</p>
          <p></p>
          <p>Phone: 9205603401</p>
          <p></p>
          <p>
            By using the App and making a purchase, you acknowledge that you
            have read, understood, and agree to this Cancellation and Refund
            Policy.
          </p>

          <p>MyMegaMall</p>
        </div>
      </div>
    </div>
  );
}

export default CancellationPolicy;
