import React, { useState, useEffect } from "react";
import Backicon from "../../Assets/backicon.png";
import Button from "../../common/Button";
import Heading from "../../common/Heading";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import DropDown from "../../common/DropDown";
import dropimg from "../../Assets/dropimg.png";
import deleteicon from "../../Assets/trash_icon.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ShopForm = (props) => {
  const findNameByAgentId = (agentId) => {
    const agent = allMerchantsFromApi.find((item) => item._id === agentId);
    return agent ? agent.name : null;
  };
  
  const [allMerchantsFromApi, setAllMerchantsFromApi] = useState([]);
  useEffect(() => {
    fetchDataForAllMerchants();
    fetchCategories();
    fetchData();
  }, []);
  useEffect(()=>{
    if (props.editActive ) {
      fetchShopDetails(props.editActive);
    } else {
      // If no shopId is provided, reset the form fields
      resetFormFields();
    }
  },[allMerchantsFromApi])
  const fetchShopDetails = async (shopId) => {
    const url = endpoints.baseUrl + "admin/" + endpoints.updateShop + shopId;
    try {
      const response = await axiosInstance.get(url);
      const shopData = response.data.data;
      const ownerName=findNameByAgentId(shopData?.owner)
      setName(shopData.name);
      setOwner(ownerName); 
      const agentNamesArray=findNamesByAgentIds(shopData.agents,allMerchantsFromApi)
      setAddNewAgent(agentNamesArray);
      const categoryNames = shopData.categories.map((category) => category.name);
      setCategories(categoryNames);
    } catch (error) {
      console.error("Error fetching shop details:", error);
    }
  };

 
  function findNamesByAgentIds(agentIdsArray, dataArray) {
    const agentIDMapping = {};
    dataArray.forEach(item => {
      agentIDMapping[item._id] = item.name;
    });
    const namesArray = [];
    agentIdsArray.forEach(agentId => {
      if (agentIDMapping.hasOwnProperty(agentId)) {
        namesArray.push(agentIDMapping[agentId]);
      } else {
        namesArray.push(null);
      }
    });
  
    return namesArray;
  }
  
  
  
  const [dataFromApi, setdataFromApi] = useState([]);
  const resetFormFields = () => {
    setName("");
    setOwner("");
    setAddNewAgent([]);
    setCategories([]);
  };

  
  const [shopIdData, setShopIdData] = [];
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allMerchants
      );
      setdataFromApi(response.data);
      response.data.data.map((item, index) => { });
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  function findIdsByCategoryNames(categoriesArray, dataArray) {
    const categoryIDMapping = {};
    dataArray.forEach(item => {
      categoryIDMapping[item.name] =item._id;
    });
  
    // Initialize a new array to store the IDs
    const idsArray = [];
  
    // Find and store the IDs for each category in the categories array
    categoriesArray.forEach(category => {
      if (categoryIDMapping.hasOwnProperty(category)) {
        idsArray.push(`${categoryIDMapping[category]}`);
      } else {
        idsArray.push(null); 
      }
    });
    // console.log(idsArray,"****************categories names")
    return idsArray;
  }
  function findIdsByAgentNames(agentsArray, dataArray) {

    // Create a dictionary to map category names to their IDs from the data array
    const agentIDMapping = {};
    dataArray.forEach(item => {
      agentIDMapping[item.name] =item._id;
    });
  
    // Initialize a new array to store the IDs
    const idsArray = [];
  
    // Find and store the IDs for each category in the categories array
    agentsArray.forEach(agent => {
      if (agentIDMapping.hasOwnProperty(agent)) {
        idsArray.push(`${agentIDMapping[agent]}`);
      } else {
        idsArray.push(null); 
      }
    });
  
    return idsArray;
  }
  const findCategoryIdByName = (name) => {
    for (let i = 0; i < allMerchantsFromApi.length; i++) {
      if (allMerchantsFromApi[i].name === name) {
        return allMerchantsFromApi[i]._id;
      }
    }
    return null;
  };
  const updateShop = async (categoriesId,agentsId,idOfAgentAsOwner) => {
    const url = endpoints.baseUrl + "admin/" + endpoints.updateShop+props.editActive; // Replace with your API endpoint URL

    // console.log(url);
    const data = {
      name: name,
      agents: agentsId,
      owner:idOfAgentAsOwner,
      categories: categoriesId,
      
    }; 
    try {
      const response = await axiosInstance.patch(url, data);
      props.fetchDataOfAllShops()
      // window.location.reload()
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
      // setError(err.message || 'Something went wrong.');
    }
  };

  const [owner, setOwner] = useState("");
  const OwnerOpations = ["owner 1", "owner 2", "owner 3"];
  const handleSelectOwnerChange = (event) => {
    const OwnerValue = event.target.value;
    setOwner(OwnerValue);
    // console.log("ShopId Option:", OwnerValue); 
  };
  const [name, setName] = useState("");
  const handleSubmit = () => {
    // Gather the form data
    const subcategoryInputs = document.getElementsByClassName("add-new-agent");
    const subcategoryValues = Array.from(subcategoryInputs).map(
      (input) => input.value
    );
    // console.log("categories options:", categories);
    // console.log("addNewAgent options:", addNewAgent);
    // console.log("name", name);
    // console.log("owner", owner);
    const idArrayOfCategories=findIdsByCategoryNames(categories,categoriesFromApi)
    const idArrayOfAgents=findIdsByAgentNames(addNewAgent,allMerchantsFromApi)
    const idOfAgentAsOwner=findCategoryIdByName(owner)
    console.log(idOfAgentAsOwner,"*****AGENT AS OWNER*********")
    updateShop(idArrayOfCategories, idArrayOfAgents,idOfAgentAsOwner);
    props.closePopUp();


  };
  const closePopUpHandeler = () => {
    props.closePopUp();
  };

  const [categoriesFromApi, setCategoriesFromApi] = useState([])
  const fetchCategories = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct;
    try {
      const response = await axiosInstance.get(url);
      setCategoriesFromApi(response.data.data);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  
  const fetchDataForAllMerchants = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allMerchants
      );
      setAllMerchantsFromApi(response.data.data);
      console.log("all merchant api in ShopForm");
      response.data.data.map((item, index) => { });
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const AgentsOpations = ["Agent 1", "Agent 2", "Agent 3"];
  const categoriesOpations = ["category 1", "category 2", "category 3"];
  const [addNewAgent, setAddNewAgent] = useState([]);
  const addNewAgentS = () => {
    setAddNewAgent([...addNewAgent, ""]);
  };

  const handleAddNewAgentsChange = (index, value) => {
    const updatedAddNewAgent = [...addNewAgent];
    updatedAddNewAgent[index] = value;
    setAddNewAgent(updatedAddNewAgent);
  };
  const deleteAllAddNewAgent = () => {
    setAddNewAgent([]);
  };

  const deleteAgentElement = (index, ar) => {
    for (let i = index; i < ar.length - 1; i++) {
      ar[i] = ar[i + 1];
    }
    ar.pop();
    return ar;
  };
  const deleteHandlerAgent = (val) => {
    let ar = deleteAgentElement(val, addNewAgent);
    setAddNewAgent([...ar]);
  };
  const [categories, setCategories] = useState([]);
  const addCategory = () => {
    setCategories([...categories, ""]);
  };

  const handleSubcategoryChange = (index, value) => {
    const updatedcategories = [...categories];
    updatedcategories[index] = value;
    setCategories(updatedcategories);
  };
  const deleteAllcategories = () => {
    setCategories([]);
  };
  const deleteCategoryElement = (index, ar) => {
    for (let i = index; i < ar.length - 1; i++) {
      ar[i] = ar[i + 1];
    }
    ar.pop();
    return ar;
  };
  const deleteCategoryHandler = (val) => {
    let ar = deleteCategoryElement(val, categories);
    setCategories([...ar]);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          
        }}
      >
        <div
          className="container-fluid px-3 py-3"
          style={{ border: "1px solid #5082C4 ", backgroundColor: "#fff" }}
        >
          <div className="d-flex align-items-center">
            <div onClick={closePopUpHandeler}>
              <img
                src={Backicon}
                className="pe-3"
                alt="Back Icon"
                style={{ cursor: "pointer" }}
              />
            </div>
            <Heading name={name} fontSize="25px" fontWeight="500" />
          </div>
          <div
            className="row pt-4"
            style={{ overflowY: "auto", height: "100%" }}
          >
            <div className="col-12 pt-3">
              <label style={{ fontWeight: 500, fontSize: 16 }}>Name</label>
              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                className="mt-2 ps-3 py-2"
                type="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-12  pt-3">
              <label
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  display: "block",
                }}
                className="pb-2"
              >
                Owner
              </label>
              <select
                value={owner}
                onChange={handleSelectOwnerChange}
                style={{
                  display: "block",
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                className="ps-2 py-2 mt-1"
              >
                <option value="" disabled className="d-none">
                  Select owner
                </option>
                {allMerchantsFromApi.map((option, ownerIndex) => (
                  <option key={ownerIndex} value={option.name}>
                    {option.name} ({option.mobileNumber})
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12">
              {addNewAgent.map((addNewAgent, agentIndex) => (
                <div className="col-12 pt-3" key={agentIndex}>
                  <div className="d-flex align-items-center justify-content-between">
                    <label style={{ fontWeight: 500, fontSize: 16 }}>{`Agent ${agentIndex + 1
                      }`}</label>
                    <div
                      onClick={() => {
                        deleteHandlerAgent(agentIndex);
                      }}
                    >
                      <img src={deleteicon} alt="remove" />
                    </div>
                  </div>

                  <select
                    value={addNewAgent}
                    onChange={(e) =>
                      handleAddNewAgentsChange(agentIndex, e.target.value)
                    }
                    style={{
                      display: "block",
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                    className="ps-2 py-2 mt-1 add-new-agent"
                  >
                    <option value="" disabled className="d-none">
                      Select Agent Id
                    </option>
                    {allMerchantsFromApi.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name} ({option.mobileNumber})
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              <div className="mt-4 d-flex align-items-center justify-content-between">
                <div
                  onClick={addNewAgentS}
                  className="text-center text-sm-start"
                >
                  <Button
                    name="Add new Agent"
                    width="fit-content"
                    borderRadius="12px"
                    fontSize="15px"
                  />
                </div>
                <div
                  onClick={deleteAllAddNewAgent}
                  className="text-center text-sm-start "
                >
                  <Button
                    name="Delete all"
                    width="fit-content"
                    borderRadius="12px"
                    fontSize="15px"
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-12">
              {categories.map((category, index) => (
                <div className="col-12 pt-3" key={index}>
                  <div className="d-flex align-items-center justify-content-between">
                    <label
                      style={{ fontWeight: 500, fontSize: 16 }}
                    >{`category ${index + 1}`}</label>
                    <div
                      onClick={() => {
                        deleteCategoryHandler(index);
                      }}
                    >
                      <img src={deleteicon} alt="remove" />
                    </div>
                  </div>

                  <select
                    value={category}
                    onChange={(e) =>
                      handleSubcategoryChange(index, e.target.value)
                    }
                    style={{
                      display: "block",
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="ps-2 py-2 mt-1 add-new-agent"
                  >
                    <option value="" disabled className="d-none">
                      Select category Id
                    </option>
                    {categoriesFromApi.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              <div className="mt-4 d-flex align-items-center justify-content-between">
                <div onClick={addCategory} className="text-center text-sm-start">
                  <Button
                    name="Add categories"
                    width="fit-content"
                    borderRadius="12px"
                    fontSize="15px"
                  />
                </div>
                <div
                  onClick={deleteAllcategories}
                  className="text-center text-sm-start"
                >
                  <Button
                    name="Delete all"
                    width="fit-content"
                    borderRadius="12px"
                    fontSize="15px"
                  />
                </div>
              </div>
            </div> */}
          </div>

          <div className="mt-5 text-center" onClick={handleSubmit}>
            <Button
              name="Update"
              width="20%"
              borderRadius="10px"
              fontSize="15px"
            // onClick={handleUpdate}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ShopForm;
