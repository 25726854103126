import React, { useState, useEffect } from "react";
import Sidebar from "../../../common/SideBar";
import NavBarEx from "../../../common/NavBarEx";
import styles from "./CustomerBanner.module.css";
import colors from "../../../utils/helper";
import Card from "../../../common/Card";
import upload from "../../../Assets/Upload to the Cloud.svg";
import trashicon from "../../../Assets/trash_icon.png";
import edit from "../../../Assets/edit.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../../../common/Button";
import { ReactComponent as ArrowRight } from "../../../Assets/arrowright.svg";
import { ReactComponent as ArrowLeft } from "../../../Assets/arrowleft.svg";
import CreateBannerForm from "../../../components/Supllier/CreateBannerForm";
import AddImage from "./AddImage";
import endpoints from "../../../utils/endpoints";
import axiosInstance from "../../../utils";
import placeHolder from "../../../Assets/placeholder.png";
import WarningPop from "../../../common/WarningPop";
import PreLoader from "../../../common/PreLoader";
const CustomerBanner = () => {
  useEffect(() => {
    fetchBanners();
  }, []);
  const [banners, setBanners] = useState([]);
  const [loading, setloading] = useState(false);
  const [newSlideImage, setNewSlideImage] = useState("");
  const [slideImage, setslideImage] = useState(false);
  const fetchBanners = async () => {
    setloading(true);
    const url = endpoints.baseUrl + "admin/" + endpoints.getAllBanners;
    try {
      const response = await axiosInstance.get(url);
      console.log(response.data.data, "****************************");

      // const sliderDataRecieved=convertArrayToSliderData(response.data.data)
      setBanners(response.data.data);
      setloading(false);
    } catch (error) {
      // console.error("Error fetching products:", error);
      // toast.error("Internal Server Error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 5000, // Set the duration for how long the popup should be visible
      // });
    }
  };

  const [slide, setSlide] = useState();
  const editSlideForBanner = async (bannerSlideId, bannerId) => {
    console.log(bannerId, "---", bannerSlideId);
    setOpen(true);
    setActiveBannerIndex(bannerId);
    setSlide(bannerSlideId);
  };
  // Function to add a new slide to the slider
  const addNewSlide = () => {
    const newSlide = {
      id: sliderData[`banner${activeBannerIndex}`].length + 1,
      src: newSlideImage,
      alt: "New Slide",
    };
    const updatedSliderData = { ...sliderData };
    updatedSliderData[`banner${activeBannerIndex}`].push(newSlide);
    setSliderData(updatedSliderData);
    setOpen(false);
    setNewSlideImage("");
  };
  const [imageSrc, setImageSrc] = useState("");
  const [Open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const flagForSideNav = true;
  const [sliderData, setSliderData] = useState();
  const handleImageChange = (index, value) => {
    const updatedSubcategories = [...sliderData];
    updatedSubcategories[index] = value;
    setSliderData(updatedSubcategories);
  };

  const deleteBannerApi = async (slider_id) => {
    const url = `${endpoints.baseUrl}admin/product/banner/${slider_id}`;
    try {
      await axiosInstance.delete(url);
      // console.log("Item deleted successfully:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    // const updatedData = [...banners];
    // updatedData.splice(index, 1);
    // setBanners(updatedData);
  };

  const deleteSlideFromBanner = async (imageId, bannerId) => {
    console.log(imageId, bannerId);
    const url = `${endpoints.baseUrl}admin/product/banner/${bannerId}/delete/${imageId}`;
    try {
      const response = await axiosInstance.delete(url);
      console.log("Item deleted successfully:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  };

  const [activeBannerIndex, setActiveBannerIndex] = useState();
  const handelCLickToOpen = (val) => {
    // console.log(val,"****************")
    setOpen(true);
    setActiveBannerIndex(val);
    setSlide()
  };
  const closePopAddImage = () => {
    setOpen(false);
  };
  const handelCLickToBack = () => {
    setOpen(false);
  };
  const [popupOpen, setPopupOpen] = useState(false);
  const handleVerifyClick = (value) => {
    setPopupOpen(true);
  };

  const closePopUp = () => {
    setPopupOpen(false);
  };
  const [warning, setWarning] = useState(false);
  const [active, setActive] = useState();
  const warningHandler = (val) => {
    // console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };
  return (
    <div className="position-relative">
      <div>{/* <NavBar buttonFlag="true" /> */}</div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-lg-2 col-md-3  max-vh-100 pe-0 ${styles.left}`}
            style={Open === 1 ? { filter: "blur(2px)" } : {}}
          >
            <Sidebar flag={flagForSideNav} activeTabColor="Custom banners" />
          </div>
          <div
            className="col-lg-10 col-md-9 col-12 pb-4"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: colors.bgcolor,
            }}
          >
            <div style={Open === 1 ? { filter: "blur(2px)" } : {}}>
              <div className="py-3">
                <NavBarEx
                  // flag={false}
                  buttonName={"Create Banner"}
                  buttonFlag={true}
                  search={true}
                  popUp={handleVerifyClick}
                />
              </div>
              <div className="row justify-content-center pt-5">
                {!loading && (
                  <div className="col-11">
                    {banners.map((sliderIndex, index) => {
                      return (
                        <div key={index}>
                          <div className="d-flex justify-content-between align-items-center pb-2">
                            <p style={{ fontSize: "18px", fontWeight: "600" }}>
                              {sliderIndex.name}
                            </p>
                            <div className="d-flex">
                              <div
                                className="px-2"
                                onClick={() => {
                                  handelCLickToOpen(sliderIndex._id);
                                }}
                              >
                                <Button name="Add Slide" />
                              </div>
                              <div
                                onClick={() => {
                                  setActiveBannerIndex(sliderIndex._id)
                                  setShow(true);
                                }}
                              >
                                <Button name="Delete Banner" />
                              </div>
                            </div>
                          </div>
                          <Slider {...settings} className="mb-5">
                            {sliderIndex?.bannerId.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div
                                    style={{
                                      padding: "20px",
                                      backgroundColor: "white",
                                    }}
                                    className="mt-2"
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <img
                                        onClick={() => {
                                          editSlideForBanner(
                                            item,
                                            sliderIndex._id
                                          );
                                        }}
                                        style={{
                                          height: "16px",
                                          cursor: "pointer",
                                        }}
                                        src={edit}
                                        alt="Edit"
                                      />
                                      <img
                                        style={{ cursor: "pointer" }}
                                        src={trashicon}
                                        alt="Delete"
                                        onClick={() => {
                                          // deleteSlideFromBanner(
                                          //   item._id,
                                          //   sliderIndex._id
                                          // );
                                          setActive(sliderIndex._id);
                                          setslideImage(item._id);
                                          warningHandler();
                                        }}
                                      />
                                    </div>
                                    <div className=" d-flex justify-content-between py-3 px-2">
                                      <div>
                                        <h6>Promotes:&nbsp;{item.promotes || "N/A"}</h6>
                                        {item.promotes === "category" && <h6>categoryId:&nbsp;{item.categoryId}</h6>}
                                        {item.promotes === "website" && <h6>url:&nbsp;{item.url}</h6>}
                                        {item.promotes === "product" && <h6>productId:&nbsp;{item.productId}</h6>}
                                      </div>
                                      <div style={{ height: "300px" }}>
                                        {item?.image?.includes("https") &&
                                          item.image != null ? (
                                          <img
                                            className="w-100 h-100"
                                            src={item.image}
                                            alt="placeholder"
                                          />
                                        ) : (
                                          <img
                                            className="w-100 h-100"
                                            src={placeHolder}
                                            alt="placeholder"
                                          />
                                        )}

                                        {/* {!item.image &&<img
                                          className="w-100 h-100"
                                          src={placeHolder}
                                          alt="Placeholder"
                                        />} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                      );
                    })}
                  </div>
                )}
                {loading && <PreLoader />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {Open && (
        <div className={` ${styles.tablecontainer}`}>
          <div style={{ position: "relative", zIndex: "100" }}>
            <AddImage
              edit={slide ? "edit" : "add"}
              slide={slide}
              fetchBanners={fetchBanners}
              img={(e) => setNewSlideImage(e)} // Update the newSlideImage state with the selected image
              activeBanner={activeBannerIndex}
              handelCLickToBack={handelCLickToBack}
              closePopAddImage={closePopAddImage}
              addNewSlide={addNewSlide} // Pass the function to add a new slide
            />
          </div>
        </div>
      )}
      {popupOpen ? (
        <div className={` ${styles.popcontainer}`}>
          <div style={{ position: "relative", zIndex: "100" }}>
            <CreateBannerForm closePopUp={closePopUp} />
          </div>
        </div>
      ) : null}
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "33%",
            position: "absolute",
            left: "35%",
            top: "45%",
            zIndex: 10000,
          }}
        >
          <WarningPop
            delete={() => {
              if (slideImage) {
                deleteSlideFromBanner(slideImage, active);
                setslideImage(false);
                setActive(false);
                // warningHandler("hii");
                // window.location.reload();
              }
            }}
            closePopUp={(val) => {
              setActive()
              warningHandler(val);
            }}
            message="Are you sure you want to remove this slide?"
          />
        </div>
      ) : null}
      {show ? <div
        style={{
          width: "35%",
          height: "33%",
          position: "absolute",
          left: "35%",
          top: "45%",
          zIndex: 10000,
        }}
      ><WarningPop
          delete={() => {
            if (activeBannerIndex) {
              deleteBannerApi(activeBannerIndex);
            }
          }}
          closePopUp={(val) => {
            setActiveBannerIndex()
            setShow(false)
          }}
          message="Are you sure you want to delete this banner?"
        /></div> : null}
    </div>
  );

};

export default CustomerBanner;
