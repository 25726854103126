import React, { useEffect, useState } from "react";
import NavBarEx from "../../common/NavBarEx";
import Sidebar from "../../common/SideBar";
import Heading from "../../common/Heading";
import Backicon from "../../Assets/backicon.png";
import Button from "../../common/Button";
import styles from "./Settings.module.css";
import Profile from "../../Assets/profile.png";
import { useNavigate } from "react-router-dom";
import WarningPop from "../../common/WarningPop";
import PreLoader from "../../common/PreLoader";
import EditProfilePopUp from "./EditProfilePopUp";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import editicon from "../../Assets/edit.svg";

const Settings = (props) => {
  const updateProfile = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.updateProfile;
    let response;
    const formData = new FormData();
    if (name.length > 1) {
      formData.append("name", name);
      formData.append("email", localStorage.getItem("username"));
      formData.append("pic", pic);

      console.log(formData);

      try {
        response = await axiosInstance.patch(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setprofileChanges(response.data.data);
        setprofilePic(response.data.data.profilePic);
        localStorage.setItem("profilePic", response.data.data.profilePic);

        // window.location.reload()
      } catch (err) {
        console.log(err);
      }
    } else {
      formData.append("name", name);
      formData.append("email", localStorage.getItem("username"));
      formData.append("pic", pic);

      console.log(formData);

      try {
        response = await axiosInstance.patch(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setprofileChanges(response.data.data);
        setprofilePic(response.data.data.profilePic);
        localStorage.setItem("profilePic", response.data.data.profilePic);

        // window.location.reload()
      } catch (err) {
        console.log(err);
      }
    }
    setShowButton(!showButton);
  };
  const [profileChanges, setprofileChanges] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    localStorage.getItem("profilePic");
    if (
      localStorage.getItem("profilePic") &&
      localStorage.getItem("profilePic").includes("https://")
    ) {
      setprofilePic(localStorage.getItem("profilePic"));
    } else if (
      localStorage.getItem("userData") &&
      JSON.parse(localStorage.getItem("userData")).profilePic
    ) {
      // profilePic
      setprofilePic(JSON.parse(localStorage.getItem("userData")).profilePic);
    }
    setTimeout(() => {
      setLoading(false);
      // document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const [profilePic, setprofilePic] = useState("");
  const [pic, setPic] = useState();
  const [name, setName] = useState("");
  const [email, setemail] = useState("");

  const handleUsernameChange = (e) => {
    const name = e.target.value;
    localStorage.setItem("name", name);
    setName(localStorage.getItem("name"));
  };

  const handelEmailChange = (e) => {
    const email = e.target.value;
    localStorage.setItem("username", email);
    setemail(e.target.value);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      setPic(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    } else {
      setPic(null);
    }
  };

  const clickhandler = () => {
    // console.log("Name:", name);
    // console.log("Email id:", email);
    console.log("uploded image", pic);
  };
  const [open, setOpen] = useState(false);
  const showPopUp = () => {
    setOpen(true);
  };
  const closePopUp = () => {
    setOpen(false);
  };

  // const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const handleToggleClick = () => {
    setIsInputDisabled((prevState) => !prevState);
    setShowButton(!showButton);
  };
  return (
    <div>
      <div>{/* <NavBar /> */}</div>
      <div className="container-fluid">
        <div className="row">
          {/* sidebar */}
          <div
            className={` col-lg-2 col-md-3 pe-0 ${styles.left}`}
            style={open ? { filter: "blur(2px)" } : {}}
          >
            {localStorage.getItem("role") == "admin" ? (
              <Sidebar activeTabColor="Settings" />
            ) : (
              <Sidebar flag activeTabColor="Settings" />
            )}
          </div>
          {/*  screen-conetent */}
          <div
            className="col-lg-10 col-md-9 col-sm-12 col-12 pb-4"
            style={{
              backgroundColor: "#EAF0FA",
              height: "100vh",
              overflowY: "auto",
            }}
          >
            {loading && <PreLoader />}
            <div style={open ? { filter: "blur(2px)" } : {}}>
              <div>
                <div
                  style={{
                    maxWidth: "1000px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div className="py-3">
                    <NavBarEx
                      buttonFlag={false}
                      search={false}
                      popUp={showPopUp}
                    />
                  </div>

                  <div className="pt-5">
                    <div>
                      <div>
                        {localStorage.getItem("role") == "admin" ? (
                          <div className=" d-sm-flex align-items-center justify-content-between pb-2">
                            <div className="d-flex">
                              <div
                                className="pe-3"
                                onClick={() => navigate("/admin/home")}
                              >
                                <img
                                  width={30}
                                  height={30}
                                  src={Backicon}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div>
                                <Heading
                                  fontSize={"36px"}
                                  fontWeight={600}
                                  name={localStorage.getItem("name")}
                                />
                              </div>
                            </div>

                            <div className="d-flex justify-content-between pt-4 pt-sm-0">
                              <div onClick={handleToggleClick}>
                                <Button
                                  name="Edit Profile"
                                  width="fit-content"
                                  fontSize="15px"
                                />
                              </div>
                              <div className="ps-sm-4" onClick={showPopUp}>
                                <Button
                                  name="Change Password"
                                  width="fit-content"
                                  fontSize="15px"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className=" d-flex justify-content-between align-items-center pb-2">
                            <div className="d-flex">
                              <div
                                className="pe-3"
                                onClick={() => navigate("/agent/home")}
                              >
                                <img
                                  width={30}
                                  height={30}
                                  src={Backicon}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <Heading
                                fontSize={"36px"}
                                fontWeight={600}
                                name={localStorage.getItem("name")}
                              />
                            </div>
                            <div className="d-flex justify-content-between pt-4 pt-sm-0">
                              <div onClick={handleToggleClick}>
                                <Button
                                  name="Edit Profile"
                                  width="fit-content"
                                  fontSize="15px"
                                />
                              </div>
                              <div className="ps-sm-4" onClick={showPopUp}>
                                <Button
                                  name="Change Password"
                                  width="fit-content"
                                  fontSize="15px"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="pt-3">
                      <div>
                        <div
                          style={{
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          {/* {pic ? ( */}
                          <img
                            src={
                              showButton
                                ? pic
                                  ? pic?.preview
                                  : "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
                                : profilePic
                                ? profilePic
                                : "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
                            }
                            alt="pic"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                              position: "relative",
                            }}
                          />
                          {/* ) : (
                            <img
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                                position: "relative",
                              }}
                              src={
                                profileChanges?.profilePic
                                  ? localStorage.getItem("profilePic")
                                  : Profile
                              }
                            />
                          )} */}
                          {showButton && (
                            <div
                              className={styles.transform}
                              style={{
                                // backgroundColor: "black",
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                opacity: "1",
                              }}
                            >
                              <div
                                className={styles.transform}
                                style={{
                                  position: "absolute",
                                  top: "10%",
                                  right: "-50%",
                                }}
                              >
                                <label
                                  htmlFor="fileInput"
                                  className="upload-icon"
                                >
                                  <i className="fa fa-upload"></i>
                                  <img
                                    style={{ width: "40%", cursor: "pointer" }}
                                    src={editicon}
                                  />
                                  {/* You can use any icon library or your custom SVG icon */}
                                </label>
                                <input
                                  type="file"
                                  id="fileInput"
                                  style={{ display: "none" }}
                                  onChange={handleImageChange}
                                  accept="image/*"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 pt-3">
                          <label style={{ fontWeight: 500, fontSize: 16 }}>
                            Name
                          </label>

                          <input
                            style={{
                              backgroundColor: "#FFFFFF",
                              width: "100%",
                              display: "block",
                              border: "none",
                              borderRadius: 10,
                            }}
                            className="mt-2 ps-3 py-2 "
                            value={localStorage.getItem("name")}
                            onChange={handleUsernameChange}
                            readOnly={isInputDisabled}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 pt-5">
                          <label style={{ fontWeight: 500, fontSize: 16 }}>
                            Email id
                          </label>

                          <input
                            style={{
                              backgroundColor: " lightgray",
                              width: "100%",
                              display: "block",
                              border: "none",
                              borderRadius: 10,
                              outline: "none",
                            }}
                            className="mt-2 ps-3 py-2 "
                            value={localStorage.getItem("username")}
                            onChange={handelEmailChange}
                            type="text"
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="pt-5">
                        <hr></hr>
                      </div>
                      <div className="text-end">
                        {showButton && (
                          <div onClick={updateProfile}>
                            <Button
                              name="Save Changes"
                              width="fit-content"
                              fontSize="15px"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.popcontainer}`} hidden={!open ? true : false}>
          {open && <EditProfilePopUp closePopUp={closePopUp} />}
        </div>
        {warning ? (
          <div
            style={{
              width: "35%",
              height: "50%",
              position: "absolute",
              left: "35%",
              top: "40%",
              // zIndex: 10,
            }}
          >
            <WarningPop
              // delete={() => deleteHandler(active)}
              closePopUp={(val) => {
                warningHandler(val);
              }}
              message="Are you sure you want to accept the request?"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Settings;
