import React, { useState, useEffect } from "react";
import NavBarEx from "../../common/NavBarEx";
import Sidebar from "../../common/SideBar";
import Styles from "./SpecificShop.module.css";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
// import Button from "../../common/Button";
import AgentAssociated from "../../components/Shop/AgentAssociated";
// import CustomLineChart  from "../../common/CustomLineChart";
// import CustomCircularChart  from "../../common/CircularChart";
import { CustomLineChart } from "../../common/CustomLineChart";
import { CustomCircularChart } from "../../common/CircularChart";
import TotalCatagries from "../../components/Shop/TotalCatagries";
import { useNavigate } from "react-router-dom";
import ShopForm from "../../components/Shop/ShopForm";
import Backicon from "../../Assets/backicon.png";
// import DropDown from "../../common/DropDown";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../common/Button";

const SpecificShop = () => {
  const [agentAssociated, setAgentAssociated] = useState([]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const graphoptions = ["Yearly", "Monthly", "Weekly"];
  const [graphoption, setgraphoption] = useState("Monthly");
  const fetchAgentAssociated = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allMerchants + "?shopId=" + id
      );
      setAgentAssociated(response.data.data);
      console.log("===========shop agents=============", response.data);
      setLoading(false);
      console.log(response.data.data);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [graphData, setGraphData] = useState([]);

  function fetchTotalAmountsForYear(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const totalAmount = item.totalAmount;

      yearsArray.push(year);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForMonth(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const month = item._id.month;
      const totalAmount = item.totalAmount;

      yearsArray.push(`${months[month - 1]} ${year}`);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForWeekly(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const week = item._id.week;
      const totalAmount = item.totalAmount;

      yearsArray.push(`week ${week} of ${year}`);
      totalAmountsArray.push(totalAmount);
    }
    console.log({ x: yearsArray, y: totalAmountsArray });

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }

  const [amountsForGraph, setAmountsForGraph] = useState();
  const [labels, setLabels] = useState([]);
  const fetchDataFroGraph = async (key) => {
    console.log(
      endpoints.baseUrl +
        "admin/" +
        endpoints.totalEarningGraph +
        "sale-trends?shop=" +
        id +
        "&groupBy=yearly"
    );
    try {
      // Make a GET request to the API

      if (key == "Yearly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
            "admin/" +
            endpoints.totalEarningGraph +
            "sale-trends?shop=" +
            id +
            "&groupBy=weekly"
        );
        setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForYear(response.data.data);

        setAmountsForGraph(amounts);
      }
      if (key == "Monthly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
            "admin/" +
            endpoints.totalEarningGraph +
            "sale-trends?shop=" +
            id +
            "&groupBy=monthly"
        );
        setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForMonth(response.data.data);
        setAmountsForGraph(amounts);
      }
      if (key == "Weekly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
            "admin/" +
            endpoints.totalEarningGraph +
            "sale-trends?shop=" +
            id +
            "&groupBy=weekly"
        );
        console.log(response.data.data);
        setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForWeekly(response.data.data);
        setAmountsForGraph(amounts);
      }
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };
  const item1 = ["first Week", "last Week"];
  const params = useParams();
  const id = params.id;

  // const [salesBySpecificShop, setSalesBySpecificShop] = useState([]);
  // const salesBySpecificShopApi = async () => {
  //   console.log(endpoints.baseUrl + "admin/" + endpoints.shopsById + id);
  //   try {
  //     const response = await axiosInstance.get(
  //       endpoints.baseUrl + "admin/" + endpoints.shopsById + id
  //     );
  //     setSalesBySpecificShop(response.data.data);
  //     // console.log(response.data.data)
  //   } catch (error) {
  //     // console.error(error);
  //     toast.error("Internal Server Error", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 5000, // Set the duration for how long the popup should be visible
  //     });
  //   }
  // };
  useEffect(() => {
    fetchDataFroGraph(graphoption);
    // salesBySpecificShopApi();
    // SpecificShopAPI(id);
  }, [graphoption]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchAgentAssociated();
  }, []);

  const handleSelectgraphoptionChange = (event) => {
    setgraphoption(event.target.value);
  };
  return (
    <div>
      <div>
        <div className="container-fluid position-relative">
          <div className="row">
            <div className={` col-lg-2 col-md-3 pe-0  ${Styles.left}`}>
              <Sidebar activeTabColor="Shops" />
            </div>
            <div
              className="col-lg-10 col-md-9 col-sm-12 pb-4"
              style={{
                overflowY: "auto",
                height: "100vh",
                backgroundColor: "#EAF0FA",
              }}
            >
              {loading && <PreLoader />}
              <div className="pt-3">
                {/* <NavBar /> */}
                <NavBarEx buttonFlag={false} />
              </div>

              <div className="row">
                <div className="py-3 px-3 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div style={{ cursor: "pointer" }}>
                      <img
                        src={Backicon}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    <p
                      className="mb-0 ps-3 "
                      style={{
                        fontSize: "22px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      Back To Shop
                    </p>
                  </div>
                  <div>
                    <Button
                      name={"Settlements"}
                      onClick={() => {
                        navigate("/admin/specificShop/" + id + "/settlements");
                      }}
                    />
                  </div>
                </div>
                <div
                  className="col-12 mb-3"
                  style={{ height: "46vh" }}
                >
                  <Card width="100%" height="100%">
                    <div className="row align-items-center pt-3">
                      <div className="col-7 col-lg-9">
                        <Heading
                          paddingLeft={"4%"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Total Earning"
                        />
                      </div>
                      {/* <div className="ps-3">
                          <DropDown top="Weekly" items={item1} bg="#E4EEF8" />
                        </div> */}
                      <div className="col-4 col-lg-2">
                        <select
                          value={graphoption}
                          onChange={handleSelectgraphoptionChange}
                          style={{
                            display: "block",
                            backgroundColor: "#E4EEF8",
                            width: "100%",
                            display: "block",
                            border: "none",
                            borderRadius: 10,
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                          className="ps-2 py-2 mt-1 add-new-agent"
                        >
                          <option value="" disabled className="d-none">
                            Weekly
                          </option>
                          {graphoptions.map((option, index) => (
                            <option
                              key={index}
                              value={option}
                              style={{ backgroundColor: "#F5F5F5" }}
                            >
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {amountsForGraph != null && amountsForGraph.x.length > 0 ? (
                      <CustomLineChart
                        amountsForGraph={amountsForGraph}
                        height={"84%"}
                      />
                    ) : (
                      <div style={{ height: "80%" }}>
                        <h1
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          No Earnings
                        </h1>
                      </div>
                    )}
                  </Card>
                </div>
                {/* <div className="col-lg-6 col-md-6 col-sm-6">
                  <TotalCatagries shopId={id} />
                </div> */}
                <div className="col-lg-12 col-md-12 col-sm-12 pt-4">
                  <AgentAssociated
                    agentAssociated={agentAssociated}
                    popUp={showPopUp}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.popcontainer} hidden={!open ? true : false}>
        <div style={{ position: "relative", zIndex: "20" }}>
          {open && <ShopForm closePopUp={closePopUp} />}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SpecificShop;
