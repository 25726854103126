import React, { useState, useEffect, useRef } from "react";
import Backicon from "../../Assets/backicon.png";
import Button from "../../common/Button";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import { toast, ToastContainer } from "react-toastify";
import { useFormik, Formik, FieldArray, setNestedObjectValues } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import PreLoader from "../../common/PreLoader";
import preloaderImg from "../../Assets/preloader_img.webp";
import DragDrop from "../../common/DragDrop";
import { MdClose } from "react-icons/md";
import TextInputWithBadges from "../../common/TextInputWithBadges";
const NewProduct = (props) => {
  const [categoryNames, setCategoryNames] = useState([]);
  const [pickupLocations, setPickupLocations] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const formikRef = useRef();

  const fetchPickupLocations = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getPickups
      );
      setPickupLocations(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const fetchCategories = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct;
    // console.log("**************", url);
    try {
      const response = await axiosInstance.get(url);
      setCategoryNames(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const fetchSubCategories = async (categoryId) => {
    setSubCategory([])
    const url =
      endpoints.baseUrl +
      "admin/" +
      endpoints.getAllCategoriesInProduct +
      "?parent=" +
      categoryId;
    try {
      const response = await axiosInstance.get(url);
      const subcategories = response.data.data;
      if (subcategories.length === 0) {
        // If no subcategories are available for the selected category, show an error toast.
        toast.error("No SubCategory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
      setSubCategory(subcategories);
    } catch (error) {
      // Handle the error as before.
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchPickupLocations();
  }, []);

  useEffect(() => {
    if (props?.productId) getProductInfo(props?.productId);
  }, [props?.productId]);

  const closePopUpHandeler = () => {
    props.closePopUp();
  };

  const getProductInfo = async (id) => {
    try {
      const url = endpoints.baseUrl + "admin/" + endpoints.getProductById + id;
      let response = await axiosInstance.get(url);
      console.log("product info", response.data.data);
      const formik = formikRef.current;
      formik.setFieldValue("name", response.data.data?.name)
      formik.setFieldValue("categoryId", response.data.data?.categoryId)
      fetchSubCategories(response.data.data?.categoryId)
      formik.setFieldValue("pickupId", response.data.data?.pickUpId)
      formik.setFieldValue("subcategoryId", response.data.data?.subcategoryId)
      formik.setFieldValue("description", response.data.data?.description)
      formik.setFieldValue("gst", response.data.data?.gst)
      formik.setFieldValue("profitMargin", response.data.data?.profitMargin)
      formik.setFieldValue("companyDiscount", response.data.data?.companyDiscount)
      formik.setFieldValue("agentDiscount", response.data.data?.agentDiscount)
      formik.setFieldValue("returnDuration", response.data.data?.returnDuration)
      formik.setFieldValue("brand", response.data.data?.brandName)
      formik.setFieldValue("weight", response.data.data?.weight)
      formik.setFieldValue("link", response.data.data?.link)
      formik.setFieldValue("tags", response.data.data?.tags)
      formik.setFieldValue("color", response.data.data?.color?.map((c) => { return { ...c, old_images: c.images, images: [] } }))
    } catch (error) { }
  };

  return (
    <>
      <div style={{ border: "2px solid #4F81C3", backgroundColor: "#fff" }}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            name: "",
            categoryId: "",
            pickupId: "",
            subcategoryId: "",
            description: "",
            gst: "",
            profitMargin: "",
            companyDiscount: "",
            agentDiscount: "",
            returnDuration: "",
            brand: "",
            weight: "",
            link: "",
            tags: [],
            color: [
              {
                images: [],
                old_images: [],
                name: "",
                shade: "#000000",
                sizes: [
                  {
                    size: "",
                    price: "",
                    stock: "",
                  },
                ],
              },
            ],
          }}
          onSubmit={async (values, actions) => {
            try {
              console.log(values);
              if (!values.name || !values.categoryId || !values.subcategoryId) {
                toast.error("Missing required fields", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 5000, // Set the duration for how long the popup should be visible
                });
                return;
              }
              const formData = new FormData();
              formData.append("name", values.name);
              formData.append("categoryId", values.categoryId);
              formData.append("pickUpId", values.pickupId);
              formData.append("subcategoryId", values.subcategoryId);
              formData.append("description", values.description);
              formData.append("link", values.link);
              formData.append("gst", values.gst);
              formData.append("weight", values.weight);
              formData.append("brandName", values.brand);
              formData.append("returnDuration", values.returnDuration);
              formData.append("companyDiscount", values.companyDiscount);
              formData.append("agentDiscount", values.agentDiscount);
              formData.append("profitMargin", values.profitMargin);
              formData.append("tags", values.tags);
              formData.append("color", JSON.stringify(values.color));
              for (let i = 0; i < values.color.length; i++) {
                for (let j = 0; j < values.color[i].images.length; j++) {
                  formData.append(
                    `color[${i}][images]`,
                    values.color[i].images[j]
                  );
                }
              }

              let response;
              if (props?.productId) {
                // If productId exists, it's an update operation
                response = await axiosInstance.patch(
                  // Adjust the API endpoint URL for the update operation
                  endpoints.baseUrl +
                  "admin/" +
                  endpoints.editProduct +
                  `${props.productId}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
              } else {
                // If productId does not exist, it's a new product creation
                response = await axiosInstance.post(
                  endpoints.baseUrl + "admin/" + endpoints.addProduct,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data", // Set the content type to 'multipart/form-data' for uploading files
                    },
                  }
                );
              }

              console.log(response.data);
              if (response.data.code === 200) {
                toast.success("Product created successfuly", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 5000, // Set the duration for how long the popup should be visible
                });
              }
            } catch (error) {
              console.error(error);
              toast.error("Internal Server Error", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000, // Set the duration for how long the popup should be visible
              });
            } finally {
              actions.setSubmitting(false);
              closePopUpHandeler();
              // window.location.reload();
            }
          }}

        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
            const handleChange = (event) => {
              const { name, value, type, files } = event.target;
              console.log(files);
              console.log(type, name, value);
              if (name === "categoryId") fetchSubCategories(value);
              if (type === "file") setFieldValue(name, files[0]);
              else setFieldValue(name, value);
            };

            return (
              <form
                className=" container-fluid py-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="d-flex justify-content-start">
                  <img
                    src={Backicon}
                    onClick={closePopUpHandeler}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  className="overflow-auto px-5"
                  style={{ maxHeight: "70vh" }}
                >
                  <div className="row">
                    <div className="col-6 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Product name
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        name="name"
                        className="mt-2 ps-3 py-2 d-block"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-6 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Product weight (in kg)
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        name="weight"
                        className="mt-2 ps-3 py-2 d-block"
                        type="number"
                        value={values.weight}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Brand name
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        name="brand"
                        className="mt-2 ps-3 py-2 d-block"
                        type="text"
                        value={values.brand}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-6 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Max return duration (in days)
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        name="returnDuration"
                        className="mt-2 ps-3 py-2 d-block"
                        type="number"
                        value={values.returnDuration}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                        className="pb-2"
                      >
                        Pickup Location
                      </label>
                      <select
                        value={values.pickupId}
                        onChange={handleChange}
                        style={{
                          display: "block",
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          display: "block",
                          border: "none",
                          borderRadius: 10,
                        }}
                        className="ps-2 py-2 mt-1"
                        name="pickupId"
                        required
                      >
                        <option value="" disabled className="d-none">
                          Select Pickup Location
                        </option>
                        {pickupLocations?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                        className="pb-2"
                      >
                        Category
                      </label>
                      <select
                        value={values.categoryId}
                        onChange={handleChange}
                        style={{
                          display: "block",
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          display: "block",
                          border: "none",
                          borderRadius: 10,
                        }}
                        className="ps-2 py-2 mt-1"
                        name="categoryId"
                        required
                      >
                        <option value="" disabled className="d-none">
                          Select Category
                        </option>
                        {categoryNames?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                        className="pb-2"
                      >
                        Subcategory
                      </label>
                      <select
                        value={values.subcategoryId}
                        onChange={handleChange}
                        style={{
                          display: "block",
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          display: "block",
                          border: "none",
                          borderRadius: 10,
                        }}
                        className="ps-2 py-2 mt-1"
                        name="subcategoryId"
                        required
                      >
                        <option value="" disabled className="d-none">
                          Select Subcategory
                        </option>
                        {subCategory?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className=" col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Product description
                      </label>
                      <textarea
                        rows={4}
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                          resize: "none",
                        }}
                        name="description"
                        className="mt-2 ps-3 py-2 d-block"
                        type="text"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Tags
                      </label>
                      <TextInputWithBadges
                        value={values.tags}
                        onChange={(tags) => {
                          setFieldValue("tags", tags);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        GST(in %)
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        className="mt-2 ps-3 py-2 d-block"
                        type="number"
                        name="gst"
                        value={values.gst}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Profit Margin(in ₹)
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        className="mt-2 ps-3 py-2 d-block"
                        type="number"
                        name="profitMargin"
                        value={values.profitMargin}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Company discount(in ₹)
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        className="mt-2 ps-3 py-2 d-block"
                        type="number"
                        name="companyDiscount"
                        value={values.companyDiscount}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 pt-3">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Agent discount(in ₹)
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        className="mt-2 ps-3 py-2 d-block"
                        type="number"
                        name="agentDiscount"
                        value={values.agentDiscount}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row py-3">
                    <div className="col-12">
                      <label
                        style={{
                          fontWeight: 500,
                          fontSize: 15,
                          display: "block",
                          textAlign: "start",
                        }}
                      >
                        Youtube link
                      </label>
                      <input
                        style={{
                          backgroundColor: "#F3F1F1",
                          width: "100%",
                          border: "none",
                          borderRadius: 10,
                        }}
                        className="mt-2 ps-3 py-2 d-block"
                        type="text"
                        name="link"
                        value={values.link}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <FieldArray
                    name={`color`}
                    render={(colorHelpers) => (
                      <div>
                        <label
                          style={{
                            fontWeight: 500,
                            fontSize: 15,
                            display: "block",
                            textAlign: "start",
                          }}
                        >
                          Color options
                        </label>

                        {values?.color?.map((color, colorIndex) => (
                          <div
                            className="py-3 ps-4 pe-3 my-2"
                            style={{
                              backgroundColor: "#F3F1F1",
                              borderRadius: 10,
                            }}
                            key={colorIndex}
                          >
                            {values.color.length > 1 && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  onClick={() => {
                                    colorHelpers.remove(colorIndex);
                                  }}
                                  className="border-0 fs_xsm"
                                  style={{
                                    backgroundColor: "#ea4335",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                    color: "white",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <MdClose />
                                </button>
                              </div>
                            )}
                            <div className="row">
                              <div className="col-12 pt-3">
                                <label
                                  style={{
                                    fontWeight: 500,
                                    fontSize: 15,
                                    display: "block",
                                    textAlign: "start",
                                  }}
                                >
                                  Select color
                                </label>
                                <div className="row">
                                  <div className="col-10">
                                    <input
                                      style={{
                                        backgroundColor: "#FFFFFF",
                                        width: "100%",
                                        border: "none",
                                        borderRadius: 10,
                                      }}
                                      className="mt-2 ps-3 py-2 d-block"
                                      name={`color[${colorIndex}].name`}
                                      type="text"
                                      value={values.color[colorIndex].name}
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="col-2">
                                    <input
                                      className="mt-2 ps-3 py-2 d-block colorPicker"
                                      name={`color[${colorIndex}].shade`}
                                      type="color"
                                      value={values.color[colorIndex].shade}
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(props?.productId && values.color[colorIndex].old_images?.length > 0) ? <div><p style={{
                              fontWeight: 500,
                              fontSize: 15,
                              display: "block",
                              textAlign: "start",
                            }}>adding new images while editing will remove previously added images</p>
                              <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                // alignItems: "center",
                                justifyContent: "center",
                              }}>
                                {values.color[colorIndex].old_images?.map((img, index) => <img
                                  key={index}
                                  className="m-1"
                                  src={img}
                                  style={{
                                    objectFit: "cover",
                                    height: 50,
                                    width: 50,
                                    borderRadius: 25,
                                  }}
                                />)}</div>
                            </div> : ""}
                            {/* {false && */}
                            <div className="row">
                              <div className="col-12 pt-3">
                                <DragDrop
                                  value={values.color[colorIndex].images}
                                  onChange={(files) => {
                                    setFieldValue(
                                      `color[${colorIndex}].images`,
                                      files
                                    );
                                  }}
                                  multiple={true}
                                />
                              </div>
                            </div>

                            <FieldArray
                              name={`color[${colorIndex}].sizes`}
                              render={(sizeHelpers) => (
                                <div>
                                  {values.color[colorIndex].sizes.map(
                                    (size, sizeIndex) => (
                                      <div
                                        className="row align-items-end"
                                        key={sizeIndex}
                                      >
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-12 pt-3">
                                          <input
                                            style={{
                                              backgroundColor: "#FFFFFF",
                                              width: "100%",
                                              border: "none",
                                              borderRadius: 10,
                                            }}
                                            placeholder="Size"
                                            className="mt-2 ps-3 py-2 d-block"
                                            type="text"
                                            name={`color[${colorIndex}].sizes[${sizeIndex}].size`}
                                            value={
                                              values.color[colorIndex].sizes[
                                                sizeIndex
                                              ].size
                                            }
                                            onChange={handleChange}
                                            required
                                          />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-12 pt-3">
                                          <input
                                            style={{
                                              backgroundColor: "#FFFFFF",
                                              width: "100%",
                                              border: "none",
                                              borderRadius: 10,
                                            }}
                                            placeholder="Quantity"
                                            className="mt-2 ps-3 py-2 d-block"
                                            type="number"
                                            name={`color[${colorIndex}].sizes[${sizeIndex}].stock`}
                                            value={
                                              values.color[colorIndex].sizes[
                                                sizeIndex
                                              ].stock
                                            }
                                            onChange={handleChange}
                                            required
                                          />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-12 pt-3">
                                          <input
                                            style={{
                                              backgroundColor: "#FFFFFF",
                                              width: "100%",
                                              border: "none",
                                              borderRadius: 10,
                                            }}
                                            placeholder="Cost Price"
                                            className="mt-2 ps-3 py-2 d-block"
                                            type="number"
                                            name={`color[${colorIndex}].sizes[${sizeIndex}].price`}
                                            value={
                                              values.color[colorIndex].sizes[
                                                sizeIndex
                                              ].price
                                            }
                                            onChange={handleChange}
                                            required
                                          />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-12 pt-3">
                                          {sizeIndex ===
                                            values.color[colorIndex].sizes
                                              .length -
                                            1 ? (
                                            <button
                                              className="mt-2 ps-3 py-2 d-block"
                                              style={{
                                                backgroundColor: "#4F81C3",
                                                padding: 10,
                                                borderRadius: 10,
                                                color: "white",
                                                fontWeight: "bold",
                                                // marginBottom: 10,
                                                border: "none",
                                              }}
                                              type="button"
                                              onClick={() => {
                                                sizeHelpers.push({
                                                  size: "",
                                                  price: "",
                                                  stock: "",
                                                });
                                              }}
                                            >
                                              Add another size
                                            </button>
                                          ) : (
                                            <button
                                              className="mt-2 ps-3 py-2 d-block"
                                              style={{
                                                backgroundColor: "#ea4335",
                                                padding: 10,
                                                borderRadius: 10,
                                                color: "white",
                                                fontWeight: "bold",
                                                // marginBottom: 10,
                                                border: "none",
                                              }}
                                              onClick={() => {
                                                sizeHelpers.remove(sizeIndex);
                                              }}
                                            >
                                              Remove
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            />
                            <div className="pt-5 text-center">
                              {colorIndex === values.color.length - 1 ? (
                                <button
                                  width="fit-content"
                                  style={{
                                    backgroundColor: "#4F81C3",
                                    padding: 10,
                                    borderRadius: 10,
                                    color: "white",
                                    fontWeight: "bold",
                                    // marginBottom: 10,
                                    border: "none",
                                  }}
                                  borderRadius="5px"
                                  onClick={() => {
                                    colorHelpers.push({
                                      images: [],
                                      name: "",
                                      shade: "#000000",
                                      sizes: [
                                        {
                                          size: "",
                                          price: "",
                                          stock: "",
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  Add more
                                </button>
                              ) : null}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>

                <div className="pt-5 text-center">
                  {isSubmitting ? (
                    <img
                      src={preloaderImg}
                      // className="w-25"
                      height={50}
                      width={50}
                    />
                  ) : (
                    <Button
                      type="submit"
                      name="Submit"
                      width="fit-content"
                      borderRadius="5px"
                    />
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      <ToastContainer />
    </>
  );
};

export default NewProduct;
