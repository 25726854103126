import React from "react";
import Heading from "./Heading";
import Button from "./Button";
import Card from "./Card";
export default function WarningPop(props) {
  const clickHandler = (val) => {
    if (val == "No") {
      props.closePopUp(1);
    }
    if (val == "Yes") {
      props.delete();
    }
  };
  return (
    <div
      className="px-3"
      style={{
        height: "150px",
        backgroundColor: "#F0F0F0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border:"2px solid #000000",
        borderRadius:"25px"
      }}
    >
      <Card bgColor={"#F0F0F0"}>
        <div>
          <Heading textAlign="left" name={props.message} />
        </div>

        <div className="d-flex justify-content-center  pt-3">
          <div className="mx-2" onClick={() => clickHandler("Yes")}>
            <Button name={"Yes"} />
          </div>
          <div className="mx-2" onClick={() => clickHandler("No")}>
            <Button name={"No"} />
          </div>
        </div>
      </Card>
    </div>
  );
}
