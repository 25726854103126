import React, { useState, useEffect } from "react";
import Heading from "../common/Heading";
import BackIcon from "../Assets/backicon.png";
import Button from "../common/Button";
import Bellicon from "../Assets/notification.svg";
import Questionicon from "../Assets/Questionicon.png";
import styles from "./SpecificCustomerDetails.module.css";
import Profile from "../Assets/profile.png";
import Card from "../common/Card";
import eye from "../Assets/remove_red_eye.png";
import download from "../Assets/icon_download.png";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils";
import endpoints from "../utils/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export default function SpecificCustomerDetails(props) {
  const navigate = useNavigate();

  const [btnColor, setBtnColor] = useState("All orders");
  const [OrdersData, setOrdersData] = useState([]);
  // const [txtcolor, setTxtColor] = useState("All orders");

  const fetchAllOrdersData = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getAllOrders
      );
      setOrdersData(...OrdersData, response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      // Handle errors here
      // console.error(error);
    }
  };

  const blockUnblockCustomer = async (action) => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.patch(
        endpoints.baseUrl +
          "admin/" +
          endpoints.blockUnblockCustomer +
          props.specificCustomer._id +
          "/" +
          action
      );
      props?.getData();
      // console.log(response.data.data);
    } catch (error) {
      // Handle errors here
      // console.error(error);
    }
  };

  useEffect(() => {
    fetchAllOrdersData();
    // specificRecentReviews();
  }, []);
  const hr = {
    width: "99%",
    height: "1px",
    backgroundColor: "grey",
  };
  const vr = {
    width: "1px",
    height: "88%",
    backgroundColor: "grey",
  };
  return (
    <>
      <div style={{ paddingLeft: "75px", paddingTop: "35px" }}></div>
      <div
        className="row "
        style={{ backgroundColor: "#EAF0FA", paddingTop: "30px" }}
      >
        <div className="col-lg-8 col-md-8 col-sm-8 ">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{ alignSelf: "center" }}
              onClick={() => {
                navigate("/admin/customer");
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                width={30}
                height={30}
                src={BackIcon}
              />
            </div>
            <Heading
              paddingLeft={"4%"}
              fontSize={"36px"}
              fontWeight={400}
              name={props.specificCustomer.name}
            />
            {/* <Button
              width={"15%"}
              marginLeft={"2%"}
              borderRadius={10}
              name="Promote customer as agent"
              fontSize="15px"
            /> */}
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-4 ">
          <div style={{ textAlign: "end" }}>
            <Button
              width={"29%"}
              marginLeft={"2%"}
              marginRight={"0px"}
              borderRadius={10}
              name={
                props.specificCustomer.isBlocked
                  ? "Unblock customer"
                  : "Block customer"
              }
              onClick={() => {
                blockUnblockCustomer(
                  props.specificCustomer.isBlocked ? "unblock" : "block"
                );
              }}
              fontSize="15px"
            />
          </div>
        </div>

        <div
          className="col-lg-3 col-md-3 mt-4"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ padding: "10%" }}>
            <Heading
              width={"100%"}
              marginLeft="auto"
              marginRight="auto"
              fontSize={"20px"}
              fontWeight={600}
              name="Total Payments"
            />
            <Heading
              fontSize={"26px"}
              fontWeight={600}
              name={"₹" + props.customerAnayticsData.totalSales?.toFixed(2)}
              color="#4F81C3"
            />
          </div>

          <div style={vr}></div>
        </div>
        <div
          className="col-lg-3 col-md-3 mt-4"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ padding: "10%" }}>
            <Heading
              fontSize={"20px"}
              fontWeight={600}
              name="Delivered orders"
            />
            <Heading
              fontSize={"26px"}
              fontWeight={600}
              name={props.customerAnayticsData.totalDeliveredOrders}
              color="#4F81C3"
            />
          </div>
          <div style={vr}></div>
        </div>
        <div
          className="col-lg-3 col-md-3 mt-4"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ padding: "10%" }}>
            <Heading fontSize={"20px"} fontWeight={600} name="Pending orders" />
            <Heading
              fontSize={"26px"}
              fontWeight={600}
              name={props.customerAnayticsData.totalPendingOrders}
              color="#4F81C3"
            />
          </div>
          <div style={vr}></div>
        </div>
        <div className="col-lg-3 col-md-3 mt-4">
          <div style={{ padding: "10%" }}>
            <Heading fontSize={"20px"} fontWeight={600} name="Refunded" />
            <Heading
              fontSize={"26px"}
              fontWeight={600}
              name={"₹" + props.customerAnayticsData.totalRefunds}
              color="#4F81C3"
            />
            {/* chnages */}
          </div>
        </div>

        {/* Horizontal Line */}
        <div className="col-lg-12 mt-3 mb-3">
          <div style={hr}></div>
        </div>

        <div className="col-lg-4 mt-2">
          <div className="row">
            <div className="col-lg-12">
              <Card bgColor={"#fff"}>
                <div className="pt-3" style={{ paddingLeft: "4%" }}>
                  <Heading
                    fontSize={"24px"}
                    fontWeight={600}
                    name="Customers Information"
                  />
                </div>
                <div
                  className="py-3 px-4"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="mb-2">
                      <div>
                        <p
                          className="mb-0"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          Name
                        </p>
                        <p
                          className="mb-0"
                          style={{ fontSize: "18px", fontWeight: "500" }}
                        >
                          {props.specificCustomer.name}
                        </p>
                      </div>
                    </div>

                    <div className="my-2">
                      <div style={{ fontSize: "16px", fontWeight: "500" }}>
                        Email
                      </div>
                      <div style={{ fontSize: "18px", fontWeight: "500" }}>
                        {props.specificCustomer.email}
                      </div>
                    </div>

                    <div className="my-2">
                      <div style={{ fontSize: "16px", fontWeight: "500" }}>
                        Phone
                      </div>
                      <div style={{ fontSize: "18px", fontWeight: "500" }}>
                        {props.specificCustomer.mobileNumber}
                      </div>
                    </div>
                    <div className="my-2">
                      <div style={{ fontSize: "16px", fontWeight: "500" }}>
                        Shiping Address
                      </div>
                      <div style={{ fontSize: "18px", fontWeight: "500" }}>
                        {props.specificCustomer?.address?.length > 0
                          ? `${props.specificCustomer.address[0].street}, ${props.specificCustomer.address[0].city}, ${props.specificCustomer.address[0].state}, ${props.specificCustomer.address[0].country} - ${props.specificCustomer.address[0].pin}`
                          : "---------"}
                      </div>
                    </div>

                    <div className="my-2">
                      <div style={{ fontSize: "16px", fontWeight: "500" }}>
                        Billing Address
                      </div>
                      <div style={{ fontSize: "18px", fontWeight: "500" }}>
                        {props.specificCustomer?.address?.length > 0
                          ? `${props.specificCustomer.address[0].street}, ${props.specificCustomer.address[0].city}, ${props.specificCustomer.address[0].state}, ${props.specificCustomer.address[0].country} - ${props.specificCustomer.address[0].pin}`
                          : "---------"}
                      </div>
                    </div>
                  </div>
                  {props.specificCustomer.profilePicture && (
                    <div className="d-flex  flex-column justify-content-between align-items-center">
                      <div>
                        <img
                          style={{
                            width: "100%",
                            // height: "40px",
                            borderRadius: "50%",
                          }}
                          src={props.specificCustomer.profilePicture}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>

            {/* <div className="col-lg-12 mt-3">
              <Card bgColor={"#fff"}>
                <div className="py-2">
                  <Heading
                    paddingLeft={"4%"}
                    fontSize={"24px"}
                    fontWeight={600}
                    name="Recent Activity"
                  />
                  <div
                    className="p-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <svg
                        width="34"
                        height="31"
                        viewBox="0 0 34 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.4417 2.92705C15.7411 2.00574 17.0445 2.00574 17.3438 2.92705L18.213 5.6019C18.3468 6.01392 18.7308 6.29288 19.164 6.29288L21.9765 6.29288C22.9452 6.29288 23.348 7.5325 22.5643 8.1019L20.2889 9.75504C19.9384 10.0097 19.7918 10.4611 19.9257 10.8731L20.7948 13.5479C21.0941 14.4692 20.0396 15.2354 19.2559 14.666L16.9806 13.0128C16.6301 12.7582 16.1555 12.7582 15.805 13.0128L13.5296 14.666C12.7459 15.2354 11.6914 14.4692 11.9908 13.5479L12.8599 10.8731C12.9938 10.4611 12.8471 10.0097 12.4966 9.75504L10.2213 8.1019C9.43756 7.5325 9.84034 6.29288 10.8091 6.29288L13.6216 6.29288C14.0548 6.29288 14.4387 6.01392 14.5726 5.6019L15.4417 2.92705Z"
                          fill="#EC751F"
                        />
                        <path
                          d="M23.9417 15.0699C24.2411 14.1486 25.5445 14.1486 25.8438 15.0699L26.713 17.7447C26.8468 18.1567 27.2308 18.4357 27.664 18.4357L30.4765 18.4357C31.4452 18.4357 31.848 19.6753 31.0643 20.2447L28.7889 21.8979C28.4384 22.1525 28.2918 22.6039 28.4257 23.0159L29.2948 25.6907C29.5941 26.6121 28.5396 27.3782 27.7559 26.8088L25.4806 25.1556C25.1301 24.901 24.6555 24.901 24.305 25.1556L22.0296 26.8088C21.2459 27.3782 20.1914 26.6121 20.4908 25.6907L21.3599 23.0159C21.4938 22.6039 21.3471 22.1525 20.9966 21.8979L18.7213 20.2447C17.9376 19.6753 18.3403 18.4357 19.3091 18.4357L22.1216 18.4357C22.5548 18.4357 22.9387 18.1567 23.0726 17.7447L23.9417 15.0699Z"
                          fill="#EC751F"
                        />
                        <path
                          d="M8.15609 15.0699C8.45544 14.1486 9.75885 14.1486 10.0582 15.0699L10.9273 17.7447C11.0612 18.1567 11.4451 18.4357 11.8784 18.4357L14.6909 18.4357C15.6596 18.4357 16.0624 19.6753 15.2787 20.2447L13.0033 21.8979C12.6528 22.1525 12.5061 22.6039 12.64 23.0159L13.5091 25.6907C13.8085 26.6121 12.754 27.3782 11.9703 26.8088L9.69493 25.1556C9.34444 24.901 8.86984 24.901 8.51936 25.1556L6.244 26.8088C5.46028 27.3782 4.4058 26.6121 4.70515 25.6907L5.57427 23.0159C5.70814 22.6039 5.56148 22.1525 5.21099 21.8979L2.93563 20.2447C2.15192 19.6753 2.55469 18.4357 3.52342 18.4357L6.33592 18.4357C6.76915 18.4357 7.1531 18.1567 7.28698 17.7447L8.15609 15.0699Z"
                          fill="#EC751F"
                        />
                      </svg>
                    </div>
                    <div className="ps-3">Rating</div>
                  </div>
                  <div className="ps-3">
                    <div className="d-flex align-items-center justify-content-between pb-2">
                      <p className="mb-0 ">Product name 1</p>
                      <div className="pe-2">
                        <p>
                          {props.recentReviews.reviews
                            ? props.recentReviews.reviews
                            : "------------"}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between pb-2">
                      <p className="mb-0 ">Product name 1</p>
                      <div className="pe-2">
                        <p>
                          {props.recentReviews.reviews
                            ? props.recentReviews.reviews
                            : "------------"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div> */}
          </div>
        </div>
        <div className="col-lg-8 my-2">
          {/* <div className="py-3" style={{ paddingLeft: "3%" }}>
            <Heading fontSize={"24px"} fontWeight={600} name="Orders" />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
              className="pt-2"
            >
              <div
                style={{ width: "auto", marginRight: "2%" }}
                onClick={() => setBtnColor("All orders")}
              >
                <Button
                  marginLeft="2px"
                  marginRight="4%"
                  bgColor={btnColor == "All orders" ? "#4f81C3" : "transparent"}
                  btnTextColor={btnColor == "All orders" ? "#fff" : "black"}
                  name="All orders"
                  width="fit-content"
                  borderRadius="8px"
                  fontSize="18px"
                  fontWeight="500"
                />
              </div>
              <div
                style={{ marginRight: "2%" }}
                onClick={() => setBtnColor("Processing")}
              >
                <Button
                  name="Processing"
                  width="100%"
                  bgColor={btnColor == "Processing" ? "#4f81C3" : "transparent"}
                  btnTextColor={btnColor == "Processing" ? "#fff" : "black"}
                  borderRadius="8px"
                  fontSize="18px"
                  fontWeight="500"
                />
              </div>
              <div
                style={{ marginRight: "2%" }}
                onClick={() => setBtnColor("Completed")}
              >
                <Button
                  name="Completed"
                  width="100%"
                  bgColor={btnColor == "Completed" ? "#4f81C3" : "transparent"}
                  btnTextColor={btnColor == "Completed" ? "#fff" : "black"}
                  borderRadius="8px"
                  fontSize="18px"
                  fontWeight="500"
                />
              </div>
              <div
                style={{ marginRight: "2%" }}
                onClick={() => setBtnColor("Cancelled")}
              >
                <Button
                  name="Cancelled"
                  width="100%"
                  bgColor={btnColor == "Cancelled" ? "#4f81C3" : "transparent"}
                  btnTextColor={btnColor == "Cancelled" ? "#fff" : "black"}
                  borderRadius="8px"
                  fontSize="18px"
                  fontWeight="500"
                />
              </div>
            </div>
          </div> */}
          <Card height={"auto"}>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "500px",
                paddingLeft: "3%",
                paddingRight: "1%",
              }}
            >
              <div className="py-2">
                <table className="table table-responsive mb-0 ">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Price</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.allOrdersData.length > 0 ? (
                      props.allOrdersData.map((row, index) => {
                        return (
                          <tr
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "white" : "#F0F5FC",
                              borderBottom: "1px solid #EBEBEB",
                            }}
                          >
                            <td>{row._id ? row._id : "------"}</td>
                            <td>{row.createdAt ? moment(row.createdAt).format("DD MMM YYYY") : "-------"}</td>
                            <td>{row.status ? row.status : "-------"}</td>
                            <td>
                              {row.paymentMode ? row.paymentMode : "-------"}
                            </td>
                            <td>{row.amount ? row.amount?.toFixed(2) : "-------"}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <div
                                  onClick={() =>
                                    navigate("/admin/orderdescription/"+row._id)
                                  }
                                >
                                  <img
                                    style={{ cursor: "pointer" }}
                                    width={20}
                                    height={20}
                                    src={eye}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center">No orders found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
