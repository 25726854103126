import React, { useState, useEffect } from "react";
// import NavBar from "../../common/NavBar";
import Sidebar from "../../common/SideBar";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
import NavBarEx from "../../common/NavBarEx";
// import Button from "../../common/Button";
import styles from "./BuisnessAnalytics.module.css";
import { CustomCircularChart } from "../../common/CircularChart";
import { BarChart } from "../../common/BarChart";
import redeye from "../../Assets/remove_red_eye.png";
import "../../common/Fonts.css";
import colors from "../../utils/helper";
import DropDown from "../../common/DropDown";
import AllTable from "../../components/AllTable";
import dropimg from "../../Assets/dropimg.png";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BuisnessAnalytics = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [loading, setLoading] = useState(true);
  const [salesByRegion, setSalesByRegion] = useState([]);
  const [salesByChannel, setSalesByChannel] = useState([]);
  const [inventoryProducts, setInventoryProducts] = useState([]);
  const [salesRevnue, setSalesRevenue] = useState([]);
  const [products, setProducts] = useState([]);
  const [totalStock, setTotalStock] = useState(0);
  useEffect(() => {
    fetchDataSalesByRegion();
    fetchDataSalesByChannels();
    fetchDataInventoryProducts();
    fetchDataSalesRvenue();
    fetchProducts();
    getIncomingPayments();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);

  const fetchDataSalesRvenue = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.salesRevnue
      );
      setSalesRevenue(response.data.data);
      // console.log("sales revenue api");
    } catch (error) {
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  function sortByUpdatedAtDescending(data) {
    // Check if the data array is valid and not empty
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error("Invalid or empty data array");
    }

    // Sort the data based on the 'updatedAt' field in descending order
    data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    return data;
  }
  const fetchProducts = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.productInventory;
    try {
      const response = await axiosInstance.get(url);
      const sortedData = sortByUpdatedAtDescending(response.data.data);

      // let totalStock = 0;
      // sortedData.forEach((product) => {
      //   product.color.forEach((color) => {
      //     color.sizes.forEach((size) => {
      //       totalStock += size.stock;
      //     });
      //   });
      // });
      sortedData.map((item, index) => {
        let totalStock = 0;
        item.color.forEach((color) => {
          color.sizes.forEach((size) => {
            totalStock += size.stock;
          });
        });
        item.totalStock = totalStock;
      });

      setProducts(sortedData);
    } catch (error) {
      // Handle error
    }
  };

  const fetchDataSalesByRegion = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.salesByRegion
      );
      console.log("region", response.data.data)
      setSalesByRegion(response.data.data);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const fetchDataSalesByChannels = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.salesByChannels
      );
      let labels = [];
      let data = [];
      for (const key in response.data.data) {
        if (Object.hasOwnProperty.call(response.data.data, key)) {
          labels.push(key);
          data.push(response.data.data[key]);
        }
      }
      setSalesByChannel({
        labels,
        data,
      });
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const fetchDataInventoryProducts = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.salesByRegion
      );
      setInventoryProducts(...inventoryProducts, response.data.data);
      // console.log("Inventory Products Api");
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const [graphData, setGraphData] = useState([]);

  function fetchTotalAmountsForYear(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const totalAmount = item.totalAmount;

      yearsArray.push(year);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForMonth(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const month = item._id.month;
      const totalAmount = item.totalAmount;

      yearsArray.push(`${months[month - 1]} ${year}`);

      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForWeekly(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const week = item._id.week;
      const totalAmount = item.totalAmount;

      yearsArray.push(`week ${week} of ${year}`);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }

  const [amountsForGraph, setAmountsForGraph] = useState();
  const [labels, setLabels] = useState([]);
  const fetchDataFroGraph = async (key) => {
    try {
      // Make a GET request to the API

      if (key == "Yearly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.buisnessGraph +
          "sale-trends?groupBy=yearly"
        );
        setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForYear(response.data.data);
        // setLabels(['January', 'February', 'March', 'April', 'May', 'June', 'July'])
        console.log("heloo hello sajna sajna sajn", amounts);
        setAmountsForGraph(amounts);
      }
      if (key == "Monthly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.buisnessGraph +
          "sale-trends?groupBy=monthly"
        );
        setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForMonth(response.data.data);
        setLabels([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ]);
        console.log("heloo hello sajna sajna sajn", amounts);
        setAmountsForGraph(amounts);
      }
      if (key == "Weekly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.buisnessGraph +
          "sale-trends?groupBy=weekly"
        );
        setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForWeekly(response.data.data);
        setLabels([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ]);
        console.log("heloo hello sajna sajna sajn", amounts);
        setAmountsForGraph(amounts);
      }
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const graphoptions = ["Yearly", "Monthly", "Weekly"];
  const [graphoption, setgraphoption] = useState("Monthly");
  useEffect(() => {
    fetchDataFroGraph(graphoption);
  }, [graphoption]);
  const handleSelectgraphoptionChange = (event) => {
    const graphoptionValue = event.target.value;
    setgraphoption(graphoptionValue);
  };
  const [transactionstableData, setTransactions] = useState([]);
  const getIncomingPayments = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getIncomingPayments
      );
      console.log(response.data.data);
      let data = response.data.data.map((item) => {
        return {
          item1: new Date(item.createdAt).toLocaleDateString(),
          item2: item.currency,
          item3: item.orderId,
          item4: item.userId,
          item5: item.amount,
        };
      });
      setTransactions(data);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  // const transactionstableData = [
  //   {
  //     item1: "10/01/2023",
  //     item2: "xxxxxxxxxx",
  //     item3: "500",
  //     item4: "3500",
  //     item5: "6000",
  //   },
  //   {
  //     item1: "10/01/2023",
  //     item2: "xxxxxxxxxx",
  //     item3: "500",
  //     item4: "3500",
  //     item5: "6000",
  //   },
  // ];
  const headerdata = [
    {
      header1: "Date",
      header2: "Currency",
      header3: "Order Id",
      header4: "User Id",
      header5: "Amount",
    },
  ];
  const border = {
    // borderTop: "12px solid #EAF0FA",
    // borderBottom: "12px solid #EAF0FA",
  };

  const tableHead = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const item1 = ["Year", "Week", "Month"];
  return (
    <div>
      {/* {JSON.stringify(salesByRegion)} */}
      <div>{/* <NavBar /> */}</div>
      <div className="container-fluid ">
        <div className="row">
          {/* sidebar */}
          <div
            className={` col-lg-2 col-md-3  ${styles.left}`}
            style={{ paddingRight: "0px" }}
          >
            <Sidebar activeTabColor="Business Analytics" />
          </div>
          {/* buisnees analytics content */}
          <div
            className="col-lg-10 col-md-9 col-sm-12 col-12 pb-4"
            style={{
              backgroundColor: colors.bgcolor,
              overflowY: "auto",
              height: "100vh",
              flexWrap: "wrap",
            }}
          >
            {loading && <PreLoader />}
            {/* navabr */}
            <div className="py-3">
              <NavBarEx
                search={true}
                icons={false}
                // buttonName={"Add new admin"}
                buttonFlag={false}
              />
            </div>
            <div className="row  pt-3">
              {/* sidegraph */}
              <div className="col-lg-5 col-md-6 col-sm-6 ">
                <Card height="100%">
                  <div className=" px-2 px-lg-3 py-3">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-6 col-xl-5">
                        <Heading
                          name="Sales revenue"
                          fontSize="24px"
                          fontWeight="600"
                        />
                      </div>
                      <div className="col-5 col-sm-6 col-md-5 col-lg-4  col-xxl-3">
                        {/* <DropDown
                          top="Weekly"
                          items={item1}
                          bg={"#E4EEF8"}
                          img={dropimg}
                        /> */}
                        <select
                          value={graphoption}
                          onChange={handleSelectgraphoptionChange}
                          style={{
                            display: "block",
                            backgroundColor: "#E4EEF8",
                            width: "100%",
                            display: "block",
                            border: "none",
                            borderRadius: 10,
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                          className="ps-2 py-2 mt-1 add-new-agent"
                        >
                          <option value="" disabled className="d-none">
                            Weekly
                          </option>
                          {graphoptions.map((option, index) => (
                            <option
                              key={index}
                              value={option}
                              style={{ backgroundColor: "#F5F5F5" }}
                            >
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="pt-4">
                      <BarChart amountsForGraph={amountsForGraph} />
                    </div>
                  </div>
                </Card>
              </div>
              {/* sales by region */}
              <div className="col-lg-4 col-md-6 col-sm-6 pt-4 pt-sm-0">
                <Card
                  className="px-2"
                  backgroundColor="#EDEDED"
                  width="100%"
                  height="100%"
                >
                  <div className="ps-3 pt-3">
                    <Heading
                      name="Sales by region"
                      fontSize="24px"
                      fontWeight="600"
                    />
                    <div className={`p-1 ${styles.tablecontainer}`}>
                      <table
                        className={`table table-borderless  ${styles.table}`}
                      >
                        <thead>
                          <tr>
                            <th
                              className="text-center"
                              style={{
                                color: "#787878",
                                fontWeight: "500",
                                fontSize: "18px",
                                fontFamily: "InterMedium",
                                display: "table-cell",
                                verticalAlign: "middle",
                              }}
                            >
                              Region
                            </th>
                            <th
                              className="text-center"
                              style={{
                                color: "#787878",
                                fontWeight: "500",
                                fontSize: "18px",
                                fontFamily: "InterMedium",
                                display: "table-cell",
                                verticalAlign: "middle",
                              }}
                            >
                              No. of orders
                            </th>
                            <th
                              className="text-center"
                              style={{
                                color: "#787878",
                                fontWeight: "500",
                                fontSize: "18px",
                                fontFamily: "InterMedium",
                                display: "table-cell",
                                verticalAlign: "middle",
                              }}
                            >
                              Sale amount
                            </th>
                          </tr>
                        </thead>
                        <tbody className="pt-3">
                          {/* salestableData */}
                          {salesByRegion.map((row, index) => (
                            <tr
                              key={index}
                              className="text-center"
                              style={{
                                borderBottom: "2px solid #E4EEF8",
                                backgroundColor:
                                  index % 2 === 0 ? "white" : "#F0F5FC",
                              }}
                            >
                              <td
                                style={{
                                  fontWeight: "500",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  fontSize: "16px",
                                  fontFamily: "InterMedium",
                                }}
                              >
                                <div
                                  title={row.heading}
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  className="mx-auto"
                                >
                                  {row._id.city === undefined
                                    ? "-"
                                    : row._id.city}
                                </div>
                              </td>
                              <td
                                style={{
                                  fontWeight: "500",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  fontSize: "16px",
                                  fontFamily: "InterMedium",
                                }}
                              >
                                <div
                                  title={row.column1}
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  className="mx-auto"
                                >
                                  {row.totalOrders < 0 ? "-" : row.totalOrders}
                                </div>
                              </td>
                              <td
                                style={{
                                  fontWeight: "500",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  fontSize: "16px",
                                  fontFamily: "InterMedium",
                                }}
                              >
                                <div
                                  title={row.column2}
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  className="mx-auto"
                                >
                                  {row.totalAmount < 0 ? "-" : row.totalAmount}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Card>
              </div>
              {/* sales by channels */}
              <div className="col-lg-3 col-md-6 col-sm-6 pt-4 pt-lg-0">
                <Card height="100%">
                  <div className="px-3 py-3">
                    <Heading
                      name="Sales by channels"
                      fontSize="24px"
                      fontWeight="600"
                    />
                    {/* <div>
                      <CustomCircularChart />
                    </div>  */}
                    <div
                      className="mt-5"
                      style={{ width: "100%", height: "26vh" }}
                    >
                      <div
                        className="my-auto mx-auto"
                        style={{ width: "80%", height: "98%" }}
                      >
                        <CustomCircularChart data={salesByChannel?.data} />
                      </div>
                    </div>
                  </div>
                  <div
                    // style={{
                    //   width: "100%",
                    //   display: "flex",
                    //   justifyContent: "space-around",
                    // }}
                    className={`${styles.salesByChannelBottom}`}
                  >
                    {salesByChannel?.labels?.map((item, index) => {
                      const colours = ["#5C84B9", "#EC751F", "#E4EEF8"];
                      return (
                        <div
                          className="d-flex align-items-center mt-1"
                          key={index}
                        >
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: colours[index] }}
                          />
                          <p
                            className="mb-0 mx-1 "
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {item}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </Card>
              </div>
              {/* inventory- levels */}
              {/* <div className="col-lg-4 col-md-6 col-sm-6 pt-4 ">
                <Card className="px-3" height="100%">
                  <div className="px-2 py-2 row">
                    <div className={`col-lg-12  ${styles.table}`}>
                      <div className="mt-2" style={tableHead}>
                        <Heading
                          name="Inventory levels"
                          fontSize="24px"
                          fontWeight="600"
                        />
                      </div>
                      <div className={`${styles.tablecontainer}`}>
                        <table
                          className={`table table-borderless px-5 ${styles.table}`}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  color: "#787878",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  maxWidth: "100px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Product
                              </th>
                              <th
                                style={{
                                  color: "#787878",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  maxWidth: "100px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Storage name
                              </th>
                              <th
                                style={{
                                  color: "#787878",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  maxWidth: "100px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Location
                              </th>
                              <th
                                style={{
                                  color: "#787878",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  maxWidth: "100px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Stock
                              </th>
                            </tr>
                          </thead>
                          <tbody className="pt-3">
                            {products.map((row, index) => (
                              <tr
                                key={index}
                                // className="text-center"
                                style={{
                                  borderBottom: "2px solid #E4EEF8",
                                  backgroundColor:
                                    index % 2 === 0 ? "white" : "#F0F5FC",
                                }}
                              >
                                <th
                                  style={{
                                    borderBottom: "2px solid #E4EEF8",
                                    // backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                                    fontWeight: "500",
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                    fontSize: "16px",
                                    fontFamily: "InterMedium",
                                  }}
                                >
                                  <div
                                    title={row.heading}
                                    style={{
                                      maxWidth: "120px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="mx-auto"
                                  >
                                    {row.name ? row.name : "-------"}
                                  </div>
                                </th>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                    fontSize: "16px",
                                    fontFamily: "InterMedium",
                                  }}
                                >
                                  <div
                                    title={row.column1}
                                    style={{
                                      fontWeight: "500",
                                      display: "table-cell",
                                      verticalAlign: "middle",
                                      fontSize: "16px",
                                      fontFamily: "InterMedium",
                                      maxWidth: "120px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="mx-auto"
                                  >
                                    {row.totalStock
                                      ? row.totalStock
                                      : "-------"}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                    fontSize: "16px",
                                    fontFamily: "InterMedium",
                                  }}
                                >
                                  {row.totalStock ? row.totalStock : "-------"}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                    fontSize: "16px",
                                    fontFamily: "InterMedium",
                                  }}
                                >
                                  <div
                                    title={row.column3}
                                    style={{
                                      maxWidth: "120px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="mx-auto"
                                  >
                                    {row.totalStock
                                      ? row.totalStock
                                      : "-------"}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Card>
              </div> */}
              {/* transection table */}
              <div
                className="col-lg-12 col-md-12 col-sm-12 pt-4 "
                style={{ overflowX: "auto" }}
              >
                <Card>
                  <div
                    className="d-flex justify-content-between pt-3"
                    style={{ paddingRight: "3%" }}
                  >
                    <Heading
                      paddingLeft="3%"
                      name="Transactions"
                      fontSize="24px"
                      fontWeight="600"
                    />
                    <div>
                      <AllTable
                        // data={transactionstableData}
                        header={headerdata}
                        mappedDataForTable={transactionstableData}
                        headingname={"Transactions"}
                        item5
                        item6
                        searchicon
                        activeTabColor="Business Analytics"
                      />
                    </div>
                  </div>
                  <div className="table-responsive pt-2 ">
                    <div
                      className={`${styles.transactionscontainer}`}
                      style={{ paddingLeft: "3%", paddingRight: "3%" }}
                    >
                      <table className={`table  ${styles.table}`}>
                        <thead>
                          {headerdata.map((th, index) => (
                            <tr
                              key={index}
                              style={{
                                borderRadius: "12px",
                              }}
                            >
                              <th
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                }}
                              >
                                {th.header1}
                              </th>
                              <th
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                }}
                              >
                                {th.header2}
                              </th>
                              <th
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                }}
                              >
                                {th.header3}
                              </th>
                              <th
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                }}
                              >
                                {th.header4}
                              </th>
                              <th
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                }}
                              >
                                {th.header5}
                              </th>
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {transactionstableData.map((row, index) => (
                            <tr
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "white" : "#F0F5FC",
                                borderBottom: "1px solid #EBEBEB",
                                ...border,
                              }}
                            >
                              <td
                                style={{
                                  fontSize: "16px",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                }}
                              >
                                <div
                                  title={row.item1}
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  // className="mx-auto"
                                >
                                  {row.item1}
                                </div>
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                }}
                              >
                                <div
                                  title={row.item2}
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  // className="mx-auto"
                                >
                                  {row.item2}
                                </div>
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                }}
                              >
                                <div
                                  title={row.item3}
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                // className="mx-auto"
                                >
                                  {row.item3}
                                </div>
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                }}
                              >
                                <div
                                  title={row.item4}
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                // className="mx-auto"
                                >
                                  {row.item4}
                                </div>
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  display: "table-cell",
                                  verticalAlign: "middle",
                                  fontWeight: "500",
                                  fontFamily: "InterMedium",
                                }}
                              >
                                <div
                                  title={row.item5}
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  // className="mx-auto"
                                >
                                  {row.item5}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BuisnessAnalytics;
