import React, { useEffect, useState } from "react";
import Backicon from "../Assets/backicon.png";
import Button from "../common/Button";
import axiosInstance from "../utils";
import endpoints from "../utils/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CreateCouponform = (props) => {
  useEffect(() => {
    fetchDataOfAllShops();
    fetchCategories();
    fetchProducts();
  }, []);
  function restrictToRange(event) {
    const input = event.target;
    const value = parseInt(input.value);

    if (isNaN(value) || value < 1 || value > 99) {
      input.value = "";
    }
  }
  const clickHandler = () => {
    props.closePopUp();
  };
  const handlepromoCodeChange = (e) => {
    setpromoCode(e.target.value);
  };
  const handlePromoTypeChange = (e) => {
    setPromoType(e.target.value);
  };

  // const handelDiscountChange = (e) => {
  //   setDiscount(e.target.value);
  // }
  const handelvalidFromChange = (e) => {
    setvalidFrom(e.target.value);
  };
  const handelvalidTillChange = (e) => {
    setvalidTill(e.target.value);
  };
  const handeldescriptionChange = (e) => {
    setdescription(e.target.value);
  };
  const handleMinCartValueChange = (e) => {
    setMinCartValue(e.target.value);
  };
  const handleOfferAmountChange = (e) => {
    setOfferAmount(e.target.value);
  };
  
  const promoTypeOpations = ["upto", "flat"];
  const [promoCode, setpromoCode] = useState("");
  const [productId, setproductId] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [shopId, setShopId] = useState("");
  const [validFrom, setvalidFrom] = useState("");
  const [validTill, setvalidTill] = useState("");
  const [description, setdescription] = useState("");
  const [promoType, setPromoType] = useState("flat");
  const [minCartValue, setMinCartValue] = useState();
  const [percentageOff, setPercentageOff] = useState();
  const [maxDisc, setMaxDisc] = useState();
  const [offerAmount, setOfferAmount] = useState();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dataOfAllShops, setdataOfAllShops] = useState([]);

  const CreateCouponform = async (tempShopId, tempCategoryId, prodId) => {
    console.log("promoCode:", promoCode);
    console.log("productId:", productId);
    console.log("categoryId", categoryId);
    console.log("validFrom", validFrom);
    console.log("validTill", validTill);
    console.log("description", description);
    console.log("minCartValue", typeof minCartValue);
    console.log("offerAmount", typeof offerAmount);
    if (!promoCode && !validFrom && !validTill && !promoType && !minCartValue) {
      alert("missing required fields");
      return;
    }
    const formData = {
      promoCode: promoCode,
      validFrom: validFrom,
      validTill: validTill,
      description: description,
      minCartValue: Number(minCartValue),
      promoType: promoType,
    };
    if (promoType === "flat") {
      if (!offerAmount) {
        alert("missing required fields");
        return;
      }
      formData.offerAmount = Number(offerAmount);
    } else {
      if (!maxDisc && !percentageOff) {
        alert("missing required fields");
        return;
      }
      formData.maxDisc = maxDisc;
      formData.percentageOff = percentageOff;
    }
    if (productId) formData.productId = productId;
    if (categoryId) formData.categoryId = categoryId;
    if (shopId) formData.shopId = shopId;
    const url = endpoints.baseUrl + "admin/" + endpoints.CreateCouponform;

    // console.log(formData, "Form data Ceate Coupon");
    try {
      const response = await axiosInstance.post(url, formData);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      props.closePopUp();
      props.getAllCouponApi();
    }
  };

  const handleSelectpromoTypeChange = (event) => {
    setPromoType(event.target.value);
  };

  const fetchCategories = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct;
    // console.log("**", url);
    try {
      const response = await axiosInstance.get(url);
      setCategories(response.data.data);
      // console.log(response.data.data,"***************************");
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const fetchDataOfAllShops = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allShops
      );
      setdataOfAllShops(response.data.data);
    } catch (error) {
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const fetchProducts = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.productInventory;
    console.log("**", url);
    try {
      const response = await axiosInstance.get(url);
      // const sortedData = sortByUpdatedAtDescending(response.data.data);
      setProducts(response.data.data);
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  return (
    <div
      className="px-2 py-3 "
      style={{
        backgroundColor: "#fff",
        border: "1px solid #4F81C3",
        paddingTop: "40px",
      }}
    >
      <div className="ps-2">
        <img
          src={Backicon}
          onClick={clickHandler}
          style={{ cursor: "pointer" }}
        />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          CreateCouponform();
        }}
      >
        <div
          className="row px-4 "
          style={{ maxHeight: "550px", overflow: "auto" }}
        >
          <div className="col-12 pt-4">
            <label style={{ fontWeight: 500, fontSize: 16 }}>Promo Code</label>
            <input
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="mt-2 ps-3 py-2"
              type="text"
              value={promoCode}
              onChange={handlepromoCodeChange}
              required
            />
          </div>
          <div className="col-12 pt-4">
            <label
              style={{ fontWeight: 500, fontSize: 15, display: "block" }}
              className="pb-2"
            >
              Promo Type
            </label>
            <select
              value={promoType}
              onChange={handleSelectpromoTypeChange}
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="ps-2 py-2 mt-1"
              required
            >
              <option value="" disabled className="d-none">
                select promo Type
              </option>
              {promoTypeOpations.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 pt-4">
            <label style={{ fontWeight: 500, fontSize: 16 }}>
              Min Cart Value
            </label>
            <input
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="mt-2 ps-3 py-2"
              type="number"
              value={minCartValue}
              onChange={handleMinCartValueChange}
              required
            />
          </div>
          {promoType === "flat" ? (
            <div className="col-12 pt-4">
              <label style={{ fontWeight: 500, fontSize: 16 }}>
                offer Amount
              </label>
              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  display: "block",
                  border: "none",
                  borderRadius: 10,
                }}
                className="mt-2 ps-3 py-2"
                type="number"
                value={offerAmount}
                onChange={handleOfferAmountChange}
                required
              />
            </div>
          ) : (
            <>
              <div className="col-6 pt-4">
                <label style={{ fontWeight: 500, fontSize: 16 }}>
                  Max Disc(in Rupees)
                </label>
                <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    display: "block",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="mt-2 ps-3 py-2"
                  type="number"
                  value={maxDisc}
                  onChange={(e) => setMaxDisc(e.target.value)}
                  required
                />
              </div>
              <div className="col-6 pt-4">
                <label style={{ fontWeight: 500, fontSize: 16 }}>
                  Percentage off
                </label>
                <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    display: "block",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="mt-2 ps-3 py-2"
                  type="number"
                  value={percentageOff}
                  onChange={(e) => setPercentageOff(e.target.value)}
                  required
                />
              </div>
            </>
          )}
          <div className="col-6 pt-4">
            <label
              style={{ fontWeight: 500, fontSize: 15, display: "block" }}
              className="pb-2"
            >
              ProductId
            </label>
            <select
              value={productId}
              onChange={(e) => setproductId(e.target.value)}
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="ps-2 py-2 mt-1"
            >
              <option value="" disabled className="d-none">
                select product Id
              </option>
              {products.map((option, index) => (
                <option key={index} value={option._id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6 pt-4">
            <label
              style={{ fontWeight: 500, fontSize: 15, display: "block" }}
              className="pb-2"
            >
              Category Id
            </label>
            <select
              value={categoryId}
              onChange={(e) => setcategoryId(e.target.value)}
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="ps-2 py-2 mt-1"
            >
              <option value="" disabled className="d-none">
                select category Id
              </option>
              {categories.map((option, index) => (
                <option key={index} value={option._id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-4 pt-4">
            <label
              style={{ fontWeight: 500, fontSize: 15, display: "block" }}
              className="pb-2"
            >
              Shop Id
            </label>
            <select
              value={shopId}
              onChange={(e) => setShopId(e.target.value)}
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="ps-2 py-2 mt-1"
            >
              <option value="" disabled className="d-none">
                select Shop Id
              </option>
              {dataOfAllShops.map((option, index) => (
                <option key={index} value={option._id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="col-6 pt-4">
            <label style={{ fontWeight: 500, fontSize: 16 }}>validFrom</label>
            <input
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="mt-2 ps-3 py-2"
              type="date"
              value={validFrom}
              onChange={handelvalidFromChange}
              required
            />
          </div>
          <div className="col-6 pt-4">
            <label style={{ fontWeight: 500, fontSize: 16 }}>validTill</label>
            <input
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="mt-2 ps-3 py-2"
              type="date"
              value={validTill}
              onChange={handelvalidTillChange}
              required
            />
          </div>
          <div className="col-12 pt-4">
            <label style={{ fontWeight: 500, fontSize: 16 }}>description</label>
            <textarea
              rows={4}
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
                resize: "none",
              }}
              className="mt-2 ps-3 py-2"
              value={description}
              onChange={handeldescriptionChange}
              // maxLength={20}
            />
          </div>
        </div>
        <div className="mt-5 text-center">
          <Button type={"submit"} name="submit" width="20%" fontSize="18px" />
        </div>
      </form>
    </div>
  );
};

export default CreateCouponform;
