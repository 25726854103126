import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./screens/Login";
import Admin from "./routing/admin/index";
import Agent from "./routing/agent/index";
import PageNotFound from "./screens/PageNotFound";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Dashboard from "./screens/agent/supplier/Dashboard";
import PreLoader from "./common/PreLoader";
import { useEffect, useState } from "react";
import "./App.css"
import ResetPassword from "./components/Auth/ResetPassword";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsConditions from "./screens/TermsConditions";
import ContactUs from "./screens/ContactUs";
import ShippingPolicy from "./screens/ShippingPolicy";
import CancellationPolicy from "./screens/CancellationPolicy";
import AboutUs from "./screens/AboutUs";
import CompleteProfile from "./components/Auth/CompleteProfile";
function App() {
  // const RoleBasedRouting = ({ role }) => {
  //   if (role === 'admin') {
  //     return (
  //       <Routes>
  //         <Route path="/admin-home" element={<HomeScreen />} />
  //         <Route path="/notification" element={<Notification />} />
  //         <Route path="/customer" element={<Customer />} />
  //         <Route path="/shop" element={<Shop />} />
  //         <Route path="/customer" element={<Customer />} />
  //         {/* <Route path="/setting" element={<Setting />} /> */}
  //         <Route path="/merchant" element={<Merchant />} />
  //         <Route path="/buisness-analytics" element={<BuisnessAnalytics />} />
  //         <Route path="/create-coupon" element={<CreateCouponform />} />
  //         <Route path="/settings" element={<Settings />} />
  //         <Route path="/coupons" element={<Coupon />} />
  //         <Route path="/sub-admin" element={<SubAdmin />} />
  //         {/* <Redirect to="/dashboard" /> */}
  //       </Routes>

  //     );
  //   } else if (role === 'agent') {
  //     return (
  //       <Routes>
  //         <Route path="/agent-home" element={<Dashboard />} />
  //         <Route path="/product" element={<Product />} />
  //         <Route path="/review" element={<Review />} />
  //         <Route path="/product" element={<Product />} />
  //         {/* <Route path="/settings" element={<Settings />} /> */}
  //         <Route path="/catalog" element={<Catalog />} />

  //         {/* <Navigate to="/agent" /> */}
  //       </Routes>
  //     );
  //   } else {
  //     // Redirect to a login page or unauthorized page for other roles
  //     return <Navigate to="/unauthorized" />;
  //   }
  // };
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //     document.body.classList.remove("fix-screen");
  //   }, 500);
  // }, []);
  return (
    <>
      {/* {loading && <PreLoader />} */}
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/complete-profile/:token" element={<CompleteProfile />} />
        <Route exact path="/agent/*" element={<Agent />} />
        {/* <Route exact path='/agent/*' element={<div>baby</div>} /> */}

        <Route exact path="/admin/*" element={<Admin />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/Terms&Conditions" element={<TermsConditions />} />
        <Route exact path="/Contact-us" element={<ContactUs />} />
        <Route exact path="/About-us" element={<AboutUs />} />
        <Route exact path="/Shipping-and-Delivery-policy" element={<ShippingPolicy />} />
        <Route exact path="/Cancellation-and-Refund-policy" element={<CancellationPolicy />} />

        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
      {/* {role=='agent'?<Agent />:<Admin />} */}
    </>
  );
}

export default App;
