import React, { useState, useEffect } from "react";
import NavBarEx from "../../common/NavBarEx";
import Sidebar from "../../common/SideBar";
import Backicon from "../../Assets/backicon.png";
import Heading from "../../common/Heading";
import Bellicon from "../../Assets/notification.svg";
import Questionicon from "../../Assets/Questionicon.png";
import Button from "../../common/Button";
import Card from "../../common/Card";
import Xicon from "../../Assets/x.png";
import styles from "./Coupon.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreLoader from "../../common/PreLoader";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

function ReturnDescription() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id } = useParams();
  const columns = [
    { field: "id", headerName: "Sno" },
    {
      flex: 1,
      field: "details",
      headerName: "Product Details",
      sortable: false,
      minWidth: 300,
      renderCell: (params) => (
        <div>
          <div className="d-flex align-items-center">
            {params?.row?.productData?.color.length > 0 && (
              <div>
                <img
                  src={params?.row?.productData?.color[0].images[0]}
                  height={100}
                  width={80}
                />
              </div>
            )}
            <p
              className="ps-3 mb-0"
              style={{
                fontSize: "10px",
                width: "168px",
              }}
            >
              {params.row.name}
            </p>
          </div>
        </div>
      ),
    },
    { flex: 1, field: "pickUp", headerName: "Return Location", minWidth: 150 },
    {
      flex: 1,
      field: "pickUpAlias",
      headerName: "Return Alias",
      minWidth: 150,
    },
    // { flex: 1, field: "costPerItem", type: "number", headerName: "Price" },
    // { flex: 1, field: "quantity", type: "number", headerName: "Quantity" },
    // {
    //   flex: 1,
    //   field: "subtoatal",
    //   headerName: "Subtotal",
    //   type: "number",
    //   valueGetter: (params) =>
    //     `${params.row.costPerItem * params.row.quantity}`,
    // },
    {
      flex: 1,
      field: "shipmentId",
      headerName: "Shiprocket Id",
      minWidth: 300,
    },
  ];
  const [rows, setRows] = useState([]);
  useEffect(() => {
    fetchOrdersByIdData();
  }, []);
  const navigate = useNavigate();

  const [returnData, setReturnData] = useState([]);

  const fetchOrdersByIdData = async () => {
    try {
      setLoading(true);
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + endpoints.returnById + id
      );
      console.log(response.data);
      setRows(
        response.data.data?.products.map((item, index) => {
          return {
            ...item,
            productData: item?.id,
            id: index + 1,
            createdAt: moment(item.createdAt),
            pickUp: item?.id?.pickUpId?.name,
            pickUpAlias: item?.id?.pickUpId?.shipRocketAlias,
          };
        })
      );
      setReturnData(response.data.data);
    } catch (error) {
      // Handle errors here
      console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      setLoading(false);
    }
  };

  const cancelReturnRequest = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(
        endpoints.baseUrl + endpoints.cancelReturnById + id
      );
      await fetchOrdersByIdData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const aprooveReturn = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.patch(
        endpoints.baseUrl + endpoints.approveReturnById + id
      );
      await fetchOrdersByIdData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const initiateRedfund = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        endpoints.baseUrl + endpoints.refundReturnById.replace(":id", id)
      );
      await fetchOrdersByIdData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div>{/* <NavBar /> */}</div>
      <div className="container-fluid">
        <div className=" row">
          <div className={` col-lg-2 col-md-3 pe-0 ${styles.left}`}>
            <Sidebar activeTabColor="Orders" />
          </div>
          <div
            className="col-lg-10 col-md-9 pt-3"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#EAF0FA",
            }}
          >
            {loading && <PreLoader />}
            <div>
              <NavBarEx />
            </div>
            <div className="row pt-4">
              <div
                className="col-lg-8 col-md-8 col-sm-8 col-8"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ alignSelf: "center" }}
                  onClick={() => navigate(-1)}
                >
                  <img
                    width={30}
                    height={30}
                    src={Backicon}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Heading
                  paddingLeft={"4%"}
                  fontSize={"24px"}
                  fontWeight={600}
                  name={returnData?.customer?.name}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="pt-4">
                  <Card>
                    <div className="px-4 py-3 row">
                      <div className="col-4">
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Return Request Id:{" "}
                          </span>
                          #{returnData?._id}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Reason to return:{" "}
                          </span>
                          {returnData?.reason}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Requested on:
                          </span>{" "}
                          {moment(returnData?.createdAt).toString()}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Status: </span>
                          {returnData?.status}
                        </p>
                      </div>
                      <div className="col-4">
                        <p>
                          <span style={{ fontWeight: "bold" }}>Order Id:</span>{" "}
                          #{returnData?.orderId?._id}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Delivered on:
                          </span>{" "}
                          {moment(returnData?.orderId?.updatedAt).toString()}
                        </p>
                      </div>
                      {returnData?.status === "PLACED" ? (
                        <div className="col-4 text-end">
                          <Button
                            name={"Approve Request"}
                            width="fit-content"
                            onClick={() => {
                              aprooveReturn();
                            }}
                          />
                          <Button
                            name={"Cancel Request"}
                            className="mx-2"
                            width="fit-content"
                            onClick={() => {
                              cancelReturnRequest();
                            }}
                            bgColor={"tomato"}
                          />
                        </div>
                      ) : (
                        returnData?.status !== "CANCELLED" &&
                        returnData?.status !== "REFUND_INITIATED" && (
                          <div className="col-4 text-end">
                            {selectedRows.length > 0 && (
                              <Button
                                name={"Create Shipment"}
                                width="fit-content"
                                onClick={async () => {
                                  try {
                                    let pickups = [
                                      ...new Set(
                                        selectedRows.map(
                                          (item) => item?.pickUpAlias
                                        )
                                      ),
                                    ];
                                    if (pickups.length > 1) {
                                      return toast.error(
                                        "Please select products with same pickup location",
                                        {
                                          position: toast.POSITION.TOP_RIGHT,
                                          autoClose: 5000, // Set the duration for how long the popup should be visible
                                        }
                                      );
                                    }
                                    let data = {
                                      pickupLocation:
                                        selectedRows[0].pickUpAlias,
                                      items: selectedRows.map((item) => {
                                        return {
                                          name: item?.name,
                                          sku: item?.productData?._id,
                                          _id: item?._id,
                                          units: item?.quantity,
                                          selling_price: item?.costPerItem,
                                          weight: item?.productData?.weight
                                        };
                                      }),
                                    };

                                    const response = await axiosInstance.post(
                                      endpoints.baseUrl +
                                        endpoints.createReturnOrderShipment.replace(
                                          ":id",
                                          id
                                        ),
                                      data
                                    );
                                    console.log(response.data)
                                    await fetchOrdersByIdData()
                                  } catch (error) {
                                    console.log(error)
                                  }
                                }}
                              />
                            )}
                            <Button
                              name={"Initate Refund"}
                              className={"mx-2"}
                              width="fit-content"
                              onClick={() => {
                                initiateRedfund();
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                    <div className="pt-3">
                      <Card bgColor="#fff">
                        <div className="px-4 py-3" style={{ height: 400 }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                              },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection={returnData?.status === "PENDING"}
                            style={{ border: "none" }}
                            onRowSelectionModelChange={(ids) => {
                              let data = rows.filter((row) =>
                                ids.includes(row.id)
                              );
                              console.log(data);
                              setSelectedRows(data);
                            }}
                            isRowSelectable={(params) =>
                              !params?.row?.shipmentId
                            }
                          />
                        </div>
                      </Card>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ReturnDescription;
