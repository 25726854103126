import React, { useEffect, useState } from "react";
import Card from "../../../common/Card";
import Sidebar from "../../../common/SideBar";
// import NavBar from "../../../common/NavBar";
import NavBarEx from "../../../common/NavBarEx";
import styles from "../catalog/Catalog.module.css";
import dustbin from "../../../Assets/icon_trash.png";
import Heading from "../../../common/Heading";
import Button from "../../../common/Button";
import colors from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
// import DropdownButton from "../../../common/DropDownButton";
// import CreateCatalog from "./CreateCatalog";
// import { saveAs } from "file-saver";
// import NewProduct from "../../../components/Supllier/NewProduct";
// import AddProductCatalogForm from "../../../components/Supllier/AddProductCatalogForm";
import WarningPop from "../../../common/WarningPop";
import endpoints from "../../../utils/endpoints";
import axiosInstance from "../../../utils";
import PreLoader from "../../../common/PreLoader";
import jsPDF from "jspdf";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddProductPopUp from "./AddProductPopUp";
import Backicon from "../../../Assets/backicon.png";
import axios from "axios";
import baseUrl from "../../../utils/baseUrls";
export default function Catalog() {
  const params = useParams();
  const id = params.id;
  const deleteHandler = (index) => {
    console.log("from delete handler product screen", products[index]._id);
    deleteProduct(products[index]._id);
    setWarning(0);
  };

  const deleteProduct = async (prodId) => {
    try {
      const data = {
        productId: prodId,
      };
      // Make the DELETE request to the API
      const response = await axiosInstance.delete(
        endpoints.baseUrl + `admin/${endpoints.addProductToCatalog}${id}/item`,
        data
      );
      console.log(response.data.message);
      // If successful, remove the deleted category from the categoriesFromApi state
      setProducts((prevState) =>
        prevState.filter((prod) => prod._id !== prodId)
      );
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  const [catalogName, setCatlogName] = useState()
  const [products, setProducts] = useState();
  const fetchAllProductForCatalog = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getProductsByCatalogId + id;
    console.log(url);
    try {
      const response = await axiosInstance.get(url);
      console.log(response.data.data, "************************");
      setCatlogName(response.data.data.name)
      setProducts(response.data.data.products);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const deleteCatalog = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getProductsByCatalogId + id;
    console.log(url);
    try {
      await axiosInstance.delete(url);
      navigate("/agent/catalogList")
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const addProductToParticularCatalog = async () => { };
  useEffect(() => {
    fetchAllProductForCatalog();
  }, []);


  const downloadPDF = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}catalog/${id}/download`,
        {
          responseType: 'arraybuffer',
        });

      // Create a Blob from the PDF data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute and click the link to trigger the download
      link.download = `${catalogName}.pdf`;
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading PDF', error);
    }
  };

  // Sample data for PDF generation
  const temp = {
    _id: "64bad286366dbb60869883c0",
    name: "lalit ki dukkan",
    products: ["product1", "product2", "product3"],
    createdAt: "2023-07-21T18:46:30.863Z",
    updatedAt: "2023-07-21T20:24:05.982Z",
    __v: 0,
  };

  // Call the function with the sample data to generate and download the PDF
  // downloadPDF(temp);

  // const [open, setOpen] = useState(0);
  // const showPopUp = (value) => {
  //   setOpen(value);
  // };
  // const closePopUp = () => {
  //   setOpen(0);
  // };
  const [data, setData] = useState([1, 20, 3, 4, 5, 6, 7]);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  useEffect(() => { }, [data]);

  const deleteElement = (index, ar) => {
    for (let i = 0; i < ar.length; i++) {
      if (i < index) {
        console.log("delete index ->", i);
        continue;
      }
      ar[i] = ar[i + 1];
    }
    ar.pop();
    // data=[...ar]
    console.log(data);
    return ar;
  };

  const removeAll = async () => {
    try {
      // Make the DELETE request to the API
      const response = await axiosInstance.delete(
        endpoints.baseUrl +
        `admin/${endpoints.removeAllProductsFromCatalog}${id}/item/remove/all`
      );
      console.log(response.data.message);
      fetchAllProductForCatalog();
      setData([]);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }

    setData([]);
  };
  const [popupOpen, setPopupOpen] = useState(false);
  const handleVerifyClick = (e) => {
    // e.preventDefault();
    setPopupOpen(true);
  };
  const PopUpclose = () => {
    setPopupOpen(false);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  return (
    <>
      {/* <NavBar buttonName={"Create New Catalog"} buttonFlag={true} /> */}

      <div className={`container-fluid `}>
        <div className="row">
          {/* giving the grid of 2 to side nav */}
          <div
            className={`col-lg-2 col-md-3 pe-0  ${styles.left}`}
            style={popupOpen ? { filter: "blur(2px)" } : {}}
          >
            <Sidebar flag={true} activeTabColor="Catalog" />
          </div>

          <div
            className="col-lg-10 col-md-9 right pb-4 "
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: colors.bgcolor,
            }}
          >
            {loading && <PreLoader />}
            <div style={popupOpen ? { filter: "blur(2px)" } : {}}>
              <div className="py-3">
                <NavBarEx
                  flag={false}
                  buttonName={"Create Catalog"}
                  buttonFlag={false}
                  // popUp={showPopUp}
                  fontSize="15px"
                  search={true}
                  siderBarButton
                />
              </div>
              <div className="row pt-3 align-items-center pb-3">
                <div className="text-start pb-3">
                  <img
                    src={Backicon}
                    alt="Back Icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/agent/catalogList")}
                  />
                </div>
                <div className="col-sm-6">
                  <div className="row align-items-center">
                    <div className="col-xl-5">
                      <Heading
                        name={catalogName}
                        fontSize="24px"
                        fontWeight={600}
                      />
                    </div>
                    <div className=" col-xl-7 col-xxl-6 pt-2 pt-xl-0">
                      <div className="row">
                        <div
                          className="col-6 text-center text-lg-end "
                          onClick={removeAll}
                        >
                          <Button
                            marginLeft="2px"
                            marginRight="2px"
                            width="fit-content"
                            name="Remove All"
                            borderRadius={8}
                            btnTextColor="white"
                            paddingLeft="8px"
                            paddingRight="8px"
                            fontSize="15px"
                          />
                        </div>
                        <div
                          className="col-6 text-center text-lg-start"
                          onClick={handleVerifyClick}
                        >
                          <Button
                            marginLeft="2px"
                            marginRight="2px"
                            width="fit-content"
                            name="Add products"
                            borderRadius={8}
                            btnTextColor="white"
                            paddingLeft="8px"
                            paddingRight="8px"
                            fontSize="15px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row justify-content-end align-items-center pt-5  pt-xl-0 ">
                    <div className="col-6 col-lg-4 col-xxl-3 text-center text-sm-end pe-xl-0">
                      <Button
                        marginLeft="2px"
                        marginRight="2px"
                        width="fit-content"
                        name="Delete"
                        borderRadius={8}
                        btnTextColor="white"
                        paddingLeft="8px"
                        paddingRight="8px"
                        fontSize="15px"
                        onClick={() => deleteCatalog()}
                      />
                    </div>
                    <div
                      className="col-6 col-lg-4 col-xxl-3 text-center text-sm-end ps-lg-0"
                      onClick={downloadPDF}
                    >
                      <Button
                        marginLeft="2px"
                        marginRight="2px"
                        width="fit-content"
                        name="Download pdf"
                        borderRadius={8}
                        btnTextColor="white"
                        paddingLeft="8px"
                        paddingRight="8px"
                        fontSize="15px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="row"
                  style={{
                    overflowY: "auto",
                    maxHeight: "750px",
                    paddingTop: "25px",
                  }}
                >
                  {products?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="col-lg-3 col-md-4 col-sm-6 mt-3"
                      >
                        <Card bgColor="#fff" width="100%">
                          <div
                            className={`${styles.container}`}
                            style={{ position: "relative" }}
                          >
                            <div onClick={() => navigate("/agent/shopProduct/" + item?._id)}>
                              <div
                                style={{
                                  height: "350px",
                                  // width: "250px",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  style={{
                                    cursor: "pointer",
                                    // height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={item.color[0].images[0]}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  position: "absolute",
                                  height: "90px",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                }}
                                className="w-100 "
                              >
                                <div className="ps-2">
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Kurta sets
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <p
                                      className="mb-0"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      ₹{item.color[0].sizes[0]?.price + item?.profitMargin - item?.companyDiscount}/-
                                    </p>
                                    <p
                                      className="ps-3 mb-0"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {((item?.companyDiscount
                                        / (item.color[0].sizes[0]?.price + item?.profitMargin)) * 100).toPrecision(2)}% off
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                paddingTop: "8px",
                                position: "absolute",
                                top: "2%",
                                left: "3%",
                                cursor: "pointer",
                              }}
                              onClick={(event) => {
                                setActive(index);
                                warningHandler();
                              }}
                            >
                              <img
                                width={15}
                                height={20}
                                src={dustbin}
                                alt="Trash Icon"
                              />
                            </div>
                          </div>
                        </Card>

                        {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          paddingTop: "8px",
                        }}
                      >
                        <img
                          width={15}
                          height={20}
                          src={dustbin}
                          alt="Trash Icon"
                        />
                      </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {open ? (
          <div className={` ${styles.popcontainer}`}>
            <div style={{ position: "relative", zIndex: "100" }}>
              <CreateCatalog closePopUp={closePopUp} />
            </div>
          </div>
        ) : null} */}
        {popupOpen && (
          <div className={` ${styles.AddProductPopUp}`}>
            <div style={{ position: "relative", zIndex: "100" }}>
              <AddProductPopUp
                fetchAllProductForCatalog={fetchAllProductForCatalog}
                id={id}
                closePopUp={PopUpclose}
              />
            </div>
          </div>
        )}
        {warning ? (
          <div
            style={{
              width: "35%",
              height: "50%",
              position: "absolute",
              left: "30%",
              top: "50%",
              zIndex: 1000,
            }}
          >
            <WarningPop
              delete={() => deleteHandler(active)}
              closePopUp={(val) => {
                warningHandler(val);
              }}
              message="Are you sure you want to accept the request?"
            />
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </>
  );
}
