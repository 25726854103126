import { React, useEffect, useState } from "react";
import Backicon from "../../../Assets/backicon.png";
import Button from "../../../common/Button";
import endpoints from "../../../utils/endpoints";
import axiosInstance from "../../../utils";
import { toast } from "react-toastify";

const AddProductPopUp = (props) => {
  const [productsForCatalog, setProductsForCatalog] = useState([]);
  const [categoryNames, setCategoryNames] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  // const getProductIdByName = (name) => {
  //   for (const product of productsForCatalog) {
  //     if (product.name === name) {
  //       return product._id;
  //     }
  //   }
  //   return null;
  // };
  const fetchProducts = async () => {
    let url = endpoints.baseUrl + "admin/" + endpoints.productInventory;
    if (selectedCategory && selectedSubCategory) {
      url += `?categoryId=${selectedCategory}&subcategoryId=${selectedSubCategory}`;
    }
    try {
      const response = await axiosInstance.get(url);

      setProductsForCatalog(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      // console.error("Error fetching products:", error);
    }
  };
  const fetchCategories = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct;
    // console.log("**************", url);
    try {
      const response = await axiosInstance.get(url);
      setCategoryNames(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const fetchSubCategories = async (categoryId) => {
    setSubCategory([]);
    const url =
      endpoints.baseUrl +
      "admin/" +
      endpoints.getAllCategoriesInProduct +
      "?parent=" +
      categoryId;
    try {
      const response = await axiosInstance.get(url);
      const subcategories = response.data.data;
      if (subcategories.length === 0) {
        // If no subcategories are available for the selected category, show an error toast.
        toast.error("No SubCategory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
      setSubCategory(subcategories);
    } catch (error) {
      // Handle the error as before.
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    fetchProducts();
  }, [selectedCategory, selectedSubCategory]);

  const [productName, setproductName] = useState();
  const closePopUpHandeler = () => {
    props.closePopUp();
  };

  const closePopUp = () => {
    props.closePopUp();
    const product = productsForCatalog.filter(
      (prod) => prod.name === productName
    )[0];
    console.log(product);
    addProductToCatalog(product._id);
  };

  const addProductToCatalog = async (id) => {
    try {
      const data = {
        productId: id,
      };
      const response = await axiosInstance.post(
        `${endpoints.baseUrl}admin/${endpoints.getProductsByCatalogId}${props.id}/item`,
        data
      );
      props.fetchAllProductForCatalog();
      console.log(response.data.data);
    } catch (error) {
      // Handle errors here
      console.error("Error:", error.message);
    }
  };

  const handleProductNameChange = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    setproductName(selectedValue);
    // console.log("Category Id:", selectedValue);
  };
  return (
    <div
      className=" container-fluid py-5"
      style={{ border: "2px solid #4F81C3", backgroundColor: "#fff" }}
    >
      <img
        src={Backicon}
        onClick={closePopUpHandeler}
        style={{ cursor: "pointer" }}
        alt="back"
      />
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 pt-3">
          <label
            style={{
              fontWeight: 500,
              fontSize: 15,
              display: "block",
              textAlign: "start",
            }}
            className="pb-2"
          >
            Category
          </label>
          <select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              fetchSubCategories(e.target.value);
            }}
            style={{
              display: "block",
              backgroundColor: "#F3F1F1",
              width: "100%",
              border: "none",
              borderRadius: 10,
            }}
            className="ps-2 py-2 mt-1"
            name="categoryId"
            required
          >
            <option value="" disabled className="d-none">
              Select Category
            </option>
            {categoryNames?.map((item, index) => (
              <option key={index} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 pt-3">
          <label
            style={{
              fontWeight: 500,
              fontSize: 15,
              display: "block",
              textAlign: "start",
            }}
            className="pb-2"
          >
            Subcategory
          </label>
          <select
            value={selectedSubCategory}
            onChange={(e) => setSelectedSubCategory(e.target.value)}
            style={{
              display: "block",
              backgroundColor: "#F3F1F1",
              width: "100%",
              border: "none",
              borderRadius: 10,
            }}
            className="ps-2 py-2 mt-1"
            name="subcategoryId"
            required
          >
            <option value="" disabled className="d-none">
              Select Subcategory
            </option>
            {subCategory?.map((item, index) => (
              <option key={index} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pt-4">
          <label
            style={{
              fontWeight: 500,
              fontSize: 15,
              display: "block",
            }}
            className="pb-2"
          >
            Products
          </label>
          <input
            type="text"
            list="data"
            onChange={handleProductNameChange}
            style={{
              display: "block",
              backgroundColor: "#F3F1F1",
              width: "100%",
              border: "none",
              borderRadius: 10,
            }}
            className="ps-2 py-2 mt-1"
          />
          <datalist value={productName} id="data">
            <option value="" className="d-none">
              Select Product
            </option>
            {productsForCatalog.map((option, index) => {
              return <option key={index} value={option.name} />;
            })}
          </datalist>
        </div>
      </div>
      <div className=" text-center pt-4" onClick={closePopUp}>
        <Button name="Submit" />
      </div>
    </div>
  );
};

export default AddProductPopUp;
