import React, { useState, useRef, useEffect } from "react";
import Heading from "../../common/Heading";
import Button from "../../common/Button";
import styles from "./ForgotPassword.module.css";
import OtpForm from "./OtpForm";
import endpoints from "../../utils/endpoints";
import axios from "axios";
import rightImg from "../../Assets/login_image.jpg";
import { ToastContainer, toast } from "react-toastify";

export default function () {
  const orContainer = {
    display: "flex",
    justifyContent: "center",
  };
  const popcontainer = {
    width: "320px",
    position: "absolute",
    top: "35%",
    left: "40%",
    border: "1px solid #DDDDE0",
    borderRadius: "15px",
    backgroundColor: "#fff",
  };
  const [popupOpen, setPopupOpen] = useState(false);
  const [email, setEmail] = useState("");
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleVerifyClick = async (e) => {
    e.preventDefault();
    console.log("Input Value:", email); // Logging the input value
    try {
      // Create the request body in JSON format
      const requestBody = {
        email: email,
      };

      // Make the API request using Axios
      const response = await axios.post(
        endpoints.baseUrl + endpoints.forgotPassword,
        requestBody
      );
      console.log(response.data.data);
      if (response.data.code === 200) {
        // setPopupOpen(true);
        toast.success("link to reset password has been sent to your email id", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    } catch (error) {
      // Handle error (if any) from the API response
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const loginFormContainer = {
    // height:'100vh',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    //     display: "grid",
    // placeItems: "center",
  };
  const left = {
    backgroundColor: "#fff",
    height: "100vh",
  };
  const right = {
    backgroundColor: "#4F81C3",
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${rightImg})`,
  };
  return (
    <div className="container-fluid full-height-container">
      <div className="row">
        {/* left part contains form */}
        <div className="col-sm-6 left" style={left}>
          <div style={loginFormContainer}></div>
          <div
            className={styles.parent}
            style={{ paddingTop: "20px" }}
            ref={popupRef}
          >
            <Heading name="Forget Password" textAlign="center" />
            <div>
              <p className="text-center pt-5" style={{ fontSize: "24px" }}>
                In order to reset your password please enter your registered
                Email Id.
              </p>
              <form className="pt-5" onSubmit={(e) => handleVerifyClick(e)}>
                <div>
                  <input
                    style={{ width: "90%", margin: "auto" }}
                    type="text"
                    className="form-control"
                    value={email} // Bind the input value to the state
                    onChange={handleInputChange} // Handle input change
                    required
                  />
                </div>
                <div
                  className="pt-5 pb--3"
                  style={{ alignSelf: "center", display: "flex" }}
                >
                  <Button
                    name="Verify"
                    width="100%"
                    height="50px"
                    borderRadius="10px"
                    type={"submit"}
                  />
                </div>
              </form>
              {popupOpen && (
                <div style={popcontainer}>
                  <OtpForm />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-none d-sm-block col-sm-6 right" style={right}></div>
      </div>
      <ToastContainer />
    </div>
  );
}
