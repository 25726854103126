import React, { useState, useEffect } from "react";
import Sidebar from "../../common/SideBar";
import Heading from "../../common/Heading";
import Card from "../../common/Card";
import NavBarEx from "../../common/NavBarEx";
import { CustomLineChart } from "../../common/CustomLineChart";
import TableRow from "../../components/Table/TableRow";
import stylesCustomer from "./Customer.module.css";
import SpecificCustomerDetails from "../../components/SpecificCustomerDetails";
import { useNavigate } from "react-router-dom";
import Lehenga from "../../Assets/lehenga.png";
import Saree from "../../Assets/saree.png";
import Suit from "../../Assets/suit.png";
import shirt from "../../Assets/shirt.png";
import "../../common/Fonts.css";
import CustomerListTable from "../../components/Customer/CustomerListTable";
import AllTable from "../../components/AllTable";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../common/Button";

export default function Customer() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const graphoptions = ["Yearly", "Monthly", "Weekly"];
  const [graphoption, setgraphoption] = useState("Monthly");
  function fetchTotalAmountsForYear(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const totalAmount = item.totalAmount;

      yearsArray.push(year);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForMonth(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const month = item._id.month;
      const totalAmount = item.totalAmount;

      yearsArray.push(`${months[month - 1]} ${year}`);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForWeekly(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const week = item._id.week;
      const totalAmount = item.totalAmount;

      yearsArray.push(`week ${week} of ${year}`);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  const [amountsForGraph, setAmountsForGraph] = useState();
  const fetchDataFroGraph = async (key) => {
    try {
      if (key == "Yearly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.customerGraph +
          "?groupBy=yearly"
        );
        const amounts = fetchTotalAmountsForYear(response.data.data);
        setAmountsForGraph(amounts);
      }
      if (key == "Monthly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.customerGraph +
          "?groupBy=monthly"
        );
        // setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForMonth(response.data.data);
        setAmountsForGraph(amounts);
      }
      if (key == "Weekly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.customerGraph +
          "?groupBy=weekly"
        );
        // setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForWeekly(response.data.data);
        setAmountsForGraph(amounts);
      }
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [review, setReview] = useState([]);
  const compareById = (a, b) => {
    if (a._id > b._id) return -1;
    if (a._id < b._id) return 1;
    return 0;
  };
  const fetchDataForReviews = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.reviews
      );

      const sortedArrayDescending = response.data.data
        .slice()
        .sort(compareById);
      setReview(sortedArrayDescending);
    } catch (error) {
      // Handle errors here
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [reviewTrends, setreviewTrends] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const allCustomersReviews = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.reviews
      );
      let totalreviews = response.data.data.reduce(
        (sum, a) => sum + a.count,
        0
      );
      setTotalReviews(totalreviews);
      let review = [
        {
          _id: 5,
          count:
            response.data.data.filter((val) => val._id === 5)[0]?.count || 0,
        },
        {
          _id: 4,
          count:
            response.data.data.filter((val) => val._id === 4)[0]?.count || 0,
        },
        {
          _id: 3,
          count:
            response.data.data.filter((val) => val._id === 3)[0]?.count || 0,
        },
        {
          _id: 2,
          count:
            response.data.data.filter((val) => val._id === 2)[0]?.count || 0,
        },
        {
          _id: 1,
          count:
            response.data.data.filter((val) => val._id === 1)[0]?.count || 0,
        },
      ];
      setreviewTrends(review);
    } catch (error) {
      console.error(error);
    }
  };
  const percentile = (part, total) => {
    if (part === 0 || total === 0) return 0;
    const percentage = (Number(part) / Number(total)) * 100;
    return percentage.toFixed(0).toString();
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };
  const CategryItems = [
    "Lehenga",
    "Saree",
    "Shirt",
    "Suit",
    "Lehenga",
    "Saree",
    "Shirt",
    "Suit",
    "Lehenga",
    "Saree",
    "Shirt",
    "Suit",
  ];

  const headerdata = [
    {
      header1: "Name",
      header2: "Contact Details",
      header3: "Gender",
      header4: "Dob",
      header5: "Total orders",
      header6: "Shipping address",
      header7: "View",
      header8: "Delete",
      // header14: "View",
      // header15: "Delete",
    },
  ];
  const [mappedDataForTable, setMappedDataForTable] = useState([]);
  const [customerPreffrence, setcustomerPreffrence] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFromApi, setdataFromApi] = useState([]);
  const fetchDataOFAllCustomer = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allCustomers + "?limit=5"
      );
      setdataFromApi(response.data.data);
      const reciveData = mapDataToTableFormat(response.data.data);
      setMappedDataForTable(reciveData);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const fetchDataOFCustomerPreffrence = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.productPrefrences
      );
      setcustomerPreffrence(response.data.data);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const mapDataToTableFormat = (data) => {
    return data.map((item) => {
      const { _id, name, mobileNumber, email, address } = item;

      return {
        item0: _id,
        item1: name || "---------",
        item2: email ? email : mobileNumber || "---------",
        item3: "---------------",
        item4: "---------",
        item5:
          address?.length > 0
            ? `${address[0].street}, ${address[0].city}, ${address[0].state}, ${address[0].country} - ${address[0].pin}`
            : "---------",
      };
    });
  };

  const handleSelectgraphoptionChange = (event) => {
    const graphoptionValue = event.target.value;
    setgraphoption(graphoptionValue);
  };
  const deleteCustomer = async (tempId) => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.deleteCustomer + tempId; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      fetchDataOFAllCustomer();
      toast.success("customer deleted succesfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
      console.log("Delete response------------------->:", response.data.data);
    } catch (err) {
      // console.log("Delete error:", err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  useEffect(() => {
    fetchDataOFCustomerPreffrence();
    setTimeout(() => {
      setLoading(false);
      // document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  useEffect(() => {
    allCustomersReviews();
    fetchDataForReviews();
    fetchDataOFAllCustomer();
  }, []);
  useEffect(() => {
    fetchDataFroGraph(graphoption);
  }, [graphoption]);
  return (
    <>
      {/* <NavBar/> */}
      <div>
        <div className="container-fluid">
          <div className="row">
            {/* sidebar */}
            <div className={`col-lg-2 col-md-3 pe-0  ${stylesCustomer.left}`}>
              <Sidebar activeTabColor="Customers" />
            </div>
            {/* customer-list content */}
            <div
              className="col-lg-10 col-md-9 pb-4"
              style={{
                overflowY: "auto",
                height: "100vh",
                backgroundColor: "#EAF0FA",
              }}
            >
              {loading && <PreLoader />}
              {/* navbar */}
              <div className="py-3">
                <NavBarEx
                  search={true}
                  // buttonName={"Create New Customer"}
                  // buttonFlag={true}
                  buttonFlag={false}
                  popUp={showPopUp}
                />
              </div>
              <div className="row pt-3">
                {/* linechart */}
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 ">
                  <Card width="100%" height="100%">
                    <div className="pt-3 row align-items-center pb-2">
                      <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-5">
                        <Heading
                          paddingLeft={"4%"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="New customers "
                        />
                      </div>
                      <div className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-3">
                        <select
                          value={graphoption}
                          onChange={handleSelectgraphoptionChange}
                          style={{
                            display: "block",
                            backgroundColor: "#E4EEF8",
                            width: "100%",
                            display: "block",
                            border: "none",
                            borderRadius: 10,
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                          className="ps-2 py-2 mt-1 add-new-agent"
                        >
                          <option value="" disabled className="d-none">
                            Weekly
                          </option>
                          {graphoptions.map((option, index) => (
                            <option
                              key={index}
                              value={option}
                              style={{ backgroundColor: "#F5F5F5" }}
                            >
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <CustomLineChart
                      amountsForGraph={amountsForGraph}
                      height={"83%"}
                    />
                  </Card>
                </div>
                {/* customer-prefrence */}
                <div
                  className={`col-xl-3 col-lg-6 col-md-6 col-sm-6 mt-3 mt-xl-0 ${stylesCustomer.mt}`}
                >
                  <Card height={"100%"} bgColor={"#FFFFFF"} borderwidth="0">
                    <div className="p-3">
                      <Heading
                        paddingLeft={"4%"}
                        fontSize={"24px"}
                        fontWeight={600}
                        name="Customer Prefrences"
                      />
                    </div>
                    <div
                      style={{
                        overflowY: "hidden",
                        overflowX: "hidden",
                        maxHeight: "260px",
                      }}
                    >
                      <div
                        className="px-5 align-items-center"
                        style={{ overflow: "hidden" }}
                      >
                        {customerPreffrence.length > 0 ? (
                          customerPreffrence.map((item, index) => {
                            return (
                              <div className="row" key={index}>
                                <div
                                  className="col-10 pt-3 "
                                  style={{
                                    fontWeight: "16px",
                                    fontSize: "500",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item._id.name}
                                </div>
                                <div
                                  className="col-2 pt-3 "
                                  style={{
                                    fontWeight: "16px",
                                    fontSize: "500",
                                  }}
                                >
                                  {item?.num_orders}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="p-5">No products found</p>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
                {/* reviews */}
                <div
                  className={`col-xl-3 col-lg-6 col-md-6 col-sm-6 mt-3 mt-xl-0  ${stylesCustomer.mt}`}
                >
                  <Card className="px-3" height={"100%"} borderwidth={"0"}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingRight: "4%",
                      }}
                      className="pt-3"
                    >
                      <Heading
                        paddingLeft={"4%"}
                        fontSize={"24px"}
                        fontWeight={600}
                        name="Reviews"
                      />
                      {/* <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        View All
                      </div> */}
                    </div>

                    <div style={{ padding: "5%" }} className="pt-2">
                      {reviewTrends.map((item, index) => {
                        const colors = [
                          "#0468C4",
                          "#5391CA",
                          "#74B3D7",
                          "#A8CDD9",
                          "#D0F4FF",
                        ]; // Array of colors
                        const progressColor = colors[index];
                        let percentage = percentile(item.count, totalReviews);
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            <div
                              className="mt-4"
                              style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                width: "10%",
                              }}
                            >
                              {item._id}
                            </div>
                            <div
                              style={{ width: "60%", height: "10px" }}
                              className="progress mt-4"
                            >
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${percentage}%`,
                                  backgroundColor: progressColor,
                                }}
                                aria-valuenow={percentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              />
                            </div>
                            <div className="mt-4" style={{ width: "10%" }}>
                              {item.count}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </div>
                {/* customer-list table */}
                <div
                  className="col-lg-12 col-md-12 mt-4"
                  style={{ overflowX: "auto" }}
                >
                  <Card bgColor={"#FFFFFF"} borderColor={"#fff"}>
                    <div
                      className="pt-3 pb-3 d-flex justify-content-between"
                      style={{ paddingLeft: "2%", paddingRight: "2%" }}
                    >
                      <div>
                        <Heading
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Customers List"
                          fontFamily={"InterSemiBold"}
                        />
                      </div>
                      <div
                        className="col-4 col-sm-3 col-lg-4 pt-sm-0 text-end"
                        style={{ paddingTop: "3px" }}
                      >
                        <Button
                          name="View all"
                          fontSize="15px"
                          onClick={() => navigate("/admin/customer/all")}
                        />
                      </div>
                      {/* <div>
                        <AllTable
                          // data={data}
                          header={headerdata}
                          mappedDataForTable={mappedDataForTable}
                          redeye
                          trash
                          item5
                          item14
                          item15
                          headingname={"Customers list"}
                          activeTabColor="Customers"
                          searchicon
                          navigation={"/admin/specificcustomer"}
                          deleteHandler={(customer) => deleteCustomer(customer)}
                        />
                      </div> */}
                    </div>
                    <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
                      <CustomerListTable
                        data={dataFromApi}
                        header={headerdata}
                        fetchDataOFAllCustomer={fetchDataOFAllCustomer}
                      />
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
