import React, { useEffect, useState } from "react";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import Heading from "../../common/Heading";
import Sidebar from "../../common/SideBar";
import NavBarEx from "../../common/NavBarEx";
import { DataGrid } from "@mui/x-data-grid";
import redeye from "../../Assets/remove_red_eye.png";
import styles from "./Merchant.module.css";
import colors from "../../utils/helper";
import PreLoader from "../../common/PreLoader";
import Card from "../../common/Card";
import trashicon from "../../Assets/icon_trash.png";
import editicon from "../../Assets/edit.svg";
import Backicon from "../../Assets/backicon.png";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import WarningPop from "../../common/WarningPop";
import MerchantEditForm from "../../components/MerchantEditForm";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function AllMerchant() {
  const navigate = useNavigate();
  const [dataFromApi, setdataFromApi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [warning, setWarning] = useState(false);
  const [editActive, setEditActive] = useState();
  const [active, setActive] = useState(-1);
  const [rows, setRows] = useState([]);
  const [requests, setRequests] = useState([]);
  const PopUpclose = () => {
    setPopupOpen(false);
  };
  const handleVerifyClick = (e) => {
    // e.preventDefault();
    // console.log(e,"__________")
    setEditActive(e);
    setPopupOpen(true);
  };
  const deleteMerchant = async (merchantId) => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.merchantbyId + merchantId; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      // console.log("Delete response------------------->:", response.data);
    } catch (err) {
      // console.log("Delete error:", err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const deleteHandler = async (val) => {
    try {
      const merchantId = val._id;
      await deleteMerchant(merchantId);
      fetchData();
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }

    setWarning(0);
  };
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };
  const columns = [
    { flex: 1, field: "id", headerName: "Sno" },
    { flex: 1, field: "name", headerName: "Name" },
    { flex: 1, field: "email", headerName: "Email ID" },
    { flex: 1, field: "shopId", headerName: "Shop" },
    { flex: 1, field: "mobileNumber", headerName: "Contact" },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      resizable: true,
    },
    {
      flex: 1,
      field: "totalOrders",
      headerName: "Total Orders",
      type: "number",
    },
    { flex: 1, field: "totalSales", headerName: "Total Sales", type: "number" },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => handleVerifyClick(params.row._id)}
            >
              <img
                style={{ height: "15px", cursor: "pointer" }}
                src={editicon}
                alt="edit"
              />
            </button>
          </>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
            navigate("/admin/specificMerchant" + "/" + params.row._id);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={onClick}
            >
              <img src={redeye} style={{ width: "20px" }} />
            </button>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => {
                setActive(params.row);
                warningHandler();
              }}
            >
              <img src={trashicon} alt="Delete" />
            </button>
          </>
        );
      },
    },
  ];
  const requestColumns = [
    { flex: 1, field: "id", headerName: "Sno" },
    { flex: 1, field: "name", headerName: "Name" },
    { flex: 1, field: "email", headerName: "Email ID" },
    { flex: 1, field: "mobileNumber", headerName: "Contact" },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      resizable: true,
    },
    {
      field: "docs",
      headerName: "Documents",
      sortable: false,
      flex: 1,
      resizable: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            <Link to={params?.row?.pan} target="_blank">
              <img src={params?.row?.pan} height={50} />
            </Link>
            {params?.row?.aadhaarCard.map((item, index) => (
              <Link key={index} to={item} target="_blank">
                <img src={item} height={50} />
              </Link>
            ))}
          </div>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
            navigate("/admin/specificMerchant" + "/" + params.row._id);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={onClick}
            >
              <img src={redeye} style={{ width: "20px" }} />
            </button>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => {
                setActive(params.row);
                warningHandler();
              }}
            >
              <img src={trashicon} alt="Delete" />
            </button>
          </>
        );
      },
    },
  ];
  const fetchData = async () => {
    try {
      setLoading(true);
      await getapprovedAgents();
      await getUnapprovedAgents();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getapprovedAgents = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allMerchants
      );
      console.log("data");
      console.log(response.data.data);
      let data = response.data.data.map((item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });
      setRows(data);
    } catch (error) { }
  };
  const getUnapprovedAgents = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.unapprovedMerchants
      );
      console.log("data");
      console.log(response.data.data);
      let data = response.data.data.map((item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });
      setRequests(data);
    } catch (error) { }
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-lg-2 col-md-3 ${styles.left}`}
            style={{ paddingRight: "0px" }}
          >
            <Sidebar active={true} activeTabColor="Merchants" />
          </div>

          <div
            className="col-lg-10 col-md-9 right py-3"
            style={{
              backgroundColor: colors.bgcolor,
              overflowY: "auto",
              height: "100vh",
              flexWrap: "wrap",
            }}
          >
            {loading && <PreLoader />}
            <div className="pt-3">
              <NavBarEx
                buttonFlag={false}
                buttonName={"Add new customer"}
                fontSize={"38px"}
                search={true}
              />
            </div>
            <div className="text-start pt-3 d-flex">
              <div>
                <img
                  src={Backicon}
                  alt="Back Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <Heading name="All Merchants" paddingLeft="1%" />
            </div>
            <div className="row pt-4">
              <div className="col-12">
                {/* <ReccentOrders /> */}
                <Card backgroundColor="#fff">
                  <div className="pt-4 px-2 d-flex justify-content-between align-items-center">
                    {/*  */}
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Approved Merchants" {...a11yProps(0)} />
                      <Tab label="Unapproved Merchants" {...a11yProps(1)} />
                    </Tabs>
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTabPanel value={value} index={0}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        //   checkboxSelection
                        // hideFooter
                        style={{ border: "none", minHeight: 400, }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <DataGrid
                        rows={requests}
                        columns={requestColumns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        //   checkboxSelection
                        // hideFooter
                        style={{ border: "none", minHeight: 400, }}
                        rowHeight={150}
                      />
                    </CustomTabPanel>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          {popupOpen && (
            <div className={styles.popcontainer}>
              <div style={{ position: "relative", zIndex: "20" }}>
                <MerchantEditForm
                  editActive={editActive}
                  fetchData={fetchData}
                  closePopUp={PopUpclose}
                  isbuttonVisible
                />
              </div>
            </div>
          )}
          {warning ? (
            <div
              style={{
                width: "35%",
                height: "50%",
                position: "absolute",
                left: "40%",
                top: "33%",
                zIndex: 1000000,
              }}
            >
              <WarningPop
                delete={() => deleteHandler(active)}
                closePopUp={(val) => {
                  warningHandler(val);
                }}
                message="Are you sure you want to delete this merchant?"
              />
            </div>
          ) : null}
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default AllMerchant;
