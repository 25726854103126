import React, { useState, useEffect } from "react";
import Heading from "../../common/Heading";
import Button from "../../common/Button";
import styles from "./LoginForm.module.css";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import endpoints from "../../utils/endpoints";
import axios from "axios";
import axiosInstance from "../../utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoImg from "../../Assets/bluelogo.svg";

export default function LoginForm(props) {
  const navigate = useNavigate();
  const [isToggleOn, setToggleOn] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState({});

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};

    if (username.trim() === "") {
      newErrors.username = "Username is required";
    }

    if (password.trim() === "") {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }

    if (role === "") {
      newErrors.role = "Role is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // ... your existing code

      localStorage.setItem("role", role);
      localStorage.setItem("username", username);

      console.log("Username:", username);
      console.log("Password:", password);
      console.log("Role:", role);
      // Create an object with the form data
      const formData = {
        email: username,
        pswd: password,
        type: localStorage.getItem("role") == "admin" ? "GENERAL" : "PRODUCT",
      };

      // Make a POST request to submit the form data
      console.log(endpoints.baseUrl + "admin" + "/" + endpoints.login);
      try {
        const response = await axiosInstance.post(
          endpoints.baseUrl + "admin" + "/" + endpoints.login,
          formData
        );

        // if(response.data)
        if (response.status == 200) {
          console.log(response.data.data);
          localStorage.setItem("name", response.data.data.user.name);
          localStorage.setItem("jwtToken", response.data.data.jwtAccessToken);
          localStorage.setItem(
            "userData",
            JSON.stringify(response.data.data.user)
          );
          // Perform navigation based on the role
          if (role === "admin") {
            navigate("/admin/home");
          } else if (role === "agent") {
            navigate("/agent/home");
          }
        }
        // console.log(response.status);
      } catch (error) {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          toast.error("Username and password did not match", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000, // Set the duration for how long the popup should be visible
          });
        } else if (error?.response?.status === 404) {
          toast.error("User not found", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000, // Set the duration for how long the popup should be visible
          });
        } else {
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000, // Set the duration for how long the popup should be visible
          });
        }
      }
    }
  };

  const toggleForgotPassword = () => {
    setToggleOn(!isToggleOn);
  };

  const checkStatus = async () => {
    try {
      let response = await axiosInstance.get(
        endpoints.baseUrl + endpoints.checkToken
      );
      console.log(localStorage.getItem("role"));
      if (response.data.code) {
        if (localStorage.getItem("role") === "admin") {
          navigate("/admin/home");
        } else {
          navigate("/agent/home");
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (localStorage.getItem("role") && localStorage.getItem("jwtToken"))
      checkStatus();
  }, []);
  return (
    <>
      <div className={styles.parent}>
        <div>
          <form onSubmit={handleFormSubmit}>
            {/* <Heading
              name="Login"
              textAlign="center"
              fontweight="700"
              fontsize="25px"
              color="#4F81C3"
            /> */}
            <div className=" d-flex align-items-center justify-content-center">
              <div className="text-end col-3">
                <img className="w-100" src={logoImg} />
              </div>
              <div className="ps-3">
                <p
                  className={styles.fsXxl}
                  style={{
                    color: "#4F81C3",

                    fontWeight: "500",
                  }}
                >
                  MY MEGA MALL
                </p>
                <p
                  className={styles.fsSm}
                  style={{
                    color: "#4F81C3",

                    fontWeight: "500",
                  }}
                >
                  Login Account
                </p>
              </div>
            </div>

            <div className="pt-4">
              <label
                htmlFor="exampleInputEmail1"
                style={{ fontWeight: "500", fontSize: "16px" }}
              >
                Username
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={username}
                onChange={handleUsernameChange}
                style={{ border: "1px solid #4F81C3" }}
              />
              {errors.username && (
                <span style={{ color: "red" }} className="error-message">
                  {errors.username}
                </span>
              )}
            </div>
            <div className="pt-3">
              <label
                htmlFor="exampleInputPassword1"
                style={{ fontWeight: "500", fontSize: "16px" }}
              >
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                value={password}
                onChange={handlePasswordChange}
                style={{ border: "1px solid #4F81C3" }}
              />
              {errors.password && (
                <span style={{ color: "red" }} className="error-message">
                  {errors.password}
                </span>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <p
                onClick={() => navigate("/forgotpassword")}
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                Forgot Password ?
              </p>
            </div>
            <div className="pt-2 pb-4">
              <select
                className="form-control"
                id="exampleInputPassword1"
                value={role}
                onChange={handleRoleChange}
                style={{ backgroundColor: "#F6F5F5" }}
                placeholder="Role"
              >
                <option
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "none",
                  }}
                  value=""
                >
                  Role
                </option>
                <option
                  style={{ fontWeight: "500", fontSize: "16px" }}
                  value="admin"
                >
                  General
                </option>
                <option
                  style={{ fontWeight: "500", fontSize: "16px" }}
                  value="agent"
                >
                  Product
                </option>
              </select>
              {errors.role && (
                <span style={{ color: "red" }} className="error-message">
                  {errors.role}
                </span>
              )}
            </div>
            <div style={{ alignSelf: "center", display: "flex" }}>
              <Button
                name="Submit"
                width="fit-content"
                btnTextColor="#fff"
                type="submit"
                style={{margin:"auto"}}
              />
            </div>
          </form>
          {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          className="pt-4"
        >
          <p>Don't have an account?</p>
          <a>
            <p> &nbsp; Signup</p>
          </a>
        </div> */}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
