import React, { useState, useEffect } from "react";
// import NavBar from "../../../common/NavBar";
import NavBarEx from "../../../common/NavBarEx";
import SideBar from "../../../common/SideBar";
import Heading from "../../../common/Heading";
// import Button from "../../../common/Button";
import styles from "../supplier/Product.module.css";
import StockReport from "../../../components/Supllier/StockReport";
import AllProductList from "../../../components/Supllier/AllProductList";
import colors from "../../../utils/helper";
import AllTable from "../../../components/AllTable";
import Newproduct from "../../../components/Supllier/NewProduct";
import BulkProduct from "../../../components/Supllier/BulkProduct";
import StockReportPopUp from "../../../components/Supllier/StockReportPopUp";
import PreLoader from "../../../common/PreLoader";
import Backicon from "../../../Assets/backicon.png";
import { useNavigate } from "react-router-dom";

import AddProductCatalogForm from "../../../components/Supllier/AddProductCatalogForm";
const AllProduct = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const headerdata = [
    {
      header1: "Product name",
      header2: "Product Id",
      header3: "Category",
      header4: "SubCategory",
      header5: "Date added",
      header6: "Price",
      header7: "Quantity",
      header8: "Edit",
      header9: "Delete ",
      header10: "View ",
    },
  ];
  const [editpopupOpen, setEditPopupOpen] = useState(false);
  const [btnName, setBtnName] = useState();
  const [addCatalogForm, setAddCatalogForm] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [Open, setOpen] = useState(false);
  const handelEditOpen = (e) => {
    // e.preventDefault();
    setEditPopupOpen(true);
    // setBtnName("edit")
  };
  const handelPopUpclose = () => {
    setEditPopupOpen(false);
  };

  const [productId, setProductId] = useState();
  const handleVerifyClick = (value) => {
    // e.preventDefault();
    setBtnName(value);
    // console.log(value, "*************************************");
    setPopupOpen(true);
  };

  const editHandler = (value) => {
    setPopupOpen(true);
    setBtnName("edit");
    setProductId(value);
  };
  const closePopUp = () => {
    setEditPopupOpen(false);
    setPopupOpen(false);
    setAddCatalogForm(false);
  };

  const handelCLickToOpen = () => {
    // setBtnName("add product")
    setOpen(true);
  };
  const handelCLickToBack = () => {
    setOpen(false);
  };

  return (
    <>
      <div className=" position-relative">
        <div>{/* <NavBar /> */}</div>
        <div className=" container-fluid">
          <div className=" row ">
            <div
              className={` col-lg-2 col-md-3 max-vh-100 pe-0  ${styles.left}`}
              style={
                popupOpen || editpopupOpen || addCatalogForm || Open == 1
                  ? { filter: "blur(2px)" }
                  : {}
              }
            >
              <SideBar flag={true} activeTabColor="Products" />
            </div>
            <div
              className="col-lg-10 col-md-9 col-12  pb-4"
              style={{
                overflowY: "auto",
                maxHeight: "100vh",
                backgroundColor: colors.bgcolor,
              }}
            >
              {loading && <PreLoader />}
              <div
                style={
                  popupOpen || editpopupOpen || addCatalogForm || Open == 1
                    ? { filter: "blur(2px)" }
                    : {}
                }
              >
                <div className="py-3">
                  <NavBarEx
                    buttonName={"Create Notification"}
                    buttonFlag={false}
                    search={true}
                  />
                </div>
                <div className="text-start py-3">
                  <img
                    src={Backicon}
                    alt="Back Icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div>
                  <StockReport
                    header={headerdata}
                    handelEditOpen={handelEditOpen}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {editpopupOpen && (
          <div className={` ${styles.editpopcontainer}`}>
            <div style={{ position: "relative", zIndex: "100" }}>
              {/* <StockReportPopUp handelPopUpclose={handelPopUpclose} /> */}
              <Newproduct
                productId={productId}
                btnName={btnName}
                closePopUp={closePopUp}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllProduct;
