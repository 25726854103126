import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../../screens/agent/supplier/Dashboard";
import Product from "../../screens/agent/supplier/Product";
import Review from "../../screens/admin/Reviews";
import Catalog from "../../screens/agent/catalog/Catalog";
import Protected from "../../screens/Protected";
import CatalogList from "../../screens/agent/catalog/CatalogList";
import Settings from "../../screens/admin/Settings";
import HappyWall from "../../components/Supllier/HappyWall";
import CustomerBanner from "../../screens/agent/supplier/CustomerBanner";
import BulkProduct from "../../components/Supllier/BulkProduct";
import ShopProduct from "../../components/Supllier/ShopProduct";
import CreateCatalog from "../../screens/agent/catalog/CreateCatalog";
import OrderDescription from "../../screens/admin/OrderDescription"
import Orders from "../../screens/admin/Orders"
import SubCategory from "../../screens/agent/SubCategory";
import Pickup from "../../screens/agent/supplier/Pickup";
import AllProduct from "../../screens/agent/supplier/AllProducts";
export default function index() {
  return (
    <Routes>
      <Route exact path="/home" element={<Protected cmp={Dashboard} />} />
      <Route exact path="/Orders" element={<Protected cmp={Orders} />} />
      <Route exact path="/product" element={<Protected cmp={Product} />} />
      <Route exact path="/product/all" element={<Protected cmp={AllProduct} />} />
      <Route
        exact
        path="/shopProduct/:id"
        element={<Protected cmp={ShopProduct} />}
      />
      <Route exact path="/pickup" element={<Protected cmp={Pickup} />} />
      <Route exact path="/review" element={<Protected cmp={Review} />} />
      <Route exact path="/catalog/:id" element={<Protected cmp={Catalog} />} />
      <Route exact path="/subcategory/:id" element={<Protected cmp={SubCategory} />} />
      <Route
        exact
        path="/catalogList"
        element={<Protected cmp={CatalogList} />}
      />
      <Route exact path="/settings" element={<Protected cmp={Settings} />} />
      <Route
        exact
        path="/customerbanner"
        element={<Protected cmp={CustomerBanner} />}
        
      />
      <Route exact path="/happyWall" element={<Protected cmp={HappyWall} />} />
      <Route
        exact
        path="/bulkProduct"
        element={<Protected cmp={BulkProduct} />}
      />
      
      <Route
        exact
        path="/createCatalog"
        element={<Protected cmp={CreateCatalog} />}
      />
    </Routes>
  );
}
