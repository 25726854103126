import React, { useState, useEffect } from "react";
import Backicon from "../../Assets/backicon.png";
import Button from "../../common/Button";
import Heading from "../../common/Heading";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import DropDown from "../../common/DropDown";
import dropimg from "../../Assets/dropimg.png";
import deleteicon from "../../Assets/trash_icon.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ShopForm = (props) => {
  const [categoriesFromApi, setCategoriesFromApi] = useState([]);
  const fetchCategories = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct;
    try {
      const response = await axiosInstance.get(url);
      setCategoriesFromApi(response.data.data);
      // console.log(response.data.data,"***************************");
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchDataForAllMerchants();
  }, []);
  const [allMerchantsFromApi, setAllMerchantsFromApi] = useState([]);

  const [shopIdData, setShopIdData] = [];
  const fetchDataForAllMerchants = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allMerchants
      );
      setAllMerchantsFromApi(response.data.data);
      console.log("all merchant api in ShopForm");
      // console.log(response.data.data)
      response.data.data.map((item, index) => {});
      // console.log("id**********" + typeof shopIdData)
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [logId, setLogId] = useState("");
  const [nameError, setNameError] = useState("");
  const [ownerError, setOwnerError] = useState("");
  const [agentError, setAgentError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [name, setName] = useState("");
  const [shopName, setShopName] = useState("");
  const [contact, setContact] = useState("");
  const [logo, setLogo] = useState("");
  const [tagline, setTagline] = useState("");
  const [category, setCategory] = useState("");
  const [agentCount, setAgentCount] = useState("");

  const addShop = async () => {
    try {
      const url = endpoints.baseUrl + "admin/" + endpoints.addShop; // Replace with your API endpoint URL

      console.log(url);
      const data = {
        name: name,
        // agents: agentsId,
        // owner: idOfAgentAsOwner,
        // categories: categoriesId,
      };

      const response = await axiosInstance.post(url, data);
      window.location.reload();
      // Handle the response data
      // console.log("add shop api")
      // console.log(response.data.data);
    } catch (error) {
      // Handle any errors that occurred during the API call
      // console.log(err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
      // setError(err.message || 'Something went wrong.');
    }
  };

  function findIdsByCategoryNames(categoriesArray, dataArray) {
    // Create a dictionary to map category names to their IDs from the data array
    const categoryIDMapping = {};
    dataArray.forEach((item) => {
      categoryIDMapping[item.name] = item._id;
    });

    // Initialize a new array to store the IDs
    const idsArray = [];

    // Find and store the IDs for each category in the categories array
    categoriesArray.forEach((category) => {
      if (categoryIDMapping.hasOwnProperty(category)) {
        idsArray.push(`${categoryIDMapping[category]}`);
      } else {
        idsArray.push(null);
      }
    });
    console.log(idsArray, "****************categories names");
    return idsArray;
  }
  function findIdsByAgentNames(agentsArray, dataArray) {
    console.log();
    // Create a dictionary to map category names to their IDs from the data array
    const agentIDMapping = {};
    dataArray.forEach((item) => {
      agentIDMapping[item.name] = item._id;
    });

    // Initialize a new array to store the IDs
    const idsArray = [];

    // Find and store the IDs for each category in the categories array
    agentsArray.forEach((agent) => {
      if (agentIDMapping.hasOwnProperty(agent)) {
        idsArray.push(`${agentIDMapping[agent]}`);
      } else {
        idsArray.push(null);
      }
    });

    return idsArray;
  }

  const handleSubmit = () => {
    // Gather the form data
    // const subcategoryInputs = document.getElementsByClassName("add-new-agent");
    // const subcategoryValues = Array.from(subcategoryInputs).map(
    //   (input) => input.value
    // );
    console.log("categories options:", categories);
    console.log("addNewAgent options:", addNewAgent);
    console.log("name", name);
    console.log("owner", owner);

    // Form validation
    let formIsValid = true;

    if (formIsValid) {
      addShop();
      props.closePopUp();
    }
  };
  const findCategoryIdByName = (name) => {
    for (let i = 0; i < allMerchantsFromApi.length; i++) {
      if (allMerchantsFromApi[i].name === name) {
        return allMerchantsFromApi[i]._id;
      }
    }
    return null;
  };
  const closePopUpHandeler = () => {
    props.closePopUp();
  };
  // const handleLogIdChange = (e) => {
  //   setLogId(e.target.value);
  // };
  const AgentsOpations = ["Agent 1", "Agent 2", "Agent 3"];
  const categoriesOpations = ["category 1", "category 2", "category 3"];
  // const [LogIdOption, setLogIdOption] = useState("");

  // const handleSelectLogIdChange = (event) => {
  //   const LogIdValue = event.target.value;
  //   setLogIdOption(LogIdValue);
  //   console.log("LogId Option:", LogIdValue);
  // };
  const [addNewAgent, setAddNewAgent] = useState([]);
  const addNewAgentS = () => {
    setAddNewAgent([...addNewAgent, ""]);
  };

  const handleAddNewAgentsChange = (index, value) => {
    const updatedAddNewAgent = [...addNewAgent];
    updatedAddNewAgent[index] = value;
    setAddNewAgent(updatedAddNewAgent);
  };
  const deleteAllAddNewAgent = () => {
    setAddNewAgent([]);
  };

  const deleteAgentElement = (index, ar) => {
    for (let i = index; i < ar.length - 1; i++) {
      ar[i] = ar[i + 1];
    }
    ar.pop();
    return ar;
  };
  const deleteHandlerAgent = (val) => {
    let ar = deleteAgentElement(val, addNewAgent);
    setAddNewAgent([...ar]);
  };
  const [categories, setCategories] = useState([]);
  const addCategory = () => {
    setCategories([...categories, ""]);
  };

  const handleSubcategoryChange = (index, value) => {
    const updatedcategories = [...categories];
    updatedcategories[index] = value;
    setCategories(updatedcategories);
  };
  const deleteAllcategories = () => {
    setCategories([]);
  };
  const deleteCategoryElement = (index, ar) => {
    for (let i = index; i < ar.length - 1; i++) {
      ar[i] = ar[i + 1];
    }
    ar.pop();
    return ar;
  };
  const deleteCategoryHandler = (val) => {
    let ar = deleteCategoryElement(val, categories);
    setCategories([...ar]);
  };
  const OwnerOpations = ["owner 1", "owner 2", "owner 3"];
  const [owner, setOwner] = useState("");
  const handleSelectOwnerChange = (event) => {
    const OwnerValue = event.target.value;
    setOwner(OwnerValue);
    console.log("ShopId Option:", OwnerValue);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {JSON.stringify(shopIdData)}
        <div
          className="container-fluid px-3 py-3"
          style={{ border: "1px solid #5082C4 ", backgroundColor: "#fff" }}
        >
          <div className="d-flex align-items-center">
            <div onClick={closePopUpHandeler}>
              <img
                src={Backicon}
                className="pe-3"
                alt="Back Icon"
                style={{ cursor: "pointer" }}
              />
            </div>
            <Heading name="Create New Shop" fontSize="25px" fontWeight="500" />
          </div>
          <div className="row pt-4" style={{ overflowY: "auto" }}>
            {/* <div className="col-12 col-md-6 pt-3">
            <label style={{ fontWeight: 500, fontSize: 16 }}>Owner</label>
            <input
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                border: "none",
                borderRadius: 10,
              }}
              className="mt-2 ps-3 py-2"
              type="text"
              value={owner}
              onChange={(e) => setOwner(e.target.value)}
            />
            <select
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="py-2"
              value={owner}
              onChange={(e) => setOwner(e.target.value)}
            >
              <option value="volvo" className="d-none">
                Shop Allotted
              </option>
              {shopIdData.map((item, index) => {
                return(
                <option key={index} value={item}>
                  {item}
                </option>)
              })}

            </select>

          </div> */}
            {/* <div className="col-12 pt-3">
              <label
                style={{ fontWeight: 500, fontSize: 15, display: "block" }}
                className="pb-2"
              >
                Log Id
              </label>
              <select
                value={LogIdOption}
                onChange={handleSelectLogIdChange}
                style={{
                  display: "block",
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  display: "block",
                  border: "none",
                  borderRadius: 10,
                }}
                className="ps-2 py-2 mt-1"
              >
                <option value="" disabled className="d-none">
                  select Log Id
                </option>
                {LogIdoptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="col-12">
              <label style={{ fontWeight: 500, fontSize: 16 }}>Name</label>
              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                className="mt-2 ps-3 py-2"
                type="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && <small style={{ color: "red" }}>{nameError}</small>}
            </div>
            {/* <div className="col-12  pt-3"> */}
            {/* <label style={{ fontWeight: 500, fontSize: 16 }}>Owner</label>
              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                className="mt-2 ps-3 py-2"
                type="text"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
              /> */}
            {/* <label
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  display: "block",
                }}
                className="pb-2"
              >
                Owner
              </label> */}
            {/* <select
                value={owner}
                onChange={handleSelectOwnerChange}
                style={{
                  display: "block",
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                className="ps-2 py-2 mt-1"
              >
                <option value="" disabled className="d-none">
                  Select owner
                </option>
                {allMerchantsFromApi.map((option, index) => (
                  <option key={index} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select> */}
            {/* {ownerError && <span style={{ color: "red" }}>{ownerError}</span>} */}
            {/* </div> */}

            {/* <div className="col-12">
              {addNewAgent.map((addNewAgent, index) => (
                <div className="col-12 pt-3" key={index}>
                  <div className="d-flex align-items-center justify-content-between">
                    <label style={{ fontWeight: 500, fontSize: 16 }}>{`Agent ${
                      index + 1
                    }`}</label>
                    <div
                      onClick={() => {
                        deleteHandlerAgent(index);
                      }}
                    >
                      <img src={deleteicon} alt="remove" />
                    </div>
                  </div>

                  <select
                    value={addNewAgent}
                    onChange={(e) =>
                      handleAddNewAgentsChange(index, e.target.value)
                    }
                    style={{
                      display: "block",
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="ps-2 py-2 mt-1 add-new-agent"
                  >
                    <option value="" disabled className="d-none">
                      Select Agent Id
                    </option>
                    {allMerchantsFromApi.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {agentError && (
                    <span style={{ color: "red" }}>{agentError}</span>
                  )}
                </div>
              ))}
              <div className="mt-4 d-flex align-items-center justify-content-between">
                <div
                  onClick={addNewAgentS}
                  className="text-center text-sm-start"
                >
                  <Button
                    name="Add new Agent"
                    width="fit-content"
                    borderRadius="12px"
                    fontSize="15px"
                  />
                </div>
                <div
                  onClick={deleteAllAddNewAgent}
                  className="text-center text-sm-start "
                >
                  <Button
                    name="Delete all"
                    width="fit-content"
                    borderRadius="12px"
                    fontSize="15px"
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-12">
              {categories.map((category, index) => (
                <div className="col-12 pt-3" key={index}>
                  <div className="d-flex align-items-center justify-content-between">
                    <label
                      style={{ fontWeight: 500, fontSize: 16 }}
                    >{`category ${index + 1}`}</label>
                    <div
                      onClick={() => {
                        deleteCategoryHandler(index);
                      }}
                    >
                      <img src={deleteicon} />
                    </div>
                  </div>
                  {/* <input
                    style={{
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="mt-2 ps-3 py-2 subcategory-input"
                    type="text"
                    value={category}
                    onChange={(e) =>
                      handleSubcategoryChange(index, e.target.value)
                    }
                  /> 
                  <select
                    value={category}
                    onChange={(e) =>
                      handleSubcategoryChange(index, e.target.value)
                    }
                    style={{
                      display: "block",
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      display: "block",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="ps-2 py-2 mt-1 add-new-agent"
                  >
                    <option value="" disabled className="d-none">
                      Select category Id
                    </option>
                    {categoriesFromApi.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {categoryError && (
                    <span style={{ color: "red" }}>{categoryError}</span>
                  )}
                </div>
              ))}
              <div className="mt-4 d-flex align-items-center justify-content-between">
                <div
                  onClick={addCategory}
                  className="text-center text-sm-start"
                >
                  <Button
                    name="Add categories"
                    width="fit-content"
                    borderRadius="12px"
                    fontSize="15px"
                  />
                </div>
                <div
                  onClick={deleteAllcategories}
                  className="text-center text-sm-start "
                >
                  <Button
                    name="Delete all"
                    width="fit-content"
                    borderRadius="12px"
                    fontSize="15px"
                  />
                </div>
              </div>
            </div> */}
          </div>

          <div className="mt-5 text-center" onClick={handleSubmit}>
            <Button
              name="Update"
              width="20%"
              borderRadius="10px"
              fontSize="15px"
              // onClick={handleUpdate}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ShopForm;
