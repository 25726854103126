import React, { useState, useRef, useEffect } from "react";
import styles from "./MerchantList.module.css";
import trashicon from "../../Assets/icon_trash.png";
import redeye from "../../Assets/remove_red_eye.png";
import MerchantEditForm from "../MerchantEditForm";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import editicon from "../../Assets/edit.svg";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
import DropDown from "../../common/DropDown";
import AllTable from "../AllTable";
import dropimg from "../../Assets/dropimgwhite.svg";
import WarningPop from "../../common/WarningPop";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../common/Button";

const MerchantListTable = (props) => {
  const [dataFromApi, setdataFromApi] = useState([]);

  useEffect(() => {
    fetchData();
    // SpecificMerchantFromId();
  }, []);
  const fetchData = async () => {
    console.log("Fetch DATA function");
    const url =
      endpoints.baseUrl + "admin/" + endpoints.allMerchants + "?limit=5";
    try {
      const response = await axiosInstance.get(url);
      setdataFromApi(response.data.data);
      // const recievedData = mapDataToTableFormat(response.data.data);
      // setMappedDataForTable(recievedData);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [mappedDataForTable, setMappedDataForTable] = useState([]);
  const mapDataToTableFormat = (data) => {
    return data.map((item) => {
      const {
        name,
        mobileNumber,
        shopId,
        email,
        _id,
        totalSales,
        totalOrders,
        type
      } = item;

      return {
        item0: _id || "-----",
        item1: name || "----",
        item2: type || "----",
        item3: shopId ? shopId : "----",
        item4: email ? email : mobileNumber || "----",
        item5: totalOrders || "----",
        item6: totalSales || "----",
      };
    });
  };
  // const mappedData = mapData(inputData);
  // console.log(mappedData);

  const [getDataOfMerchant, setGetDataOfMerchant] = useState([]);
  const deleteMerchant = async (merchantId) => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.merchantbyId + merchantId; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      // console.log("Delete response------------------->:", response.data);
    } catch (err) {
      // console.log("Delete error:", err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const deleteHandler = async (val) => {
    try {
      const merchantId = dataFromApi[val]._id;
      await deleteMerchant(merchantId);

      // const newData = getDataOfMerchant.filter((item, index) => index !== val);
      // setdataFromApi(newData);
      // window.location.reload();
      fetchData();
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }

    setWarning(0);
  };

  const navigate = useNavigate();
  const [data, setData] = useState(props.data);
  const [popupOpen, setPopupOpen] = useState(false);
  const [warning, setWarning] = useState(false);
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  // const popupRef = useRef(null);

  const [editActive, setEditActive] = useState();
  const handleVerifyClick = (e) => {
    // e.preventDefault();
    // console.log(e,"__________")
    setEditActive(e);
    setPopupOpen(true);
  };

  const PopUpclose = () => {
    setPopupOpen(false);
  };

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  useEffect(() => { }, [data]);

  const item1 = [
    "All agents ",
    "Top 5 agents",
    "New agents",
    "New to old",
    "Old to new",
  ];
  const item2 = ["Filter by sales", "Filter by shop", "Filter by orders"];
  const headerdata = [
    {
      // header1: "Log id",
      header1: "Name",
      header2: "Type",
      header3: "Shop",
      header4: "Contact",
      header5: "Total orders",
      header6: "Total sales",
      header7: "Edit",
      header8: "View",
      header9: "Delete",
      header13: "Edit",
      header14: "View",
      header15: "Delete",
    },
  ];

  return (
    <div ref={popupRef}>
      <Card>
        <div className=" row pt-3 px-3 align-items-sm-center">
          <div className=" col-8 col-sm-9 col-lg-8 ">
            <div className="row align-items-sm-center">
              <div className="col-sm-12 col-lg-12">
                <Heading
                  name="Recently Added"
                  fontSize="24px"
                  fontWeight="600"
                  fontFamily={"InterSemiBold"}
                />
              </div>
            </div>
          </div>
          <div
            className="col-4 col-sm-3 col-lg-4 pt-sm-0 text-end"
            style={{ paddingTop: "3px" }}
          >
            <Button
              name="View all"
              fontSize="15px"
              onClick={() => navigate("/admin/merchant/all")}
            />
            {/* <AllTable
              fetchData={fetchData}
              deleteHandler={deleteHandler}
              data={data}
              header={headerdata}
              mappedDataForTable={mappedDataForTable}
              item5
              item6
              item13
              item14
              item15
              redeye
              trash
              editMerchant
              searchicon
              headingname={"Agent list"}
              activeTabColor="Merchants"
              navigation={"/admin/specificMerchant"}
            /> */}
          </div>
        </div>
        <div
          className={`table-responsive px-3 ${styles.tablecontainer}`}
          style={{ paddingTop: "15px" }}
        >
          <table className={`table table-borderless ${styles.table}`}>
            <thead>
              {headerdata.map((th, index) => (
                <tr
                  key={index}
                  style={{
                    borderRadius: "12px",
                    // textAlign: "center",
                  }}
                >
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header1}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header2}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header3}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header4}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header5}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header6}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header7}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header8}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header9}
                  </th>
                  <th></th>
                </tr>
              ))}
            </thead>
            <tbody>
              {dataFromApi.map((row, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    borderBottom: "1px solid #EBEBEB",
                    // textAlign: "center",
                  }}
                >
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      fontWeight: "500",
                      fontSize: "15px",
                      fontFamily: "InterMedium",
                    }}
                  >
                    <div
                      title={row.item2}
                      style={{
                        maxWidth: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.name ? row.name : "----"}
                    </div>
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      fontWeight: "500",
                      fontSize: "15px",
                      fontFamily: "InterMedium",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                      }}
                      title={row.email}
                    >
                      {row.type ? row.type : "----"}
                    </div>
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      fontWeight: "500",
                      fontSize: "15px",
                      fontFamily: "InterMedium",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                      }}
                      title={row.shopId}
                    >
                      {row.shopId ? row.shopId : "----"}
                    </div>
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      fontWeight: "500",
                      fontSize: "15px",
                      fontFamily: "InterMedium",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                      }}
                      title={"N.A"}
                    >
                      {row?.email ? row?.email : row?.mobileNumber ? row?.mobileNumber : "----"}
                    </div>
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      fontWeight: "500",
                      fontSize: "15px",
                      fontFamily: "InterMedium",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                      }}
                      title={row.totalOrders}
                    >
                      {row.totalOrders}
                    </div>
                  </td>
                  <td
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      fontWeight: "500",
                      fontSize: "15px",
                      fontFamily: "InterMedium",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                      }}
                      title={row.totalSales}
                    >
                      {row.totalSales?.toFixed(2)}
                    </div>
                  </td>
                  <td
                    style={{
                      // color: colors.tableEditColor,

                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                    onClick={() => handleVerifyClick(row._id)}
                  >
                    <img
                      style={{ height: "15px", cursor: "pointer" }}
                      src={editicon}
                      alt="edit"
                    />
                  </td>
                  <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <a
                      href="#"
                      style={{ color: "#787878" }}
                      onClick={() =>
                        navigate("/admin/specificMerchant/" + row._id)
                      }
                    >
                      <img src={redeye} alt="View" />
                    </a>
                  </td>
                  <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <a
                      href="#"
                      style={{ color: "#787878" }}
                      onClick={() => {
                        setActive(index);
                        warningHandler();
                      }}
                    >
                      <img src={trashicon} alt="Delete" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
      {popupOpen && (
        <div className={styles.popcontainer}>
          <div style={{ position: "relative", zIndex: "20" }}>
            <MerchantEditForm
              editActive={editActive}
              fetchData={fetchData}
              closePopUp={PopUpclose}
              isbuttonVisible
            />
          </div>
        </div>
      )}
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "40%",
            top: "30%",
            zIndex: 1000000,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to delete this merchant?"
          />
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default MerchantListTable;
