import React, { useEffect, useState } from "react";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import Heading from "../../common/Heading";
import Sidebar from "../../common/SideBar";
import NavBarEx from "../../common/NavBarEx";
import { DataGrid } from "@mui/x-data-grid";
import redeye from "../../Assets/remove_red_eye.png";
import styles from "./Order.module.css";
import colors from "../../utils/helper";
import PreLoader from "../../common/PreLoader";
import Card from "../../common/Card";
import Backicon from "../../Assets/backicon.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import trashicon from "../../Assets/icon_trash.png";
import WarningPop from "../../common/WarningPop";
import { toast } from "react-toastify";

function AllCustomer() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([
        // { orderId: "11111111", customerId: "1111111111111" },
    ]);
    const [active, setActive] = useState(-1);
    const [warning, setWarning] = useState(false);
    const columns = [
        // { flex: 1, field: "id", headerName: "Sno.", maxWidth: 100 },
        // { flex: 1, field: "_id", headerName: "Customer ID" },
        { flex: 1, field: "name", headerName: "Name" },
        { flex: 1, field: "contact", headerName: "Contact Details", renderCell: (params) => <>{params.row.email ? params.row.email : params.row.mobileNumber}</> },
        { flex: 1, field: "gender", headerName: "Gender" },
        { flex: 1, field: "dob", headerName: "Dob" },
        { field: "totalOrders", headerName: "Total orders" },
        {
            flex: 1, field: "add", headerName: "Shipping address", renderCell: ({ row }) => <>{row?.address?.length > 0
                ? `${row.address[0].street}, ${row.address[0].city}, ${row.address[0].state}, ${row.address[0].country} - ${row.address[0].pin}`
                : "---------"}</>
        },
        {
            field: "view",
            headerName: "View",
            sortable: false,
            renderCell: (params) => {
                const onClick = async (e) => {
                    try {
                        e.stopPropagation(); // don't select this row after clicking
                        console.log(params.row);
                        navigate("/admin/specificCustomer/" + params?.row?._id);
                    } catch (error) {
                        console.log(error);
                    }
                };

                return (
                    <>
                        {!params?.row?.resolved && (
                            <button
                                style={{ backgroundColor: "transparent", border: "none" }}
                                onClick={onClick}
                            >
                                <img src={redeye} style={{ width: "20px" }} />
                            </button>
                        )}
                    </>
                );
            },
        },
        {
            field: "delete",
            headerName: "Delete",
            sortable: false,
            renderCell: (params) => {
                const onClick = async (e) => {
                    try {
                        e.stopPropagation(); // don't select this row after clicking
                        console.log(params.row);
                        setActive(params?.row?._id)
                        setWarning(true)
                    } catch (error) {
                        console.log(error);
                    }
                };

                return (
                    <>
                        {!params?.row?.resolved && (
                            <button
                                style={{ backgroundColor: "transparent", border: "none" }}
                                onClick={onClick}
                            >
                                <img src={trashicon} style={{ width: "20px" }} />
                            </button>
                        )}
                    </>
                );
            },
        },
    ];
    const fetchData = async () => {
        try {
            // Make a GET request to the API
            const response = await axiosInstance.get(
                endpoints.baseUrl + "admin/" + endpoints.allCustomers
            );
            console.log("data");
            console.log(response.data.data);
            let data = response.data.data.map((item, index) => {
                return {
                    ...item,
                    id: index + 1,
                    createdAt: moment(item.createdAt),
                };
            });
            setRows(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };
    const deleteCustomer = async (tempId) => {
        const url =
            endpoints.baseUrl + "admin/" + endpoints.deleteCustomer + tempId; // Adjust the endpoint for delete API
        try {
            const response = await axiosInstance.delete(url);
            console.log("Delete response------------------->:", response.data.data);
            toast.success("customer deleted succesfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000, // Set the duration for how long the popup should be visible
            });
        } catch (err) {
            // console.log("Delete error:", err);
            toast.error("Internal Server Error", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000, // Set the duration for how long the popup should be visible
            });
        } finally {
            setWarning(false)
            fetchData()
        }
    };

    const warningHandler = (val) => {
        console.log("hello from warning handler");
        if (val == true) {
            setWarning(false);
        }
        if (warning == false) {
            setWarning(true);
        } else {
            setWarning(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div
                        className={`col-lg-2 col-md-3 ${styles.left}`}
                        style={{ paddingRight: "0px" }}
                    >
                        <Sidebar active={true} activeTabColor="Customers" />
                    </div>

                    <div
                        className="col-lg-10 col-md-9 right py-3"
                        style={{
                            backgroundColor: colors.bgcolor,
                            overflowY: "auto",
                            height: "100vh",
                            flexWrap: "wrap",
                        }}
                    >
                        {loading && <PreLoader />}
                        <div className="pt-3">
                            <NavBarEx
                                buttonFlag={false}
                                buttonName={"Add new customer"}
                                fontSize={"38px"}
                                search={true}
                            />
                        </div>
                        <div className="text-start pt-3">
                            <img
                                src={Backicon}
                                alt="Back Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            />
                        </div>
                        <div className="row pt-4">
                            <div className="col-12">
                                {/* <ReccentOrders /> */}
                                <Card backgroundColor="#fff">
                                    <div className="pt-4 d-flex justify-content-between align-items-center">
                                        <Heading name="All Customers" paddingLeft="1%" />
                                    </div>
                                    <div
                                        className="px-4 py-2"
                                        style={{ width: "100%" }}
                                    >
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 5 },
                                                },
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            //   checkboxSelection
                                            // hideFooter
                                            style={{ border: "none", minHeight: 400, }}
                                        />
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {warning ? (
                <div
                    style={{
                        width: "35%",
                        height: "50%",
                        position: "absolute",
                        left: "30%",
                        top: "30%",
                        // zIndex: 10,
                    }}
                >
                    <WarningPop
                        delete={() => deleteCustomer(active)}
                        closePopUp={(val) => {
                            warningHandler(val);
                        }}
                        message="Are you sure you want to delete this user?"
                    />
                </div>
            ) : null}
        </div>
    );
}

export default AllCustomer;
