import React, { useState, useEffect } from "react";
import Sidebar from "../../../common/SideBar";
import NavBarEx from "../../../common/NavBarEx";
import Card from "../../../common/Card";
import Heading from "../../../common/Heading";
import Button from "../../../common/Button";
import styles from "../supplier/Dashboard.module.css";
import { BarChart } from "../../../common/BarChart";
import dustbin from "../../../Assets/trash_icon.png";
import colors from "../../../utils/helper";
import TShirt from "../../../Assets/T-Shirt.png";
import Lehenga from "../../../Assets/lehenga.png";
import NewCategryForm from "../../../components/Supllier/NewCategryForm";
import WarningPop from "../../../common/WarningPop";
import axiosInstance from "../../../utils";
import endpoints from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import PreLoader from "../../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import Headphone from "../../../Assets/headphone.jpeg";
import EditCategryForm from "../../../components/Supllier/EditCategryForm";
import "react-toastify/dist/ReactToastify.css";
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // fetchDataFroGraph("Yearly")
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const navigate = useNavigate();
  const [categoriesFromApi, setCategoriesFromApi] = useState([]);
  const [fiveProducts, setFiveProducts] = useState([]);
  useEffect(() => {
    fetchAllCategoriesForProduct();
    topFiveProducts();
  }, []);

  const imageData = [];
  const [amountsForGraph, setAmountsForGraph] = useState();
  const [labels, setLabels] = useState([]);

  const topFiveProducts = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.productPrefrences
      );
      setFiveProducts(response.data.data);
      console.log(
        response.data.data,
        "*******************************************"
      );

      const graphamount = fetchTotalOrderVsShop(response.data.data);
      setAmountsForGraph(graphamount);
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const fetchAllCategoriesForProduct = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct
      );
      setCategoriesFromApi(response.data.data);
      // console.log(response.data.data)
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };


  const deleteHandler = (index) => {
    console.log("from delete handler", index);
    deleteCategory(categoriesFromApi[index]._id);
    setWarning(0);
  };
  const deleteCategory = async (categoryId) => {
    // console.log("**********************")
    try {
      // Make the DELETE request to the API
      const response = await axiosInstance.delete(
        endpoints.baseUrl + `admin/${endpoints.deleteCategory}/${categoryId}`
      );
      console.log(response.data.message);
      // If successful, remove the deleted category from the categoriesFromApi state
      setCategoriesFromApi((prevState) =>
        prevState.filter((category) => category._id !== categoryId)
      );
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  const Productdata = [
    {
      imageSrc: Lehenga,
      name: "Jeans",
      price: "222,000",
    },
    {
      imageSrc: Lehenga,
      name: "Saree",
      price: "2,78,000",
    },
    {
      imageSrc: Lehenga,
      name: "T-shirt",
      price: "118,000",
    },
    {
      imageSrc: Lehenga,
      name: "Kurti",
      price: "2,78,000",
    },
    {
      imageSrc: Lehenga,
      name: "Kurti",
      price: "2,78,000",
    },
    {
      imageSrc: Lehenga,
      name: "Suit",
      price: "2,78,000",
    },
  ];
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [editActive, setEditActive] = useState("");

  const handleVerifyClick = () => {
    // e.preventDefault();
    setPopupOpen(true);
  };
  const handleVerifyClickForEdit = (edit) => {
    // console.log(edit);
    // e.preventDefault();
    // setPopupOpen(true);
    setEditActive(edit);
  };
  const PopUpclose = () => {
    setPopupOpen(false);
    setEditActive("");
  };
  const flagForSideNav = true;
  const [data, setData] = useState([1, 20, 3, 4, 5, 6, 7, 8]);
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  useEffect(() => { }, [data]);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };

  const item1 = [
    "Recent sales ",
    "Top sales",
    "Last 15 days",
    "Last 30 days",
    "Last year",
  ];
  const graphoptions = ["Yearly", "Monthly", "Weekly"];
  const [graphoption, setgraphoption] = useState("");
  const handleSelectgraphoptionChange = (event) => {
    const graphoptionValue = event.target.value;
    // fetchDataFroGraph(graphoptionValue)
    setgraphoption(graphoptionValue);
    console.log("graphoption:", graphoptionValue);
  };

  function fetchTotalOrderVsShop(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item?.num_orders;
      const shopName = item._id.name;

      yearsArray.push(year);
      totalAmountsArray.push(shopName);
    }

    // if (totalAmountsArray.length <= 2) {
    //   totalAmountsArray.push("-");
    //   totalAmountsArray.push("-");
    //   totalAmountsArray.push("-");
    //   totalAmountsArray.push("-");
    // }

    return {
      x: totalAmountsArray,
      y: yearsArray,
    };
  }
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={` col-lg-2 col-md-3 h-vh-100 pe-0 ${styles.left}`}
            style={popupOpen == 1 ? { filter: "blur(2px)" } : {}}
          >
            <Sidebar flag={flagForSideNav} activeTabColor="Dashboard" />
          </div>
          <div
            className="col-lg-10 col-md-9 col-12 pb-4"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: colors.bgcolor,
            }}
          >
            {loading && <PreLoader />}
            <div style={popupOpen == 1 ? { filter: "blur(2px)" } : {}}>
              <div className="py-3">
                <NavBarEx
                  welcomeMsg={"Welcome back," + localStorage.getItem("name")}
                  search={true}
                  icons={false}
                  fontSize={"38px"}
                />
              </div>
              <div className="row pt-3">
                <div className="col-xl-8 col-md-7 col-sm-7">
                  <Card width="100%" height="100%" paddingTop="3%">
                    <div className="row align-items-center">
                      <div className=" col-6 col-sm-5 col-lg-4 col-xl-3">
                        <Heading
                          name="Product sales"
                          fontSize="24px"
                          fontWeight="600"
                          paddingLeft={"4%"}
                        />
                      </div>
                    </div>
                    <div className="pt-4 px-3">
                      <BarChart amountsForGraph={amountsForGraph} />
                    </div>
                  </Card>
                </div>
                <div className="col-xl-4 col-md-5 col-sm-5 pt-4 pt-sm-0">
                  <Card height="100%">
                    <div className="ps-3 pt-3 pb-4">
                      <div className="pb-2">
                        <Heading
                          name="Top products"
                          fontSize="24px"
                          fontWeight="600"
                        />
                      </div>
                      <div
                        style={{
                          overflowY: "auto",
                        }}
                        className=" 
                         px-4 px-sm-0"
                      >
                        {fiveProducts.length > 0 ? (
                          fiveProducts.map((item, index) => (
                            <div
                              className="d-flex justify-content-between align-items-center"
                              key={index}
                            >
                              <div className="my-2">
                                <img
                                  // className="w-100"
                                  style={{
                                    height: "45px",
                                    width: "45px",
                                    borderRadius: "50%",
                                  }}
                                  src={item._id?.color[0]?.images[0] || TShirt}
                                />
                              </div>
                              <p
                                style={{
                                  fontWeight: "600", fontSize: "16px", whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  paddingLeft: 25,
                                  width:"80%"
                                }}
                              >
                                {item?._id?.name}
                              </p>
                              <p
                                style={{
                                  fontWeight: "600", fontSize: "16px",
                                  paddingLeft: 25,
                                  paddingRight: 25
                                }}
                              >
                                {item.num_orders}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              justifyContent: "center",
                              verticalAlign: "center",
                              flex: 1,
                            }}
                          >
                            No Product available
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-12 pt-5">
                  <div className="d-flex align-items-center justify-content-between">
                    <Heading
                      name="Categories"
                      fontSize="24px"
                      fontWeight="600"
                    />
                    <div onClick={handleVerifyClick}>
                      <Button
                        name="Add new category"
                        width="fit-content"
                        fontSize="15px"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center justify-content-sm-start pt-3">
                    {categoriesFromApi.length > 0 ? (
                      categoriesFromApi.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="col-11 col-lg-4 col-xl-3 col-md-6 col-sm-6 my-3"
                          >
                            <Card bgColor="#fff" width="100%">
                              <div
                                className={`${styles.container}`}
                                style={{ position: "relative" }}
                              >
                                <div
                                  onClick={() =>
                                    navigate("/agent/subcategory/" + item._id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    style={{
                                      height: "350px",
                                      // width: "250px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                      className=""
                                      src={
                                        // TShirt
                                        item.images.length == 0
                                          ? TShirt
                                          : item?.images[item.images.length - 1]
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "#D6E4F7",
                                      opacity: "80%",
                                      position: "absolute",
                                      height: "50px",
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                    }}
                                    className="d-flex justify-content-center align-items-center w-100 "
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#000000",
                                        textTransform: "capitalize"
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Card>
                            <div
                              className="mt-1 px-1"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  color: colors.tableTextColor,
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleVerifyClickForEdit(item);
                                }}
                              >
                                Edit
                              </p>
                              <img
                                onClick={(event) => {
                                  setActive(index);
                                  warningHandler();
                                }}
                                width={15}
                                height={20}
                                src={dustbin}
                                alt="Trash Icon"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          justifyContent: "center",
                          verticalAlign: "center",
                          flex: 1,
                        }}
                      >
                        No Catagories available
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupOpen && (
        <div className={` ${styles.popcontainer}`}>
          <div style={{ position: "relative", zIndex: "50" }}>
            <NewCategryForm closePopUp={PopUpclose} />
          </div>
        </div>
      )}
      {editActive && (
        <div className={` ${styles.popcontainer}`}>
          <div style={{ position: "relative", zIndex: "50" }}>
            <EditCategryForm closePopUp={PopUpclose} editActive={editActive} />
          </div>
        </div>
      )}
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "38%",
            top: "50%",
            // zIndex: 10,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to delete this Catagories?"
          />
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
