import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sidebarStyle from "./SideBar.module.css";
import Dashboardicon from "../Assets/dashboard.svg";
import OrdersIcon from "../Assets/SidebarOrder.svg";
import Merchant from "../Assets/merchant.svg";
import Shop from "../Assets/shop.svg";
import Customer from "../Assets/customer.svg";
import Subadmin from "../Assets/subadmin.svg";
import Buisnessanalytics from "../Assets/buisnessanalytics.svg";
import Notification from "../Assets/notification.svg";
import Coupon from "../Assets/coupon.svg";
import Settings from "../Assets/settings.svg";
import Logout from "../Assets/logout.svg";
import Product from "../Assets/product.svg";
import Custombanner from "../Assets/custom banners.svg";
import Catalog from "../Assets/catalog.svg";
import Comment from "../Assets/comment-regular.svg";
import Reviews from "../Assets/Review.svg";
import HappyWall from "../Assets/HappyWall.svg";
import "../common/Fonts.css";
import Logo from "../Assets/logo.svg";
import { useState } from "react";

export default function Sidebar(props) {
  const navigate = useNavigate();
  const menuItems = [
    { name: "Dashboard", icon: Dashboardicon, path: "/admin/home" },
    { name: "Orders", icon: OrdersIcon, path: "/admin/Orders" },
    { name: "Merchants", icon: Merchant, path: "/admin/merchant" },
    { name: "Shops", icon: Shop, path: "/admin/shop" },
    { name: "Customers", icon: Customer, path: "/admin/customer" },
    { name: "Add sub-admin", icon: Subadmin, path: "/admin/subadmin" },
    {
      name: "Business Analytics",
      icon: Buisnessanalytics,
      path: "/admin/buisness-analytics",
    },
    { name: "Notification", icon: Notification, path: "/admin/notification" },
    { name: "Create coupons", icon: Coupon, path: "/admin/coupons" },
    { name: "Settings", icon: Settings, path: "/admin/settings" },
    { name: "Support", icon: Comment, path: "/admin/support" },
  ];

  const menuItemsForAgent = [
    { name: "Dashboard", icon: Dashboardicon, path: "/agent/home" },
    { name: "Pickup", icon: Product, path: "/agent/pickup" },
    { name: "Products", icon: Product, path: "/agent/product" },
    {
      name: "Custom banners",
      icon: Custombanner,
      path: "/agent/customerbanner",
    },
    { name: "Catalog", icon: Catalog, path: "/agent/catalogList" },
    { name: "Reviews", icon: Reviews, path: "/agent/review" },
    { name: "Settings", icon: Settings, path: "/agent/settings" },
    { name: "Happy wall", icon: HappyWall, path: "/agent/happyWall" },
  ];

  useEffect(() => {
    const x = localStorage.getItem("role");
  }, []);
  const styles = {
    text: {
      // fontSize: "17px",
      fontSize: "1rem",
      marginLeft: "10px",
      color: "#5D85BB",
      fontFamily: "InterMedium",
      whiteSpace: "nowrap",
      // width: "120px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // border: "1px solid #000000"
    },
    menuRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingBottom: "12px",
      paddingTop: "12px",
    },
    container: {
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      // padding: "30px 3px 20px",
      paddingTop: "50px",
      height: "90vh",
      // borderRight: "2px solid #4F81C3",
      // minWidth:"0"
    },
    height100vh: {
      height: "100vh",
      borderRight: "2px solid #4F81C3",
    },
  };
  const [activeTab, setActiveTab] = useState(props.activeTabColor);
  const clickHandler = (value) => {
    // setActiveTab(value);

    navigate(value?.path);
  };

  return (
    <>
      <div
        style={{ paddingTop: "10%", ...styles.height100vh, overflowY: "auto" }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <a
            href={
              localStorage.getItem("role") == "admin"
                ? "/admin/home"
                : "/agent/home"
            }
            className="d-none d-md-block"
            style={{
              textDecoration: "none",
              color: "#303A91",
            }}
          >
            <img
              style={{
                width: "50px",
                paddingRight: "8px",
                fontSize: "24px",
                // display: "flex",
                marginLeft: "-12px",
              }}
              className="pe-3"
              src={Logo}
            />
            MyMegaMall
          </a>
        </div>
        <div style={{ ...styles.container }}>
          <div className="d-flex flex-column ">
            {props.flag == true
              ? menuItemsForAgent.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={styles.menuRow}
                      // className={`${sidebarStyle.hoverable}`}
                      className={`${sidebarStyle.hoverable} ${
                        activeTab == item.name ? sidebarStyle.activeTab : ""
                      }`}
                      onClick={() => {
                        clickHandler(item);
                      }}
                    >
                      <div className=" d-flex px-2 justify-content-start align-items-center">
                        <div>
                          <img
                            width={"20px"}
                            height={"20px"}
                            src={item.icon}
                            alt="Happy wall Icon"
                            className="my-1"
                          />
                        </div>
                        <div
                          className={`${sidebarStyle.truncate}`}
                          style={styles.text}
                        >
                          {item.name}
                        </div>
                      </div>
                    </div>
                  );
                })
              : menuItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={styles.menuRow}
                      // className={`${sidebarStyle.hoverable}`}
                      className={`${sidebarStyle.hoverable} ${
                        activeTab === item.name ? sidebarStyle.activeTab : ""
                      }`}
                      onClick={() => {
                        clickHandler(item);
                      }}
                    >
                      <div className=" d-flex ps-3 pe-2 justify-content-start align-items-center ">
                        <div>
                          <img
                            width={"20px"}
                            height={"20px"}
                            src={item?.icon}
                            alt="Item 1 Icon"
                            className="my-1"
                          />
                        </div>
                        <div
                          className={`${sidebarStyle.truncate}`}
                          style={styles.text}
                        >
                          {item.name}
                          {/* {item.name === "Notification" && (
                            <span
                              style={{
                                marginLeft: "10px",
                                fontWeight: 400,
                                fontSize: "14px",
                              }}
                              className={sidebarStyle.badge}
                            >
                              2
                            </span>
                          )} */}
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
          <div
            style={{ ...styles.menuRow, marginBottom: 0, cursor: "pointer" }}
            className={`ps-3 pe-2 py-2  ${sidebarStyle.hoverable}`}
            onClick={() => {
              localStorage.setItem("role", "");
              localStorage.setItem("username", "");
              localStorage.setItem("jwtToken", "");
              localStorage.setItem("name", "");

              navigate("/", { replace: false });
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img className="my-1" style={{ width: "20px" }} src={Logout} />
            </div>
            <div style={styles.text}>Logout</div>
          </div>
        </div>
      </div>
    </>
  );
}
