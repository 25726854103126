import React, { useState, useEffect, useRef } from "react";
import styles from "../../screens/agent/catalog/Catalog.module.css";
import dustbin from "../../Assets/trash_icon.png";
import Card from "../../common/Card";
import Kurtiicon from "../../Assets/Kurti.png";
import colors from "../../utils/helper";
import Button from "../../common/Button";
import Heading from "../../common/Heading";
import Searchicon from "../../Assets/icon_search.svg";
import { useNavigate } from "react-router-dom";
import WarningPop from "../../common/WarningPop";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import Papa from "papaparse";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import placeHolder from "../../Assets/placeholder.png";
import "react-toastify/dist/ReactToastify.css";
import { findBestMatch } from "string-similarity";

const AllProductList = (props) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [data, setData] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  useEffect(() => {
    fetchProducts();
  }, []);
  const [file, setFile] = useState(null);

  // useEffect(() => {}, [file]);
  const buttonStyle = {
    padding: "5px 12px",
    background: "#4F81C3",
    color: "white",
    borderRadius: "8px",
    cursor: "pointer",
    fontWeight: "600",
  };
  const fileInputRef = useRef(null);
  const handleFileChange = async (e) => {
    // setFile(e.target.files[0]);
    await handleFileUpload(e.target.files[0]);
  };


  const handleFileUpload = async (tempFile) => {
    console.log("csv", tempFile);
    try {
      if (!tempFile) {
        return
      }
      const formData = new FormData();
      const fileExtension = tempFile?.name?.split('.').pop().toLowerCase();

      // Check if the file extension is 'csv'
      if (fileExtension !== 'csv') {
        return alert("only csv format is supported")
      }
      formData.append("csvFile", tempFile);
      const response = await axiosInstance.post(
        endpoints.baseUrl + "admin/" + endpoints.bulkUploadOfProducts,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert("File uploaded successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error uploading file:", error);
      // toast.error(" falied File uploaded ", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 5000, // Set the duration for how long the popup should be visible
      // });
    }
  };

  const extractProductDetails = (products) => {
    const extractedProducts = [];

    products.forEach((product) => {
      const { name: productName, description, color, categoryId } = product;
      const categoryName = categoryId ? categoryId.name : "Uncategorized";
      color.forEach((colorItem) => {
        const { name: colorName, sizes } = colorItem;
        sizes.forEach((sizeItem) => {
          const { size, price, stock } = sizeItem;
          extractedProducts.push({
            // id: product?._id,
            categoryName,
            productName,
            description,
            color: colorName,
            size,
            price,
            stock,
          });
        });
      });
    });

    return extractedProducts;
  };

  function sortByUpdatedAtDescending(data) {
    // Check if the data array is valid and not empty
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error("Invalid or empty data array");
    }

    // Sort the data based on the 'updatedAt' field in descending order
    data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    return data;
  }
  const fetchProducts = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.productInventory;
    try {
      const response = await axiosInstance.get(url);
      const sortedData = sortByUpdatedAtDescending(response.data.data);
      setProducts(sortedData);
      setFilteredProducts(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const downloadCSV = () => {
    const arr = extractProductDetails(products);
    console.log(arr);
    const csv = Papa.unparse(arr);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Products");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const deleteHandler = (id) => {
    deleteProduct(id);
    setWarning(0);
  };

  const deleteProduct = async (categoryId) => {
    // console.log("**")
    try {
      // Make the DELETE request to the API
      const response = await axiosInstance.delete(
        endpoints.baseUrl + `admin/${endpoints.deleteProduct}/${categoryId}`
      );
      console.log(response.data.message);
      // If successful, remove the deleted category from the categoriesFromApi state
      setProducts((prevState) =>
        prevState.filter((category) => category._id !== categoryId)
      );
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };
  const navigate = useNavigate();
  const handelPopUpOpen = () => {
    // console.log(id,"----------->>> edit")
    props.handleVerifyClick();
  };

  const handleEditButton = (id) => {
    props.editHandler(id);
  };

  const handelTableOpen = () => {
    props.handelCLickToOpen();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value === "") {
      // If the search input is cleared, reset the filteredProducts to show all products
      setFilteredProducts(products);
    }
  };

  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };

  const openAddtoCatalogHandler = (prodId) => {
    props.openAddCatalogForm(true);
    props.clickedProduct(prodId);
  };

  useEffect(() => {
    // Initially, set the filtered products to all products
    if (inputValue) {
      return setFilteredProducts(products.filter((row) => {
        // Convert all values to lowercase for case-insensitive search
        const lowerCaseSearchValue =
          inputValue?.toLowerCase();

        // Check if any column in the current row contains the search value
        return Object.values(row).some((value) =>
          value?.toString()
            ?.toLowerCase()
            .includes(lowerCaseSearchValue)
        );
      }))
    }
    setFilteredProducts(products);
  }, [products, inputValue]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      // Filter products based on the user's search query
      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };
  const getCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
  };

  // Function to handle pagination click
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4); // Set the number of items to display per page
  return (
    <div>
      <div className="row pt-3 align-items-center pb-3">
        <div className=" col-md-12 col-lg-4 col-xl-6">
          <div className="row align-items-center">
            <div className="col-sm-4 col-xl-3">
              <Heading name="Products" fontSize="24px" fontWeight={600} />
            </div>
            <div className="col-sm-8 col-xxl-6 pt-2 pt-sm-0">
              <div className="row">
                <div className=" col-sm-11 col-lg-12 text-center text-lg-end">
                  <div
                    style={{
                      border: " 2px solid #8C8C8C",
                      borderRadius: "10px",
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <img src={Searchicon} style={{ margin: "5px" }} />
                    <input
                      type="text"
                      style={{
                        outline: "none",
                        backgroundColor: "transparent",
                        border: "none",
                        width: "250px",
                        // paddingRight:"600px",
                        margin: "3px",
                      }}
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-12 col-lg-8 col-xl-6">
          <div className="row justify-content-end align-items-center pt-4  pt-lg-0 ">
            <div
              className="col-6 col-sm-4 text-center text-lg-end ps-lg-0"
            >
              {/* <Button
                marginLeft="2px"
                marginRight="2px"
                width="100%"
                name="Download CSV"
                borderRadius={8}
                btnTextColor="white"
                paddingLeft="8px"
                paddingRight="8px"
                fontSize="15px"
              /> */}
              <label
                htmlFor="fileInput"
                style={buttonStyle}
                className={styles.fs_xsm}
              >
                Bulk Upload
              </label>
              <input
                type="file"
                id="fileInput"
                value={file}
                // style={buttonStyle}
                style={{ display: "none" }}
                onClick={(e) => handleFileChange(e)}
                accept=".csv"
              />
            </div>
            <div
              className="col-6 col-sm-4 text-center text-lg-end pe-lg-0"
              onClick={downloadCSV}
            >
              <Button
                marginLeft="2px"
                marginRight="2px"
                width="100%"
                name="Download CSV"
                borderRadius={8}
                btnTextColor="white"
                paddingLeft="8px"
                paddingRight="8px"
                fontSize="15px"
              />
            </div>
            <div
              className=" col-sm-4 text-center text-lg-end pt-3 pt-sm-0 ps-lg-0"
              onClick={handelPopUpOpen}
            >
              <Button
                marginLeft="2px"
                marginRight="2px"
                width="100%"
                name="Add new product"
                borderRadius={8}
                btnTextColor="white"
                paddingLeft="8px"
                paddingRight="8px"
                fontSize="15px"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {getCurrentItems().map((item, index) => {
          return (
            <div
              key={index}
              className="col-11 col-lg-4 col-xl-3 col-md-6 col-sm-6 my-3"
            // onClick={() => navigate("/agent/shopProduct")}
            >
              <Card bgColor="#fff" width="100%">
                <div
                  className={`${styles.container}`}
                  style={{ position: "relative" }}
                >
                  <div
                    onClick={() => navigate("/agent/shopProduct/" + item._id)}
                  >
                    <div
                      style={{
                        height: "350px",
                        // width: "250px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={
                          item.color[0]?.images[0]
                            ? item.color[0]?.images[0]
                            : placeHolder
                        }
                        style={{
                          cursor: "pointer",
                          // height: "100%",
                          width: "100%",
                          objectFit: "contain",

                        }}
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        position: "absolute",
                        height: "75px",
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                      className="w-100 "
                    >
                      <div className="ps-2">
                        <p
                          className="mb-0"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          {item?.name}
                          {/* A-Line blue printed kurta */}
                        </p>
                        <p
                          className="mb-0"
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {/* Kurta sets */}
                          {item.categoryId?.name}
                        </p>
                        <div className="d-flex align-items-center">
                          <p
                            className="mb-0"
                            style={{ fontSize: "14px", fontWeight: "700" }}
                          >
                            ₹{item.color[0].sizes[0]?.price + item?.profitMargin - item?.companyDiscount}/-
                          </p>
                          <p
                            className="ps-3 mb-0"
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            {((item?.companyDiscount
                              / (item.color[0].sizes[0]?.price + item?.profitMargin)) * 100).toPrecision(2)}% off
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="text-center pb-4"
                  onClick={() => openAddtoCatalogHandler(item._id)}
                >
                  <Button width="100%" name=" Add to catalog" />
                </div>
              </Card>

              <div
                className="mt-1 px-1"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    color: colors.tableTextColor,
                    cursor: "pointer",
                    paddingRight: "18px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  onClick={() => handleEditButton(item._id)}
                >
                  Edit
                </p>
                <div
                  onClick={(event) => {
                    // calculateXandY(event);
                    setActive(item._id);
                    warningHandler();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img width={15} height={15} src={dustbin} alt="Trash Icon" />
                </div>
              </div>
            </div>
          );
        })}
        {
          <div className=" d-flex flex-column justify-content-center align-items-end">
            <div className="row">
              <div className="col-md-12 text-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination flex-wrap">
                    {Array.from({
                      length: Math.ceil(filteredProducts.length / itemsPerPage),
                    }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${currentPage === index + 1 ? "active" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        }
      </div>
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "35%",
            top: "45%",
            zIndex: 10000,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to remove this product?"
          />
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default AllProductList;
