import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import trashicon from "../../Assets/icon_trash.png";
import redeye from "../../Assets/remove_red_eye.png";
import styles from "./CustomerListTable.module.css";
import WarningPop from "../../common/WarningPop";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import { toast, ToastContainer } from "react-toastify";
const CustomerListTable = (props) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const navigate = useNavigate();
  const dataOFAllCustomer = () => {
    props.fetchDataOFAllCustomer();
  };
  // const [specificCustomer, setspecificCustomer] = useState([]);
  // const specificCustomerData = async (tempId) => {
  //   try {
  //     const response = await axiosInstance.get(
  //       endpoints.baseUrl +"admin/" +endpoints.specificCustomer +tempId
  //     );
  //     setspecificCustomer(response.data.data);
  //     // console.log(response.data.data,"*****8******************************");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteCustomer = async (tempId) => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.deleteCustomer + tempId; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      console.log("Delete response------------------->:", response.data.data);
      toast.success("customer deleted succesfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } catch (err) {
      // console.log("Delete error:", err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const deleteHandler = async (val) => {
    try {
      const tempId = props.data[val]._id;
      await deleteCustomer(tempId);
      console.log("this is my temp id");
      // const newData = getDataOfMerchant.filter((item, index) => index !== val);
      // setdataFromApi(newData);
      // window.location.reload();
      dataOFAllCustomer();
    } catch (error) {
      console.log(error);
    }

    setWarning(0);
  };

  const warningHandler = (val) => {
    console.log("hello from warning handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  useEffect(() => { }, [data]);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };

  // const deleteElement = (index, ar) => {
  //   for (let i = 0; i < ar.length; i++) {
  //     if (i < index) {
  //       console.log("delete index ->", i);
  //       continue;
  //     }
  //     ar[i] = ar[i + 1];
  //   }
  //   ar.pop();
  //   // data=[...ar]
  //   console.log(data);
  //   return ar;
  // };
  // const deleteHandler = (val) => {
  //   console.log("from delete handler", val);
  //   let ar = deleteElement(val, data);

  //   setData([...ar]);
  //   setWarning(0);
  // };

  return (
    <>
      <div className={`table-responsive ${styles.tablecontainer}`}>
        <table className={`table table-borderless  ${styles.table}`}>
          <thead style={{ backgroundColor: "#fff" }}>
            {props.header.map((th, index) => (
              <tr key={index}>
                <th
                  style={{
                    fontFamily: "InterSemiBold",
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                  scope="col"
                >
                  {th.header1}
                </th>
                <th
                  style={{
                    fontFamily: "InterSemiBold",
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                  scope="col"
                >
                  {th.header2}
                </th>
                <th
                  style={{
                    fontFamily: "InterSemiBold",
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                  scope="col"
                >
                  {th.header3}
                </th>
                <th
                  style={{
                    fontFamily: "InterSemiBold",
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "table-cell",
                    verticalAlign: "middle",
                    // textAlign:"center"
                  }}
                  scope="col"
                >
                  {th.header4}
                </th>
                <th
                  style={{
                    fontFamily: "InterSemiBold",
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                  scope="col"
                >
                  {th.header5}
                </th>
                <th
                  style={{
                    fontFamily: "InterSemiBold",
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                  scope="col"
                >
                  {th.header6}
                </th>
                <th
                  style={{
                    fontFamily: "InterSemiBold",
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                  scope="col"
                >
                  {th.header7}
                </th>
                <th
                  style={{
                    fontFamily: "InterSemiBold",
                    fontWeight: "500",
                    fontSize: "16px",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                  scope="col"
                >
                  {th.header8}
                </th>
                {/* <th></th>
                <th></th> */}
              </tr>
            ))}
          </thead>
          <tbody>
            {props.data.map((row, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                  borderBottom: "1px solid #EBEBEB",
                }}
              >
                <td
                  style={{
                    // fontWeight: "500",
                    display: "table-cell",
                    verticalAlign: "middle",
                    fontSize: "16px",
                    // fontFamily: "InterMedium",
                  }}
                >
                  <div
                    title={row.name}
                    style={{
                      maxWidth: "120px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.name ? row.name : "---------"}
                  </div>
                </td>
                <td
                  style={{
                    // fontFamily: "InterSemiBold",
                    display: "table-cell",
                    verticalAlign: "middle",
                    // fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  {row.mobileNumber ? row.mobileNumber : row.email || "---------"}
                </td>
                <td
                  style={{
                    // fontFamily: "InterSemiBold",
                    display: "table-cell",
                    verticalAlign: "middle",
                    // fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  <div
                    title={row.item3}
                    style={{
                      maxWidth: "120px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.gender ? row.gender : "---------"}
                  </div>
                </td>
                <td
                  style={{
                    // fontFamily: "InterSemiBold",
                    display: "table-cell",
                    verticalAlign: "middle",
                    // fontWeight: "500",
                    fontSize: "16px",
                    // textAlign:"center"
                  }}
                >
                  {typeof row.dob !== "undefined"
                    ? row.dob
                    : "---------"}
                </td>
                <td
                  style={{
                    // fontFamily: "InterSemiBold",
                    display: "table-cell",
                    verticalAlign: "middle",
                    // fontWeight: "500",
                    fontSize: "16px",
                    // textAlign:"center"
                  }}
                >
                  {typeof row.totalOrders !== "undefined"
                    ? row.totalOrders
                    : "---------"}
                </td>
                <td
                  style={{
                    // fontFamily: "InterSemiBold",
                    display: "table-cell",
                    verticalAlign: "middle",
                    // fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  {row?.address?.length > 0
                    ? `${row.address[0].street}, ${row.address[0].city}, ${row.address[0].state}, ${row.address[0].country} - ${row.address[0].pin}`
                    : "---------"}
                </td>
                <td
                  style={{
                    // fontFamily: "InterSemiBold",
                    display: "table-cell",
                    verticalAlign: "middle",
                    // fontWeight: "500",
                    fontSize: "16px",
                    color: "#EC751F",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/admin/specificCustomer/" + row._id)}
                >
                  <img src={redeye} alt="View" />
                </td>
                <td
                  style={{
                    fontFamily: "InterSemiBold",
                    display: "table-cell",
                    verticalAlign: "middle",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#EC751F",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActive(index);
                    warningHandler();
                  }}
                >
                  <img src={trashicon} alt="Delete" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {warning ? (
          <div
            style={{
              width: "35%",
              height: "50%",
              position: "absolute",
              left: "30%",
              top: "30%",
              // zIndex: 10,
            }}
          >
            <WarningPop
              delete={() => deleteHandler(active)}
              closePopUp={(val) => {
                warningHandler(val);
              }}
              message="Are you sure you want to delete this user?"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CustomerListTable;
