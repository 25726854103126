import React from "react";
import Heading from "../../common/Heading";
import Sidebar from "../../common/SideBar";
import NavBarEx from "../../common/NavBarEx";
import styles from "./Order.module.css";
import colors from "../../utils/helper";
import ReccentOrders from "../../components/Orders/ReccentOrders";
import ReturnRequest from "../../components/Orders/ReturnRequest";
import redeye from "../../Assets/remove_red_eye.png";
import PreLoader from "../../common/PreLoader";
import { useEffect, useState } from "react";
import Card from "../../common/Card";
import { DataGrid } from "@mui/x-data-grid";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const Orders = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [returns, setReturns] = useState([]);
  const columns = [
    { flex: 1, field: "id", headerName: "Sno." },
    { flex: 1, field: "_id", headerName: "Order ID" },
    { flex: 1, field: "customer", headerName: "Customer ID" },
    { flex: 1, field: "createdAt", headerName: "Order date" },
    { flex: 1, field: "amount", headerName: "Order total", type: "number" },
    // { flex: 1, field: "paymentStatus", headerName: "Payment status" },
    { flex: 1, field: "addressId", headerName: "Billing address" },
    { flex: 1, field: "status", headerName: "Order status" },
    { flex: 1, field: "paymentMode", headerName: "Payment method" },
    // { field: "products", headerName: "Products" },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
            navigate("/admin/orderDescription" + "/" + params.row._id);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            {!params?.row?.resolved && (
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={onClick}
              >
                <img src={redeye} style={{ width: "20px" }} />
              </button>
            )}
          </>
        );
      },
    },
  ];
  const returnColumns = [
    { flex: 1, field: "id", headerName: "Sno." },
    { flex: 1, field: "_id", headerName: "Return ID" },
    { flex: 1, field: "orderId", headerName: "Order ID" },
    { flex: 1, field: "customer", headerName: "Customer ID" },
    { flex: 1, field: "reason", headerName: "Reason" },
    { flex: 1, field: "status", headerName: "Status" },
    { flex: 1, field: "paymentMode", headerName: "Payment mode" },
    { flex: 1, field: "createdAt", headerName: "Order date" },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          try {
            e.stopPropagation(); // don't select this row after clicking
            console.log(params.row);
            navigate("/admin/returnDescription" + "/" + params.row._id);
          } catch (error) {
            console.log(error);
          }
        };

        return (
          <>
            {!params?.row?.resolved && (
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={onClick}
              >
                <img src={redeye} style={{ width: "20px" }} />
              </button>
            )}
          </>
        );
      },
    },
  ];
  const getReturns = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getAllReturns + "?limit=5"
      );
      console.log(response.data.data);
      setReturns(
        response.data.data.map((item, index) => {
          return {
            ...item,
            id: index + 1,
            orderId: item.orderId._id,
            customer: item?.customer?._id,
            createdAt: moment(item.createdAt),
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const fetchData = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getAllOrders + "?limit=5"
      );

      let data = response.data.data.map((item, index) => {
        return {
          ...item,
          id: index + 1,
          createdAt: moment(item.createdAt),
          customer: item?.customer?._id,
          addressId: `${item?.addressId?.houseNo}, ${item?.addressId?.street}, ${item?.addressId?.city}, ${item?.addressId?.state} - ${item?.addressId?.pincode}, Near ${item?.addressId?.landmark}`,
        };
      });
      setRows(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    getReturns();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-lg-2 col-md-3 ${styles.left}`}
            style={{ paddingRight: "0px" }}
          >
            <Sidebar active={true} activeTabColor="Orders" />
          </div>

          <div
            className="col-lg-10 col-md-9 right py-3"
            style={{
              backgroundColor: colors.bgcolor,
              overflowY: "auto",
              height: "100vh",
              flexWrap: "wrap",
            }}
          >
            {loading && <PreLoader />}
            <div className="pt-3">
              <NavBarEx
                buttonFlag={false}
                buttonName={"Add new customer"}
                fontSize={"38px"}
                search={true}
              />
            </div>
            <div className="row pt-4">
              <div className="col-12">
                {/* <ReccentOrders /> */}
                <Card backgroundColor="#fff">
                  <div className="pt-4 d-flex justify-content-between align-items-center">
                    <Heading name="Recent Orders" paddingLeft="1%" />
                    <div className=" me-3">
                      <Button
                        name="View all"
                        fontSize="15px"
                        onClick={() => navigate("/admin/orders/all")}
                      />
                    </div>
                  </div>
                  <div
                    className="px-4 py-2"
                    style={{ height: 400, width: "100%", overflow: "scroll" }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      // initialState={{
                      //   // pagination: {
                      //   //   paginationModel: { page: 0, pageSize: 5 },
                      //   // },
                      // }}
                      // pageSizeOptions={[5, 10]}
                      //   checkboxSelection
                      hideFooter
                      style={{ border: "none" }}
                      scrollbarSize={100}
                    />
                  </div>
                </Card>
              </div>
              <div className="col-12 pt-4">
                {/* <ReturnRequest /> */}
                <Card backgroundColor="#fff">
                  <div className="pt-4 d-flex justify-content-between align-items-center">
                    <Heading name="Recent Return Requests" paddingLeft="1%" />
                    <div className=" me-3">
                      <Button
                        name="View all"
                        fontSize="15px"
                        onClick={() => navigate("/admin/orders/return/all")}
                      />
                    </div>
                  </div>
                  <div
                    className="px-4 py-2"
                    style={{ height: 400, width: "100%", overflow: "scroll" }}
                  >
                    <DataGrid
                      rows={returns}
                      columns={returnColumns}
                      // initialState={{
                      //   // pagination: {
                      //   //   paginationModel: { page: 0, pageSize: 5 },
                      //   // },
                      // }}
                      // pageSizeOptions={[5, 10]}
                      //   checkboxSelection
                      hideFooter
                      style={{ border: "none" }}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
