import React, { useEffect, useState } from "react";
import Sidebar from "../../common/SideBar";
import NavBarEx from "../../common/NavBarEx";
import styles from "./SpecificShop.module.css";
import SpecificCustomerDetails from "../../components/SpecificCustomerDetails";
import PreLoader from "../../common/PreLoader";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";

export default function SpecificCustomer() {
  const params = useParams();
  const id = params.id;
  const [open, setOpen] = useState(0);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    specificCustomerData();
    customerAnaytics(id);
    specificRecentReviews(id);
    orderTableData(id);
  }, [id]);
  const [customerAnayticsData, setcustomerAnayticsData] = useState([]);
  const customerAnaytics = async (tempId) => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl +
          "admin/" +
          endpoints.anyticsOfSpecificCusotmer +
          tempId
      );
      setcustomerAnayticsData(response.data.data);
      // console.log(response.data.data,"*****8******************************");
    } catch (error) {
      console.error(error);
    }
  };
  const [specificCustomer, setspecificCustomer] = useState([]);
  const specificCustomerData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.specificCustomer + id
      );
      setspecificCustomer(response.data.data);
      // console.log(response.data.data,"*****8******************************");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const [allOrdersData, setallOrdersData] = useState([]);
  const orderTableData = async (tempId) => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl +
          "admin/" +
          endpoints.customerAllOrdersById +
          tempId +
          "/all"
      );
      setallOrdersData(response.data.data);
      console.log(response.data.data, "*****8******************************");
    } catch (error) {
      console.log(error);
    }
  };
  const [recentReviews, setRecentReviews] = useState([]);
  const specificRecentReviews = async (tempId) => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl +
          "admin/" +
          endpoints.specificCustomerRecentReviews +
          tempId
      );
      setRecentReviews(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {/* <NavBar/> */}

      <div className="container-fluid">
        <div className="row ">
          {/* sidebar */}
          <div className={`col-lg-2 col-md-3 pe-0  ${styles.left}`}>
            <Sidebar activeTabColor="Customers" />
          </div>
          {/* page-content */}
          <div
            className="col-lg-10 col-md-9 py-3"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#EAF0FA",
            }}
          >
            {loading && <PreLoader />}
            {/* navbar */}
            <NavBarEx search={true} />
            {/* page-content */}
            <SpecificCustomerDetails
              specificCustomer={specificCustomer}
              customerAnayticsData={customerAnayticsData}
              allOrdersData={allOrdersData}
              recentReviews={recentReviews}
              getData={specificCustomerData}
            />
          </div>
        </div>
      </div>
    </>
  );
}
