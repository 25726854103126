import React, { useState, useRef, useEffect } from "react";
import styles from "./ShopList.module.css";
import trashicon from "../../Assets/icon_trash.png";
import redeye from "../../Assets/remove_red_eye.png";
import ShopEditForm from "../Shop/ShopEditForm";
import colors from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import editicon from "../../Assets/edit.svg";
import Heading from "../../common/Heading";
import DropDown from "../../common/DropDown";
import AllTable from "../AllTable";
import dropimg from "../../Assets/dropimgwhite.svg";
import Card from "../../common/Card";
import WarningPop from "../../common/WarningPop";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../common/Button";

const ShopListTable = (props) => {
  const [editActive, setEditActive] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState(props.data);
  const [popupOpen, setPopupOpen] = useState(false);
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);

  const warningHandler = (val) => {
    // console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };
  const [getDataOfShop, setDataOSfhop] = useState([]);
  const deleteMerchant = async (merchantId) => {
    const url = endpoints.baseUrl + "admin/general/shop/" + merchantId; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      console.log("Delete response:", response.data);
    } catch (err) {
      // console.log("Delete error:", err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const deleteHandler = async (val) => {
    console.log("from delete handler", val);
    try {
      // Get the _id of the subadmin to be deleted
      const merchantId = dataOfAllShops[val]._id;

      // Make the delete API call
      await deleteMerchant(merchantId);

      // Remove the subadmin from the local state
      const newData = dataOfAllShops.filter((item, index) => index !== val);
      setDataOSfhop(newData);
      fetchDataOfAllShops();
    } catch (error) {
      // console.log("Delete Handler Error:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }

    setWarning(0);
  };
  const handleVerifyClick = (e) => {
    setEditActive(e);
    // e.preventDefault();
    console.log("edit form shop", e);
    setPopupOpen(true);
  };

  const PopUpclose = () => {
    setPopupOpen(false);
  };

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const item1 = [
    "Recent sales ",
    "Top sales",
    "Last 15 days",
    "Last 30 days",
    "Last year",
  ];
  const headerdata = [
    {
      header1: "Shop name",
      header2: "Shop owner",
      header3: "No. of agent",
      header4: "No. of category",
      header5: "Total orders",
      header6: "Total sales",
      header3: "Edit",
      header14: "View",
      header15: "Delete",
    },
  ];
  useEffect(() => {
    fetchDataOfAllShops();
    // getFiveShops();
    // TopSalesData();
  }, [popupOpen]);
  function sortByUpdatedAtDescending(data) {
    // Check if the data array is valid and not empty
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error("Invalid or empty data array");
    }

    // Sort the data based on the 'updatedAt' field in descending order
    // data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    return data;
  }
  const [dataOfAllShops, setdataOfAllShops] = useState([]);
  const fetchDataOfAllShops = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allShops
      );
      const sortedData = sortByUpdatedAtDescending(response.data.data);
      const slicedArray = response.data.data.slice(0, 6);
      setdataOfAllShops(slicedArray);
      const reciveData = mapDataToTableFormat(response.data.data);
      setMappedDataForTable(reciveData);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [mappedDataForTable, setMappedDataForTable] = useState([]);
  const mapDataToTableFormat = (data) => {
    return data.map((item) => {
      const {
        name,
        totalOrders,
        _id,
        owner,
        agents,
        categories,
        totalRevenue,
      } = item;

      return {
        item0: _id,
        item1: name || "-----",
        item2: "-----",
        item3: owner || "-----",
        item4: agents?.length || "-----",
        item5: categories?.length || "-----",
        item6: totalOrders || "-----",
        item7: totalRevenue.toPrecision(2) || "-----",
      };
    });
  };

  return (
    <div ref={popupRef}>
      <Card>
        <div
          className="row justify-content-between py-3"
          style={{ paddingLeft: "3%", paddingRight: "2.5%" }}
        >
          <div className="col-8 col-sm-6 ">
            <div className="d-sm-flex align-items-sm-center">
              <Heading fontSize={"24px"} fontWeight={600} name="Shop list" />
              {/* <div className="ps-sm-5 pt-3 pt-sm-0">
                <DropDown
                  top="Top sales"
                  items={item1}
                  img={dropimg}
                  bg={"#4F81C3"}
                  zIndex="0"
                  textcolor={"white"}
                />
              </div> */}
            </div>
          </div>
          <div
            className="col-4 col-sm-3 col-lg-4 pt-sm-0 text-end"
            style={{ paddingTop: "3px" }}
          >
            <Button
              name="View all"
              fontSize="15px"
              onClick={() => navigate("/admin/shop/all")}
            />
          </div>
          {/* <div
            className="col-4 col-sm-6 text-end"
            style={{ paddingTop: "3px" }}
          >
            <AllTable
              fetchDataOfAllShops={fetchDataOfAllShops}
              deleteHandler={deleteHandler}
              data={data}
              header={headerdata}
              mappedDataForTable={mappedDataForTable}
              editShop
              trash
              redeye
              item5
              item6
              item7
              item13
              item14
              item15
              headingname={"Shop list"}
              searchicon
              activeTabColor="Shops"
              navigation={"/admin/specificShop"}
            />
          </div> */}
        </div>
        <div
          className={`table-responsive ${styles.tablecontainer}`}
          style={{ paddingLeft: "2.4%", paddingRight: "2.5%" }}
          // style={{ overflowX: "auto", maxHeight: "400px" }}
        >
          <table className={`table table-borderless ${styles.table}`}>
            <thead>
              {props.header.map((th, index) => (
                <tr
                  style={{
                    borderRadius: "12px",
                  }}
                  key={index}
                >
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header1}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header2}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header3}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header4}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header5}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header6}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header7}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header8}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header9}
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {th.header10}
                  </th>
                </tr>
              ))}
            </thead>
            <tbody>
              {dataOfAllShops.map((row, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                    borderBottom: "1px solid #EBEBEB",
                  }}
                >
                  <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <div
                      title={row.name}
                      style={{
                        maxWidth: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.name ? row.name : "--------"}
                    </div>
                  </td>
                  <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <div
                      title={row.item3}
                      style={{
                        maxWidth: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.owner ? row?.owner[0]?._id : "N/A"}
                    </div>
                  </td>
                  <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <div
                      title={row.item3}
                      style={{
                        maxWidth: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.owner ? row?.owner[0]?.name : "N/A"}
                    </div>
                  </td>
                  <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {row?.agents?.length}
                  </td>
                  {/* <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {row?.categories?.length > 0
                      ? row?.categories?.length
                      : "--------"}
                  </td> */}
                  <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {row.totalOrders != null ? row.totalOrders : "--------"}
                  </td>
                  <td
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    {row.totalRevenue != null
                      ? row.totalRevenue.toFixed(2)
                      : "--------"}
                  </td>
                  <td
                    style={{
                      color: colors.tableEditColor,
                      cursor: "pointer",
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                    onClick={() => handleVerifyClick(row._id)}
                  >
                    <img
                      style={{ height: "15px", cursor: "pointer" }}
                      src={editicon}
                      alt="Delete"
                    />
                  </td>
                  <td
                    onClick={(event) => {
                      setActive(index);
                      warningHandler();
                    }}
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <img
                      src={trashicon}
                      alt="Delete"
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                    onClick={() => navigate("/admin/specificShop/" + row._id)}
                  >
                    <img src={redeye} alt="View" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
      {popupOpen && (
        <div className={styles.popcontainer}>
          <div style={{ position: "relative", zIndex: "20" }}>
            <ShopEditForm
              fetchDataOfAllShops={fetchDataOfAllShops}
              editActive={editActive}
              closePopUp={PopUpclose}
            />
          </div>
        </div>
      )}
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "30%",
            top: "30%",
            // zIndex: 10,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to delete the shop?"
          />
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default ShopListTable;
