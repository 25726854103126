import React from "react";
import Backicon from "../../Assets/backicon.png";
import Button from "../../common/Button";
import { useState, useEffect } from "react";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
export default function AddProductCatalogForm(props) {
  const [cataLogNames, setCataLogNames] = useState([]);

  const fetchCatalogs = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.getAllCatalogList;
    try {
      const response = await axiosInstance.get(url);
      // setProducts(response.data.data);
      setCataLogNames(response.data.data);
      console.log(response.data.data, "in add product catalog form");
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  useEffect(() => {
    fetchCatalogs();
  }, []);

  const findCatalogIdByName = (cataLogName) => {
    for (let i = 0; i < cataLogNames.length; i++) {
      if (cataLogNames[i].name === cataLogName) {
        return cataLogNames[i]._id;
      }
    }
    return null;
  };

  const handleSubmit = () => {
    const catalogId = findCatalogIdByName(selectedOption);
    console.log(catalogId,"catalogId****************")
    addProductToCatalog(catalogId);
    props.closePopUp();
  };
  const addProductToCatalog = async (catalogId) => {
    try {
      const url =
        endpoints.baseUrl +
        "admin/" +
        endpoints.addProductToCatalog +
        catalogId +
        "/item";
      const data = {
        productId: props.productId,
      };
      const response = await axiosInstance.post(url, data);
    } catch (err) {
      console.log(err);
    }
  };

  const closePopUpHandeler = () => {
    // console.log("close *********")
    props.closePopUp();
  };
  const [size, setSize] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [catalogName, setCatalogName] = useState("");
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    console.log("Selected Option:", selectedValue);
  };

  return (
    <div style={{ border: "2px solid #4F81C3", backgroundColor: "#fff" }}>
      {/* {JSON.stringify(categoryNames)} */}
      <div className=" container-fluid py-3">
        <img
          src={Backicon}
          onClick={closePopUpHandeler}
          style={{ cursor: "pointer" }}
        />
        <div
          className="row justify-content-center overflow-auto"
          style={{ maxHeight: "70vh" }}
        >
          <div className=" col-12 pt-3">
            <label
              style={{ fontWeight: 500, fontSize: 15, display: "block" }}
              className="pb-2"
            >
              Catalog
            </label>
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                // width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="ps-2 py-2 mt-1 w-100"
            >
              <option value="" disabled className="d-none">
                Select Catalog
              </option>
              {cataLogNames.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="pt-5 text-center" onClick={() => handleSubmit()}>
            <Button name="Submit" width="fit-content" borderRadius="12px" />
          </div>
        </div>
      </div>
    </div>
  );
}
