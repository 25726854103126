import React from 'react'
import pagenotfound from "../Assets/pageNotFound.webp"
export default function PageNotFound() {
    const backgroundStyles = {
        backgroundImage: "url('../Assets/pageNotFound.webp')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
    return (
        <div style={{ height: '100vh', width: '100%', backgroundColor: 'beige' ,display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{display:'flex',justifyContent:'center'}}>
                <h1 style={{fontSize:'800%'}}>
                    Page Not Found
                </h1>
            </div>


        </div>
        
        // <div style={backgroundStyles}>
        //     hello
        // </div>
    )
}
