import React from "react";
import styles from "./BlukProduct.module.css";
import Card from "../../common/Card";
import Button from "../../common/Button";
import Papa from "papaparse";
const BulkProduct = (props) => {
  const handelBackToCustomer = () => {
    props.handelCLickToBack();
  };

  const downloadCSV = () => {
    const csv = Papa.unparse(tabledata);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "test");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };
  
  const tabledata = [
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Lorem Ipsum is simply dummy text of the  ",
      item3: "Lehenga",
      item4: "A-line Lehenga",
      item5: "Bridal Lehenga",
      item6: "Red",
      item7: "S",
      item8: "56",
      item9: "link1,link2,link3",
      item10: "link10",
      item11: "9,000",
      item12: "5%",
      item13: "20%",
    },
  ];
  const headerdata = [
    {
      header1: "Product name",
      header2: "Product description",
      header3: "Category",
      header4: "Subcategory",
      header5: "Subsubcategory",
      header6: "Colour",
      header7: "Size",
      header8: "Quantity",
      header9: "Images link",
      header10: "Youtube link",
      header11: "Amount",
      header12: "Gst",
      header13: "Company discount",
    },
  ];
  return (
    <div>
      <Card backgroundColor="#fff">
        <div className="px-4 py-5" style={{ border: "2px solid #4F81C3" }}>
          <div
            className={`table-responsive ${styles.tablecontainer}`}
            style={{ overflow: "auto", maxHeight: "350px" }}
          >
            <table className={`table table-borderless ${styles.table}`}>
              <thead>
                {headerdata.map((th, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "12px",
                      textAlign: "center",
                    }}
                  >
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header1}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header2}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header3}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header4}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header5}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header6}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header7}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header8}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header9}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header10}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header11}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header12}
                    </th>
                    <th
                      style={{
                        color: "#181A1D",
                        background: "#DEE9F8",
                        fontWeight: "500",
                        fontSize: "18px",
                        fontFamily: "InterMedium",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      {th.header13}
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody>
                {tabledata.map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      // ...border,
                      backgroundColor: "#F3F3F3",
                      textAlign: "center",
                    }}
                  >
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                      }}
                    >
                      {row.item1}
                    </td>
                    <td
                      title={row.item2}
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                        maxWidth: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.item2}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                      }}
                    >
                      {row.item3}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                      }}
                    >
                      {row.item4}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                      }}
                    >
                      {row.item5}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                        maxWidth: "139px",
                      }}
                    >
                      {row.item6}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                        maxWidth: "139px",
                      }}
                    >
                      {row.item7}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                      }}
                    >
                      {row.item8}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                      }}
                    >
                      {row.item9}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                      }}
                    >
                      {row.item10}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                      }}
                    >
                      {row.item11}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                        maxWidth: "139px",
                      }}
                    >
                      {row.item12}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "InterMedium",
                        maxWidth: "139px",
                      }}
                    >
                      {row.item13}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pt-3 d-flex align-items-center justify-content-center">
            <div onClick={downloadCSV}>
              <Button width="100%" name="Download sample csv" />
            </div>
            <div onClick={handelBackToCustomer} className="ps-4">
              <Button width="100%" name="Back to Customer" />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default BulkProduct;
