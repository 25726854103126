import React, { useState } from "react";
import BackIcon from "../../Assets/backicon.png";
import Button from "../../common/Button";
import deleteicon from "../../Assets/trash_icon.png";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";

const NewCategoryForm = (props) => {
  const [image, setImage] = useState(null);
  const [nameOfCategory, setNameOfCategory] = useState("");
  const [categoryNameError, setCategoryNameError] = useState("");
  const [coverPhotoError, setCoverPhotoError] = useState("");

  const imageHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const nameHandler = (e) => {
    setNameOfCategory(e.target.value);
  };

  const validateForm = () => {
    let isValid = true;

    if (nameOfCategory.trim() === "") {
      setCategoryNameError("Category name cannot be empty");
      isValid = false;
    } else {
      setCategoryNameError("");
    }

    if (!image) {
      setCoverPhotoError("Please select a cover photo");
      isValid = false;
    } else {
      setCoverPhotoError("");
    }

    return isValid;
  };

  const createCategory = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      const formData = new FormData();
      formData.append("name", nameOfCategory);
      formData.append("images", image);

      const response = await axiosInstance.post(
        endpoints.baseUrl + "admin/" + endpoints.createNewCategory,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data.data);
      window.location.reload();
    } catch (error) {
      console.error("Internal Server Error", error);
    }
  };

  const closePopUpHandeler = () => {
    props.closePopUp();
  };

  return (
    <>
      <div
        style={{ backgroundColor: "#fff", border: "2px solid #4F81C3" }}
        className=" "
      >
        <div className="container-fluid py-5 px-4">
          <img
            src={BackIcon}
            alt="Back Icon"
            onClick={closePopUpHandeler}
            style={{ cursor: "pointer" }}
          />
          <div className="row pt-4">
            <div className="col-12 pt-3">
              <label style={{ fontWeight: 500, fontSize: 16 }}>
                Category name
              </label>
              <input
                id="categoryName"
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                className="mt-2 ps-3 py-2"
                type="text"
                value={nameOfCategory}
                onChange={nameHandler}
              />
              {categoryNameError && (
                <div style={{ color: "red" }}>{categoryNameError}</div>
              )}
            </div>
            <div className="col-12 pt-3">
              <label style={{ fontWeight: 500, fontSize: 16 }}>
                Cover photo
              </label>
              <input
                id="coverPhoto"
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                onChange={imageHandler}
                className="mt-2 ps-3 py-5"
                type="file"
                accept="image/*"
              />
              {coverPhotoError && (
                <div style={{ color: "red" }}>{coverPhotoError}</div>
              )}
            </div>
          </div>
          <div className="pt-5 text-center" onClick={createCategory}>
            <Button
              name="Submit"
              width="fit-content"
              borderRadius="12px"
              fontSize="15px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCategoryForm;
