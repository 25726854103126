import React, { useEffect, useState } from "react";
import styles from "./StockReport.module.css";
import dustbin from "../../Assets/icon_trash.png";
import redeye from "../../Assets/remove_red_eye.png";
import Heading from "../../common/Heading";
import AllTable from "../AllTable";
import editicon from "../../Assets/edit.svg";
import Card from "../../common/Card";
import WarningPop from "../../common/WarningPop";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Button from "../../common/Button";
import moment from "moment/moment";
const StockReport = (props) => {
  const navigate = useNavigate();
  const columns = [
    { field: "id", headerName: "Sno" },
    {
      field: "cover",
      headerName: "Image",
      sortable: false,
      flex: 1,
      resizable: true,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            <img src={params?.row?.cover} height={50} alt="No image found" />
          </div>
        );
      },
    },
    { field: "_id", headerName: "Product Id", flex: 1, minWidth: 200 },
    { field: "name", headerName: "Product name", flex: 1, minWidth: 200 },
    { field: "category", headerName: "Category", flex: 1, minWidth: 200 },
    { field: "subcategory", headerName: "SubCategory", flex: 1, minWidth: 200 },
    // { field: "createdAt", headerName: "Date added", flex: 1 },
    { field: "color", headerName: "Color", flex: 1, minWidth: 200 },
    { field: "size", headerName: "Size", flex: 1, minWidth: 200 },
    { field: "qty", headerName: "Quantity", flex: 1, type: "number", minWidth: 100 },
    { field: "price", headerName: "Cost Price", flex: 1, type: "number", minWidth: 200 },
    {
      field: "view",
      headerName: "View",
      maxWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return (
          <img
            onClick={() => navigate("/agent/shopProduct/" + params.row._id)}
            src={redeye}
            alt="Red Eye Icon"
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      maxWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              OpenEditPopup(params.row._id);
            }}
          >
            <img
              style={{ height: "15px", cursor: "pointer" }}
              src={editicon}
              alt="Edit"
            />
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      maxWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              setActive(params.row._id);
              warningHandler();
            }}
          >
            <img
              width={15}
              height={20}
              src={dustbin}
              alt="Trash Icon"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
  const [rows, setrows] = useState([]);
  const deleteProduct = async (id) => {
    // console.log("**********************")
    try {
      // Make the DELETE request to the API
      const response = await axiosInstance.delete(
        endpoints.baseUrl + `admin/${endpoints.deleteProduct}/${id}`
      );
      console.log(response.data.message);
      fetchProductsFive();
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };
  const deleteHandler = async (index) => {
    try {
      console.log("from delete handler product screen", index);
      deleteProduct(active);
    } catch (error) {
      console.log(error);
    } finally {
      setWarning(false);
    }
  };
  const [fiveProducts, setFiveProducts] = useState([]);
  // Function to fetch all products using Axios
  const fetchProductsFive = async () => {
    let url = endpoints.baseUrl + "admin/" + endpoints.productInventory;
    if (props?.limit) {
      url = url + `?limit=${props?.limit}`;
    }
    console.log("******", url);
    try {
      const response = await axiosInstance.get(url);
      setFiveProducts(response.data.data);
      let extractedProducts = [];
      response.data.data.forEach((product, index) => {
        const {
          name: productName,
          description,
          color,
          categoryId,
          subcategoryId,
          createdAt,
        } = product;
        color.forEach((colorItem) => {
          const { name: colorName, sizes, images } = colorItem;
          sizes.forEach((sizeItem) => {
            const { size, price, stock } = sizeItem;
            extractedProducts.push({
              ...product,
              category: categoryId ? categoryId.name : "Uncategorized",
              subcategory: subcategoryId?.name,
              name: productName,
              description,
              color: colorName,
              cover: images[0] || "",
              size,
              price,
              qty: stock,
              createdAt: moment(createdAt),
            });
          });
        });
      });
      extractedProducts = extractedProducts.map((row, index) => {
        return {
          ...row,
          id: index + 1,
        };
      });
      setrows(extractedProducts);
      const recievedData = mapDataToTableFormat(response.data.data);
      console.log(recievedData);
      setMappedDataForTable(recievedData);
    } catch (error) {
      console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  useEffect(() => {
    fetchProductsFive();
  }, []);

  const [active, setActive] = useState();
  const [warning, setWarning] = useState(false);
  const [mappedDataForTable, setMappedDataForTable] = useState([]);
  const mapDataToTableFormat = (data) => {
    return data.map((item) => {
      const { name, color, categoryId, createdAt, _id } = item;
      const totalStock = color.reduce((acc, curr) => {
        return acc + (curr.sizes.length > 0 ? curr.sizes[0].stock : 0);
      }, 0);
      const totalPrice = color.reduce((acc, curr) => {
        return acc + (curr.sizes.length > 0 ? curr.sizes[0].price : 0);
      }, 0);

      return {
        item0: _id || "-----",
        item1: name || "empty",
        item2: `#${categoryId?._id}` || "empty",
        item3: categoryId?.name || "empty",
        item4: "High availability",
        item5: createdAt || "empty",
        item6: totalPrice || "empty",
        item7: totalStock || "empty",
      };
    });
  };

  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  const OpenEditPopup = (id) => {
    props.handelEditOpen(id);
  };

  return (
    <div>
      <Card>
        <div className="py-3 row justify-content-between">
          <div className="col-6">
            <Heading paddingLeft={"4%"} name="Stock report" fontSize="24px" />
          </div>
          <div className="col-6 text-end" style={{ paddingRight: "4%" }}>
            {/* <Button
              name="View all"
              fontSize="15px"
              onClick={() => navigate("/agent/product/all")}
            /> */}
            {/* <AllTable
              // data={data}
              mappedDataForTable={mappedDataForTable}
              // flag={"product"}
              fiveProducts={fiveProducts}
              deleteHandler={deleteHandler}
              // fetchProductsFive={fetchProductsFive}
              navigation={"/agent/shopProduct"}
              header={headerdata}
              edit
              trash
              redeye
              item5
              item6
              item7
              item13
              item14
              item15
              editProductStock
              headingname={"Stock report"}
              activeTabColor="Products"
            /> */}
          </div>
        </div>

        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          //   checkboxSelection
          style={{ border: "none", minHeight: 400, paddingInline: 25 }}
        />
      </Card>
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "30%",
            top: "50%",
            zIndex: 1000000000,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to delete this product?"
          />
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default StockReport;
