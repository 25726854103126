import React, { useState, useEffect } from "react";
import Backicon from "../Assets/backicon.png";
import Heading from "../common/Heading";
import Button from "../common/Button";
import axiosInstance from "../utils";
import endpoints from "../utils/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MerchantForm = (props) => {
  // const handleLogIdChange = (e) => {
  //   setLogId(e.target.value);
  // };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // const handleMobileChange = (e) => {
  //   setMobileNumber(e.target.value);
  // };

  const handleShopIdChange = (e) => {
    setshopId(e.target.value);
  };

  // const [logId, setLogId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [type, setType] = useState("OWNER");
  const [shopId, setshopId] = useState("");
  const handleSelectTypeChange = (e) => {
    setType(e.target.value);
  };
  const validateForm = () => {
    let valid = true;

    // Validate Name field
    if (name.trim() === "") {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    // // Validate Email field
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email)) {
    //   setEmailError("Invalid email format");
    //   valid = false;
    // } else {
    //   setEmailError("");
    // }

    // Validate Contact field
    if (mobileNumber.trim() === "") {
      setMobileError("Contact number is required");
      valid = false;
    } else {
      setMobileError("");
    }

    // Validate Shop Id field
    if (ShopIdOption.trim() === "") {
      setShopIdError("Please select a Shop Id");
      valid = false;
    } else {
      setShopIdError("");
    }

    // Validate Category Id field
    if (selectedOption.trim() === "") {
      setCategoryIdError("Please select a Category Id");
      valid = false;
    } else {
      setCategoryIdError("");
    }

    return valid;
  };
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [shopIdError, setShopIdError] = useState("");
  const [categoryIdError, setCategoryIdError] = useState("");
  const clickhandler = () => {
    // console.log("Login id:", logId);
    console.log("Name:", name);
    console.log("Email", email);
    console.log("mobile", mobileNumber);
    console.log("ShopId", ShopIdOption);
    console.log("CategryId", selectedOption);
    const tempCategoryId = findCategoryIdByName(selectedOption);
    const tempShopId = findShopIdByName(selectedOption);
    // addNewMerchant(name, email, mobileNumber, tempCategoryId, tempShopId)
    if (validateForm()) {
      // Form is valid, proceed with API call
      const tempCategoryId = findCategoryIdByName(selectedOption);
      const tempShopId = findShopIdByName(ShopIdOption);
      addNewMerchant(name, email, mobileNumber, tempCategoryId, tempShopId);
    }
  };
  const closePopUpHandeler = () => {
    props.closePopUp();
  };
  const addNewMerchant = async (
    name,
    email,
    mobileNumber,
    categoryId,
    shopId
  ) => {
    const url = endpoints.baseUrl + "admin/" + endpoints.merchantAdd;
    const formData = {
      name: name,
      email: email,
      mobileNumber: mobileNumber,
      categoryId: categoryId,
      shopId: shopId,
      type: type,
    };
    console.log(formData, "Form data in add merchant");
    try {
      const response = await axiosInstance.post(url, formData);
      console.log(response.data.data);
      props.closePopUp();
      window.location.reload();
    } catch (error) {
      console.log(error);
      // toast.error("Internal Server Error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 5000, // Set the duration for how long the popup should be visible
      // });
    }
  };
  useEffect(() => {
    fetchDataOfAllShops();
    fetchCategories();
  }, []);

  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct;
    // console.log("**", url);
    try {
      const response = await axiosInstance.get(url);
      setCategories(response.data.data);
      // console.log(response.data.data,"***************************");
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [dataOfAllShops, setdataOfAllShops] = useState([]);
  const fetchDataOfAllShops = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allShops
      );
      setdataOfAllShops(response.data.data);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const findCategoryIdByName = (categoryName) => {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].name === categoryName) {
        return categories[i]._id;
      }
    }
    return null;
  };
  const findShopIdByName = (shopName) => {
    for (let i = 0; i < dataOfAllShops.length; i++) {
      if (dataOfAllShops[i].name === shopName) {
        return dataOfAllShops[i]._id;
      }
    }
    return null;
  };
  const options = ["Option 1", "Option 2", "Option 3"];
  const [selectedOption, setSelectedOption] = useState("");

  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    console.log("Category Id:", selectedValue);
  };
  const ShopIdoptions = ["Option 1", "Option 2", "Option 3"];
  const [ShopIdOption, setShopIdOption] = useState("");

  const handleSelectShopIdChange = (event) => {
    const ShopIdValue = event.target.value;
    setShopIdOption(ShopIdValue);
    console.log("ShopId Option:", ShopIdValue);
  };
  // const LogIdoptions = ["Option 1", "Option 2", "Option 3"];
  // const [LogIdOption, setLogIdOption] = useState("");

  // const handleSelectLogIdChange = (event) => {
  //   const LogIdValue = event.target.value;
  //   setLogIdOption(LogIdValue);
  //   console.log("LogId Option:", LogIdValue);
  // };
  const [inputValue, setInputValue] = useState("");

  const restrictToRange = (event) => {
    const value = parseInt(event.target.value);

    if (isNaN(value) || value < 1 || value > 9999999999) {
      setMobileNumber("");
    } else {
      setMobileNumber(value.toString());
    }
  };
  return (
    <>
      <div
        className="bg-white"
        style={{
          border: "1px solid #5082C4 ",
        }}
      >
        <div className="ps-3 py-2 " style={{ paddingRight: "12px" }}>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="d-flex align-items-center">
                <img
                  className="pe-3"
                  src={Backicon}
                  alt="Back Icon"
                  onClick={closePopUpHandeler}
                  style={{ cursor: "pointer" }}
                />
                <Heading name="Add Merchant" fontSize="25px" fontWeight="500" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 text-start text-sm-end pt-3 pt-sm-0">
              {props.isbuttonVisible ? (
                <Button
                  name="Upgrade as shop owner"
                  width="100%"
                  borderRadius="10px"
                  fontSize="15px"
                />
              ) : null}
            </div>
            <div className="col-12">
              <form>
                <div
                  className="row pt-4"
                  style={{ maxHeight: "77vh", overflow: "auto" }}
                >
                  {/* <div className="col-6 pt-3">
                  <label style={{ fontWeight: 500, fontSize: 16 }}>
                    Log ID
                  </label>
                  <input
                    style={{
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="mt-2 ps-3 py-2"
                    type="text"
                    value={logId}
                    onChange={handleLogIdChange}
                  />
                </div> */}
                  {/* <div className="col-6 pt-3"> */}
                  {/* <label
                    style={{ fontWeight: 500, fontSize: 16 }}
                    className="pb-2"
                  >
                    Log Id
                  </label>
                  <select
                    style={{
                      display: "block",
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      display: "block",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="py-2 pe-5"
                    value={logId}
                    onChange={handleLogIdChange}
                  >
                    <option className="d-none">log Id</option>
                    <option value="123">123</option>
                    <option value="1234">1234</option>
                    <option value="12345">12345</option>
                   
                  </select> */}
                  {/* <label
                    style={{ fontWeight: 500, fontSize: 15, display: "block" }}
                    className="pb-2"
                  >
                    Log Id
                  </label>
                  <select
                    value={LogIdOption}
                    onChange={handleSelectLogIdChange}
                    style={{
                      display: "block",
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      display: "block",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="ps-2 py-2 mt-1"
                  >
                    <option value="" disabled className="d-none">
                      select Log Id
                    </option>
                    {LogIdoptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select> */}
                  {/* </div> */}
                  <div className="col-12 pt-3">
                    <label style={{ fontWeight: 500, fontSize: 16 }}>
                      Name
                    </label>
                    <input
                      style={{
                        backgroundColor: "#F3F1F1",
                        width: "100%",
                        border: "none",
                        borderRadius: 10,
                      }}
                      className="mt-2 ps-3 py-2"
                      type="text"
                      value={name}
                      onChange={handleNameChange}
                    />
                    {nameError && (
                      <small className="error-text text-danger">
                        {nameError}
                      </small>
                    )}
                  </div>
                  <div className="col-12 pt-4">
                    <label style={{ fontWeight: 500, fontSize: 16 }}>
                      Email ID
                    </label>
                    <input
                      style={{
                        backgroundColor: "#F3F1F1",
                        width: "100%",
                        display: "block",
                        border: "none",
                        borderRadius: 10,
                      }}
                      className="mt-2 ps-3 py-2"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <small className="error-text text-danger">
                        {emailError}
                      </small>
                    )}
                  </div>
                  <div className="col-12 pt-4">
                    <label style={{ fontWeight: 500, fontSize: 16 }}>
                      Contact
                    </label>
                    <input
                      style={{
                        backgroundColor: "#F3F1F1",
                        width: "100%",
                        display: "block",
                        border: "none",
                        borderRadius: 10,
                      }}
                      className="mt-2 ps-3 py-2"
                      type="number"
                      value={mobileNumber}
                      onChange={restrictToRange}
                    />
                    {mobileError && (
                      <small className="error-text text-danger">
                        {mobileError}
                      </small>
                    )}
                  </div>
                  <div className="col-12 pt-4">
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: 15,
                        display: "block",
                      }}
                      className="pb-2"
                    >
                      Type
                    </label>
                    <select
                      value={type}
                      onChange={handleSelectTypeChange}
                      style={{
                        display: "block",
                        backgroundColor: "#F3F1F1",
                        width: "100%",
                        border: "none",
                        borderRadius: 10,
                      }}
                      className="ps-2 py-2 mt-1"
                    >
                      <option value="" disabled className="d-none">
                        Select Type
                      </option>
                      <option value="OWNER">Owner</option>
                      <option value="AGENT">Agent</option>
                    </select>
                  </div>
                  <div className="col-12 pt-4">
                    {/* <label style={{ fontWeight: 500, fontSize: 16 }}>
                    Shop Id
                  </label>
                  <select
                    style={{
                      display: "block",
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      display: "block",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="py-2"
                    value={shopId}
                    onChange={handleShopIdChange}
                  >
                    <option className="d-none">Shop Id</option>
                    <option value="123">123</option>
                    <option value="1234">1234</option>
                    <option value="12345">12345</option>
                
                  </select> */}
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: 15,
                        display: "block",
                      }}
                      className="pb-2"
                    >
                      Shop Id
                    </label>
                    <select
                      value={ShopIdOption}
                      onChange={handleSelectShopIdChange}
                      style={{
                        display: "block",
                        backgroundColor: "#F3F1F1",
                        width: "100%",
                        display: "block",
                        border: "none",
                        borderRadius: 10,
                      }}
                      className="ps-2 py-2 mt-1"
                    >
                      <option value="" disabled className="d-none">
                        Select Shop Id
                      </option>
                      {dataOfAllShops.map((option, index) => (
                        <option key={index} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {shopIdError && (
                      <small className="error-text text-danger">
                        {shopIdError}
                      </small>
                    )}
                  </div>
                  <div className="col-12 pt-4">
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: 15,
                        display: "block",
                      }}
                      className="pb-2"
                    >
                      Category Id
                    </label>
                    <select
                      value={selectedOption}
                      onChange={handleCategoryChange}
                      style={{
                        display: "block",
                        backgroundColor: "#F3F1F1",
                        width: "100%",
                        display: "block",
                        border: "none",
                        borderRadius: 10,
                      }}
                      className="ps-2 py-2 mt-1"
                    >
                      <option value="" disabled className="d-none">
                        Select Category Id
                      </option>
                      {categories.map((option, index) => (
                        <option key={index} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {categoryIdError && (
                      <small className="error-text text-danger">
                        {categoryIdError}
                      </small>
                    )}
                  </div>
                </div>
              </form>
              <div className="mt-5  text-center" onClick={clickhandler}>
                <Button
                  name="Update"
                  width="15%"
                  borderRadius="10px"
                  fontSize="15px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default MerchantForm;
