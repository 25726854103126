import React, { useEffect, useState } from "react";
import Heading from "../../common/Heading";
import Card from "../../common/Card";
import Trashicon from "../../Assets/icon_trash.png";
import Button from "../../common/Button";
import WarningPop from "../../common/WarningPop";

const AgentAssociated = (props) => {
  const [data, setData] = useState([
    {
      logId: "1123556",
      name: "Rahul Sharma",
      email: "rahul@gmail.com",
      category: "Traderxxx",
      contact: "9992321133",
      totalOrders: 58,
      totalSales: 98000,
    },
    {
      logId: "1123556",
      name: "Rahul Sharma",
      email: "rahul@gmail.com",
      category: "Traderxxx",
      contact: "9992321133",
      totalOrders: 58,
      totalSales: 98000,
    },
    {
      logId: "1123556",
      name: "Rahul Sharma",
      email: "rahul@gmail.com",
      category: "Traderxxx",
      contact: "9992321133",
      totalOrders: 58,
      totalSales: 98000,
    },
    {
      logId: "1123557",
      name: "Priya Singh",
      email: "priya@gmail.com",
      category: "Traderyyy",
      contact: "8889988777",
      totalOrders: 42,
      totalSales: 75000,
    },
    {
      logId: "1123556",
      name: "Rahul Sharma",
      email: "rahul@gmail.com",
      category: "Traderxxx",
      contact: "9992321133",
      totalOrders: 58,
      totalSales: 98000,
    },
    {
      logId: "1123557",
      name: "Priya Singh",
      email: "priya@gmail.com",
      category: "Traderyyy",
      contact: "8889988777",
      totalOrders: 42,
      totalSales: 75000,
    },
    {
      logId: "1123556",
      name: "Rahul Sharma",
      email: "rahul@gmail.com",
      category: "Traderxxx",
      contact: "9992321133",
      totalOrders: 58,
      totalSales: 98000,
    },
    {
      logId: "1123557",
      name: "Priya Singh",
      email: "priya@gmail.com",
      category: "Traderyyy",
      contact: "8889988777",
      totalOrders: 42,
      totalSales: 75000,
    },
    {
      logId: "1123556",
      name: "Rahul Sharma",
      email: "rahul@gmail.com",
      category: "Traderxxx",
      contact: "9992321133",
      totalOrders: 58,
      totalSales: 98000,
    },
    {
      logId: "1123557",
      name: "Priya Singh",
      email: "priya@gmail.com",
      category: "Traderyyy",
      contact: "8889988777",
      totalOrders: 42,
      totalSales: 75000,
    },
    // Add more agent objects here as needed
  ]);
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  useEffect(() => {}, [data]);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };

  const deleteElement = (index, ar) => {
    for (let i = 0; i < ar.length; i++) {
      if (i < index) {
        console.log("delete index ->", i);
        continue;
      }
      ar[i] = ar[i + 1];
    }
    ar.pop();
    // data=[...ar]
    console.log(data);
    return ar;
  };
  const deleteHandler = (val) => {
    console.log("from delete handler", val);
    let ar = deleteElement(val, data);

    setData([...ar]);
    setWarning(0);
  };
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const calculateXandY = (event) => {
    const clickX = event.clientX; // X coordinate of the click
    console.log(clickX, "click x value");
    console.log(window.innerWidth);
    setX(clickX);
    const clickY = event.clientY;
    setY(clickY);

    console.log("x coordinate", typeof clickX);
  };
  const popupOpen = () => {
    props.popUp(1);
  };
  return (
    <>
      <div>
        <div className="">
          <Card height="46vh">
            <div
              className="d-flex justify-content-between pt-3"
              style={{ paddingLeft: "1%", paddingRight: "1%" }}
            >
              <Heading
                name="Agents associated"
                fontSize="24px"
                fontWeight="600"
              />
              <div className="d-flex align-items-center">
                {/* <div>
                  <Button name="Add" width="fit-content" />
                </div> */}
                {/* <div className="ms-3" onClick={popupOpen}>
                  <Button name="Edit" width="fit-content" />
                </div> */}
              </div>
            </div>
            <div
              className="px-3 my-2"
              style={{ overflowX: "auto", maxHeight: "390px" }}
            >
              <div className="table-responsive">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                          textAlign: "center",
                          maxWidth: "90px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        scope="col"
                      >
                        Log id
                      </th>
                      <th
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                          textAlign: "center",
                          maxWidth: "90px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        scope="col"
                      >
                        Name
                      </th>
                      <th
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                          textAlign: "center",
                          maxWidth: "90px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        scope="col"
                      >
                        Email Id
                      </th>
                      <th
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                          textAlign: "center",
                          maxWidth: "90px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        scope="col"
                      >
                        Category
                      </th>
                      <th
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                          textAlign: "center",
                          maxWidth: "90px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        scope="col"
                      >
                        Contact
                      </th>
                      <th
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                          textAlign: "center",
                          maxWidth: "90px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        scope="col"
                      >
                        Total orders
                      </th>
                      <th
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                          textAlign: "center",
                          maxWidth: "90px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        scope="col"
                      >
                        Total sales
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {props.agentAssociated.map((agent, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "white" : "#F0F5FC",
                          borderBottom: "1px solid #EBEBEB",
                        }}
                      >
                        <td
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            maxWidth: "120px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          scope="row"
                        >
                          {agent?._id}
                        </td>
                        <td
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            maxWidth: "120px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {agent?.name}
                        </td>
                        <td
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            maxWidth: "120px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {agent?.email}
                        </td>
                        <td
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            maxWidth: "120px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {agent?.categoryId?.name ? agent?.categoryId?.name : "-"}
                        </td>
                        <td
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            maxWidth: "120px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {agent?.mobileNumber}
                        </td>
                        <td
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            maxWidth: "120px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {agent?.totalOrders}
                        </td>
                        <td
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            maxWidth: "120px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {agent?.totalSales}
                        </td>
                        <td
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            maxWidth: "120px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: "pointer",
                          }}
                          onClick={(event) => {
                            calculateXandY(event);
                            setActive(index);
                            warningHandler();
                          }}
                        >
                          <img src={Trashicon} alt="Trash Icon" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {props.agentAssociated.length === 0 && (
                  <div className="p-5" style={{ textAlign: "center" }}>
                    No agents found
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
        {warning ? (
          <div
            style={{
              width: "35%",
              height: "50%",
              position: "absolute",
              left: "30%",
              top: "50%",
              // zIndex: 10,
            }}
          >
            <WarningPop
              delete={() => deleteHandler(active)}
              closePopUp={(val) => {
                warningHandler(val);
              }}
              message="Are you sure you want to accept the request?"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AgentAssociated;
