import React from "react";
import LoginForm from "../components/Auth/LoginForm";
import ForgotPassword from "../components/Auth/ForgotPassword";
import OtpForm from "../components/Auth/OtpForm";
import PreLoader from "../common/PreLoader";
import { useEffect, useState } from "react";
import logInImg from "../Assets/login_img.jpg"
import styles from "./login.module.css"

export default function Login() {
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //     document.body.classList.remove("fix-screen");
  //   }, 500);
  // }, []);
  const loginFormContainer = {
    // height:'100vh',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    //     display: "grid",
    // placeItems: "center",

    height: "100%",
  };
  const left = {
    backgroundColor: "#fff",
    height: "100vh",
  };
  const right = {
    backgroundColor: "#4F81C3",
    height: "100vh",
  };
 
  return (
    <>
      {/* {loading && <PreLoader />} */}
      <div className="container-fluid full-height-container">
        <div className="row">
          {/* left part contains form */}
          <div className="col-sm-6 left" style={left}>
            <div style={loginFormContainer}>
              <LoginForm />
            </div>
          </div>
          {/* Right Part contains image */}
          <div
            className={`d-none d-sm-block col-sm-6 right px-0 ${styles.backgroundImg}`}
            style={right}
          >
            {/* <img className="w-100 h-100 " src={logInImg} /> */}
          </div>
        </div>
      </div>
    </>
  );
}
