import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Notification from "../../screens/admin/Notification";
import Customer from "../../screens/admin/Customer";
import Shop from "../../screens/admin/Shop";
import Merchant from "../../screens/admin/Merchant";
import BuisnessAnalytics from "../../screens/admin/BuisnessAnalytics";
import CreateCouponform from "../../components/CreateCouponform";
import Settings from "../../screens/admin/Settings";
import Coupon from "../../screens/admin/Coupon";
import SubAdmin from "../../screens/admin/SubAdmin";
import HomeScreen from "../../screens/admin/HomeScreen";
import Protected from "../../screens/Protected";
import PageNotFound from "../../screens/PageNotFound";
import SpecificMerchant from "../../screens/admin/SpecificMerchant";
import SpecificShop from "../../screens/admin/SpecificShop";
import SpecificCustomer from "../../screens/admin/SpecificCustomer";
import Product from "../../screens/agent/supplier/Product";
import Orders from "../../screens/admin/Orders";
import OrderDescription from "../../screens/admin/OrderDescription";
import TopAgentsTable from "../../components/AllTable";
import Support from "../../screens/admin/Support";
import AllOrders from "../../screens/admin/AllOrders";
import AllReturns from "../../screens/admin/AllReturns";
import ReturnDescription from "../../screens/admin/ReturnDescription";
import AllMerchant from "../../screens/admin/AllMerchant";
import Settlement from "../../screens/admin/Settlement";
import AllCustomer from "../../screens/admin/AllCustomer";
import AllShop from "../../screens/admin/AllShop";
export default function index() {
  return (
    <Routes>
      {/* <Route path="admin"> */}
      <Route exact path="home" element={<Protected cmp={HomeScreen} />} />
      <Route
        exact
        path="notification"
        element={<Protected cmp={Notification} />}
      />
      <Route exact path="customer" element={<Protected cmp={Customer} />} />
      <Route
        exact
        path="/specificCustomer/:id"
        element={<Protected cmp={SpecificCustomer} />}
      />
      <Route exact path="shop" element={<Protected cmp={Shop} />} />
      <Route exact path="shop/all" element={<Protected cmp={AllShop} />} />
      <Route
        exact
        path="/specificShop/:id"
        element={<Protected cmp={SpecificShop} />}
      />
      <Route
        exact
        path="/specificShop/:id/settlements"
        element={<Protected cmp={Settlement} />}
      />
      <Route exact path="support" element={<Protected cmp={Support} />} />
      <Route exact path="customer" element={<Protected cmp={Customer} />} />
      <Route exact path="customer/all" element={<Protected cmp={AllCustomer} />} />
      {/* <Route
        exact
        path="specificcustomer"
        element={<Protected cmp={SpecificCustomer} />}
      /> */}
      <Route exact path="merchant" element={<Protected cmp={Merchant} />} />
      <Route exact path="merchant/all" element={<Protected cmp={AllMerchant} />} />
      <Route
        exact
        path="/specificMerchant/:id"
        element={<Protected cmp={SpecificMerchant} />}
      />

      <Route
        exact
        path="buisness-analytics"
        element={<Protected cmp={BuisnessAnalytics} />}
      />
      {/* <Route
        exact
        path="create-coupon"
        element={<Protected cmp={CreateCouponform} />}
      /> */}
      <Route exact path="settings" element={<Protected cmp={Settings} />} />
      <Route exact path="coupons" element={<Protected cmp={Coupon} />} />
      <Route exact path="subadmin" element={<Protected cmp={SubAdmin} />} />

      {/* <Route exact path="product" element={<Product />}  />   */}
      <Route exact path="orders" element={<Protected cmp={Orders} />} />
      <Route exact path="orders/all" element={<Protected cmp={AllOrders} />} />
      <Route
        exact
        path="orderDescription/:id"
        element={<Protected cmp={OrderDescription} />}
      />
      <Route
        exact
        path="orders/return/all"
        element={<Protected cmp={AllReturns} />}
      />
      <Route
        exact
        path="returnDescription/:id"
        element={<Protected cmp={ReturnDescription} />}
      />
      <Route
        exact
        path="topAgentsTable"
        element={<Protected cmp={TopAgentsTable} />}
      />
    </Routes>
  );
}
