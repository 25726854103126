import React, { useState } from "react";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
import Button from "../../common/Button";

const OtpForm = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleOtpChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  return (
    <div style={{ border: "2px solid black", borderRadius: "12px" }}>
      <Card>
        <div className="px-3 py-3">
          <div className="text-center">
            <Heading name="Enter OTP" />
          </div>
          <p className="text-center py-2" style={{ fontSize: "12px" }}>
            Please enter the 4 digit OTP which you received on your email
            ********31@gmail.com
          </p>
          <div className="d-flex justify-content-around pb-2">
            {otp.map((digit, index) => (
              <input
                key={index}
                style={{
                  width: "20%",
                  height: "50px",
                  textAlign: "center",
                  fontSize: "20px",
                }}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
              />
            ))}
          </div>
          <p className="py-2" style={{ fontSize: "12px", textAlign: "center" }}>
            Didn't receive the OTP?{" "}
            <span style={{ cursor: "pointer" }}>Resend</span>
          </p>
          <div className="text-center">
            <Button name="Verify" width="fit-content" borderRadius="10px" />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OtpForm;
