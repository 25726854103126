import React, { useEffect, useState } from "react";
import Card from "../../common/Card";
import Button from "../../common/Button";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Heading from "../../common/Heading";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ProfileDetails = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const color = {
    fontSize: "16px",
    fontWeight: "600",
    color: "#9C8989",
    padding: "5px",
  };
  const [dataOfAllShops, setdataOfAllShops] = useState([]);
  const [shopId, setShopId] = useState(props?.merchantInfo?.shopId?._id || "");
  const [categoryId, setcategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const fetchDataOfAllShops = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allShops
      );
      setdataOfAllShops(response.data.data);
    } catch (error) {
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const handelpopUpOpen = () => {
    props.showPopUp(1);
  };
  const fetchCategories = async () => {
    const url =
      endpoints.baseUrl +
      "admin/" +
      endpoints.getAvailableCategoriesInProduct +
      shopId;
    // console.log("**", url);
    try {
      const response = await axiosInstance.get(url);
      setCategories(response.data.data);
      // console.log(response.data.data,"***************************");
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const approveAgent = async () => {
    try {
      let update = {
        agentId: props?.merchantInfo?._id,
        categoryId,
      };
      if (props?.merchantInfo?.type === "AGENT") {
        update.shopId = shopId;
      }
      const resp = await axiosInstance.post(
        endpoints.baseUrl + endpoints.approveMerchant,
        update
      );
      console.log(resp.data);
      if (resp.data.code === 200) {
        if (props?.fetchData) props.fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataOfAllShops();
  }, []);
  useEffect(() => {
    console.log(props.merchantInfo);
    shopId && fetchCategories();
  }, [shopId]);

  return (
    <div>
      <Card bgColor="#FFF">
        <div
          className="px-3 py-3  d-flex flex-column"
          style={{ height: "530px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            {!props.merchantInfo?.isApproved && (
              <button
                style={{
                  backgroundColor: "#4F81C3",
                  color: "#fff",
                  borderRadius: "12px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
                className="border-0 fs_xsm"
                onClick={handleOpen}
              >
                Approve
              </button>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <p style={color}>Merchant Id</p>
              <p style={{ ...color, color: "#434242" }}>
                {props.merchantInfo?._id ? props.merchantInfo._id : "--------"}
              </p>
            </div>
            <div className=" col-4">
              <p style={color}>Name</p>
              <p style={{ ...color, color: "#434242" }}>
                {props.merchantInfo?.name
                  ? props.merchantInfo.name
                  : "--------"}
              </p>
            </div>
            <div className=" col-4">
              <p style={color}>Contact</p>
              <p style={{ ...color, color: "#434242" }}>
                {props.merchantInfo?.mobileNumber
                  ? props.merchantInfo.mobileNumber
                  : "--------"}
              </p>
            </div>
            <div className=" col-4">
              <p style={color}>Email id</p>
              <p
                // title={props.merchantInfo?.email}
                style={{
                  ...color,
                  color: "#434242",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
              >
                {props.merchantInfo?.email
                  ? props.merchantInfo.email
                  : "--------"}
              </p>
            </div>
            <div className=" col-4">
              <p style={color}>Type</p>
              <p style={{ ...color, color: "#434242" }}>
                {props.merchantInfo.type
                  ? props.merchantInfo.type
                  : "---------"}
              </p>
            </div>
            <div className=" col-4">
              <p style={color}>Shop allotted</p>
              <p style={{ ...color, color: "#434242" }}>
                {props.merchantInfo?.shopId?.name
                  ? props.merchantInfo.shopId?.name
                  : "---------"}
              </p>
            </div>
            <div className=" col-4">
              <p style={color}>Category allotted</p>
              <p style={{ ...color, color: "#434242" }}>
                {props.merchantInfo?.categoryId?.name
                  ? props.merchantInfo.categoryId?.name
                  : "---------"}
              </p>
            </div>
            <div className=" col-12">
              <p style={color}>Identification doc.</p>
              {/* <p style={{ ...color, color: "#434242" }}>
                {props.merchantInfo.Shop
                  ? props.merchantInfo.Shop
                  : "---------"}
              </p> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 10,
                }}
              >
                <Link to={props.merchantInfo.pan} target="_blank">
                  <img src={props.merchantInfo.pan} height={50} />
                </Link>
                {props?.merchantInfo?.aadhaarCard?.map((item, index) => (
                  <Link key={index} to={item} target="_blank">
                    <img src={item} height={50} />
                  </Link>
                ))}
              </div>
            </div>
            {/* <div className=" col-12">
              <p style={color}>Experience</p>
              <p style={{ ...color, color: "#434242" }}>
                {props.merchantInfo.Shop ? props.merchantInfo.Shop : "--------"}
              </p>
            </div> */}
          </div>
          <div className="text-center mt-5 pb-2" onClick={handelpopUpOpen}>
            <Button name="Edit" width="fit-content" fontSize="15px" />
          </div>
        </div>
      </Card>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log(shopId, categoryId);
              approveAgent();
            }}
          >
            {props?.merchantInfo?.type === "AGENT" && (
              <div className="row">
                <div className="col">
                  <label
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      display: "block",
                      textAlign: "start",
                    }}
                  >
                    Select Shop
                  </label>
                  <select
                    value={shopId}
                    onChange={(e) => setShopId(e.target.value)}
                    style={{
                      display: "block",
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      display: "block",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="ps-2 py-2 mt-1"
                  >
                    <option value="" disabled className="d-none">
                      select Shop
                    </option>
                    {dataOfAllShops?.map((option, index) => (
                      <option key={index} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col">
                <label
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    display: "block",
                    textAlign: "start",
                  }}
                >
                  Select Category
                </label>
                <select
                  value={categoryId}
                  onChange={(e) => setcategoryId(e.target.value)}
                  style={{
                    display: "block",
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    display: "block",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="ps-2 py-2 mt-1"
                >
                  <option value="" disabled className="d-none">
                    select category Id
                  </option>
                  {categories?.map((option, index) => (
                    <option key={index} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className=" text-center"
              style={{ paddingTop: "10px", marginRight: 10 }}
            >
              <Button name="Submit" type="submit" />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ProfileDetails;
