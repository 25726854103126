import React, { useEffect, useState } from "react";
import CommonTable from "../../common/CommonTable";
// import Card from "../../common/Card";
import Heading from "../../common/Heading";
import Searchicon from "../../Assets/icon_search.svg";
import AllTable from "../AllTable";
import Card from "../../common/Card";
import styles from "./TopAgent.module.css";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TopAgent = (props) => {
  const [topAgents, setTopAgents] = useState([]);

  useEffect(() => {
    fetchTopAgents();
    // topAgentDataApi();
  }, []);
  const fetchTopAgents = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.topagents;
    try {
      const response = await axiosInstance.get(url);
      const slicedArray = response.data.data
        .slice(0, 6)
        .filter((item) => item.agent._id != null);
      setTopAgents(slicedArray);
      const reciveData = mapDataToTableFormat(response.data.data);
      setMappedDataForTable(reciveData);
      // console.log(response.data.data)
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const mapDataToTableFormat = (data) => {
    return data.map((item) => {
      const { agent, orderCount, totalSales } = item;
      const { name, mobileNumber, shop, category } = agent || {};

      return {
        item1: name || "--------",
        item2: mobileNumber || "--------",
        item3: shop ? shop.name : "--------",
        item4: category ? category.name : "--------",
        item5: totalSales ? `${totalSales.toFixed(2)}` : "--------",
        item6: orderCount ? "PAN / ADHAAR" : "--------",
      };
    });
  };
  const [mappedDataForTable, setMappedDataForTable] = useState([]);
  const headerdata = [
    {
      header1: "Name",
      header2: "Contact",
      header3: "Shop name",
      header4: "Category",
      header5: "Total sales",
      header6: "Identification",
    },
  ];
  const tableHead = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: "4%",
  };
  // const [inputValue, setInputValue] = useState("");
  // const handleInputChange = (event) => {
  //   setInputValue(event.target.value);
  // };
  const [inputValue, setInputValue] = useState("");
  const [filteredAgents, setFilteredAgents] = useState(topAgents);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
  };

  return (
    <div>
      <Card height="46vh">
        <div>
          <div style={{ paddingTop: "2%" }}>
            <div className="mt-0 justify-content-between " style={tableHead}>
              <Heading
                paddingLeft={"4%"}
                fontSize={"24px"}
                fontWeight={600}
                name="Top Agents"
                fontFamily={"InterSemiBold"}
              />

              <div
                className={`d-flex align-items-center justify-content-end ${styles.width}`}
              >
                <div className="">
                  <AllTable
                    // data={data}
                    mappedDataForTable={mappedDataForTable}
                    header={headerdata}
                    headingname={"Top agents"}
                    searchicon
                    item5
                    item6
                    activeTabColor="Dashboard"
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ paddingRight: "8px" }}
            >
              {/* <div
                style={{
                  border: " 2px solid #8C8C8C",
                  borderRadius: "10px",
                  width: "95%",
                  // display: "flex",
                }}
                className="d-sm-none  d-md-flex d-lg-none d-flex mt-1"
              >
                <img src={Searchicon} style={{ margin: "5px" }} />
                <input
                  type="text"
                  style={{
                    outline: "none",
                    backgroundColor: "transparent",
                    border: "none",
                    width: "100%",
                    margin: "3px",
                  }}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div> */}
            </div>
          </div>
          <div
            className={`table-responsive ${styles.tablecontainer}`}
            style={{
              paddingTop: "15px",
              paddingLeft: "4%",
              paddingRight: "3%",
            }}
          >
            <table className={`table table-borderless ${styles.table}`}>
              <thead>
                <tr>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    Name
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    Contact
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                    scope="col-4"
                  >
                    Shop name
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    Category
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    Total Sales
                  </th>
                  <th
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    Identification
                  </th>
                </tr>
              </thead>
              <tbody>
                {topAgents.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                      borderBottom: "1px solid #EBEBEB",
                    }}
                  >
                    <td
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <div
                        title={item.item1}
                        style={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div
                          title={item.item1}
                          style={{
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.agent?.name ? item.agent?.name : "--------"}
                        </div>
                      </div>
                    </td>
                    <td
                      style={{
                        maxWidth: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className="mx-auto"
                    >
                      <div
                        title={item.item2}
                        style={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="mx-auto"
                      >
                        {item.agent?.mobileNumber
                          ? item.agent?.mobileNumber
                          : "-------"}
                      </div>
                    </td>
                    <td
                      style={{
                        maxWidth: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className="mx-auto"
                    >
                      <div
                        title={item.item3}
                        style={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="mx-auto"
                      >
                        {item.agent?.shop?.name
                          ? item.agent?.shop?.name
                          : "--------"}
                      </div>
                    </td>
                    <td
                      style={{
                        maxWidth: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className="mx-auto"
                    >
                      <div
                        title={item.item4}
                        style={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="mx-auto"
                      >
                        {item.agent?.shop?.categories
                          ? item.agent?.category?.name
                          : "N.A."}
                      </div>
                    </td>
                    <td
                      style={{
                        maxWidth: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className="mx-auto"
                    >
                      {item.totalSales}
                    </td>
                    <td
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <div
                        title={item.totalSales}
                        style={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="mx-auto"
                      >
                        <div
                          title={item.totalSales}
                          style={{
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className="mx-auto"
                        >
                          {item.agent?.identification
                            ? item.agent?.identification
                            : "N.A."}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default TopAgent;
