import Searchicon from "../Assets/icon_search.svg";
import React, { useState, useEffect } from "react";
import "../App.css";
import colors from "../utils/helper";
import Button from "./Button";
import Heading from "./Heading";
import Notification from "../Assets/notification.svg";
import Buisnessanalytics from "../Assets/buisnessanalytics.svg";
import Coupon from "../Assets/coupon.svg";
import User from "../Assets/user (3) 1.svg";
import Merchant from "../Assets/merchant.svg";
import Shop from "../Assets/shop.svg";
import Customer from "../Assets/customer.svg";
import Subadmin from "../Assets/subadmin.svg";
import HappyWall from "../Assets/HappyWall.svg";
import dashboardicon from "../Assets/dashboard.svg";
import OrdersIcon from "../Assets/SidebarOrder.svg";
import producticon from "../Assets/product.svg";
import customerbannericon from "../Assets/custom banners.svg";
import catalogicon from "../Assets/catalog.svg";
import reviewsicon from "../Assets/Review.svg";
import settingicon from "../Assets/settings.svg";
import logouticon from "../Assets/logout.svg";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/logo.svg";
import { findBestMatch } from "string-similarity";

// import { getRoles } from "@testing-library/react";

const NavBarEx = (props) => {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const clickHandler = () => {
    if (
      props.buttonName === "Create Banner" ||
      props.buttonName === "Create Coupon"
    ) {
      props.popUp(true);
    } else {
      props.popUp(1);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const searchJSON = {
    home: [
      "Sales Report",
      "Sales In Top Three",
      "Top Agents",
      "Customer satisfaction",
      "Product sales",
      "Top products",
      "Categories",
      "Add new category",
    ],
    orders: ["Recent orders", "Return requested"],
    merchants: ["No. of agents vs sales", "Top agents", "Agent list"],
    shop: [
      "No. of shops vs sales",
      "Top shops with maximum sales",
      "Shop list",
    ],
    customers: [
      "New customers",
      "Customers List",
      "Customer Prefrences",
      "Reviews",
    ],
    subadmin: ["Add new admin"],
    "buisness-analytics": [
      "Sales revenue",
      "Sales by region",
      "Sales by channels",
      "Inventory levels",
      "Transactions",
    ],
    notification: ["Accept", "Reject", "Create Notification"],
    coupons: [
      "Product name",
      "Category",
      "Discount",
      "Validity",
      "Create coupon",
    ],
    product: [
      "Stock report",
      "Products",
      "Bulk Upload",
      "Download CSV",
      "Add new Product",
      "products",
    ],
    customerbanner: ["Add slide", "Custom banners", "Create Banner", "Upload"],
    catalogList: [
      "Catalog 1",
      "Remove All",
      "Add products",
      "Generate link",
      "Download pdf",
    ],
    review: [
      "Reviews and feedback",
      "Total reviews",
      "Average rating",
      "Feedback",
      "Reviews",
      "Nominate customer",
      "Direct message",
    ],
    settings: ["Neha Patil", "Name", "Email id", "Change Passord"],
    happyWall: ["Happy Wall"],
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      // Find the best match based on the user input
      const searchValues = Object.values(searchJSON).flat();
      const matches = findBestMatch(inputValue, searchValues);
      const bestMatch = matches.bestMatch.target;

      if (bestMatch) {
        // Find the key of the best match
        let bestMatchKey = null;
        Object.entries(searchJSON).forEach(([key, value]) => {
          if (value.includes(bestMatch)) {
            bestMatchKey = key;
          }
        });

        if (bestMatchKey) {
          // Navigate to the matched value
          // console.log(bestMatchKey)

          navigate(`/${localStorage.getItem("role")}/${bestMatchKey}`);
        }
      }
    }
  };

  return (
    <nav style={{ backgroundColor: colors.bgcolor }}>
      <div className="container-fluid">
        <header>
          <div className="row justify-content-between align-items-center">
            <div
              className={`col-6 col-sm-4  ${props.welcomeMsg
                ? "col-xl-4 col-lg-3 col-md-4"
                : "col-xl-2 col-lg-2 col-md-0 "
                }  col-md-0 `}
            >
              <div className="d-flex align-items-center">
                {/* <p className=" text-black mb-0"></p> */}
                {props.welcomeMsg && (
                  <div className="d-none d-md-block">
                    <p className="fs_xxl ff_primary fw_medium">
                      {props.welcomeMsg}
                    </p>
                  </div>
                )}

                <a
                  href={
                    localStorage.getItem("role") == "admin"
                      ? "/admin/home"
                      : "/agent/home"
                  }
                  className="d-flex align-items-center d-md-none"
                  style={{
                    textDecoration: "none",
                    color: "#303A91",
                  }}
                >
                  <img
                    style={{
                      width: "65px",
                      paddingRight: "8px",
                      fontSize: "24px",
                      // display: "flex",
                      marginLeft: "-12px",
                    }}
                    className="pe-3"
                    src={logo}
                  />
                  MyMegaMall
                </a>
              </div>
            </div>

            <div
              className={`d-none d-sm-none d-md-block   ${props.welcomeMsg
                ? "col-xl-8 col-lg-9 col-md-8"
                : "col-xl-8 col-lg-10"
                }   `}
            >
              <div
                style={{
                  display: !props.search && !props.icons ? "none" : "block",
                }}
              >
                <div className="row align-items-center justify-content-end">
                  {/* <div
                    style={{
                      // border: " 2px solid #8C8C8C",
                      borderRadius: "10px",
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#fff",
                    }}
                    className="col-6 col-lg-8 col-xl-9"
                  >
                    <div>
                      <img
                        src={Searchicon}
                        style={{ margin: "5px", cursor: "pointer" }}
                      />
                    </div>
                    <div className="mx-2 w-100">
                      <input
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        style={{
                          outline: "none",
                          // backgroundColor: "transparent",
                          border: "none",
                          width: "100%",
                          margin: "3px",
                          height: "22px",
                        }}
                      />
                    </div>
                  </div> */}
                  <div
                    className={`col-6 col-lg-4 col-xl-3 px-0 text-end `}
                    style={{
                      display:
                        !props.buttonFlag && !props.icons ? "none" : "block",
                    }}
                  >
                    {props.buttonFlag ? (
                      <div onClick={clickHandler}>
                        <Button
                          marginRight={"1px"}
                          marginLeft={"1px"}
                          name={props.buttonName}
                          paddingLeft={"15px"}
                          paddingRight={"15px"}
                          paddingTop={"3px"}
                          paddingBottom={"3px"}
                          fontSize={"15px"}
                        />
                      </div>
                    ) : (
                      props.icons && (
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="mx-2 position-relative">
                            <img
                              style={{
                                height: "37px",
                                cursor: "pointer",
                              }}
                              src={Notification}
                              onClick={() => navigate("/admin/notification")}
                            />
                            <span
                              style={{
                                position: "absolute",
                                top: "15%",
                                left: "54%",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: "#EC751F",
                              }}
                            ></span>
                          </div>

                          <div className="mx-2">
                            <img
                              style={{
                                height: "36px",
                                cursor: "pointer",
                                border: "1px solid #4F81C3",
                                borderRadius: "50%",
                              }}
                              src={User}
                              onClick={() => navigate("/admin/settings")}
                            />
                          </div>
                          <div
                            className="mx-2"
                            onClick={() => navigate("/admin/settings")}
                            style={{ cursor: "pointer" }}
                          >
                            <Heading
                              fontSize={"24px"}
                              name={localStorage.getItem("name")}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex align-items-end flex-column col-2 d-md-none">
              <div
                className=" position-relative  "
                onClick={toggleNav}
                style={{
                  zIndex: "7",
                  width: "25px",
                  height: "3px",
                  backgroundColor: "black",
                }}
              ></div>
              <div
                className=" position-relative  my-1 "
                onClick={toggleNav}
                style={{
                  zIndex: "7",
                  width: "25px",
                  height: "3px",
                  backgroundColor: "black",
                }}
              ></div>
              <div
                className=" position-relative"
                onClick={toggleNav}
                style={{
                  zIndex: "7",
                  width: "25px",
                  height: "3px",
                  backgroundColor: "black",
                }}
              ></div>
            </div>
            <div
              className="d-md-none "
              style={{ position: "relative", zIndex: "6" }}
            >
              <div
                className={`sidenav ${isOpen ? "open" : ""}`}
                style={{ backgroundColor: colors.bgcolor }}
              >
                {localStorage.getItem("role") == "admin" ? (
                  <div
                    style={{ maxHeight: "100vh" }}
                    className="pt-5 ps-4 mt-5 overflow-auto pb-5"
                  >
                    <div className="d-sm-block pb-5">
                      <div
                        style={{
                          // border: " 2px solid #8C8C8C",
                          borderRadius: "10px",
                          width: "90%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#fff",
                        }}
                        className="col-6 col-lg-8 col-xl-9"
                      >
                        <div
                          onClick={() => {
                            if (inputValue.trim() !== "") {
                              navigate(
                                "/" +
                                localStorage.getItem("role") +
                                "/" +
                                inputValue
                              );
                            }
                          }}
                        >
                          <img
                            src={Searchicon}
                            style={{ margin: "5px", cursor: "pointer" }}
                          />
                        </div>
                        <div className="mx-2 w-100">
                          <input
                            type="text"
                            placeholder="Search"
                            value={inputValue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            style={{
                              outline: "none",
                              // backgroundColor: "transparent",
                              border: "none",
                              width: "100%",
                              margin: "3px",
                              height: "22px",
                            }}
                          />
                        </div>
                      </div>
                      {props.siderBarButton && (
                        <div onClick={clickHandler} className="pt-3">
                          <Button
                            marginRight={"1px"}
                            marginLeft={"1px"}
                            name={props.buttonName}
                            paddingLeft={"15px"}
                            paddingRight={"15px"}
                            paddingTop={"3px"}
                            paddingBottom={"3px"}
                            fontSize={"15px"}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/home")}
                    >
                      <div className="pe-3">
                        <img src={dashboardicon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Dashboard</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/Orders")}
                    >
                      <div className="pe-3">
                        <img src={OrdersIcon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Orders</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/merchant")}
                    >
                      <div className="pe-3">
                        <img src={Merchant} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Merchant</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/shop")}
                    >
                      <div className="pe-3">
                        <img src={Shop} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Shop</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/customer")}
                    >
                      <div className="pe-3">
                        <img src={Customer} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Customer</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/subadmin")}
                    >
                      <div className="pe-3">
                        <img src={Subadmin} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Add sub-admin</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/buisness-analytics")}
                    >
                      <div className="pe-3">
                        <img src={Buisnessanalytics} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>
                        Business Analytics
                      </div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/notification")}
                    >
                      <div className="pe-3">
                        <img src={Notification} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Notification</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/coupons")}
                    >
                      <div className="pe-3">
                        <img src={Coupon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Coupons</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/admin/settings")}
                    >
                      <div className="pe-3">
                        <img src={settingicon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Settings</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-3"
                      onClick={() => navigate("/")}
                    >
                      <div className="pe-3">
                        <img src={logouticon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Logout</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className=" overflow-auto pt-5 ps-4"
                    style={{
                      zIndex: 6,
                      position: "relative",
                      maxHeight: "100vh",
                    }}
                  >
                    <div className="d-sm-block pb-5">
                      <div
                        style={{
                          // border: " 2px solid #8C8C8C",
                          borderRadius: "10px",
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#fff",
                        }}
                        className="col-6 col-lg-8 col-xl-9"
                      >
                        <div
                          onClick={() => {
                            if (inputValue.trim() !== "") {
                              navigate(
                                "/" +
                                localStorage.getItem("role") +
                                "/" +
                                inputValue
                              );
                            }
                          }}
                        >
                          <img
                            src={Searchicon}
                            style={{ margin: "5px", cursor: "pointer" }}
                          />
                        </div>
                        <div className="mx-2 w-100">
                          <input
                            type="text"
                            value={inputValue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            style={{
                              outline: "none",
                              // backgroundColor: "transparent",
                              border: "none",
                              width: "100%",
                              margin: "3px",
                              height: "22px",
                            }}
                          />
                        </div>
                      </div>
                      {props.siderBarButton && (
                        <div onClick={clickHandler} className="pt-3">
                          <Button
                            marginRight={"1px"}
                            marginLeft={"1px"}
                            name={props.buttonName}
                            paddingLeft={"15px"}
                            paddingRight={"15px"}
                            paddingTop={"3px"}
                            paddingBottom={"3px"}
                            fontSize={"15px"}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/agent/home")}
                    >
                      <div className="pe-3">
                        <img src={dashboardicon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Dashboard</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/agent/product")}
                    >
                      <div className="pe-3">
                        <img src={producticon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Products</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/agent/customerbanner")}
                    >
                      <div className="pe-3">
                        <img src={customerbannericon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Custom banners</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/agent/catalogList")}
                    >
                      <div className="pe-3">
                        <img src={catalogicon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Catalog</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/agent/review")}
                    >
                      <div className="pe-3">
                        <img src={reviewsicon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Reviews</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/agent/settings")}
                    >
                      <div className="pe-3">
                        <img src={settingicon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Settings</div>
                    </div>
                    <div
                      className=" d-flex align-items-center pb-5"
                      onClick={() => navigate("/agent/happyWall")}
                    >
                      <div className="pe-3">
                        <img src={HappyWall} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Happy Wall</div>
                    </div>
                    <div
                      className=" d-flex align-items-center"
                      onClick={() => navigate("/")}
                    >
                      <div className="pe-3">
                        <img src={logouticon} alt="dashboard" />
                      </div>
                      <div style={{ cursor: "pointer" }}>Logout</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      </div>
    </nav>
  );
};

export default NavBarEx;
