import React from 'react';
import styles from "./BarChart.module.css"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);





export function BarChart(props) {
    const labels = props.amountsForGraph?.x;
    // const labels = props.amountsForGraph?.x.map((val) => val.substring(0, 8) + "...");
    // console.log("from bar graph",props.amountsForGraph)
    const options = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            legend: {
                display: false,

                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },

        },
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    callback: (value) => labels[value].toString().substring(0, 8)+"...", // Use truncateLabels for tick labels
                },
            },
        }
    };
    const data = {


        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: props.amountsForGraph?.y,
                backgroundColor: '#4F81C3',

            },

        ],
    };

    return (
        <Bar className={`${styles.canvas}`} options={options} data={data} />
    );
}
