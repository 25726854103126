import React, { useEffect, useState } from "react";
import Sidebar from "../../common/SideBar";
import "../../common/Fonts.css";
import NavBarEx from "../../common/NavBarEx";
// import Button from "../../common/Button";
import Card from "../../common/Card";
import styles from "./HomeScreen.module.css";
import { CustomLineChart } from "../../common/CustomLineChart";
import { CustomCircularChart } from "../../common/CircularChart";
import Heading from "../../common/Heading";
import Searchicon from "../../Assets/icon_search.svg";
import colors from "../../utils/helper";
import AllTable from "../../components/AllTable";
import DashboardMenu from "../../components/HomeScreen/DashboardMenu";
import TopAgent from "../../components/HomeScreen/TopAgent";
// import { useParams } from "react-router-dom";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import DropDown from "../../common/DropDown";
import dropimg from "../../Assets/dropimgwhite.svg";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";

// import { Link } from "react-router-dom";
export default function HomeScreen() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [customerSatisfaction, setCustomerSatisfaction] = useState({});
  const [topSalesData, setTopsalesdata] = useState([]);
  const [salesReportData, setSalesReportData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    TopSalesData();
    fetchDataSalesReport();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  const item1 = ["Weekly", " Monthly", "Yearly"];

  const GetCustomerSatisfactionApi = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.customerSatisfactionData
      );
      setCustomerSatisfaction(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const [shopNames, setShopNames] = useState([]);
  const TopSalesData = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.shopWiseSale + "?limit=3"
      );
      const amountArray = response?.data?.data.map((item) => item?.totalSales);
      setTopsalesdata(amountArray);
      console.log("shop names", response.data.data)
      setShopNames(response.data.data);
      console.log(amountArray, "***************TOP SALES CARD*********");
    } catch (error) {
      //  console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const fetchDataSalesReport = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.salesRevnue
      );
      setSalesReportData(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const data = [
    {
      item1: "Rahul Sharmaxxx",
      item2: "Pune",
      item3: "Rahul Sharma",
      item4: "4",
      item5: "58",
      item6: "58",
      item7: "98000",
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Pune",
      item3: "Rahul Sharma",
      item4: 4,
      item5: 58,
      item6: 58,
      item7: 98000,
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Pune",
      item3: "Rahul Sharma",
      item4: 4,
      item5: 58,
      item6: 58,
      item7: 98000,
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Pune",
      item3: "Rahul Sharma",
      item4: 4,
      item5: 58,
      item6: 58,
      item7: 98000,
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Pune",
      item3: "Rahul Sharma",
      item4: 4,
      item5: 58,
      item6: 58,
      item7: 98000,
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Pune",
      item3: "Rahul Sharma",
      item4: 4,
      item5: 58,
      item6: 58,
      item7: 98000,
    },
    {
      item1: "Rahul Sharmaxxx",
      item2: "Pune",
      item3: "Rahul Sharma",
      item4: 4,
      item5: 58,
      item6: 58,
      item7: 98000,
    },
    // Add more data objects as needed
  ];
  const headerdata = [
    {
      header1: "Shop name",
      header2: "Location",
      header3: "Shop owner",
      header4: "No. of agent",
      header5: "No. of category",
      header6: "Total orders",
      header7: "Total sales",
    },
  ];
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    GetCustomerSatisfactionApi();
    fetchDataOfAllShops();
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const [dataOfAllShops, setdataOfAllShops] = useState([]);
  const fetchDataOfAllShops = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allShops
      );
      const slicedArray = response.data.data.slice(1, 6);
      setdataOfAllShops(slicedArray);
      const reciveData = mapDataToTableFormat(response.data.data);
      setMappedDataForTable(reciveData);
      // console.log("All Shops api");
      // console.log(response.data.data);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [mappedDataForTable, setMappedDataForTable] = useState([]);
  const mapDataToTableFormat = (data) => {
    return data.map((item) => {
      const { name, totalOrders } = item;

      return {
        item1: name || "empty",
        item2: "empty",
        item3: "empty",
        item4: "empty",
        item5: "empty",
        item6: totalOrders || "empty",
        item7: "empty",
      };
    });
  };
  const graphoptions = ["Yearly", "Monthly", "Weekly"];
  const [graphoption, setgraphoption] = useState("Monthly");
  useEffect(() => {
    fetchDataFroGraph(graphoption);
  }, [graphoption]);
  const handleSelectgraphoptionChange = (event) => {
    const graphoptionValue = event.target.value;
    setgraphoption(graphoptionValue);
  };
  function fetchTotalAmountsForYear(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const totalAmount = item.totalAmount;

      yearsArray.push(year);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForMonth(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const month = item._id.month;
      const totalAmount = item.totalAmount;

      yearsArray.push(`${months[month - 1]} ${year}`);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForWeekly(data) {
    const yearsArray = [];
    const totalAmountsArray = [];
    for (const item of data) {
      const year = item._id.year;
      const week = item._id.week;
      const totalAmount = item.totalAmount;

      yearsArray.push(`week ${week} of ${year}`);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  const [amountsForGraph, setAmountsForGraph] = useState();
  const [labels, setLabels] = useState([]);
  const fetchDataFroGraph = async (key) => {
    try {
      if (key == "Yearly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.buisnessGraph +
          "sale-trends?groupBy=yearly"
        );
        const amounts = fetchTotalAmountsForYear(response.data.data || {});
        setAmountsForGraph(amounts);
      }
      if (key == "Monthly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.buisnessGraph +
          "sale-trends?groupBy=monthly"
        );
        // setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForMonth(response.data.data || {});
        setLabels([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ]);
        console.log("heloo hello sajna sajna sajn", amounts);
        setAmountsForGraph(amounts);
      }
      if (key == "Weekly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
          "admin/" +
          endpoints.buisnessGraph +
          "sale-trends?groupBy=weekly"
        );
        // setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForWeekly(response.data.data || {});
        setLabels([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ]);
        // console.log("heloo hello sajna sajna sajn", amounts);
        setAmountsForGraph(amounts);
      }
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const [circleColors, setCircleColors] = useState([
    "#036DCF",
    "#EC751F",
    "#E4EEF8",
  ]);
  return (
    <>
      {/* <NavBar buttonFlag={false} /> */}
      <div className="container-fluid">
        <div className="row">
          {/* sidebar */}
          <div
            className={`col-lg-2 col-md-3 ${styles.left}`}
            style={{ paddingRight: "0px" }}
          >
            <Sidebar active={true} activeTabColor="Dashboard" />
          </div>
          {/* dashboard-content */}
          <div
            className="col-lg-10 col-md-9 right pb-5"
            style={{
              backgroundColor: colors.bgcolor,
              overflowY: "auto",
              height: "100vh",
              flexWrap: "wrap",
            }}
          >
            <div className=" position-relative">
              {loading && <PreLoader />}
              <div className="py-3">
                <NavBarEx
                  welcomeMsg={`Welcome back, ${localStorage.getItem("name")}`}
                  search={false}
                  icons={true}
                  fontSize={"38px"}
                />
              </div>
              {/* dashboardmenu */}
              <div>
                <DashboardMenu />
              </div>
              <div className="row mt-3">
                {/* line-chart */}
                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-sm-6 mt-2">
                  <Card
                    bgColor={"#fff"}
                    width="100%"
                    height="94%"
                    paddingTop="2%"
                    paddingBottom="3%"
                  >
                    <div className="row  align-items-center">
                      <div className="col-7 col-md-6 col-lg-4 col-xl-3">
                        <Heading
                          paddingLeft={"4%"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Sales Report"
                          fontFamily={"InterSemiBold"}
                        />
                      </div>
                      <div className="col-4 col-sm-3 col-md-5 col-lg-3 col-xl-2">
                        {/* <DropDown
                          top="Weekly"
                          items={item1}
                          bg={"#E4EEF8"}
                          img={dropimg}
                        /> */}
                        <select
                          value={graphoption}
                          onChange={handleSelectgraphoptionChange}
                          style={{
                            display: "block",
                            backgroundColor: "#E4EEF8",
                            width: "100%",
                            display: "block",
                            border: "none",
                            borderRadius: 10,
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                          className="px-2 py-2 mt-1 add-new-agent"
                        >
                          <option value="" disabled className="d-none">
                            Weekly
                          </option>
                          {graphoptions.map((option, index) => (
                            <option
                              key={index}
                              value={option}
                              style={{ backgroundColor: "#F5F5F5" }}
                            >
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <CustomLineChart amountsForGraph={amountsForGraph} />
                  </Card>
                </div>
                {/* sales in top three chart */}
                <div
                  className={`col-xl-4 col-lg-4 col-md-6 mt-2 ${styles.salesInTopThree} `}
                >
                  <Card
                    bgColor={"#fff"}
                    width="100%"
                    height={"94%"}
                    paddingTop="4%"
                  >
                    <div className=" d-flex justify-content-between align-items-center">
                      <div style={{ width: "65%" }} className="ps-4">
                        <Heading
                          // paddingLeft={windowWidth < 576 ? "0px" : "8%"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Top Shops with Max Sales"
                          fontFamily={"InterSemiBold"}
                        />
                      </div>
                      {/* <div className="me-2">
                        <AllTable
                          data={data}
                          mappedDataForTable={mappedDataForTable}
                          header={headerdata}
                          edit
                          trash
                          redeye
                          item5
                          item6
                          item7
                          searchicon
                          headingname={"Shop list"}
                          activeTabColor="Dashboard"
                          navigation={"/admin/specificShop"}
                        />
                      </div> */}
                      <div
                        className="me-2"
                        style={{ paddingTop: "3px" }}
                      >
                        <Button
                          name="View all"
                          fontSize="15px"
                          onClick={() => navigate("/admin/shop/all")}
                        />
                      </div>
                    </div>
                    {/* className="d-flex h-75 align-items-center justify-content-center" */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "80%",
                        boxSizing: "border-box",
                        objectFit: "contain",
                        padding: "10px",
                      }}
                    >
                      <div style={{ width: "60%", height: "68%" }}>
                        <CustomCircularChart data={topSalesData} />
                      </div>

                      <div style={{ width: "40%" }}>
                        {shopNames.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex align-items-center mt-1"
                            >
                              <div
                                className={`${styles.circle} `}
                                style={{ backgroundColor: circleColors[index] }}
                              ></div>
                              <p
                                className="mb-0 mx-1 "
                                style={{ fontSize: "16px", fontWeight: "600" }}
                              >
                                {item?.shop?.name
                                  ? item?.shop?.name
                                  : "N.A."}
                              </p>
                            </div>
                          );
                        })}
                        {/* <div className="d-flex align-items-center mt-1">
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: "#EC751F" }}
                          ></div>
                          <p
                            className="mb-0 mx-1"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Shop mart
                          </p>
                        </div> */}
                        {/* <div className="d-flex align-items-center mt-1">
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: "#E4EEF8" }}
                          ></div>
                          <p
                            className="mb-0 mx-1"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Shop mart
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="row mt-3">
                {/* top-agent component */}
                <div
                  className={`col-lg-8 col-md-6 col-sm-12 ${styles.colHeight}`}
                >
                  <TopAgent />
                </div>

                {/* below 767 px circular graph will be this */}
                <div className={`col-sm-6 d-md-none my-4`}>
                  {/* hello */}
                  <Card
                    bgColor={"#fff"}
                    width="100%"
                    height="30vh"
                    paddingTop="3%"
                    marginTop="30px"
                  >
                    <div className=" d-flex justify-content-between align-items-center">
                      <div style={{ width: "65%" }} className="ps-3">
                        <Heading
                          // paddingLeft={windowWidth < 576 ? "0px" : "8%"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Sales In Top Three"
                          fontFamily={"InterSemiBold"}
                        />
                      </div>
                      <div className="me-2">
                        <AllTable
                          data={data}
                          header={headerdata}
                          edit
                          trash
                          redeye
                          item5
                          item6
                          item7
                          headingname={"Shop list"}
                          activeTabColor="Dashboard"
                          searchicon
                        />
                      </div>
                    </div>
                    {/* className="d-flex h-75 align-items-center justify-content-center" */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "80%",
                        boxSizing: "border-box",
                        objectFit: "contain",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{ width: "60%", height: "68%" }}
                        className={`${styles.salesAndCustomerChart}`}
                      >
                        <CustomCircularChart />
                      </div>
                      <div style={{ width: "50%" }}>
                        <div
                          className={`d-flex align-items-center mt-1 ${styles.salesAndCustomerChartRight}`}
                        >
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: "#036DCF" }}
                          ></div>
                          <p
                            className="mb-0 mx-1 "
                            style={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Shop mart
                          </p>
                        </div>
                        <div
                          className={`d-flex align-items-center mt-1 ${styles.salesAndCustomerChartRight}`}
                        >
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: "#EC751F" }}
                          ></div>
                          <p
                            className="mb-0 mx-1"
                            style={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Shop mart
                          </p>
                        </div>
                        <div
                          className={`d-flex align-items-center mt-1 ${styles.salesAndCustomerChartRight}`}
                        >
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: "#E4EEF8" }}
                          ></div>
                          <p
                            className="mb-0 mx-1"
                            style={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Shop mart
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className={`col-sm-6 d-md-none my-4`}>
                  <div>
                    <Card
                      bgColor={"#fff"}
                      width="100%"
                      height="30vh"
                      paddingTop="3%"
                    >
                      <div className="ps-3">
                        <Heading
                          // paddingLeft={"8%"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Customer satisfaction"
                          fontFamily={"InterSemiBold"}
                        />
                      </div>
                      {/* className="d-flex h-75 align-items-center justify-content-center" */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          height: "80%",
                          boxSizing: "border-box",
                          objectFit: "contain",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{ width: "60%", height: "68%" }}
                          className={`${styles.salesAndCustomerChart}`}
                        >
                          <CustomCircularChart />
                        </div>
                        <div style={{ width: "50%" }}>
                          <div
                            className={`d-flex align-items-center mt-1 ${styles.salesAndCustomerChartRight}`}
                          >
                            <div
                              className={`${styles.circle} `}
                              style={{ backgroundColor: "#036DCF" }}
                            ></div>
                            <p
                              className="mb-0 mx-1 "
                              style={{ fontSize: "16px" }}
                            >
                              Satisfied
                            </p>
                          </div>
                          <div
                            className={`d-flex align-items-center mt-1 ${styles.salesAndCustomerChartRight}`}
                          >
                            <div
                              className={`${styles.circle} `}
                              style={{ backgroundColor: "#EC751F" }}
                            ></div>
                            <p
                              className="mb-0 mx-1"
                              style={{ fontSize: "16px" }}
                            >
                              Very Satisfied
                            </p>
                          </div>
                          <div
                            className={`d-flex align-items-center mt-1 ${styles.salesAndCustomerChartRight}`}
                          >
                            <div
                              className={`${styles.circle} `}
                              style={{ backgroundColor: "#E4EEF8" }}
                            ></div>
                            <p
                              className="mb-0 mx-1"
                              style={{ fontSize: "16px" }}
                            >
                              Unsatisfied
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div
                  className={`col-xl-4 col-lg-4 col-md-6   ${styles.salesInTopThree} `}
                >
                  <Card
                    bgColor={"#fff"}
                    width="100%"
                    height={"46vh"}
                    paddingTop="3%"
                  >
                    <div className="ps-4">
                      <Heading
                        // paddingLeft={windowWidth < 576 ? "0px" : "8%"}
                        fontSize={"24px"}
                        fontWeight={600}
                        name="Customer satisfaction"
                        fontFamily={"InterSemiBold"}
                      />
                    </div>
                    {/* className="d-flex h-75 align-items-center justify-content-center" */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "80%",
                        boxSizing: "border-box",
                        objectFit: "contain",
                        padding: "10px",
                      }}
                    >
                      <div style={{ width: "60%", height: "72%" }}>
                        <CustomCircularChart
                          data={Object.values(customerSatisfaction || {})}
                        />
                      </div>
                      <div style={{ width: "40%" }}>
                        <div className="d-flex align-items-center mt-1">
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: "#036DCF" }}
                          ></div>
                          <p
                            className="mb-0 mx-1 "
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Very satisfied
                          </p>
                        </div>
                        <div className="d-flex align-items-center mt-1">
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: "#EC751F" }}
                          ></div>
                          <p
                            className="mb-0 mx-1"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Satisfied
                          </p>
                        </div>
                        <div className="d-flex align-items-center mt-1">
                          <div
                            className={`${styles.circle} `}
                            style={{ backgroundColor: "#E4EEF8" }}
                          ></div>
                          <p
                            className="mb-0 mx-1"
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            Unsatisfied
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
