import React,{useRef,useEffect} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import styles from './CircularChart.module.css'
ChartJS.register(ArcElement, Tooltip, Legend);



export function CustomCircularChart(props) {
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: '# of Votes',
        data: props.data || [3, 2, 1],
        backgroundColor: ['#5C84B9', '#EC751F', '#E4EEF8'],
        borderWidth: 1,
        // radius: ['100%', '120%'],
      },
      
    ],
  };
  const canvasRef = useRef(null);
  // useEffect(() => {
  //   const canvasgot = canvasRef.current;
  //   // Apply custom styles to the canvas element
  //   canvasgot.canvas.style['width']= props.canvasWidth || '200px'
  //   canvasgot.canvas.style['height']= props.canvasHeight || '200px'
  //   console.log()
  // }, []);

  const options = {
    maintainAspectRatio: false,
    responsive:true,
    plugins: {
      legend: {
        position: 'right',
        display: true
      },
    },
    
  };


  const aspectRatio = props.aspectRatio || 1; // Default aspect ratio of 1:1

  return (

        <Doughnut ref={canvasRef} data={data} options={options} />

  );
}

