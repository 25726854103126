import { React, useState } from "react";
import Button from "../../common/Button";
import BackIcon from "../../Assets/backicon.png";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import { toast } from "react-toastify";

const AddSubCategoryPopup = (props) => {
  const [subCategory, setSubCategory] = useState("");
  const HandlersubCategoryChange = (e) => {
    setSubCategory(e.target.value);
  };
  const closePopUpHandeler = () => {
    props.closePopUp();
  };
  const handleSubmit = async () => {
    try {
      console.log("subCategory", subCategory);
      const formData = new FormData();
      formData.append("parent", props?.categoryId);
      formData.append("name", subCategory);
      // formData.append("images", image);
      let response
      if (props?.editActive) {
        response = await axiosInstance.patch(
          endpoints.baseUrl + "admin/" + endpoints.editCategry + props?.editActive,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axiosInstance.post(
          endpoints.baseUrl + "admin/" + endpoints.createNewCategory,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      if (response.data.code === 200) {
        toast.success(props?.editActive ? "Subcategory updated successfully" : "Subcategory added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000, // Set the duration for how long the popup should be visible
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      closePopUpHandeler();
      if (props?.getData) props.getData();
    }
  };
  return (
    <div style={{ backgroundColor: "#fff", border: "2px solid #4F81C3" }}>
      <div className="container-fluid py-5 px-4">
        <img
          src={BackIcon}
          alt="Back Icon"
          onClick={closePopUpHandeler}
          style={{ cursor: "pointer" }}
        />
        <div className="row">
          <div className="col-12 pt-3">
            <label style={{ fontWeight: 500, fontSize: 16 }}>{props.title.length ? props.title : 'Subcategory Name'}</label>
            <input
              style={{
                backgroundColor: "#F3F1F1",
                width: "100%",
                border: "none",
                borderRadius: 10,
              }}
              className="mt-2 ps-3 py-2"
              type="text"
              value={subCategory}
              onChange={HandlersubCategoryChange}
            />
          </div>
        </div>
        <div className="pt-5 text-center" onClick={handleSubmit}>
          <Button
            name="Submit"
            width="fit-content"
            borderRadius="12px"
            fontSize="15px"
          />
        </div>
      </div>
    </div>
  );
};

export default AddSubCategoryPopup;
