import React, { useState, useEffect } from "react";
import NavBarEx from "../../../common/NavBarEx";
import SideBar from "../../../common/SideBar";
import styles from "../supplier/Product.module.css";
import colors from "../../../utils/helper";
import PreLoader from "../../../common/PreLoader";
import { DataGrid } from "@mui/x-data-grid";
import axiosInstance from "../../../utils";
import endpoints from "../../../utils/endpoints";
import moment from "moment";
import Card from "../../../common/Card";
import Heading from "../../../common/Heading";
import Button from "../../../common/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { toast, ToastContainer } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Pickup = () => {
  const [loading, setLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [open, setOpen] = useState("");
  const [pincode, setPinCode] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const [senderAddressLine2, setSenderAddressLine2] = useState("");
  const [alias, setAlias] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async (e) => {
    try {
      setIsSubmiting(true);
      e.preventDefault();
      const body = {
        alias,
        senderName,
        senderEmail,
        senderPhone,
        senderAddress,
        senderAddressLine2,
        city,
        state,
        country,
        pincode,
      };
      console.log(body);
      const response = await axiosInstance.post(
        endpoints.baseUrl + "admin/" + endpoints.addPickup,
        body
      );
      console.log(response.data.data);
      await getData();
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      setOpen(false);
      setIsSubmiting(false);
    }
  };
  const columns = [
    { field: "id", headerName: "Sno", flex: 1 },
    {
      field: "name",
      headerName: "Alias",
      flex: 1,
      minWidth: 100,
    },
    {
      minWidth: 200,
      field: "shipRocketAlias",
      headerName: "shipRocket Alias",
      flex: 1,
    },
    { minWidth: 100, field: "senderName", headerName: "Sender Name", flex: 1 },
    { minWidth: 100, field: "senderPhone", headerName: "Phone", flex: 1 },
    { minWidth: 200, field: "senderEmail", headerName: "Email", flex: 1 },
    {
      minWidth: 200,
      field: "senderAddress",
      headerName: "Address Line",
      flex: 1,
    },
    {
      minWidth: 200,
      field: "senderAddressLine2",
      headerName: "Address Line 2",
      flex: 1,
    },
    { minWidth: 100, field: "state", headerName: "State", flex: 1 },
    { minWidth: 100, field: "city", headerName: "City", flex: 1 },
    { minWidth: 100, field: "country", headerName: "Country", flex: 1 },
    { minWidth: 100, field: "pincode", headerName: "Pincode", flex: 1 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
    },
  ];

  const [rows, setRows] = useState([]);
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getPickups
      );
      console.log(response.data.data);
      let data = response.data.data.map((item, index) => {
        return {
          ...item,
          id: index + 1,
          createdAt: moment(item.createdAt),
        };
      });

      setRows(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className=" position-relative">
      <div className=" container-fluid">
        <div className=" row ">
          <div className={` col-lg-2 col-md-3 max-vh-100 pe-0  ${styles.left}`}>
            <SideBar flag={true} activeTabColor="Pickup" />
          </div>
          <div
            className="col-lg-10 col-md-9 col-12  pb-4"
            style={{
              overflowY: "auto",
              maxHeight: "100vh",
              backgroundColor: colors.bgcolor,
            }}
          >
            {loading && <PreLoader />}
            <div>
              <div className="py-3">
                <NavBarEx buttonFlag={false} search={true} />
              </div>
              <div>
                <Card backgroundColor="#fff">
                  <div className="pt-4 d-flex justify-content-between align-items-center">
                    <Heading name="Pickup Locations" paddingLeft="1%" />
                    <div
                      className=" text-end"
                      style={{ paddingTop: "3px", marginRight: 10 }}
                    >
                      <Button
                        name="Add new"
                        fontSize="15px"
                        onClick={handleOpen}
                      />
                    </div>
                  </div>
                  <div
                    className="px-4 py-2"
                    style={{ width: "100%" }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      //   checkboxSelection
                      style={{ border: "none", minHeight: "75vh" }}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Heading name="Add pickup location" paddingLeft="1%" />
              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                name="alias"
                placeholder="alias"
                className="mt-2 ps-3 py-2 d-block"
                type="text"
                required
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
              />

              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                name="sender name"
                placeholder="sender name"
                className="mt-2 ps-3 py-2 d-block"
                type="text"
                required
                value={senderName}
                onChange={(e) => setSenderName(e.target.value)}
              />

              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                name="sender email"
                placeholder="sender email"
                className="mt-2 ps-3 py-2 d-block"
                type="text"
                required
                value={senderEmail}
                onChange={(e) => setSenderEmail(e.target.value)}
              />

              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                name="sender phone"
                placeholder="sender phone"
                className="mt-2 ps-3 py-2 d-block"
                type="text"
                required
                value={senderPhone}
                onChange={(e) => setSenderPhone(e.target.value)}
              />

              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                name="senderAddress"
                placeholder="Sender Address Line 1"
                className="mt-2 ps-3 py-2 d-block"
                type="text"
                required
                value={senderAddress}
                minLength={10}
                onChange={(e) => setSenderAddress(e.target.value)}
              />

              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                name="senderAddressLine2"
                placeholder="Sender Address Line 2"
                className="mt-2 ps-3 py-2 d-block"
                type="text"
                required
                value={senderAddressLine2}
                onChange={(e) => setSenderAddressLine2(e.target.value)}
              />
              <div className="row">
                <div className="col">
                  <input
                    style={{
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    name="city"
                    placeholder="city"
                    className="mt-2 ps-3 py-2 d-block"
                    type="text"
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="col">
                  <input
                    style={{
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    name="state"
                    placeholder="state"
                    className="mt-2 ps-3 py-2 d-block"
                    type="text"
                    required
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
              </div>

              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                name="country"
                placeholder="country"
                className="mt-2 ps-3 py-2 d-block"
                type="text"
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />

              <input
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                name="pincode"
                placeholder="pincode"
                className="mt-2 ps-3 py-2 d-block"
                type="text"
                required
                value={pincode}
                onChange={(e) => setPinCode(e.target.value)}
              />
              {!isSubmiting && (
                <div
                  className=" text-center"
                  style={{ paddingTop: "10px", marginRight: 10 }}
                >
                  <Button name="Submit" type="submit" />
                </div>
              )}
            </form>
          </Box>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Pickup;
