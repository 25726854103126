import React from "react";
import "./Button.module.css";
export default function Button(props) {
  const container = {
    // height: props.height || "38px",
    // width: props.width || "15%",
    // backgroundColor: props.bgColor || "#A5A5A5",
    // marginLeft: props.marginLeft || "auto",
    // marginRight: props.marginRight || "auto",
    borderRadius: props.borderRadius || "12px",
    // fontSize: props.fontSize,
    backgroundColor: props.bgColor || "#4F81C3",
    fontWeight: props.fontWeight || "600",
    color: props.btnTextColor || "#fff",
    paddingLeft: props.paddingLeft || "12px",
    paddingRight: props.paddingRight || "12px",
    paddingTop: props.paddingTop || "5px",
    paddingBottom: props.paddingBottom || "5px",
    // padding:props.padding
    whiteSpace: "nowrap",
  };
  return (
    <button
      className={`border-0 fs_xsm ${props.className}`}
      style={{ ...container, ...props?.style }}
      type={props?.type || "button"}
      onClick={props?.onClick}
    >
      {props.name}
    </button>
  );
}
