import baseUrl from "./baseUrls";
export default {
  baseUrl,
  // baseUrl:'http://206.189.136.43/api/',
  // baseUrl: "https://mymegamall.helixsmartlabs.in/api/",

  getShopById: "general/shop/",
  deductReserveByShopId: "general/shop/:mid/reserve/deduct",
  splitReserveByShopId: "general/shop/:mid/reserve/split",
  getShopTxns: "general/shop/:id/transactions",
  getShopSettlements: "general/shop/:id/settlements",
  markSettlementsPaid: "general/shop/settlements",
  updateShop: "general/shop/",
  addCategoryToShop: "general/shop/:mid/category/:category",
  removeCategoryFromShop: "general/shop/:mid/category/:category",
  login: "login",
  allMerchants: "general/merchant/all",
  unapprovedMerchants: "general/agent/approve",
  approveMerchant: "admin/general/agent/approve",
  topagents: "general/dashboard/general/top-agents",
  topsales: "general/dashboard/general/top-sales",
  getAllOrders: "general/order/all",
  getAllReturns: "general/order/return/all",
  allShops: "general/shop/all",
  allCustomers: "general/customer/all",
  addShop: "general/shop/add",
  salesByRegion: "general/ordersByRegion", //get sales by region
  salesByChannels: "general/salesByChannels", //get sales by region
  getIncomingPayments: "general/payments/incoming", //get sales by region
  productInventory: "product/all",
  createSubAdmin: "admin/create",
  allSubadmin: "general/all",
  deleteAdmin: "general/delete/", // check kr lena
  allNotificationForAdmin: "general/notification/all",
  createNotification: "general/notification",
  reviews: "general/customer/review/trends",
  forgotPassword: "admin/forgot-password",
  checkToken: "admin/check",
  resetPassword: "admin/reset-password",
  completeProfile: "admin/complete-admin-profile",
  getAllCategoriesInProduct: "product/category/all",
  getAvailableCategoriesInProduct: "product/category/available/",
  deleteCategory: "product/category", //check kr lena
  createNewCategory: "product/category/add",
  deleteProduct: "product/delete", //check kr lena
  createBanner: "admin/product/banner/add",
  getAllBanners: "product/banner/all",
  getAllHappywall: "product/happy-wall",
  getAllReviews: "product/reviews/Product",
  deleteReviews: "product/reviews/delete/",
  getReviewAnalytics: "product/reviews/analysis",
  sendToHappywall: "product/happy-wall",
  nominate: "product/nominate", //it is used in review screen on nominate button
  deleteFromHappywall: "product/happy-wall",
  getAllCatalogList: "product/catalogue/all",
  getProductsByCatalogId: "product/catalogue/",
  addProduct: "product/add",
  editProduct: "product/edit/",
  addCatalog: "product/catalogue/add",
  bulkUploadOfProducts: "product/add/bulk",
  addSlideToBanner: "product/banner/",
  deleteSlideFromBanner: "product/banner/",
  getAllFeedBack: "product/reviews/Platform",
  addProductToCatalog: "product/catalogue/",
  getAllOffer: "general/offer/all",
  deleteCoupon: "general/offer/",
  updateMerchant: "general/merchant/",
  buisnessGraph: "general/dashboard/general/",
  totalEarningGraph: "general/dashboard/general/",
  updateProfile: "update-profile",
  customerGraph: "general/customer/trends/",
  getProductById: "product/get/",
  removeAllProductsFromCatalog: "product/catalogue/",

  // *******
  merchantAdd: "general/merchant/add", //kunal is not working right now
  merchantAnalytics: "general/merchant/analytics/", //kunal is not working right now //check kr lena
  generalDashboard: "general/dashboard/general", //kunal is not working right now
  // ###########
  salesRevnue: "general/dashboard/general/", // mila nhi check kr lena
  customerSatisfactionData: "general/dashboard/general/customer-satisfaction", //check kr lena
  specificMerchantOrder: "general/merchant", //check kr lena
  ordersById: "admin/general/order/",
  createOrderShipment: "admin/general/order/:id/shipping",
  createReturnOrderShipment: "admin/general/order/return/:id/shipping",
  cancelOrderShipment: "admin/general/order/:id",
  returnById: "admin/general/order/return/", 
  cancelReturnById: "admin/general/order/return/", 
  approveReturnById: "admin/general/order/return/", 
  refundReturnById: "admin/general/order/return/:id/refund", 
  merchantbyId: "general/merchant/",
  deleteShop: "general/shop/",
  shopsById: "general/shop/",
  deleteCustomer: "general/customer/",
  salesPerAgent: "general/dashboard/general/sale-trends",
  specificMerchanTopOrders: "general/merchant/orders/",
  CreateCouponform: "general/offer/add",
  specificCustomer: "general/customer/",
  anyticsOfSpecificCusotmer: "general/customer/analytics/",
  shopWiseSale: "general/dashboard/general/top-shops",
  specificCustomerRecentReviews: "general/customer/review/recent/",
  shopEdit: "general/shop/",
  orderOfMerchantById: "general/merchant/orders/",
  customerAllOrdersById: "general/customer/orders/",
  customerPreffrence: "general/customer/category/trends",
  productPrefrences: "general/customer/product/trends",
  updateProfile: "update-profile",
  changePassword: "change-password",
  editCategry: "product/category/",
  blockUnblockCustomer:"general/customer/",
  getSupportTickets: "general/support",
  patchSupportTicket: "general/support",
  getPickups: "general/pickup",
  addPickup: "general/pickup",
};
