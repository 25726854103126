import React, { useState } from "react";
import Backicon from "../../../Assets/backicon.png";
import Button from "../../../common/Button";
import axiosInstance from "../../../utils";
import endpoints from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";
const CreateCatalog = (props) => {
  const navigate = useNavigate();
  const [catalogName, setCatalogName] = useState();
  const closePopUpHandeler = () => {
    props.closePopUp();
  };
  const postCatalogData = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.addCatalog;
    // console.log(url)
    
    const formData = {
      name: catalogName,
    };
    console.log(formData, "Form data in create subadin");
     if (!formData.name) {
       setNameError("Name field is required");
       return;
     } else {
       closePopUpHandeler();
     }
    try {
      const response = await axiosInstance.post(url, formData);
      console.log(response.data.data.name);
      props.closePopUp();
      // navigate("/agent/catalogList");
    } catch (err) {
      console.log(err);
    }
  };
   const [nameError, setNameError] = useState("");
  return (
    <div
      className=" container-fluid py-5"
      style={{ border: "2px solid #4F81C3", backgroundColor: "#fff" }}
    >
      <img
        src={Backicon}
        onClick={closePopUpHandeler}
        style={{ cursor: "pointer" }}
      />

      <div className="pt-5 " style={{ maxHeight: "500px", overflowY: "auto" }}>
        <label style={{ fontWeight: 500, fontSize: 15 }}>Catalog Name</label>
        <input
          style={{
            backgroundColor: "#F3F1F1",
            width: "100%",
            border: "none",
            borderRadius: 10,
          }}
          onChange={(e) => {
            setCatalogName(e.target.value);
          }}
          className="mt-2 ps-3 py-2 d-block"
          type="text"
        />
        {nameError && <p style={{ color: "red" }}>{nameError}</p>}
      </div>
      <div className=" text-center pt-4" onClick={postCatalogData}>
        <Button name="Submit" />
      </div>
    </div>
  );
};

export default CreateCatalog;
