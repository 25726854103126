import React, { useState } from "react";
import Backicon from "../../Assets/backicon.png";
import Heading from "../../common/Heading";
import styles from "./AgentOrderSummary.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
const AgentOrdereSummary = (props) => {
  const navigate = useNavigate();
  const square = {
    width: "200",
    height: "200",
    backgroundColor: "red",
  };
  const color = {
    color: "#EC751F",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "end",
  };
  const padding = {
    padding: "7%",
    width: "100%",
  };

  const rupeesSize = {
    fontSize: "32px",
    fontWeight: "600",
    color: "#4F81C3",
  };
  const cardBottom = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "16px",
  };
  const percentile = (part, total) => {
    if (part === 0 || total === 0) return 0;
    const percentage = (Number(part) / Number(total)) * 100;
    return percentage.toFixed(2);
  };
  return (
    <div className="pt-4">
      <div className="d-flex align-items-center pb-3">
        <div>
          <img
            src={Backicon}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div className="ps-3">
          <Heading
            name={props.merchantInfo?.name}
            fontSize="28px"
            fontWeight="500"
          />
        </div>
      </div>
      <div className="row py-3">
        <div className={styles.rowCard}>
          <div style={padding}>
            <div className={`${styles.headingCard}`}>Total sales</div>
            <div style={rupeesSize}>
              ₹{props.merchantAnalyticsApi.totalSales?.toFixed(2)}
            </div>
            <div style={cardBottom}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                {percentile(
                  props.merchantAnalyticsApi.weekOrdersValue,
                  props.merchantAnalyticsApi.totalSales
                )}
                %
              </div>
              <div style={color}>
                +₹{props.merchantAnalyticsApi.weekOrdersValue} this week
              </div>
            </div>
          </div>
          <div className={styles.verticalLines}></div>
        </div>

        <div className={styles.rowCard}>
          <div style={padding}>
            <div className={`${styles.headingCard}`}>Total Orders</div>
            <div style={rupeesSize}>
              {props.merchantAnalyticsApi.orderCount}
            </div>
            <div style={cardBottom}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                {percentile(
                  props.merchantAnalyticsApi.weekOrders,
                  props.merchantAnalyticsApi.orderCount
                )}
                %
              </div>
              <div style={color}>
                +{props.merchantAnalyticsApi.weekOrders} this week
              </div>
            </div>
          </div>
          <div className={styles.verticalLines}></div>
        </div>
        <div className={styles.rowCard}>
          <div style={padding}>
            <div className={`${styles.headingCard}`}>Completed orders</div>
            <div style={rupeesSize}>
              {props.merchantAnalyticsApi.totalDeliveredOrders}
            </div>
            <div style={cardBottom}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                {percentile(
                  props.merchantAnalyticsApi.weekDeliveredOrders,
                  props.merchantAnalyticsApi.totalDeliveredOrders
                )}
                %
              </div>
              <div style={color}>
                +{props.merchantAnalyticsApi.weekDeliveredOrders} this week
              </div>
            </div>
          </div>
          <div className={styles.verticalLines}></div>
        </div>
        <div className={styles.rowCard}>
          <div style={padding}>
            <div className={`${styles.headingCard}`}>Cancelled orders</div>
            <div style={rupeesSize}>
              {props.merchantAnalyticsApi.totalCancelledOrders}
            </div>
            <div style={cardBottom}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                {percentile(
                  props.merchantAnalyticsApi.weekCancelledOrders,
                  props.merchantAnalyticsApi.totalCancelledOrders
                )}
                %
              </div>
              <div style={color}>
                +{props.merchantAnalyticsApi.weekCancelledOrders} this week
              </div>
            </div>
          </div>
          <div className={styles.verticalLines}></div>
        </div>
        <div className={styles.rowCard}>
          <div style={padding}>
            <div className={`${styles.headingCard}`}>Pending orders</div>
            <div style={rupeesSize}>
              {props.merchantAnalyticsApi.totalPendingOrders}
            </div>
            <div style={cardBottom}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                {percentile(
                  props.merchantAnalyticsApi.weekPendingOrders,
                  props.merchantAnalyticsApi.totalPendingOrders
                )}
                %
              </div>
              <div style={color}>
                +{props.merchantAnalyticsApi.weekPendingOrders} this week
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentOrdereSummary;
