import { useEffect, React, useState } from "react";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
import sales from "../../Assets/sales.svg";
import refunded from "../../Assets/refunded.svg";
import orders from "../../Assets/orders.svg";
import visitors from "../../Assets/visitor.svg";
import styles from "../HomeScreen/DashboardMenu.module.css";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
const DashboardMenu = () => {
  const navigate = useNavigate();
  const [DashboardAnalytics, setDashboardAnalytics] = useState({});
  const generalDashboard = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.generalDashboard
      );
      setDashboardAnalytics(response.data.data || {});
      // console.log(response.data.data)
    } catch (error) {
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  useEffect(() => {
    generalDashboard();
  }, []);
  const percentile = (part, total) => {
    if (part === 0 || total === 0) return 0;
    const percentage = (Number(part) / Number(total)) * 100;
    return percentage.toFixed(2);
  };
  return (
    <div>
      <Card bgColor={"#fff"}>
        {/* we have to checked this */}
        <div className="row px-4 mt-3">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className={`${styles.border} my-4`}>
              <div
                className="d-flex  align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/admin/buisness-analytics")}
              >
                <div>
                  <img width={"37px"} height={"37px"} src={sales} />
                </div>
                <Heading
                  paddingLeft={"2%"}
                  fontSize={"20px"}
                  fontWeight={500}
                  name="Sales report"
                  color={"#8D9398"}
                  fontFamily={"InterMedium"}
                />
              </div>

              {/* <Heading
                paddingLeft={"1%"}
                fontSize={"32px"}
                fontWeight={600}
                name="Rs 100,00,00"
                fontFamily={"InterSemiBold"}
              /> */}
              <p
                className={` ff_primary fw_semibold p-1 pt-4 ${styles.fs_xxl}`}
              >
                ₹{DashboardAnalytics?.totalSales?.toFixed(2)}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <div
                  style={{
                    fontFamily: "InterSemiBold",
                    color: "#8D9398",
                  }}
                  className={styles.fs_sm}
                >
                  {percentile(
                    DashboardAnalytics?.weekOrdersValue,
                    DashboardAnalytics?.totalSales
                  )}
                  %
                </div>
                <div
                  style={{
                    fontFamily: "InterSemiBold",
                    color: "#EC751F",
                  }}
                  className={styles.fs_sm}
                >
                  +₹{DashboardAnalytics?.weekOrdersValue?.toFixed(2)} this week
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className={`${styles.border} my-4`}>
              <div className="d-flex align-items-center">
                <div>
                  <img width={"37px"} height={"37px"} src={visitors} />
                </div>
                <Heading
                  paddingLeft={"2%"}
                  fontSize={"20px"}
                  fontWeight={500}
                  name="Total Customers"
                  fontFamily={"InterMedium"}
                  color={"#8D9398"}
                />
              </div>
              {/* <Heading
                paddingLeft={"1%"}
                fontSize={"32px"}
                fontWeight={600}
                name="Rs 100,00,00"
                fontFamily={"InterSemiBold"}
              /> */}
              <p
                className={` ff_primary fw_semibold p-1 pt-4 ${styles.fs_xxl}`}
              >
                {DashboardAnalytics?.totalVisitors}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <div
                  style={{
                    fontFamily: "InterSemiBold",
                    color: "#8D9398",
                  }}
                  className={styles.fs_sm}
                >
                  {percentile(
                    DashboardAnalytics?.totalVisitorsThisWeek,
                    DashboardAnalytics?.totalVisitors
                  )}
                  %
                </div>
                <div
                  style={{
                    fontFamily: "InterSemiBold",
                    color: "#EC751F",
                  }}
                  className={styles.fs_sm}
                >
                  +{DashboardAnalytics?.totalVisitorsThisWeek} this week
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className={`${styles.border} my-4`}>
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/admin/orders")}
              >
                <div>
                  <img width={"37px"} height={"37px"} src={orders} />
                </div>

                <Heading
                  paddingLeft={"2%"}
                  fontSize={"20px"}
                  fontWeight={500}
                  name="Total orders"
                  fontFamily={"InterMedium"}
                  color={"#8D9398"}
                />
              </div>
              {/* <Heading
                paddingLeft={"1%"}
                fontSize={"32px"}
                fontWeight={600}
                name="Rs 100,00,00"
                fontFamily={"InterSemiBold"}
              /> */}
              <p
                className={` ff_primary fw_semibold p-1 pt-4 ${styles.fs_xxl}`}
              >
                {DashboardAnalytics?.totalOrders}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <div
                  style={{
                    fontFamily: "InterSemiBold",
                    color: "#8D9398",
                  }}
                  className={styles.fs_sm}
                >
                  {percentile(
                    DashboardAnalytics?.weekOrders,
                    DashboardAnalytics?.totalOrders
                  )}
                  %
                </div>
                <div
                  style={{
                    fontFamily: "InterSemiBold",
                    color: "#EC751F",
                  }}
                  className={styles.fs_sm}
                >
                  +{DashboardAnalytics?.weekOrders} this week
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="my-4">
              <div className="d-flex align-items-center">
                <div>
                  <img width={"37px"} height={"37px"} src={refunded} />
                </div>
                <Heading
                  paddingLeft={"2%"}
                  fontSize={"20px"}
                  fontWeight={500}
                  name="Total Refunded"
                  fontFamily={"InterMedium"}
                  color={"#8D9398"}
                />
              </div>
              {/* <Heading
                paddingLeft={"1%"}
                fontSize={"32px"}
                fontWeight={600}
                name="Rs 100,00,00"
                fontFamily={"InterSemiBold"}
              /> */}
              <p
                className={` ff_primary fw_semibold p-1 pt-4 ${styles.fs_xxl}`}
              >
                ₹{DashboardAnalytics?.totalRefunds}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <div
                  style={{
                    fontFamily: "InterSemiBold",
                    color: "#8D9398",
                  }}
                  className={styles.fs_sm}
                >
                  {percentile(
                    DashboardAnalytics?.totalRefundsThisWeek,
                    DashboardAnalytics?.totalRefunds
                  )}
                  %
                </div>
                <div
                  style={{
                    fontFamily: "InterSemiBold",
                    color: "#EC751F",
                  }}
                  className={styles.fs_sm}
                >
                  +₹{DashboardAnalytics?.totalRefundsThisWeek} this week
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default DashboardMenu;
