import { React, useState } from "react";
import Button from "../../common/Button";
import BackIcon from "../../Assets/backicon.png";
import { async } from "q";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import { toast } from "react-toastify";
import { useEffect } from "react";
import "./AddSubCategoryPopup.css";

const AddSubCategoryPopup = (props) => {
  const [subCategory, setSubCategory] = useState(props.nameToEditForm);
  const [categories, setCategories] = useState([]);
  const [Id, setId] = useState("");
  const HandlersubCategoryChange = (e) => {
    setSubCategory(e.target.value);
  };
  const closePopUpHandeler = () => {
    props.closePopUp();
  };
  const fetchCategories = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct;
    // console.log("**", url);
    try {
      const response = await axiosInstance.get(url);
      setCategories(response.data.data);
      console.log(response.data.data, "***************************");
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  const handleSubmit = async () => {
    try {
      const url =
        endpoints.baseUrl +
        "admin/" +
        endpoints.addCategoryToShop
          .replace(":mid", props?.shopId)
          .replace(":category", Id);
      const response = await axiosInstance.patch(url);
      if (response.data.code === 200) {
        toast.success("category added successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      closePopUpHandeler();
      if (props?.getData) props.getData();
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <div className="popcontainer">
      <div style={{ position: "relative", zIndex: "2000000000000000" }}>
        <div style={{ backgroundColor: "#fff", border: "2px solid #4F81C3" }}>
          <div className="container-fluid py-5 px-4">
            <img
              src={BackIcon}
              alt="Back Icon"
              onClick={closePopUpHandeler}
              style={{ cursor: "pointer" }}
            />
            <div className="row">
              <div className="col-12 pt-3">
                <select
                  onChange={(e) => setId(e.target.value)}
                  style={{
                    display: "block",
                    backgroundColor: "#E4EEF8",
                    width: "100%",
                    display: "block",
                    border: "none",
                    borderRadius: 10,
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                  className="ps-2 py-2 mt-1 add-new-agent"
                >
                  <option value="" disabled className="d-none">
                    Select Category
                  </option>
                  {categories.map((option, index) => (
                    <option
                      key={index}
                      value={option._id}
                      style={{ backgroundColor: "#F5F5F5" }}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="pt-5 text-center" onClick={handleSubmit}>
              <Button
                name="Submit"
                width="fit-content"
                borderRadius="12px"
                fontSize="15px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubCategoryPopup;
