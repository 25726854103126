import React from "react";
import styles from "./Card.module.css";
export default function Card({ children, ...props }) {
  // console.log(props.width);
  const cardStyle = {
    overflowY: props.overflowY,
    width: props.width,
    maxWidth: props.maxWidth,
    height: props.height,
    maxHeight: props.maxHeight,
    backgroundColor: props.bgColor || "#FFFFFF",
    borderRadius: props.borderRadius || 10,
    borderBottomRightRadius: props.borderBottomRightRadius || 10,
    borderBottomLeftRadius: props.borderBottomLeftRadius || 10,
    margin: props.margin,
    padding: props.padding,
    paddingTop: props.paddingTop,
    paddingBottom: props.paddingBottom,
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    // borderWidth: props.borderwidth||'1px',
    // borderStyle: props.borderType||'solid',
    // borderColor: props.borderColor||'grey',
    display: props.display,
    alignContent: "center",
    
  };
  return <div className={`${props?.className}`} style={cardStyle}>{children}</div>;
}
