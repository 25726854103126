import React from "react";
import "./terms.css";
import { Link } from "react-router-dom";
function TermsConditions() {
  return (
    <div className="container" style={{}}>
      <div className="row">
        <div
          className="col-lg-6 col-md-6 col-12 p-3 offset-md-3 slip"
          style={{
            minHeight: "100vh",
          }}
        >
          <h3>Terms & Conditions</h3>
          <p style={{ fontWeight: "bold" }}>
            Effective Date: 1st October, 2023
          </p>

          <p className="heading">1. Acceptance of Terms</p>

          <p>
            By accessing or using MyMegaMall's ecommerce mobile application
            ("the App"), you agree to comply with and be bound by these Terms
            and Conditions. If you do not agree with any part of these terms,
            please do not use the App.
          </p>
          <p className="heading">2. Account Registration</p>
          <p>
            You may be required to create an account to access certain features
            of the App. You agree to provide accurate and complete information
            when registering for an account and to keep your login credentials
            secure.
          </p>
          <p></p>
          <p>
            You are responsible for all activities that occur under your
            account, and MyMegaMall is not liable for any unauthorised use of
            your account.
          </p>
          <p className="heading">3. Use of the App</p>
          <p>
            You agree to use the App in accordance with all applicable laws and
            regulations.
          </p>
          <p></p>
          <p>
            MyMegaMall reserves the right to suspend or terminate your access to
            the App at its discretion, without prior notice, for any violation
            of these Terms and Conditions.
          </p>
          <p className="heading">4. Products and Services</p>
          <p>
            The App may offer products and services for sale. Prices,
            descriptions, and availability of products and services are subject
            to change without notice.
          </p>
          <p></p>
          <p>
            You agree to pay all fees associated with purchases you make through
            the App, and MyMegaMall reserves the right to refuse or cancel
            orders at any time.
          </p>
          <p className="heading">5. Privacy Policy</p>
          <p>
            Your use of the App is also governed by our Privacy Policy, which
            can be found{" "}
            <Link to={"/privacy-policy"} target="_blank">
              here
            </Link>
            . By using the App, you consent to the collection and use of your
            personal information as described in the Privacy Policy.
          </p>
          <p className="heading">6. Intellectual Property</p>
          <p>
            All content, logos, trademarks, and other intellectual property on
            the App are the property of MyMegaMall or its licensors and are
            protected by applicable intellectual property laws.
          </p>
          <p></p>
          <p>
            You may not reproduce, distribute, modify, or create derivative
            works of any content on the App without prior written consent from
            MyMegaMall.
          </p>
          <p className="heading">7. Disclaimers and Limitation of Liability</p>
          <p>
            The App is provided "as is" and "as available" without any
            warranties of any kind, either express or implied.
          </p>
          <p></p>
          <p>
            MyMegaMall is not liable for any damages or losses arising from your
            use of the App, including but not limited to direct, indirect,
            incidental, consequential, or punitive damages.
          </p>
          <p className="heading">8. Rules of Conduct</p>
          <p>
            You agree to adhere to the following rules of conduct when using the
            App:
          </p>
          <p></p>
          <p>
            a. Do not engage in any unlawful activities while using the App.
          </p>
          <p>
            b. Do not engage in any activities that may harm, disrupt, or
            interfere with the App's operation or its users.
          </p>
          <p>
            c. Do not attempt to gain unauthorized access to the App or its
            systems.
          </p>
          <p>
            d. Do not engage in any fraudulent or deceptive activities on the
            App.
          </p>
          <p>
            e. Do not use the App to post, share, or distribute offensive or
            harmful content.
          </p>
          <p className="heading">9. User Restrictions</p>
          <p>You agree to the following user restrictions:</p>
          <p></p>
          <p>
            a. You must be at least 18 years of age or have the legal capacity
            to enter into contracts in India.
          </p>
          <p>
            b. You are responsible for maintaining the confidentiality of your
            account information and for all activities that occur under your
            account.
          </p>
          <p>
            c. You may not use the App for any illegal or unauthorised purpose.
          </p>
          <p className="heading">10. Governing Law and Jurisdiction</p>
          <p>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of Indian Jurisdiction. Any disputes
            arising from or related to these terms shall be subject to the
            exclusive jurisdiction of the courts located in New Delhi.
          </p>
          <p className="heading">11. Changes to Terms and Conditions</p>
          <p>
            MyMegaMall reserves the right to update or modify these Terms and
            Conditions at any time. Any changes will be effective immediately
            upon posting on the App. It is your responsibility to review these
            terms regularly.
          </p>
          <p className="heading">12. Contact Information</p>
          <p>
            If you have any questions or concerns about these Terms and
            Conditions, please contact us at:
          </p>
          <p></p>
          <p>Address:</p>
          <p>AYNP SYSTEMS</p>
          <p>4214, SECOND FLOOR, SADAR BAZAR, DELHI - 110006</p>
          <p></p>
          <p>Email: mmm.prabandhak@gmail.com</p>
          <p>Phone: 9205603401</p>
          <p></p>
          <p>
            By using the App, you acknowledge that you have read, understood,
            and agree to these Terms and Conditions.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
