import { React, useEffect, useState } from "react";
import Backicon from "../../Assets/backicon.png";
import Button from "../../common/Button";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import { toast } from "react-toastify";
const CreateBannerForm = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    shop: "",
    type: "",
  });

  const closePopUpHandeler = () => {
    props.closePopUp();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
 const [nameError, setNameError] = useState("");
  const handleSubmit = async () => {
    console.log(formData);

    try {
      console.log(formData);
      let url = endpoints.baseUrl + endpoints.createBanner;
      if (!formData.name) {
          setNameError("Name field is required");
        return;
      } else {
         closePopUpHandeler();
      }
      const response = await axiosInstance.post(url, formData);
      window.location.reload()
      if (response.data.code === 200) {
        toast.success("Banner created successfuly", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000, // Set the duration for how long the popup should be visible
        });
      }
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
     
    }
  };

 
  const item2 = ["option1", "option2", "option3"];

  return (
    <div
      className=" container-fluid py-5"
      style={{ border: "2px solid #4F81C3", backgroundColor: "#fff" }}
    >
      <img
        src={Backicon}
        onClick={closePopUpHandeler}
        style={{ cursor: "pointer" }}
      />

      <div className="row">
        <div className="col-12 pt-4">
          <label
            style={{
              fontWeight: 500,
              fontSize: 15,
              display: "block",
            }}
            className="pb-2"
          >
            Name
          </label>
          <input
            name="name"
            style={{
              backgroundColor: "#F3F1F1",
              width: "100%",
              border: "none",
              borderRadius: 10,
            }}
            className="ps-3 py-2"
            type="text"
            value={formData.name}
            onChange={handleInputChange}
          />
          {nameError && <p style={{ color: "red" }}>{nameError}</p>}
        </div>
      </div>
      <div className=" text-center pt-4 d-block">
        <Button name="Submit" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default CreateBannerForm;
