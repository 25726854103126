import React, { useEffect, useState } from "react";
import Card from "../../../common/Card";
import upload from "../../../Assets/Upload to the Cloud.svg";
import Backicon from "../../../Assets/backicon.png";
import Button from "../../../common/Button";
import axiosInstance from "../../../utils";
import endpoints from "../../../utils/endpoints";
import { useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AddImage = (props) => {
  const handelBackToCustomBanner = () => {
    props.handelCLickToBack();
  };

  //it will pass the active banner back
  const [active, setActive] = useState(props.activeBanner);
  const [association, setAssociation] = useState("categories");
  const [category, setCategory] = useState("");
  const [productInputValue, setProductInputValue] = useState("");
  const [categoryNames, setCategoryNames] = useState([]);
  const [getAllCoupon, setgetAllCoupon] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);
  const handleAssociationChange = (event) => {
    console.log(event.target.value, "association");
    setAssociation(event.target.value);
  };
  const [categoriesFromApi, setCategoriesFromApi] = useState([]);
  const fetchAllCategoriesForProduct = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getAllCategoriesInProduct
      );
      setCategoriesFromApi(response.data.data);
      const names = response.data.data.map((category) => { return { key: category.name, value: category._id } });
      setCategoryNames(names);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [file, setFile] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageUpload = (event) => {
    const fileRec = event.target.files[0];
    setFile(event.target.files[0]);
    console.log(fileRec, "************IMAGE********");
  };

  const findCategoryIdByName = (name) => {
    for (let i = 0; i < categoriesFromApi.length; i++) {
      if (categoriesFromApi[i].name === name) {
        return categoriesFromApi[i]._id;
      }
    }
    return null;
  };
  const handleSubmit = async () => {
    // console.log(props.edit,"******************************")
    try {
      const formData = new FormData();
      if (file) formData.append("image", file);
      if (association == "categories") {
        const id = findCategoryIdByName(category);
        formData.append("categoryId", category);
      } else if (association == "products") {
        // console.log(id)
        formData.append("productId", productInputValue);
      } else if (association == "website") {
        // formData.append("categoryId", id);
        formData.append("website", productInputValue)
      }
      if (props.edit == "add") {
        const response = await axiosInstance.post(
          endpoints.baseUrl +
          "admin/" +
          "product/banner/" +
          props.activeBanner +
          "/add",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.done("Slide Create Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000, // Set the duration for how long the popup should be visible
        });
        props.fetchBanners();
        props.closePopAddImage();
      } else {
        // console.log("------?",props.edit)
        const response = await axiosInstance.patch(
          endpoints.baseUrl +
          "admin/" +
          "product/banner/" +
          props.activeBanner +
          "/edit/" +
          props.slide,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.done("Slide Create Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000, // Set the duration for how long the popup should be visible
        });
        props.fetchBanners();
        props.closePopAddImage();
      }
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  useEffect(() => {
    // console.log(props.banners[active]._id)
    console.log(props.edit, props.slide)
    if (props.edit === "edit") {
      if (props.slide.promotes === "category") {
        setAssociation("categories")
        setCategory(props.slide.categoryId)
      }
      if (props.slide.promotes === "website") {
        setAssociation("website")
        setProductInputValue(props.slide.url)
      }
      if (props.slide.promotes === "product") {
        setAssociation("products");
        setProductInputValue(props.slide.productId)
      }
    }
    fetchAllCategoriesForProduct();
  }, []);

  const handleCategoryChange = (event) => {
    // console.log(event.target.value,"*******************");
    setCategory(event.target.value);
  };

  const clickHandler = () => {
    props.closePopUp(selectedImage);
  };

  const getOptionsByAssociation = () => {
    switch (association) {
      case "categories":
        return categoryNames;
      case "promoCodes":
        return promoCodes;
      default:
        return [];
    }
  };

  // const filteredOptions = getOptionsByAssociation().filter((option) =>
  //   option.toLowerCase().includes(category.toLowerCase())
  // );

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "2px solid #4F81C3",
      }}
      className="py-3"
    >
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="pb-3">
              <img
                src={Backicon}
                alt="backicon"
                onClick={handelBackToCustomBanner}
                style={{ cursor: "pointer" }}
              />
            </div>
            <label className="pb-2"  style={{ fontWeight: 500, fontSize: 15 }}>Image</label>
            <div style={{ border: "1px dotted #000000", borderRadius: "10px" }}>
              <Card>
                <div className="d-flex justify-content-center align-items-center">
                  {/* <img
                    src={upload}
                    alt="upload"
                    style={{ cursor: "pointer" }}
                    className="w-25"
                  /> */}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
              </Card>
            </div>
            <div className="col-12 pt-3">
              <label style={{ fontWeight: 500, fontSize: 15 }}>
                Association With Image
              </label>
              <select
                style={{
                  backgroundColor: "#F3F1F1",
                  width: "100%",
                  border: "none",
                  borderRadius: 10,
                }}
                className="mt-2 ps-3 py-2 d-block"
                value={association}
                onChange={handleAssociationChange}
              >
                <option value="categories" className="d-none">
                  Categories{" "}
                </option>
                <option value="categories">Categories</option>
                {/* <option value="promoCodes">Offers</option> */}
                <option value="products">Products</option>
                <option value="website">Website</option>
              </select>
            </div>

            {association === "website" || association === "products" ? (
              <div className="col-12 pt-3">
                {association === "website" ? (
                  <label style={{ fontWeight: 500, fontSize: 15 }}>
                    Website URL
                  </label>
                ) : (
                  <label style={{ fontWeight: 500, fontSize: 15 }}>
                    Products
                  </label>
                )}
                <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  value={productInputValue} // Set the input value to the state
                  onChange={(e) => setProductInputValue(e.target.value)}
                  className="mt-2 ps-3 py-2 d-block"
                  type="text"
                />
              </div>
            ) : (
              <div className="col-12 pt-3">
                <label style={{ fontWeight: 500, fontSize: 15 }}>
                  {association ? association : "Categories"}
                </label>
                {/* <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="mt-2 ps-3 py-2 d-block"
                  type="text"
                  value={category}
                  onChange={handleCategoryChange}
                />
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {getOptionsByAssociation().map((option, index) => (
                    <li key={index}>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          borderBottom: "1px solid #ccc",
                          width: "100%",
                          textAlign: "left",
                        }}
                        onClick={() => setCategory(option)}
                      >
                        {option}
                      </button>
                    </li>
                  ))}
                </ul> */}
                <select
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="mt-2 ps-3 py-2 d-block"
                  value={category} // Set the selected value to the state
                  onChange={handleCategoryChange}
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {getOptionsByAssociation().map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="text-center pt-5" onClick={handleSubmit}>
              <Button name="Submit" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddImage;
