import React from "react";
import "./terms.css";
function ContactUs() {
  return (
    <div className="container" style={{}}>
      <div className="row">
        <div
          className="col-lg-6 col-md-6 col-12 p-3 offset-md-3 slip"
          style={{
            minHeight: "100vh",
          }}
        >
          <h3>Contact Us</h3>
          <p style={{ fontWeight: "bold" }}>
            Effective Date: 1st October, 2023
          </p>
          <p className="heading">Contact Information</p>
          <p>
            If you have any questions or concerns regarding Terms, Privacy
            Policy, Shipping and Delivery Policy, please contact us at:
          </p>
          <p></p>
          <p>Address:</p>
          <p>AYNP SYSTEMS</p>
          <p>4214, SECOND FLOOR, SADAR BAZAR, DELHI - 110006</p>
          <p></p>
          <p>Email: mmm.prabandhak@gmail.com</p>
          <p></p>
          <p>Phone: 9205603401</p>
          <p></p>
          <p>
            By using the App and making a purchase, you acknowledge that you
            have read, understood, and agree to this Shipping and Delivery
            Policy.
          </p>
          <p>MyMegaMall</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
