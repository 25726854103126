import React from "react";
import styles from "./PreLoder.module.css";
import preloaderImg from "../Assets/preloader_img.webp";
const PreLoader = () => {
  return (
    <div className=" position-relative">
      <div
        className={`d-flex justify-content-center align-items-center min-vh-100  ${styles.loading}`}
      >
        <div className=" d-flex justify-content-center pe-md-5 me-md-5 ">
          {/* <p className=" fs-3 text-black">Loding..........</p> */}
          <div className="pe-md-5 me-md-5 text-xxl-start">
            <img src={preloaderImg} className="w-25" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
