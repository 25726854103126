import React, { useState, useRef, useEffect } from "react";
import NavBarEx from "../../common/NavBarEx";
import Sidebar from "../../common/SideBar";
import Heading from "../../common/Heading";
import Card from "../../common/Card";
import { CustomLineChart } from "../../common/CustomLineChart";
import TopAgent from "../../components/Merchants/TopAgent";
import MerchantListTable from "../../components/Merchants/MerchantListTable";
import Styles from "./Merchant.module.css";
import MerchantForm from "../../components/MerchantForm";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import MerchantEditForm from "../../components/MerchantEditForm";
// import Button from "../../common/Button";
// import DropDown from "../../common/DropDown";
// import AllTable from "../../components/AllTable";
// import dropimg from "../../Assets/dropimg.png";
import "../../common/Fonts.css";
import { BarChart } from "../../common/BarChart";

const Merchant = () => {
  // const graphoptions = ["Yearly", "Monthly", "Weekly"];
  // const [graphoption, setgraphoption] = useState("");
  // const handleSelectgraphoptionChange = (event) => {
  //   const graphoptionValue = event.target.value;
  //   fetchDataFroGraph(graphoptionValue)
  //   setgraphoption(graphoptionValue);
  //   console.log("graphoption:", graphoptionValue);
  // };
  function fetchTotalAmounts(data) {
    const sales = [];
    const agents = [];
    data = data.filter((item)=>item.agent.name != null)

    for (const item of data) {
      const sale = item.totalSales;
      const shop = item.agent.name != null ? item.agent.name : "others";

      sales.push(sale);
      agents.push(shop);
    }

    return {
      x: agents,
      y: sales,
    };
  }

  const [amountsForGraph, setAmountsForGraph] = useState();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // fetchDataFroGraph("Weekly")
    setTimeout(() => {
      setLoading(false);
      // document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const [popupOpen, setPopupOpen] = useState(false);
  const handleVerifyClick = (e) => {
    e.preventDefault();
    setPopupOpen(true);
  };
  // nav-pop-up
  const [open, setOpen] = useState(0);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };
  const headerdata = [
    {
      header1: "Log id",
      header2: "Name",
      header3: "Email Id",
      header4: "Shop",
      header5: "Contact",
      header6: "Total orders",
      header7: "Total sales",
    },
  ];
  const [dataFromApi, setdataFromApi] = useState([]);
  useEffect(() => {
    fetchDataTopAgents();
  }, []);

  const fetchDataTopAgents = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.topagents
      );
      setdataFromApi(response.data.data);
      const amounts = fetchTotalAmounts(response.data.data);
      setAmountsForGraph(amounts);
      // console.log(response.data.data)
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const graphoptions = ["Yearly", "Monthly", "Weekly"];
  const [graphoption, setgraphoption] = useState("");
  const handleSelectgraphoptionChange = (event) => {
    const graphoptionValue = event.target.value;
    //  fetchDataFroGraph(graphoptionValue);
    setgraphoption(graphoptionValue);
    console.log("graphoption:", graphoptionValue);
  };
  return (
    <div>
      <div className="container-fluid position-relative">
        <div className="row">
          <div
            className={` col-lg-2 col-md-3 pe-0  ${Styles.left}`}
            style={open == 1 ? { filter: "blur(2px)" } : {}}
          >
            <Sidebar activeTabColor="Merchants" />
          </div>
          <div
            className="col-lg-10 col-md-9 pb-4"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#EAF0FA",
            }}
          >
            {loading && <PreLoader />}
            <div style={open == 1 ? { filter: "blur(2px)" } : {}}>
              <div className="py-3">
                {/* <NavBar /> */}
                <NavBarEx
                  // search={false}
                  buttonName={"Add Merchant"}
                  buttonFlag={true}
                  popUp={showPopUp}
                  search={true}
                  siderBarButton
                />
              </div>
              <div className="row pt-3">
                {/* line-chart */}
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <Card width="100%" height="100%">
                    <div className="pt-3 d-flex  align-items-center pb-2">
                      <div className=" col-7 col-xl-5">
                        <Heading
                          paddingLeft={"10%"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Merchants Vs Sales"
                          fontFamily={"InterSemiBold"}
                        />
                      </div>
                      {/* <div className=" col-3 col-sm-4  col-lg-3 col-xl-2">
                        <select
                          value={graphoption}
                          onChange={handleSelectgraphoptionChange}
                          style={{
                            display: "block",
                            backgroundColor: "#E4EEF8",
                            width: "100%",
                            display: "block",
                            border: "none",
                            borderRadius: 10,
                            fontWeight: "600",
                            fontSize:"15px"
                          }}
                          className="ps-2 py-2 mt-1 add-new-agent"
                        >
                          <option value="" disabled className="d-none">
                            Weekly
                          </option>
                          {graphoptions.map((option, index) => (
                            <option
                              key={index}
                              value={option}
                              style={{ backgroundColor: "#F5F5F5", }}
                            >
                              {option}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                    {/* <CustomLineChart
                      amountsForGraph={amountsForGraph}
                      height={"83%"}
                    /> */}
                    <div className="px-5">
                      <BarChart amountsForGraph={amountsForGraph} />
                    </div>
                  </Card>
                </div>
                {/* top-agent table */}
                <div className="col-lg-6 col-md-6 col-sm-6 pt-3 pt-sm-0">
                  <Card height="100%">
                    <div
                      className="py-3"
                      style={{ paddingLeft: "4%", paddingRight: "4%" }}
                    >
                      <TopAgent />
                    </div>
                  </Card>
                </div>
                {/* agent-list-table*/}
                <div
                  className="col-lg-12 col-md-12 pt-4"
                  style={{ backgroundColor: "#EAF0FA" }}
                >
                  {/* <div className=" row pb-3">
                    <div className=" col-6 col-md-9 col-lg-8">
                      <div className="row">
                        <div className=" col-sm-4 col-md-3 pt-2 pt-sm-0">
                          <Heading
                            nxame="Agent list"
                            fontSize="24px"
                            fontWeight="600"
                            fontFamily={"InterSemiBold"}
                          />
                        </div>
                        <div className=" col-sm-8  col-md-9 pt-sm-0 d-flex">
                          <div className=" pt-3 pt-sm-0">
                            <DropDown
                              changeTableContent={(item)=>{changeTableContent(item)}}
                              top="All agents"
                              items={item1}
                              zIndex="5"
                              img={dropimg}
                            />
                          </div>
                          <div className="ps-4 pt-3 pt-sm-0  ">
                            <DropDown

                              top="Filter by shop"
                              items={item2}
                              img={dropimg}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className=" col-6 pt-sm-0 col-md-3 col-lg-4 text-end"
                      style={{ paddingTop: "3px" }}
                    >
                      <AllTable
                        data={data}
                        header={headerdata}
                        item5
                        item6
                        item7
                        headingname={"Agent list"}
                        redeye
                        trash
                        edit
                        activeTabColor="Merchants"
                        navigation={"/admin/specificMerchant"}
                      />
                    </div>
                  </div> */}
                  <MerchantListTable
                    setPopupOpen={setPopupOpen}
                    data={[]}
                    header={headerdata}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* pop-ups */}
        {/* {popupOpen && (
          <div className={Styles.popcontainer}>
            <div>
              <MerchantEditForm closePopUp={closePopUp} />
            </div>
          </div>
        )} */}
        <div className={Styles.formcontainer} hidden={!open ? true : false}>
          <div>{open && <MerchantForm closePopUp={closePopUp} />}</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Merchant;
