import React, { useState, useEffect } from "react";
// import NavBar from "../../common/NavBar";
import NavBarEx from "../../common/NavBarEx";
import Sidebar from "../../common/SideBar";
import Heading from "../../common/Heading";
import Button from "../../common/Button";
import styles from "./Review.module.css";
import colors from "../../utils/helper";
import reviewProfile from "../../Assets/reviewProfile.png";
import Card from "../../common/Card";
import rating from "../../Assets/star.svg";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Rating } from "react-simple-star-rating";
import StarRatings from "react-star-ratings";

const Reviews = () => {
  const [feedback, setFeedback] = useState([]);
  const [count, setcount] = useState(0);
  const fetchfeedBack = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.getAllFeedBack;
    try {
      const response = await axiosInstance.get(url);
      setFeedback(response.data.data);
      console.log(response.data.data, "**************FEEDBACK");
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  useEffect(() => {
    fetchReviewsAnalytics();
    fetchfeedBack();
    fetchReviews();
  }, []);

  const [reviewsFromApi, setReviewsFromApi] = useState([]);
  const fetchReviews = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.getAllReviews;
    try {
      const response = await axiosInstance.get(url);
      setReviewsFromApi(response.data.data);
      // console.log(response.data.data,"***********************************************Reviews**************")
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const [averagerating, setAveragerating] = useState();
  const [reviewAnalytics, setreviewAnalytics] = useState({});

  const fetchReviewsAnalytics = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.getReviewAnalytics;
    // console.log("************** Review Analyitcs-------->", url);
    try {
      const response = await axiosInstance.get(url);
      // console.log(respo);
      setcount(response.data.data.count);
      setAveragerating(response.data.data.avgRating.toFixed(1));
      setreviewAnalytics(response.data.data);
      // console.log(response.data.data)
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabName) => {
    // console.log(tabName)
    if (tabName == "reviews") {
      setActiveTab(1);
    }
    if (tabName == "feedback") {
      setActiveTab(0);
    }
  };

  const [open, setOpen] = useState(0);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };
  const [happywall, setHappywall] = useState([]);
  const fetchForHappyWall = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.getAllHappywall;
    try {
      const response = await axiosInstance.get(url);
      setHappywall(response.data.data.review);
      // console.log("************** Happy wall-------->",url)

      // console.log(response.data.data+"************** Happy wall-------->")
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const convertToNumeric = (val) => {
    if (val == "five") {
      return 5;
    }
    if (val == "four") {
      return 4;
    }
    if (val == "three") {
      return 3;
    }
    if (val == "two") {
      return 2;
    }
    if (val == "one") {
      return 1;
    }
  };
  const [nominatedReviewIds, setNominatedReviewIds] = useState([]);

  const sendReviewIdToServer = async (reviewId) => {
    const url = endpoints.baseUrl + "admin/" + endpoints.sendToHappywall; // Replace with your server endpoint
    const data = {
      review: reviewId,
      // agentId: localStorage.getItem("userData")
      //   ? JSON.parse(localStorage.getItem("userData"))._id
      //   : "",
    };
    try {
      await axiosInstance.post(url, data);
      fetchReviews();
    } catch (error) {
      console.error("Error sending review ID:", error);
      // Handle the error if needed
    }
  };

  const deleteReview = async (id) => {
    try {
      const url = endpoints.baseUrl + "admin/" + endpoints.deleteReviews + id;
      let resp = await axiosInstance.delete(url);
      fetchReviews()
      console.log(resp.data)
    } catch (error) {
      console.log(error);
    }
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchForHappyWall();
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  return (
    <div>
      <div>{/* <NavBar /> */}</div>
      <div className=" container-fluid">
        <div className="row">
          <div className={` col-lg-2 col-md-3 pe-0  ${styles.left}`}>
            <Sidebar flag={true} activeTabColor="Reviews" />
          </div>
          <div
            className="col-lg-10 col-md-9 col-12  pb-4"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: colors.bgcolor,
            }}
          >
            {loading && <PreLoader />}
            <div className="py-3">
              <NavBarEx
                buttonName={"Create Notification"}
                buttonFlag={false}
                popUp={showPopUp}
                search={true}
              />
            </div>

            <div className="pt-3 ps-sm-4">
              <div className="text-center text-sm-start">
                <Heading
                  name="Reviews and feedback"
                  fontSize="24px"
                  fontWeight="600"
                />
              </div>
              <div className="row pt-4">
                <div className="col-sm-4 text-center text-sm-start">
                  <p style={{ fontSize: "20px", fontWeight: "600" }}>
                    Total reviews
                  </p>
                  <div className="pt-2">
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "32px",
                        color: " #4F81C3",
                      }}
                      className="pt-3"
                    >
                      {count}
                    </p>
                    <p style={{ fontSize: "16px", fontWeight: "500" }}>
                      Growth in reviews this year
                    </p>
                  </div>
                </div>
                <div className="col-sm-4 pt-4 pt-sm-0 text-center text-sm-start">
                  <p style={{ fontSize: "20px", fontWeight: "600" }}>
                    Average rating
                  </p>
                  <div className="pt-2">
                    <div className="d-flex justify-content-center justify-content-sm-start pt-3">
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "32px",
                          color: " #4F81C3",
                        }}
                      >
                        {averagerating}
                      </p>
                      <div
                        className="d-flex ms-2"
                        style={{
                          maxWidth: `${
                            averagerating ? (averagerating / 5) * 100 : 0
                          }px`,
                          overflow: "hidden",
                          boxSizing: "border-box",
                          // background: "red",
                          width: "100px",
                        }}
                      >
                        <img src={rating} style={{ width: "20px" }} />
                        <img src={rating} style={{ width: "20px" }} />
                        <img src={rating} style={{ width: "20px" }} />
                        <img src={rating} style={{ width: "20px" }} />
                        <img src={rating} style={{ width: "20px" }} />
                      </div>
                    </div>
                    <p style={{ fontSize: "16px", fontWeight: "500" }}>
                      Average rating on this year
                    </p>
                  </div>
                </div>
                <div className="col-sm-4 pt-4 pt-sm-0">
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      paddingLeft: "8%",
                    }}
                  >
                    Average reviews
                  </p>
                  <div style={{ padding: "5%" }} className="pt-2">
                    {["five", "four", "three", "two", "one"].map(
                      (item, index) => {
                        const colors = [
                          "#0468C4",
                          "#5391CA",
                          "#74B3D7",
                          "#A8CDD9",
                          "#b5d5de",
                        ]; // Array of colors
                        const progressColor = colors[index % colors.length];
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className=""
                              style={{ fontWeight: "600", fontSize: "16px" }}
                            >
                              {convertToNumeric(item)}
                            </div>
                            <>
                              <div
                                style={{
                                  width: "60%",
                                  height: "10px",
                                  // background: "red",
                                }}
                                className="progress"
                              >
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${
                                      count
                                        ? (reviewAnalytics[item] / count) * 100
                                        : 0
                                    }%`,
                                    backgroundColor: progressColor,
                                  }}
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </>
                            <div style={{ minWidth: "20%" }} className="">
                              {reviewAnalytics[item]}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex pt-5 pb-1">
                    <div
                      style={{
                        cursor: "pointer",
                        borderBottom:
                          activeTab === 1 ? "2px solid black" : "none",
                      }}
                      onClick={() => handleTabClick("reviews")}
                    >
                      <Heading
                        name="Reviews "
                        fontSize="24px"
                        fontWeight="600"
                      />
                    </div>
                    <div
                      className="ms-5"
                      style={{
                        cursor: "pointer",
                        borderBottom:
                          activeTab === 0 ? "2px solid black" : "none",
                      }}
                      onClick={() => handleTabClick("feedback")}
                    >
                      <Heading
                        name="Feedback"
                        fontSize="24px"
                        fontWeight="600"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  // overflowX: "auto",
                  maxHeight: "500px",
                }}
              >
                {activeTab ? (
                  reviewsFromApi.length > 0 ? (
                    reviewsFromApi.map((item, index) => (
                      <div className="row pt-5" key={index}>
                        <div className="col-lg-3 col-md-3 col-12">
                          <div className="d-flex d-md-block d-lg-flex pb-3 pb-md-0">
                            <div>
                              <img
                                src={
                                  item?.userId[0]?.profilePicture ||
                                  "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
                                }
                                style={{
                                  width: "76px",
                                  height: "76px",
                                  borderRadius: "20px",
                                }}
                              />
                            </div>
                            <div className="ps-3 ps-md-0 ps-lg-3 pt-3 pt-lg-0">
                              <p>{item?.userId[0]?.name || "Not Found"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 pb-4">
                          {/* <div className="d-flex"> */}
                          <div
                            className="d-flex"
                            style={{
                              maxWidth: `${
                                item.rating ? (item.rating / 5) * 100 : 0
                              }px`,
                              overflow: "hidden",
                              boxSizing: "border-box",
                              // background: "red",
                              width: "100px",
                            }}
                          >
                            <img src={rating} style={{ width: "20px" }} />
                            <img src={rating} style={{ width: "20px" }} />
                            <img src={rating} style={{ width: "20px" }} />
                            <img src={rating} style={{ width: "20px" }} />
                            <img src={rating} style={{ width: "20px" }} />
                          </div>

                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#181A1D",
                            }}
                          >
                            {item.review}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              marginTop: 10,
                              flexWrap: "wrap",
                            }}
                          >
                            {item?.images?.length > 0 &&
                              item?.images.map((image, index) => (
                                <img
                                  className="mx-2"
                                  key={index}
                                  src={image}
                                  height={100}
                                  style={{ borderRadius: 10, minWidth: 50 }}
                                />
                              ))}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                          <div
                            className="d-flex flex-column align-items-start"
                            style={{ gap: 10 }}
                          >
                            {item.images.length > 0 && (
                              <Button
                                bgColor={item.isNominated ? "tomato" : ""}
                                name={
                                  item.isNominated
                                    ? "Added To Happy Wall"
                                    : "Add To Happ Wall"
                                }
                                // width="fit-content"
                                fontSize="15px"
                                onClick={() => {
                                  if (!item.isNominated)
                                    return sendReviewIdToServer(item._id);
                                }}
                              />
                            )}
                            <Button
                              bgColor={"tomato"}
                              name={"Delete review"}
                              // width="fit-content"
                              fontSize="15px"
                              onClick={() => deleteReview(item._id)}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No Reviews Present</div>
                  )
                ) : null}
                {!activeTab ? (
                  feedback.length > 0 ? (
                    feedback.map((row, index) => (
                      <div className="row pt-5" key={index}>
                        <div className="col-lg-4 col-md-4 col-12">
                          <div className="d-flex d-md-block d-lg-flex pb-3 pb-md-0">
                            <div>
                              <img
                                src={
                                  row?.userId[0]?.profilePicture ||
                                  "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
                                }
                                style={{
                                  width: "76px",
                                  height: "76px",
                                  borderRadius: "20px",
                                }}
                              />
                            </div>
                            <div className="ps-3 ps-md-0 ps-lg-3 pt-3 pt-lg-0">
                              <p>{row.userId[0]?.name || "Not Found"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-12 pb-4">
                          {Array.from(
                            { length: row.rating },
                            (_, index) => index + 1
                          ).map((item) => {
                            return <img src={rating} />;
                          })}
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#181A1D",
                            }}
                          >
                            {row.review}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              marginTop: 10,
                              flexWrap: "wrap",
                            }}
                          >
                            {row?.images?.length > 0 &&
                              row?.images.map((item, index) => (
                                <img
                                  className="mx-2"
                                  key={index}
                                  src={item}
                                  height={100}
                                  style={{ borderRadius: 10, minWidth: 50 }}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No Feedback Present</div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Reviews;
