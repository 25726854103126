import { React, useState } from "react";
import Backicon from "../../Assets/backicon.png";
import Button from "../../common/Button";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import { toast } from "react-toastify";
const EditProfilePopUp = (props) => {
  const [password, setPassword] = useState("");
  const handelPasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const handelConfirmpasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError("");
  };
  const clickHandlerPopUpClose = () => {
    props.closePopUp();
  };
  const handleSubmit = () => {
    console.log("password", password);
    console.log("confirmPassword", confirmPassword);
    if (password == confirmPassword) {
      changePassword();
    }
    if (!password || !confirmPassword) {
      setError("Please fill the password");
      return;
    }

    if (password !== confirmPassword) {
      setError("password doesn't match");
      return;
    } else {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const changePassword = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.changePassword;
    const data = {
      pswd: confirmPassword,
    };

    try {
      const response = await axiosInstance.patch(url, data);
      // console.log("add shop api")
      // console.log(response.data.data);
    } catch (err) {
      console.log(err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
      // setError(err.message || 'Something went wrong.');
    }finally{
      props.closePopUp()
    }
  };
  return (
    <div
      className="px-3 pt-4 pb-5"
      style={{
        backgroundColor: "#fff",
        border: "1px solid #4F81C3",
      }}
    >
      <div onClick={clickHandlerPopUpClose}>
        <img
          width={30}
          height={30}
          src={Backicon}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="row px-3 ">
        <div className="col-lg-12 col-md-12 pt-3">
          <label style={{ fontWeight: 500, fontSize: 16 }}>Password</label>
          <input
            style={{
              backgroundColor: "#EAF0FA",
              width: "100%",
              display: "block",
              border: "none",
              borderRadius: 10,
            }}
            className="mt-2 ps-3 py-2 "
            value={password}
            onChange={handelPasswordChange}
            type="text"
          />
          {error &&  <p style={{ color: "red" }}>{error}</p>}
        </div>
        <div className="col-lg-12 col-md-12 pt-5">
          <label style={{ fontWeight: 500, fontSize: 16 }}>
            Confirm Password
          </label>
          <input
            style={{
              backgroundColor: "#EAF0FA",
              width: "100%",
              display: "block",
              border: "none",
              borderRadius: 10,
            }}
            className="mt-2 ps-3 py-2 "
            value={confirmPassword}
            onChange={handelConfirmpasswordChange}
            type="text"
          />
          {error &&  <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
      <div className="text-center">
        <div className="mt-5 text-center" onClick={handleSubmit}>
          <Button borderRadius={10} name="submit" width="20%" fontSize="15px" />
        </div>
      </div>
    </div>
  );
};

export default EditProfilePopUp;
