import React from "react";
import "./terms.css";
function ShippingPolicy() {
  return (
    <div className="container" style={{}}>
      <div className="row">
        <div
          className="col-lg-6 col-md-6 col-12 p-3 offset-md-3 slip"
          style={{
            minHeight: "100vh",
          }}
        >
          <h3>Shipping and Delivery Policy</h3>
          <p style={{ fontWeight: "bold" }}>
            Effective Date: 1st October, 2023
          </p>
          <p className="heading">1. Order Processing and Shipping Time</p>

          <p>
            At MyMegaMall, we understand the importance of timely delivery. We
            make every effort to ensure that your orders are processed promptly
            and delivered to your doorstep in a timely manner. The order
            processing and shipping time varies depending on the product and
            location. Here's what you can expect:
          </p>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <p>
                Order Processing Time: Once you place an order, we aim to
                process it within a maximum of 15 working days. Please note that
                the processing time may vary for different products, and
                specific information is provided on the product page.
              </p>
            </li>
            <li>
              <p>
                Shipping Time: After processing, the delivery time may vary
                depending on the destination and the shipping method chosen.
                Generally, you can expect your order to be delivered within 7
                working days. Please note that this is an estimated delivery
                time, and actual delivery times may vary based on the
                destination and unforeseen circumstances.
              </p>
            </li>
          </ul>
          <p className="heading">2. Shipping Costs</p>
          <p>
            We are dedicated to providing transparency and ensuring that you are
            informed about shipping costs. The shipping costs, if any, will be
            calculated based on various factors, including the destination,
            product weight, and shipping method. You can view the shipping costs
            associated with your order during the checkout process. For some
            orders or promotions, shipping may be offered for free.
          </p>
          <p className="heading">3. Contact Information</p>
          <p>
            If you have any questions or concerns regarding our Shipping and
            Delivery Policy, please contact us at:
          </p>
          <p></p>
          <p>Address:</p>
          <p>AYNP SYSTEMS</p>
          <p>4214, SECOND FLOOR, SADAR BAZAR, DELHI - 110006</p>
          <p></p>
          <p>Email: mmm.prabandhak@gmail.com</p>
          <p></p>
          <p>Phone: 9205603401</p>
          <p></p>
          <p>
            By using the App and making a purchase, you acknowledge that you
            have read, understood, and agree to this Shipping and Delivery
            Policy.
          </p>
          <p>MyMegaMall</p>
        </div>
      </div>
    </div>
  );
}

export default ShippingPolicy;
