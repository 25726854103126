import React, { useEffect, useState } from "react";
import NavBar from "../../common/NavBar";
import Sidebar from "../../common/SideBar";
import styles from "./ShopProduct.module.css";
import Heading from "../../common/Heading";
import Button from "../../common/Button";
import SimilarProduct from "../Supllier/SimilarProduct";
import NavBarEx from "../../common/NavBarEx";
import kurtiimage from "../../Assets/kurtiFullIMg.png";
import { useNavigate, useParams } from "react-router-dom";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import productStyles from "../../screens/agent/supplier/Product.module.css";
import AddProductCatalogForm from "./AddProductCatalogForm";
import rating from "../../Assets/star.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import video from "../../Assets/videos/video.mp4";
import PreLoader from "../../common/PreLoader";
import Backicon from "../../Assets/backicon.png";
const ShopProduct = () => {
  const [addCatalogForm, setAddCatalogForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    fetchProductDataById(id);
    // console.log(productDetails.color[0].images)

    console.log(id);
  }, []);
  const [productDetails, setProductDetails] = useState([]);
  const fetchProductDataById = async (id) => {
    const url = `${endpoints.baseUrl}admin/${endpoints.getProductById}${id}`;

    try {
      setLoading(true);
      const response = await axiosInstance.get(url);
      setProductDetails(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const openPopUp = () => {
    setAddCatalogForm(true);
  };

  const closePopUp = () => {
    setAddCatalogForm(false);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1500,
    // prevArrow: false,
    // nextArrow: false,
    arrows: true,
    slide: "div",
  };
  const productLink = productDetails.link;
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  const handleColorClick = (index) => {
    setSelectedColorIndex(index);
    setSelectedSizeIndex(0); // Reset the selected size index when changing color
  };
  const handleSizeClick = (index) => {
    setSelectedSizeIndex(index);
  };

  return (
    <div>
      <div className=" container-fluid">
        <div className="row">
          <div className={` col-lg-2  pe-0 ${styles.left}`}>
            <Sidebar flag={true} activeTabColor="Products" />
          </div>
          <div
            className="col-lg-10 col-md- 12 col-12"
            style={{
              backgroundColor: "#EAF0FA",
              overflowY: "auto",
              maxHeight: "100vh",
            }}
          >
            <div className=" container-fluid">
              <div className="pt-3">
                <NavBarEx />
              </div>
              <div className="text-start py-3">
                <img
                  src={Backicon}
                  alt="Back Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              {loading && <PreLoader />}
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 px-5">
                  <div
                    style={{ backgroundColor: "white", borderRadius: "25px" }}
                  >
                    {productDetails.color?.[selectedColorIndex] &&
                      productDetails.color?.[selectedColorIndex].images && (
                        <Slider {...settings}>
                          {productDetails.color?.[
                            selectedColorIndex
                          ].images.map((imageUrl, index) => (
                            <div
                              key={index}
                              className="h-100 text-center"
                              // style={{
                              //   display: "flex",
                              //   alignItems: "center",
                              //   justifyContent: "center",
                              //   backgroundColor: "blue",
                              //   width: "100%",
                              //   margin:"auto"
                              // }}
                            >
                              <img
                                src={imageUrl}
                                alt={`Slide ${index + 1}`}
                                style={{
                                  height: "550px",
                                  maxWidth: "90%",
                                  margin: "auto",
                                  overflow: "hidden",
                                }}
                              />
                            </div>
                          ))}
                        </Slider>
                      )}
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-ssm-12 col-12"
                  style={{ maxHeight: "600px", overflowY: "scroll" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Heading
                        fontSize="28px"
                        fontWeight="800"
                        name={productDetails?.name}
                      />
                    </div>
                    <div>
                      {Array.from(
                        { length: productDetails.reviews },
                        (_, index) => index + 1
                      ).map((item) => {
                        return <img src={rating} style={{ width: "20px" }} />;
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      gap: 20,
                    }}
                  >
                    <div
                      className="px-4 py-2"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "25px",
                        width: "200px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                        className="pt-1"
                      >
                        Cost Price:
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                        className="pt-1"
                      >
                        Rs.{" "}
                        {
                          productDetails.color?.[selectedColorIndex]?.sizes[
                            selectedSizeIndex
                          ]?.price
                        }
                      </p>
                    </div>
                    <div
                      className="px-4 py-2"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "25px",
                        width: "200px",
                      }}
                    >
                      <p
                        style={{ fontSize: "12px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Company Discount Amount:
                      </p>
                      <p
                        style={{ fontSize: "16px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Rs. {productDetails?.companyDiscount}
                      </p>
                    </div>
                    <div
                      className="px-4 py-2"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "25px",
                        width: "200px",
                      }}
                    >
                      <p
                        style={{ fontSize: "12px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Profit Margin:
                      </p>
                      <p
                        style={{ fontSize: "16px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Rs. {productDetails?.profitMargin}
                      </p>
                    </div>
                    <div
                      className="px-4 py-2"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "25px",
                        width: "200px",
                      }}
                    >
                      <p
                        style={{ fontSize: "12px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Agent Discount Amount:
                      </p>
                      <p
                        style={{ fontSize: "16px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Rs. {productDetails?.agentDiscount}
                      </p>
                    </div>
                    <div
                      className="px-4 py-2"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "25px",
                        width: "200px",
                      }}
                    >
                      <p
                        style={{ fontSize: "12px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Direct Selling Price:
                      </p>
                      <p
                        style={{ fontSize: "16px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Rs.{" "}
                        {productDetails.color?.[selectedColorIndex]?.sizes[
                          selectedSizeIndex
                        ]?.price +
                          productDetails?.profitMargin -
                          productDetails?.companyDiscount}
                      </p>
                    </div>
                    <div
                      className="px-4 py-2"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "25px",
                        width: "200px",
                      }}
                    >
                      <p
                        style={{ fontSize: "12px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Agent Selling Price:
                      </p>
                      <p
                        style={{ fontSize: "16px", fontWeight: "600" }}
                        className="pt-1"
                      >
                        Rs.{" "}
                        {productDetails.color?.[selectedColorIndex]?.sizes[
                          selectedSizeIndex
                        ]?.price +
                          productDetails?.profitMargin -
                          productDetails?.companyDiscount -
                          productDetails?.agentDiscount}
                      </p>
                    </div>
                  </div>

                  <p
                    className="pt-4"
                    style={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Colors
                  </p>
                  <div className="d-flex pt-2">
                    {productDetails?.color?.map((color, index) => (
                      <div
                        key={index}
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: color.shade,
                          borderRadius: "50%",
                          cursor: "pointer",
                          marginInline: 10,
                          border:
                            index === selectedColorIndex
                              ? "2px solid #000"
                              : "none", // Highlight the selected color
                        }}
                        onClick={() => handleColorClick(index)} // Handle click on color
                      />
                    ))}
                  </div>
                  <p
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    className="pt-3"
                  >
                    Select Size
                  </p>
                  <div className="pt-3 d-flex">
                    {productDetails.color?.[selectedColorIndex]?.sizes.map(
                      (
                        size,
                        index // Add the ?. operator here as well
                      ) => (
                        <Button
                          key={index}
                          name={size.size}
                          // width="60px"
                          marginRight="20px"
                          marginLeft="0"
                          borderRadius="25px"
                          bgColor={
                            index === selectedSizeIndex ? "#000" : "#FFFFFF"
                          }
                          btnTextColor={
                            index === selectedSizeIndex ? "#FFFFFF" : "#000"
                          }
                          onClick={() => handleSizeClick(index)}
                          style={{ marginInline: 10, width: "60px" }}
                        />
                      )
                    )}
                  </div>
                  <div className="pt-3" style={{ width: "80%" }}>
                    <p
                      style={{ fontSize: "16px", fontWeight: "600" }}
                      className="pt-3"
                    >
                      Description
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                      className="pt-2 pb-3"
                    >
                      {productDetails.description}
                    </p>
                    {productDetails?.returnDuration && (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          borderTop: "1px solid #4F81C3",
                          borderBottom: "1px solid #4F81C3",
                        }}
                        className="py-1"
                      >
                        This product can be returned within{" "}
                        {productDetails?.returnDuration} days from the date of
                        purchase.
                      </p>
                    )}
                    <div
                      className="pt-4"
                      onClick={() => {
                        openPopUp();
                      }}
                    >
                      <Button name="Add to Catalog" />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12 py-5">
                  <SimilarProduct />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addCatalogForm && (
        <div className={` ${productStyles.AddProductCatalogForm}`}>
          <div style={{ position: "relative", zIndex: "100" }}>
            <AddProductCatalogForm productId={id} closePopUp={closePopUp} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopProduct;
