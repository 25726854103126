import React, { useState, useEffect } from "react";
import NavBarEx from "../../common/NavBarEx";
import Sidebar from "../../common/SideBar";
import Styles from "./SpecificShop.module.css";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
// import Button from "../../common/Button";
import AgentAssociated from "../../components/Shop/AgentAssociated";
// import CustomLineChart  from "../../common/CustomLineChart";
// import CustomCircularChart  from "../../common/CircularChart";
import { CustomLineChart } from "../../common/CustomLineChart";
import { CustomCircularChart } from "../../common/CircularChart";
import TotalCatagries from "../../components/Shop/TotalCatagries";
import { useNavigate } from "react-router-dom";
import ShopForm from "../../components/Shop/ShopForm";
import Backicon from "../../Assets/backicon.png";
// import DropDown from "../../common/DropDown";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid } from "@mui/x-data-grid";
import Button from "../../common/Button";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const Settlement = () => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const columns = [
    { field: "id", headerName: "Sno", flex: 1 },
    { field: "_id", headerName: "Transaction ID", minWidth: 230, flex: 1 },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 1,
      minWidth: 230,
    },
    {
      field: "amount",
      headerName: "Order Amount",
      minWidth: 100,
      flex: 1,
      type: "number",
    },
    { field: "linkOwner", headerName: "Link Owner", minWidth: 230, flex: 1 },
    {
      field: "linkComission",
      headerName: "Link Commision",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      field: "categoryOwner",
      headerName: "Category Owner",
      flex: 1,
      minWidth: 230,
    },
    {
      field: "categoryComission",
      headerName: "Category Commision",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      field: "generalComission",
      headerName: "Distribution Amount (5%)",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      field: "generalComissionPerPerson",
      headerName: "Distribution Amount Per Agent",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      field: "reserve",
      headerName: "Reserve Amount",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      field: "createdAt",
      headerName: "createdAt",
      flex: 1,
      minWidth: 230,
    },
  ];
  const payoffColumns = [
    { field: "id", headerName: "Sno", flex: 1 },
    { field: "_id", headerName: "ID", minWidth: 230, flex: 1 },
    {
      field: "shopTxnId",
      headerName: "Transaction ID",
      minWidth: 230,
      flex: 1,
    },
    {
      field: "agentId",
      headerName: "Agent Id",
      flex: 1,
      minWidth: 230,
    },
    { field: "name", headerName: "Agent Name", flex: 1, minWidth: 200 },
    {
      field: "linkComission",
      headerName: "Link Commision",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      field: "categoryComission",
      headerName: "Category Commision",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      field: "generalComission",
      headerName: "Distribution Commision",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      flex: 1,
      minWidth: 100,
    },
    { field: "date", headerName: "Settlement Date", minWidth: 230, flex: 1 },
    { field: "status", headerName: "Status", minWidth: 200, flex: 1 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = async (e) => {
    //       try {
    //         e.stopPropagation(); // don't select this row after clicking
    //         console.log(params.row);
    //         const response = await axiosInstance.patch(
    //           endpoints.baseUrl +
    //             "admin/" +
    //             endpoints.patchSupportTicket +
    //             "?ticketId=" +
    //             params.row._id
    //         );
    //         console.log(response.data);
    //         if (response.data.code === 200) {
    //           getData();
    //         }
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     };

    //     return (
    //       <>
    //         {!params?.row?.resolved && (
    //           <Button onClick={onClick}>Resolve</Button>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];
  const [rows, setRows] = useState([]);
  const [payoffrows, setPayoffRows] = useState([]);
  const [shop, setShop] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [start, setStart] = useState();
  const [amount, setAmount] = useState(0);
  const [agent, setAgent] = useState("all");
  const [end, setEnd] = useState();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const graphoptions = ["Yearly", "Monthly", "Weekly"];

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const item1 = ["first Week", "last Week"];
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      setLoading(true);
      await getShopById();
      await getshopTxns();
      await getShopSettlements();
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getShopById = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getShopById + id
      );
      setShop(response.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const getshopTxns = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.getShopTxns.replace(":id", id)
      );
      setRows(
        response.data.data.map((row, index) => {
          return {
            ...row,
            id: index + 1,
            orderId: row.orderId?._id||"not found",
            amount: row.orderId?.amount||"not found",
          };
        })
      );
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const getShopSettlements = async () => {
    try {
      // Make a GET request to the API
      let uri =
        endpoints.baseUrl +
        "admin/" +
        endpoints.getShopSettlements.replace(":id", id);
      if (start && end)
        uri = uri + `?from=${start?.toString()}&to=${end?.toString()}`;
      const response = await axiosInstance.get(uri);
      setPayoffRows(
        response.data.data.map((row, index) => {
          return {
            ...row,
            id: index + 1,
            name: row.agent?.name||"not found",
            agentId: row.agent?._id||"not found",
          };
        })
      );
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (start && end) getShopSettlements();
  }, [start, end]);
  const markPaymentPaid = async () => {
    try {
      if (selectedRows.length === 0) {
        return alert("select payment to settle");
      }
      setLoading(true);
      const payments = selectedRows.map((row) => row._id);
      const response = await axiosInstance.post(
        endpoints.baseUrl + "admin/" + endpoints.markSettlementsPaid,
        { payments }
      );
      console.log(response.data.data);
      setSelectedRows([]);
      await getData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const deductReserve = async () => {
    try {
      setLoading(true);
      // Make a GET request to the API
      setOpen(false);
      const response = await axiosInstance.post(
        endpoints.baseUrl +
          "admin/" +
          endpoints.deductReserveByShopId.replace(":mid", id),
        { amount }
      );
      await getData();
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      setLoading(false);
    }
  };
  const splitReserve = async () => {
    try {
      if(!amount || !agent){
        return alert("missing required fields")
      }
      setLoading(true);
      // Make a GET request to the API
      setOpen2(false);
      const response = await axiosInstance.post(
        endpoints.baseUrl +
          "admin/" +
          endpoints.splitReserveByShopId.replace(":mid", id),
        { amount }
      );
      await getData();
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div>
        <div className="container-fluid position-relative">
          <div className="row">
            <div className={` col-lg-2 col-md-3 pe-0  ${Styles.left}`}>
              <Sidebar activeTabColor="Shops" />
            </div>
            <div
              className="col-lg-10 col-md-9 col-sm-12 pb-4"
              style={{
                overflowY: "auto",
                height: "100vh",
                backgroundColor: "#EAF0FA",
              }}
            >
              {loading && <PreLoader />}
              <div className="pt-3">
                {/* <NavBar /> */}
                <NavBarEx buttonFlag={false} />
              </div>
              <div className="py-3 px-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div style={{ cursor: "pointer" }}>
                    <img
                      src={Backicon}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  <p
                    className="mb-0 ps-3 "
                    style={{
                      fontSize: "22px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    Back To Shop
                  </p>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  <p
                    className="mb-0 ps-3 "
                    style={{
                      fontSize: "22px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    In Reserve: {shop?.reserve}
                  </p>
                  <Button name={"Deduct Amount"} onClick={handleOpen} />
                  <Button name={"Give Bonus"} onClick={handleOpen2} />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <Card className="py-3" width="100%" height="100%">
                    <div className="px-2 d-flex align-items-center justify-content-between">
                      <Heading
                        paddingLeft={"10px"}
                        fontSize={"24px"}
                        fontWeight={600}
                        name="Detailed Ledger"
                      />
                    </div>
                    <div
                      className="px-4 py-2"
                      style={{ height: 400, width: "100%",overflow:"scroll" }}
                    >
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        // checkboxSelection
                        style={{ border: "none" }}
                        scrollbarSize={100}
                      />
                    </div>
                  </Card>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <Card className="py-3" width="100%" height="100%">
                    <div className="px-2 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <Heading
                          paddingLeft={"10px"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Monthly Payoffs"
                        />
                        <div
                          style={{
                            paddingLeft: 10,
                            display: "flex",
                            gap: 10,
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="From"
                              value={start}
                              onChange={(newValue) => setStart(newValue)}
                            />
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="To"
                              value={end}
                              onChange={(newValue) => setEnd(newValue)}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <Button name="Settle" onClick={markPaymentPaid} />
                    </div>

                    <div
                      className="px-4 py-2"
                      style={{ height: 400, width: "100%",overflow:"scroll" }}
                    >
                      <DataGrid
                        rows={payoffrows}
                        columns={payoffColumns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        style={{ border: "none" }}
                        onRowSelectionModelChange={(ids) => {
                          let data = payoffrows.filter((row) =>
                            ids.includes(row.id)
                          );
                          console.log(data);
                          setSelectedRows(data);
                        }}
                        isRowSelectable={(params) =>
                          params?.row?.status === "PENDING"
                        }
                      />
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              deductReserve();
            }}
          >
            {/* <Heading name="Shipment Details" paddingLeft="1%" /> */}
            <div className="row">
              <div className="col">
                <label
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    display: "block",
                    textAlign: "start",
                  }}
                >
                  Amount
                </label>
                <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="mt-2 ps-3 py-2 d-block"
                  type="number"
                  required
                  value={amount}
                  max={shop?.reserve}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </div>
            <div
              className=" text-center"
              style={{ paddingTop: "10px", marginRight: 10 }}
            >
              <Button name="Submit" type="submit" />
            </div>
          </form>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              splitReserve()
            }}
          >
            {/* <Heading name="Shipment Details" paddingLeft="1%" /> */}
            <div className="row">
              <div className="col">
                <label
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    display: "block",
                    textAlign: "start",
                  }}
                >
                  Amount
                </label>
                <input
                  style={{
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="mt-2 ps-3 py-2 d-block"
                  type="number"
                  required
                  value={amount}
                  max={shop?.reserve}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col">
                <label
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    display: "block",
                    textAlign: "start",
                  }}
                >
                  Agent
                </label>
                <select
                  style={{
                    display: "block",
                    backgroundColor: "#F3F1F1",
                    width: "100%",
                    display: "block",
                    border: "none",
                    borderRadius: 10,
                  }}
                  className="py-2 pe-5"
                  value={agent}
                  onChange={(e) => setAgent(e.target.value)}
                >
                  <option className="d-none">
                    select agent to give amount
                  </option>
                  <option value="all">
                    All (this will split the selected amount in all agents under
                    the shop)
                  </option>
                  {shop?.agents.map((item, index) => (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className=" text-center"
              style={{ paddingTop: "10px", marginRight: 10 }}
            >
              <Button name="Submit" type="submit" />
            </div>
          </form>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Settlement;
