import React, { useEffect, useState } from "react";
import NavBarEx from "../common/NavBarEx";
import Sidebar from "../common/SideBar";
import Card from "../common/Card";
import colors from "../utils/helper";
import Searchicon from "./../Assets/icon_search.svg";
import Heading from "../common/Heading";
import styles from "./AllTable.module.css";
import Button from "../common/Button";
import trashicon from "../../src/Assets/icon_trash.png";
import redeye from "../../src/Assets/remove_red_eye.png";
import MerchantEditForm from "../components/MerchantEditForm";
import { useNavigate } from "react-router-dom";
import editicon from "../Assets/edit.svg";
import Backicon from "../Assets/backicon.png";
import WarningPop from "../common/WarningPop";
import ShopEditForm from "../components/Shop/ShopEditForm";
import StockReportPopUp from "../components/Supllier/StockReportPopUp";
import PreLoader from "../common/PreLoader";
import NewProduct from "./Supllier/NewProduct";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { ToastContainer } from "react-toastify";

const AllTable = (props) => {
  const tableHead = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const [data, setData] = useState(props.data);
  const [popupOpen, setPopupOpen] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [shopPopupOpen, setshopPopupOpen] = useState(false);
  const [editpopupOpen, setEditPopupOpen] = useState(false);
  const handelPopUpclose = () => {
    setEditPopupOpen(false);
  };
  const handelEditOpen = (e) => {
    setEditActive(e);
    console.log(e, "smskskkskskskskksksks");
    // e.preventDefault();
    setEditPopupOpen(true);
  };

  // const showPopUp = (value) => {
  //   setOpen(value);
  // };
  // // const closePopUp = () => {
  // //   setOpen(0);
  // // };
  // useEffect(()=>{
  // console.log(data,"*******************************")
  // },[data])

  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  useEffect(() => { }, [popupOpen]);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
    setEditPopupOpen(false);
  };

  const deleteElement = (index, ar) => {
    for (let i = 0; i < ar.length; i++) {
      if (i < index) {
        console.log("delete index ->", i);
        continue;
      }
      ar[i] = ar[i + 1];
    }
    ar.pop();
    // data=[...ar]
    console.log(data);
    return ar;
  };
  const deleteHandler = (val) => {
    // console.log("*********FROM ALL TABLE*****************************", val);
    setWarning(0);
    props.deleteHandler(val);
  };

  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const fetchData = () => {
    props.fetchData();
  };
  const fetchDataOfAllShops = () => {
    props.fetchDataOfAllShops();
  };
  const PopUpclose = (key) => {
    setPopupOpen(false);
    if (key == "merchatnEditForm") {
      fetchData();
    }
    if (key == "shopEditForm") {
      console.log("key------>", key);
      fetchDataOfAllShops();
    }
    setshopPopupOpen(false);
  };
  const [editActive, setEditActive] = useState();
  const handleClickPopupOpen = (e) => {
    setEditActive(e);
    setPopupOpen(true);
  };
  const handleClickShopPopupOpen = (e) => {
    console.log(e, "**************");
    setEditActive(e);
    setshopPopupOpen(true);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset the page when changing rows per page to avoid potential out-of-bounds issues
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      // document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const recentOrders = [];
  const handleSelectrecentOrderChange = (event) => {
    const recentOrder = event.target.value;
    // fetchDataFroGraph(graphoptionValue);
    recentOrders.push(recentOrder);
    console.log("graphoption:", recentOrder);
  }; // Initial state with an empty array
  const recentOrder = [
    "Order Placed",
    "Processing",
    "Picked up by the courier",
    "Out for delivery",
    "Delivered",
  ];
  return (
    <div>
      {loading && <PreLoader />}
      <div className="">
        <div onClick={toggleExpand}>
          <Button name="View all" fontSize="15px" />
        </div>
        {isExpanded && (
          <div
            style={{
              position: "fixed",
              zIndex: "10000",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                height: "100vh",
                margin: "auto",
                maxWidth: "100%",
              }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div
                    className={` col-lg-2 col-md-3 pe-0  ${styles.left}`}
                    style={{ paddingRight: "0px" }}
                  >
                    {localStorage.getItem("role") == "admin" ? (
                      <Sidebar activeTabColor={props.activeTabColor} />
                    ) : (
                      <Sidebar flag activeTabColor={props.activeTabColor} />
                    )}
                  </div>
                  <div
                    className="col-lg-10 col-md-9"
                    style={{
                      backgroundColor: colors.bgcolor,
                      overflowY: "auto",
                      height: "100vh",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="container-fluid">
                      <div className="pt-3">
                        <NavBarEx search={true} fontSize={"38px"} />
                      </div>
                      <div className="text-start pt-3">
                        <img
                          src={Backicon}
                          alt="Back Icon"
                          style={{ cursor: "pointer" }}
                          onClick={toggleExpand}
                        />
                      </div>
                      <div className="py-4 d-flex align-items-center ">
                        <Card
                          width={"100%"}
                          bgColor={"#fff"}
                          height="58%"
                          paddingBottom="2%"
                          paddingTop="2%"
                          backgroundColor="#EDEDED"
                          className="px-3"
                        >
                          <div
                            className="mt-0 d-flex justify-content-between"
                            style={tableHead}
                          >
                            <Heading
                              paddingLeft={"1%"}
                              fontSize={"24px"}
                              fontWeight={600}
                              name={props.headingname}
                              fontFamily={"InterSemiBold"}
                            />
                            <div
                              className="d-flex align-items-center justify-content-end "
                              style={{ width: "50%" }}
                            >
                              {props.searchicon && (
                                <div className="me-3">
                                  <div
                                    style={{
                                      border: " 2px solid #8C8C8C",
                                      borderRadius: "10px",
                                      width: "100%",
                                      // display: "flex",
                                    }}
                                    className="d-none d-xl-flex"
                                  >
                                    <img
                                      src={Searchicon}
                                      style={{ margin: "5px" }}
                                    />
                                    <input
                                      type="text"
                                      style={{
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        border: "none",
                                        width: "100%",
                                        margin: "3px",
                                      }}
                                      value={inputValue}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <TableContainer>
                            <Table>
                              <TableHead
                                style={{ borderBottom: "0.6px solid #D9D9D9" }}
                              >
                                {props.header &&
                                  props.header.map((th, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{th.header1}</TableCell>
                                      <TableCell
                                        style={{
                                          display: "table-cell",
                                          verticalAlign: "middle",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "120px",
                                        }}
                                      >
                                        {th.header2}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          display: "table-cell",
                                          verticalAlign: "middle",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "120px",
                                        }}
                                      >
                                        {th.header3}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          display: "table-cell",
                                          verticalAlign: "middle",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "120px",
                                        }}
                                      >
                                        {th.header4}
                                      </TableCell>
                                      {props.item5 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header5}
                                        </TableCell>
                                      )}
                                      {props.item6 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header6}
                                        </TableCell>
                                      )}
                                      {props.item7 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header7}
                                        </TableCell>
                                      )}
                                      {props.item8 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "500px",
                                          }}
                                        >
                                          {th.header8}
                                        </TableCell>
                                      )}
                                      {props.item9 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header9}
                                        </TableCell>
                                      )}
                                      {props.item10 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header10}
                                        </TableCell>
                                      )}
                                      {props.item11 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header11}
                                        </TableCell>
                                      )}
                                      {props.item12 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header12}
                                        </TableCell>
                                      )}
                                      {props.item13 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header13}
                                        </TableCell>
                                      )}
                                      {props.item14 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header14}
                                        </TableCell>
                                      )}
                                      {props.item15 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "120px",
                                          }}
                                        >
                                          {th.header15}
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))}
                              </TableHead>
                              <TableBody>
                                {props?.mappedDataForTable
                                  ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .filter((row) => {
                                    // Convert all values to lowercase for case-insensitive search
                                    const lowerCaseSearchValue =
                                      inputValue?.toLowerCase();

                                    // Check if any column in the current row contains the search value
                                    return Object.values(row).some((value) =>
                                      value?.toString()
                                        ?.toLowerCase()
                                        .includes(lowerCaseSearchValue)
                                    );
                                  })
                                  .map((row, index) => (
                                    <TableRow
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0 ? "white" : "#F0F5FC",
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          display: "table-cell",
                                          verticalAlign: "middle",
                                          maxWidth: "120px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {row.item1}
                                        {/* {props.flag=="product"?row?.name:'------'} */}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          display: "table-cell",
                                          verticalAlign: "middle",
                                          maxWidth: "120px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {row.item2}
                                        {/* {props.flag=="product"?row?._id:'-------'} */}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          display: "table-cell",
                                          verticalAlign: "middle",
                                          maxWidth: "120px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {row.item3}
                                        {/* {props.flag=="product"?row.categoryId?.name:'--------'} */}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          display: "table-cell",
                                          verticalAlign: "middle",
                                          maxWidth: "120px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {row.item4}
                                        {/* kyu */}
                                      </TableCell>
                                      {props.item5 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            maxWidth: "120px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {row.item5}
                                          {/* kse ho */}
                                        </TableCell>
                                      )}
                                      {props.bill && (
                                        <TableCell>
                                          <div
                                            className=""
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              borderRadius: "50%",
                                              backgroundColor: "#C3C3C3",
                                              maxWidth: "120px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          ></div>
                                        </TableCell>
                                      )}
                                      {props.item6 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            maxWidth: "120px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {row.item6}
                                          {/* {props.flag=="product"?row?.color[0].sizes[0]['price']:'--------'} */}
                                        </TableCell>
                                      )}

                                      {props.item7 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            maxWidth: "120px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {row.item7}
                                          {/* {props.flag=="product"?row?.color[0].sizes[0]['stock']:'---------'} */}
                                        </TableCell>
                                      )}
                                      {props.item8 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            maxWidth: "120px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {row.item8}
                                          {props.recentOrders && (
                                            <select
                                              onChange={
                                                handleSelectrecentOrderChange
                                              }
                                              style={{
                                                display: "block",
                                                backgroundColor: "#E4EEF8",
                                                width: "100%",
                                                border: "none",
                                                borderRadius: 10,
                                                fontWeight: "600",
                                                fontSize: "15px",
                                              }}
                                              className="ps-2 py-2 mt-1"
                                            >
                                              <option
                                                value=""
                                                disabled
                                                className="d-none"
                                              >
                                                Order status
                                              </option>
                                              {recentOrder.map(
                                                (option, index) => (
                                                  <option
                                                    key={index}
                                                    value={option}
                                                    style={{
                                                      backgroundColor:
                                                        "#F5F5F5",
                                                    }}
                                                  >
                                                    {option}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          )}
                                        </TableCell>
                                      )}
                                      {props.item9 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            maxWidth: "120px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {row.item9}
                                        </TableCell>
                                      )}
                                      {props.item10 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            maxWidth: "120px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {row.item10}
                                        </TableCell>
                                      )}
                                      {props.item11 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            maxWidth: "120px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {row.item11}
                                        </TableCell>
                                      )}
                                      {props.item12 && (
                                        <TableCell
                                          style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                            maxWidth: "120px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {row.item12}
                                        </TableCell>
                                      )}

                                      {props.editMerchant && (
                                        <TableCell
                                          style={{
                                            cursor: "pointer",
                                            color: colors.tableEditColor,
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            handleClickPopupOpen(row.item0);
                                          }}
                                        >
                                          <div>
                                            <img
                                              style={{
                                                height: "15px",
                                                cursor: "pointer",
                                              }}
                                              src={editicon}
                                              alt="edit"
                                            />
                                          </div>
                                        </TableCell>
                                      )}
                                      {props.editProductStock && (
                                        <TableCell
                                          style={{
                                            cursor: "pointer",
                                            color: colors.tableEditColor,
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() =>
                                            handelEditOpen(row.item0)
                                          }
                                        >
                                          <div>
                                            <img
                                              style={{
                                                height: "15px",
                                                cursor: "pointer",
                                              }}
                                              src={editicon}
                                              alt="edit"
                                            />
                                          </div>
                                        </TableCell>
                                      )}
                                      {props.editShop && (
                                        <TableCell
                                          style={{
                                            cursor: "pointer",
                                            color: colors.tableEditColor,
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() =>
                                            handleClickShopPopupOpen(row.item0)
                                          }
                                        >
                                          <div>
                                            <img
                                              style={{
                                                height: "15px",
                                                cursor: "pointer",
                                              }}
                                              src={editicon}
                                              alt="edit"
                                            />
                                          </div>
                                        </TableCell>
                                      )}
                                      {props.redeye && (
                                        <TableCell
                                          style={{
                                            cursor: "pointer",
                                            color: colors.tableViewColor,
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() =>
                                            navigate(
                                              props.navigation + "/" + row.item0
                                            )
                                          }
                                        >
                                          <img
                                            src={redeye}
                                            style={{ width: "20px" }}
                                          />
                                        </TableCell>
                                      )}
                                      {props.trash && (
                                        <TableCell
                                          onClick={(event) => {
                                            setActive(row.item0);
                                            warningHandler();
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <img
                                            src={trashicon}
                                            style={{
                                              fontFamily: "InterSemiBold",
                                              display: "table-cell",
                                              verticalAlign: "middle",
                                              fontWeight: "500",
                                              fontSize: "16px",
                                              color: "#EC751F",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={props?.mappedDataForTable?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {popupOpen && (
        <div className={styles.popcontainer}>
          <div style={{ position: "relative", zIndex: "2000000000000000" }}>
            <MerchantEditForm
              editActive={editActive}
              fetchData={fetchData}
              closePopUp={() => PopUpclose("merchatnEditForm")}
              isbuttonVisible
            />
          </div>
        </div>
      )}
      {shopPopupOpen && (
        <div className={styles.popcontainer}>
          <div style={{ position: "relative", zIndex: "2000000000000000" }}>
            <ShopEditForm
              editActive={editActive}
              closePopUp={() => PopUpclose("shopEditForm")}
            />
          </div>
        </div>
      )}
      {editpopupOpen && (
        <div className={` ${styles.editpopcontainer}`}>
          <div style={{ position: "relative", zIndex: "1000000000000000000" }}>
            <NewProduct
              // productId={productId}
              // btnName={btnName}
              closePopUp={closePopUp}
            />
          </div>
        </div>
      )}
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "30%",
            top: "30%",
            zIndex: 1000000000,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to delete this record?"
          />
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default AllTable;
