import axios from "axios";
import endpoint from "./endpoints";

const axiosInstance = axios.create({
  baseURL: endpoint.baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: "",
  },
});

axiosInstance.interceptors.request.use(async function (config) {
    
  let user = JSON.parse(localStorage.getItem('userData'));
  let jwtToken = localStorage.getItem("jwtToken");
  console.log(jwtToken,"from instance")
  let refreshToken =localStorage.getItem("refreshToken");
  config.headers.Authorization = jwtToken ? jwtToken : "";
  config.headers.refreshToken = refreshToken ? refreshToken : "";
  // console.log(config.headers,"from header")
  return config;
});

export default axiosInstance;
