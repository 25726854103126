import React, { useEffect, useState } from "react";
// import Heading from "../../common/Heading";
// import NavBar from "../../common/NavBar";
import Sidebar from "../../common/SideBar";
import Card from "../../common/Card";
import Button from "../../common/Button";
import styles from "./Notification.module.css";
import NavBarEx from "../../common/NavBarEx";
// import CreateCouponform from "../../components/CreateCouponform";
import NotificationPopup from "../../components/NotificationPopup";
import WarningPop from "../../common/WarningPop";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export default function Notification() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  useEffect(() => {
    getNotificationForAdmin();
  }, []);
  const getNotificationForAdmin = async () => {
    const url =
      endpoints.baseUrl + "admin/" + endpoints.allNotificationForAdmin;
    console.log(url + "get all subadmin from api");
    try {
      const response = await axiosInstance.get(url);
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      // console.log(err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  // const popcontainer = {
  //   height: "50%",
  //   width: "30%",
  //   position: "absolute",
  //   top: "15%",
  //   left: "40%",
  //   // borderColor: 'red'
  //   // border: "5px solid ",
  //   backgroundColor: "#fff",
  // };
  const [data, setData] = useState([1, 1, 1]);
  const [open, setOpen] = useState(0);
  const [unreadCount, setUnreadCount] = useState(3); // Example count, replace with your actual count

  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const calculateXandY = (event) => {
    const clickX = event.clientX; // X coordinate of the click
    console.log(clickX, "click x value");
    console.log(window.innerWidth);
    setX(clickX);
    const clickY = event.clientY;
    setY(clickY);

    console.log("x coordinate", typeof clickX);
  };
  const deleteElement = (index, ar) => {
    for (let i = 0; i < ar.length; i++) {
      if (i < index) {
        console.log("delete index ->", i);
        continue;
      }
      ar[i] = ar[i + 1];
    }
    ar.pop();
    // data=[...ar]
    console.log(data);
    return ar;
  };
  const deleteHandler = (val) => {
    console.log("from delete handler", val);
    let ar = deleteElement(val, data);

    setData([...ar]);
    setWarning(0);
  };

  const showPopUp = (value) => {
    setOpen(value);
  };

  const closePopUp = () => {
    setOpen(0);
  };

  return (
    <>
      <div className={`container-fluid `}>
        <div className="row">
          <div
            className={`col-lg-2 col-md-3 ${styles.left} ${styles.sidePadding}`}
            style={open === 1 ? { filter: "blur(2px)" } : {}}
          >
            <Sidebar activeTabColor="Notification" unreadCount={unreadCount} />
          </div>

          <div
            className={`col-lg-10 col-md-9 col-sm-12s col-12 right pb-4 ${styles.bgbluecolor}`}
            style={open === 1 ? { filter: "blur(2px)" } : {}}
          >
            {loading && <PreLoader />}
            <div className="py-3">
              <NavBarEx
                search={true}
                buttonName={"Create Notification"}
                buttonFlag={true}
                popUp={showPopUp}
                siderBarButton
              />
            </div>
            <div className="">
              {data.map((item, index) => {
                return (
                  <div className="mt-3">
                    <Card padding={"3%"} height={"75px"} display="grid">
                      <div className="row align-items-center ">
                        <div className="col-lg-8 col-md-8 col-sm-6">
                          <div
                            style={{
                              paddingLeft: "2%",
                              fontFamily: "InterMedium",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            {item?.description}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 text-end">
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            {item?.data?.agentId && (
                              <Button
                                marginRight="25px"
                                marginLeft="0"
                                borderRadius={8}
                                width={"30%"}
                                name="View"
                                fontSize="12px"
                                onClick={() =>
                                  navigate(
                                    "/admin/specificMerchant/" +
                                      item?.data?.agentId
                                  )
                                }
                              />
                            )}

                            {/* <div
                              onClick={(event) => {
                                calculateXandY(event);
                                setActive(index);
                                warningHandler();
                              }}
                            >
                              <Button
                                marginRight="25px"
                                marginLeft="0"
                                borderRadius={8}
                                bgColor="#EC751F"
                                width={"30%"}
                                name="Reject"
                                fontSize="12px"
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {warning ? (
        <div
          style={{
            width: "35%",
            height: "50%",
            position: "absolute",
            left: "35%",
            top: "50%",
            // zIndex: 10,
          }}
        >
          <WarningPop
            delete={() => deleteHandler(active)}
            closePopUp={(val) => {
              warningHandler(val);
            }}
            message="Are you sure you want to accept the request?"
          />
        </div>
      ) : null}
      <div className={`${styles.popcontainer}`} hidden={!open ? true : false}>
        {open && <NotificationPopup closePopUp={closePopUp} />}
      </div>
      <ToastContainer />
    </>
  );
}
