import React, { useState, useEffect } from "react";
// import NavBar from '../../common/NavBar'
import Sidebar from "../../../common/SideBar";
import Card from "../../../common/Card";
import TableRow from "../../../components/Table/TableRow";
import rightArrow from "../../../Assets/rightArrow.png";
import NavBarEx from "../../../common/NavBarEx";
import colors from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import endpoints from "../../../utils/endpoints";
import axiosInstance from "../../../utils";
import PreLoader from "../../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../catalog/Catalog.module.css";
import CreateCatalog from "./CreateCatalog";

export default function CatalogList(props) {
  const [catalogList, setCatalogList] = useState([]);
  const fetchAllCatalog = async () => {
    setLoading(true)
    const url = endpoints.baseUrl + "admin/" + endpoints.getAllCatalogList;
    try {
      const response = await axiosInstance.get(url);
      const maxItems = 10;
      const catalogItems = response.data.data.slice(0, maxItems);
      setCatalogList(catalogItems);
      console.log("*******************", response.data.data);
    } catch (error) {
      // console.error("Error fetching products:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchAllCatalog();
  }, []);
  // const data = [1, 2, 3, 4, 5, 6, 7, 8];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(0);
  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
    fetchAllCatalog()
  };
  return (
    <>
      {/* <NavBar buttonName={"Create New Catalog"} buttonFlag={true} /> */}

      <div className={`container-fluid`}>
        <div className="row">
          {/* giving the grid of 2 to side nav */}
          <div
            className={`col-lg-2 col-md-3 pe-0 ${styles.left}`}
            style={open == 1 ? { filter: "blur(2px)" } : {}}
          >
            <Sidebar flag={true} activeTabColor="Catalog" />
          </div>

          <div
            className="col-lg-10 col-md-9 right"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: colors.bgcolor,
            }}
          >
            <div style={open == 1 ? { filter: "blur(2px)" } : {}}>
              {loading && <PreLoader />}
              <div className="py-3">
                <NavBarEx
                  buttonName={"Create Catalog"}
                  buttonFlag={true}
                  // popUp={showPopUp}
                  // changes
                  search={true}
                  siderBarButton
                  popUp={showPopUp}
                />
              </div>

              <div className="row pt-3">
                {catalogList.length > 0 ? (
                  catalogList.map((item, index) => {
                    return (
                      <div className="col-lg-12 col-md-12 mt-2 pb-2">
                        <div
                          className={styles.catalogHover}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                          }}
                          onClick={() => navigate("/agent/catalog/" + item._id)}
                        >
                          <Card
                            height={"50px"}
                            paddingLeft="2%"
                            paddingRight="2%"
                            display="grid"
                            bgColor={"none"}
                            borderBottomRightRadius={"none"}
                            borderBottomLeftRadius={"none"}
                            borderRadius={"none"}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ fontWeight: "600" }}>
                                {item?.name || "N/A"}
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate("/agent/catalog/" + item._id)
                                }
                              >
                                <img src={rightArrow} alt="right arrow" />
                              </div>
                            </div>
                          </Card>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ display: "flex" }}>No Catalog Present</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {open ? (
        <div className={` ${styles.popcontainer}`}>
          <div style={{ position: "relative", zIndex: "100" }}>
            <CreateCatalog closePopUp={closePopUp} />
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </>
  );
}
