import React, { useState, useEffect } from "react";
import NavBarEx from "../../common/NavBarEx";
import Sidebar from "../../common/SideBar";
import Backicon from "../../Assets/backicon.png";
import Heading from "../../common/Heading";
import Bellicon from "../../Assets/notification.svg";
import Questionicon from "../../Assets/Questionicon.png";
import Button from "../../common/Button";
import Card from "../../common/Card";
import Xicon from "../../Assets/x.png";
import styles from "./Coupon.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreLoader from "../../common/PreLoader";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const OrderDescription = () => {
  const [loading, setLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { id } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const [orderData, setOrdersByIDData] = useState([]);
  const [length, setLength] = useState(0);
  const [breadth, setBreadth] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [open, setOpen] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cancelOrder = async () => {
    try {
      let data = {
        item: {
          name: selectedRows[0]?.name,
          sku: selectedRows[0]?.productData?._id,
          _id: selectedRows[0]?._id,
          units: selectedRows[0]?.quantity,
          selling_price: selectedRows[0]?.costPerItem,
        },
      };
      const response = await axiosInstance.patch(
        endpoints.baseUrl + endpoints.cancelOrderShipment.replace(":id", id),
        data
      );
      console.log(response.data);
      fetchOrdersByIdData();
    } catch (error) {}
  };
  const columns = [
    {
      flex: 1,
      field: "details",
      headerName: "Product Details",
      sortable: false,
      minWidth: 300,
      renderCell: (params) => (
        <div>
          <div className="d-flex align-items-center">
            {params?.row?.productData?.color.length > 0 && (
              <div>
                <img
                  src={params?.row?.productData?.color[0].images[0]}
                  height={100}
                  width={80}
                />
              </div>
            )}
            <p
              className="ps-3 mb-0"
              style={{
                fontSize: "10px",
                width: "168px",
              }}
            >
              {params.row.name}
            </p>
          </div>
        </div>
      ),
    },
    { flex: 1, field: "pickUp", headerName: "Pickup Location", minWidth: 150 },
    {
      flex: 1,
      field: "trackingId",
      headerName: "Shiprocket Id/Refund Id",
      minWidth: 150,
    },
    {
      flex: 1,
      field: "pickUpAlias",
      headerName: "Pickup Alias",
      minWidth: 150,
    },
    {
      flex: 1,
      field: "costPerItem",
      type: "number",
      minWidth: 100,
      headerName: "Price",
    },
    {
      flex: 1,
      field: "quantity",
      type: "number",
      minWidth: 100,
      headerName: "Quantity",
    },
    {
      flex: 1,
      field: "subtoatal",
      headerName: "Subtotal",
      type: "number",
      minWidth: 100,
      valueGetter: (params) =>
        `${params.row.costPerItem * params.row.quantity}`,
    },
    {
      flex: 1,
      field: "status",
      minWidth: 100,
      headerName: "Status",
    },
  ];
  const [rows, setRows] = useState([]);
  useEffect(() => {
    fetchOrdersByIdData();
  }, []);

  const fetchOrdersByIdData = async () => {
    try {
      setLoading(true);
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + endpoints.ordersById + id
      );
      console.log(response.data.data);
      setRows(
        response.data.data.products.map((item, index) => {
          return {
            ...item,
            productData: item?.id,
            pickUp: item?.id?.pickUpId?.name,
            pickUpAlias: item?.id?.pickUpId?.shipRocketAlias,
            id: index + 1,
            createdAt: moment(item.createdAt),
            trackingId:
              item?.status === "cancelled" ? item?.refundId : item?.shipmentId,
          };
        })
      );
      setOrdersByIDData(response.data.data);
    } catch (error) {
      // Handle errors here
      console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      setLoading(false);
    }
  };

  const createShipment = async (e) => {
    try {
      e.preventDefault()
      if (height <= 0 || length <= 0 || breadth <= 0 || weight <= 0) {
        return toast.error("Box dimensions should be more than 0", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
      setIsSubmiting(true);
      let data = {
        length,
        breadth,
        height,
        weight,
        pickupLocation: selectedRows[0].pickUpAlias,
        items: selectedRows.map((item) => {
          return {
            name: item?.name,
            sku: item?.productData?._id,
            _id: item?._id,
            units: item?.quantity,
            selling_price: item?.costPerItem,
          };
        }),
      };

      const response = await axiosInstance.post(
        endpoints.baseUrl + endpoints.createOrderShipment.replace(":id", id),
        data
      );
      fetchOrdersByIdData();
    } catch (error) {
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    } finally {
      setIsSubmiting(false);
    }
  };
  return (
    <div>
      <div>{/* <NavBar /> */}</div>
      <div className="container-fluid">
        <div className=" row">
          <div className={` col-lg-2 col-md-3 pe-0 ${styles.left}`}>
            <Sidebar activeTabColor="Orders" />
          </div>
          <div
            className="col-lg-10 col-md-9 pt-3"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#EAF0FA",
            }}
          >
            {loading && <PreLoader />}
            <div>
              <NavBarEx />
            </div>
            <div className="row pt-4">
              <div
                className="col-lg-8 col-md-8 col-sm-8 col-8"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ alignSelf: "center" }}
                  onClick={() => navigate(-1)}
                >
                  <img
                    width={30}
                    height={30}
                    src={Backicon}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Heading
                  paddingLeft={"4%"}
                  fontSize={"24px"}
                  fontWeight={600}
                  name={orderData?.customer?.name}
                />
              </div>

              {/* <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    paddingLeft: "12%",
                    paddingRight: "12%",
                  }}
                >
                  <div style={{ alignSelf: "center" }}>
                    <img
                      width={30}
                      height={30}
                      src={Bellicon}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>
                    <img
                      width={30}
                      height={30}
                      src={Questionicon}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Button
                    width={"29%"}
                    marginLeft={"2%"}
                    marginRight={"0px"}
                    borderRadius={10}
                    name="Block"
                  />
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="pt-4">
                  <Card>
                    <div className="px-4 py-3 row ">
                      <div className="col-4">
                        <p>
                          <span style={{ fontWeight: "bold" }}>Order Id:</span>{" "}
                          #{orderData?._id}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
                          {orderData?.status}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Shipping address:
                          </span>
                        </p>
                        <p>
                          {`${orderData?.addressId?.houseNo}, ${orderData?.addressId?.street}, ${orderData?.addressId?.city}, ${orderData?.addressId?.state} - ${orderData?.addressId?.pincode}, Near ${orderData?.addressId?.landmark}`}
                        </p>
                      </div>
                      <div className="col-4">
                        <p style={{ fontWeight: "bold" }}>Bill details</p>
                        <p>Discount: &nbsp;{orderData?.discount}</p>
                        <p>Shipping charges: &nbsp;{orderData?.shippingAmt}</p>
                        <p>Total amount: &nbsp;{orderData?.amount}</p>
                      </div>
                      {orderData?.status === "PARTIALLY_DISPATCHED" ||
                      orderData?.status === "PLACED" ? (
                        <div className="col-4 text-end">
                          {selectedRows.length > 0 && (
                            <Button
                              name={"Create Shipment"}
                              width="fit-content"
                              onClick={() => {
                                if (selectedRows.length === 0) {
                                  return toast.error(
                                    "Please slect the product to be shipped",
                                    {
                                      position: toast.POSITION.TOP_RIGHT,
                                      autoClose: 5000, // Set the duration for how long the popup should be visible
                                    }
                                  );
                                }
                                let pickups = [
                                  ...new Set(
                                    selectedRows.map(
                                      (item) => item?.pickUpAlias
                                    )
                                  ),
                                ];
                                if (pickups.length > 1) {
                                  return toast.error(
                                    "Please select products with same pickup location",
                                    {
                                      position: toast.POSITION.TOP_RIGHT,
                                      autoClose: 5000, // Set the duration for how long the popup should be visible
                                    }
                                  );
                                }
                                handleOpen();
                              }}
                            />
                          )}
                          {selectedRows.length === 1 && (
                            <Button
                              name={"Cancel"}
                              className={"mx-2"}
                              width="fit-content"
                              onClick={() => {
                                if (selectedRows.length === 0) {
                                  return toast.error(
                                    "Please slect the product to be cancelled",
                                    {
                                      position: toast.POSITION.TOP_RIGHT,
                                      autoClose: 5000, // Set the duration for how long the popup should be visible
                                    }
                                  );
                                }
                                if (selectedRows.length > 1) {
                                  return toast.error(
                                    "Only one product can be cancelled at a time",
                                    {
                                      position: toast.POSITION.TOP_RIGHT,
                                      autoClose: 5000, // Set the duration for how long the popup should be visible
                                    }
                                  );
                                }
                                cancelOrder();
                              }}
                              bgColor={"tomato"}
                            />
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="pt-3">
                      <Card bgColor="#fff">
                        <div className="px-4 py-3" style={{ height: 400 }}>
                          {/* <div className="row align-items-center">
                              <div className="col-5 pt-4">
                                <p>Product Details</p>
                              </div>
                              <div className="col-2">
                                <p className=" text-center">Price</p>
                              </div>
                              <div className="col-2">
                                <p className=" text-center">Quantity</p>
                              </div>
                              <div className="col-2">
                                <p>Subtotal</p>
                              </div>
                            </div>
                            {orderData?.products?.map((item, index) => (
                              <div
                                className="row align-items-center"
                                key={index}
                              >
                                <hr></hr>
                                <div className="col-5 pt-4">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <img src={Bellicon} />
                                    </div>
                                    <p
                                      className="ps-3 mb-0"
                                      style={{
                                        fontSize: "10px",
                                        width: "168px",
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="d-flex justify-content-around">
                                    <p>{item.costPerItem}</p>
                                    <div
                                      style={{
                                        width: "10%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <img src={Xicon} />
                                    </div>
                                    <p>{item.quantity}</p>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <p className="mb-0">
                                    {item.costPerItem * item.quantity}
                                  </p>
                                </div>
                              </div>
                            ))} */}
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                              },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection
                            style={{ border: "none" }}
                            onRowSelectionModelChange={(ids) => {
                              let data = rows.filter((row) =>
                                ids.includes(row.id)
                              );
                              console.log(data);
                              setSelectedRows(data);
                            }}
                            isRowSelectable={(params) =>
                              !params?.row?.shipmentId &&
                              params?.row?.status !== "cancelled"
                            }
                          />
                        </div>
                      </Card>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={(e) => createShipment(e)}>
              <Heading name="Shipment Details" paddingLeft="1%" />
              <div className="row">
                <div className="col">
                  <label
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      display: "block",
                      textAlign: "start",
                    }}
                  >
                    Length(in cm)
                  </label>
                  <input
                    style={{
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="mt-2 ps-3 py-2 d-block"
                    type="number"
                    required
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      display: "block",
                      textAlign: "start",
                    }}
                  >
                    Breadth(in cm)
                  </label>
                  <input
                    style={{
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="mt-2 ps-3 py-2 d-block"
                    type="number"
                    required
                    value={breadth}
                    onChange={(e) => setBreadth(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      display: "block",
                      textAlign: "start",
                    }}
                  >
                    Height(in cm)
                  </label>
                  <input
                    style={{
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="mt-2 ps-3 py-2 d-block"
                    type="number"
                    required
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                      display: "block",
                      textAlign: "start",
                    }}
                  >
                    Weight(in kg)
                  </label>
                  <input
                    style={{
                      backgroundColor: "#F3F1F1",
                      width: "100%",
                      border: "none",
                      borderRadius: 10,
                    }}
                    className="mt-2 ps-3 py-2 d-block"
                    type="number"
                    required
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </div>
              </div>

              {!isSubmiting && (
                <div
                  className=" text-center"
                  style={{ paddingTop: "10px", marginRight: 10 }}
                >
                  <Button name="Submit" type="submit" />
                </div>
              )}
            </form>
          </Box>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OrderDescription;
