import React, { useEffect, useState } from "react";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
import Button from "../../common/Button";
import { CustomLineChart } from "../../common/CustomLineChart";
import DropDown from "../../common/DropDown";
import dropimg from "../../Assets/dropimgwhite.svg";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
const SalesGraph = (props) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const graphoptions = ["Yearly", "Monthly", "Weekly"];
  const [graphoption, setgraphoption] = useState("Monthly");
  // const [salesrevenue, setSalesRevenue] = useState();
  // const fetchDataSalesRvenue = async () => {
  //   try {
  //     const response = await axiosInstance.get(
  //       endpoints.baseUrl + "admin/" + endpoints.salesRevnue
  //     );
  //     setSalesRevenue(response.data.data);
  //     // console.log("sales revenue api");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  function fetchTotalAmountsForYear(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const totalAmount = item.totalAmount;

      yearsArray.push(year);
      totalAmountsArray.push(totalAmount);
    }
    // if (yearsArray.length < 2) {

    //   yearsArray.push(2024);
    //   yearsArray.push(2025);
    //   yearsArray.push(2026);
    // }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForMonth(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const month = item._id.month;
      const totalAmount = item.totalAmount;

      yearsArray.push(`${months[month] - 1} ${year}`);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  function fetchTotalAmountsForWeekly(data) {
    const yearsArray = [];
    const totalAmountsArray = [];

    for (const item of data) {
      const year = item._id.year;
      const week = item._id.week;
      const totalAmount = item.totalAmount;

      yearsArray.push(`week ${week} of ${year}`);
      totalAmountsArray.push(totalAmount);
    }

    return {
      x: yearsArray,
      y: totalAmountsArray,
    };
  }
  const [amountsForGraph, setAmountsForGraph] = useState();
  const fetchDataFroGraph = async (key) => {
    try {
      if (key == "Yearly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
            "admin/" +
            endpoints.buisnessGraph +
            "sale-trends?agent=" +
            props.id +
            `&groupBy=yearly`
        );
        const amounts = fetchTotalAmountsForYear(response.data.data);
        console.log(amounts, "*****************************");
        setAmountsForGraph(amounts);
      }
      if (key == "Monthly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
            "admin/" +
            endpoints.buisnessGraph +
            "sale-trends?agent=" +
            props.id +
            "&groupBy=monthly"
        );
        // setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForMonth(response.data.data);
        setAmountsForGraph(amounts);
      }
      if (key == "Weekly") {
        const response = await axiosInstance.get(
          endpoints.baseUrl +
            "admin/" +
            endpoints.buisnessGraph +
            "sale-trends?agent=" +
            props.id +
            "&groupBy=weekly"
        );
        // setGraphData(response.data.data);
        const amounts = fetchTotalAmountsForWeekly(response.data.data);
        setAmountsForGraph(amounts);
      }
    } catch (error) {
      // toast.error("Internal Server Error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 5000, // Set the duration for how long the popup should be visible
      // });
    }
  };
  useEffect(() => {
    fetchDataFroGraph(graphoption);
  }, [graphoption]);

  const handleSelectgraphoptionChange = (event) => {
    const graphoptionValue = event.target.value;
    //  fetchDataFroGraph(graphoptionValue);
    setgraphoption(graphoptionValue);
    console.log("graphoption:", graphoptionValue);
  };
  return (
    <div>
      <Card>
        <div
          className="row  align-items-center pt-3"
          style={{ paddingLeft: "4%" }}
        >
          <div className=" col-8">
            <Heading name="Orders vs Sales" fontSize="24px" fontWeight="600" />
          </div>
          {/* <div className="ps-4">
            <DropDown
              top={"Weekly"}
              items={item1}
              bg={"#4F81C3"}
              zIndex="0"
              textcolor={"white"}
              img={dropimg}
            />
          </div> */}
          <div className=" col-3">
            <select
              value={graphoption}
              onChange={handleSelectgraphoptionChange}
              style={{
                display: "block",
                backgroundColor: "#4F81C3",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
                color: "#fff",
                fontWeight: "600",
                fontSize: "15px",
              }}
              className="ps-2 py-2 mt-1 "
            >
              <option value="" disabled className="d-none ">
                Weekly
              </option>
              {graphoptions.map((option, index) => (
                <option
                  key={index}
                  value={option}
                  style={{ backgroundColor: "#F5F5F5", color: "black" }}
                >
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="px-3 py-1">
          <div className="row align-items-center">
            <div className="col">
              <CustomLineChart
                amountsForGraph={amountsForGraph}
                height="240px"
              />
            </div>
            {/* <div className="col-lg-3">
              <div className="row  flex-lg-column row align-items-center">
                <div className="col-6 col-lg-12 d-sm-flex d-md-block align-items-center justify-content-evenly">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#CBADAD",
                      }}
                    ></div>
                    <p
                      className="mb-0 ps-3"
                      style={{ fontWeight: "600", fontSize: "18px" }}
                    >
                      Shop mart
                    </p>
                  </div>
                  <div className="d-flex align-items-center pt-3 pt-sm-0 pt-md-3 pt-lg-0">
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#CBADAD",
                      }}
                    ></div>
                    <p
                      className="mb-0 ps-3"
                      style={{ fontWeight: "600", fontSize: "18px" }}
                    >
                      Shop mart
                    </p>
                  </div>
                </div>
                <div className="col-6 col-lg-12 d-sm-flex d-md-block align-items-center justify-content-evenly">
                  <p
                    className="ps-4 "
                    style={{ fontWeight: "600", fontSize: "18px" }}
                  >
                    Growth
                  </p>
                  <p
                    className="ps-4 "
                    style={{
                      fontWeight: "600",
                      fontSize: "32px",
                      color: "#EC751F",
                    }}
                  >
                    43%
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SalesGraph;
