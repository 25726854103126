import React, { useState } from "react";
import { MdClose } from "react-icons/md";

const TextInputWithBadges = ({ value = [], onChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [badges, setBadges] = useState(value);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddBadge = (e) => {
    e.preventDefault();
    const values = inputValue.split(",").map((value) => value.trim());
    const filteredValues = values.filter((value) => value !== "");
    let tmp = [...badges, ...filteredValues];
    setBadges(tmp);
    onChange(tmp);
    setInputValue("");
  };

  const handleRemoveBadge = (badge) => {
    let tmp = badges.filter((item) => item !== badge);
    setBadges(tmp);
    onChange(tmp);
  };

  return (
    <div className="my-2 px-3" style={styles.container}>
      <form className="row">
        <input
          className="col-9"
          style={styles.input}
          value={inputValue}
          onChange={handleInputChange}
        // placeholder="Enter values (comma-separated)"
        />
        <div className="col-1" />
        <button
          className="col-2"
          style={styles.addButton}
          onClick={handleAddBadge}
        >
          Add
        </button>
      </form>
      <div style={styles.badgesContainer}>
        {badges.map((badge, index) => (
          <div
            key={index}
            style={styles.badge}
            onClick={() => handleRemoveBadge(badge)}
          >
            <span>{badge}</span>
            <MdClose />
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    // padding: 16,
  },
  input: {
    backgroundColor: "#F3F1F1",
    // width: "100%",
    border: "none",
    borderRadius: 10,
    padding: 10,
  },
  addButton: {
    backgroundColor: "#4F81C3",
    padding: 10,
    borderRadius: 10,
    color: "white",
    fontWeight: "bold",
    // marginBottom: 10,
    border: "none",
  },
  badgesContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  badge: {
    backgroundColor: "#4F81C3",
    padding: "6px 12px",
    borderRadius: 16,
    margin: 4,
    color: "white",
    fontSize: 16,
    cursor: "pointer",
  },
};

export default TextInputWithBadges;
