import { useEffect, useState } from "react";
import Backicon from "../Assets/backicon.png";
import Button from "../common/Button";
import axiosInstance from "../utils";
import endpoints from "../utils/endpoints";

const NotificationPopup = (props) => {
  const [showButtons, setShowButtons] = useState(0);
  const [userType, setuserType] = useState("all");
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");

  const handleSubmit = (val) => {
    console.log(val);
    // e.preventDefault();
    if (val == 1) {
      setShowButtons(2);
    }
    if (val == 0) {
      setShowButtons(1);
    }
  };
  const handleBack = (val) => {
    console.log(val);
    // e.preventDefault();
    if (val == 1) {
      setShowButtons(0);
    }
    if (val == 2) {
      setShowButtons(1);
    }
  };

  const createNotifications = async () => {
    const result = await axiosInstance.post(
      endpoints.baseUrl + "admin/" + endpoints.createNotification,
      {
        title,
        description,
        userType,
      }
    );
    console.log(result);
    if (result.data.code == 200) {
      alert("Notification Created");
      props.closePopUp();
    } else {
      alert("Please try again later.");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #4F81C3",
        // paddingTop: "40px",
        height: "100%",
        // boxSizing: "border-box",
        // overflow: "hidden",
      }}
    >
      {showButtons == 0 && (
        <div
          className="px-4 py-3 "
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <img
            style={{ cursor: "pointer" }}
            src={Backicon}
            onClick={() => {
              props.closePopUp();
            }}
          />
          {/* <p className="pt-3 text-center">Shop preference</p> */}
          <div className="col-12 pt-4">
            <select
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
              }}
              className="py-2 mt-2"
              placeholder="Lorem ipsum"
              onChange={(e) => {
                setuserType(e.target.value);
              }}
            >
              <option value={"all"}>All</option>
              <option value={"user"}>User</option>
              <option value={"agent"}>Agent</option>
            </select>
          </div>
          <div className="pt-2 text-center">
            <input
              placeholder="Title"
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
                padding: "5px",
                resize: "none",
                // height: "150px",
              }}
              onChange={(e) => {
                settitle(e.target.value);
              }}
            />
          </div>
          <div className="pt-2 text-center">
            <textarea
              placeholder="Write a description"
              style={{
                display: "block",
                backgroundColor: "#F3F1F1",
                width: "100%",
                display: "block",
                border: "none",
                borderRadius: 10,
                paddingLeft: "5px",
                resize: "none",
                height: "150px",
              }}
              draggable={false}
              onChange={(e) => {
                setdescription(e.target.value);
              }}
            />
          </div>
          <div
            className="mt-2 text-center"
            onClick={() => {
              // handleSubmit(0);
              createNotifications();
            }}
          >
            <Button name="submit" width="20%" borderRadius="10px" />
          </div>
        </div>
      )}
      {/* {showButtons == 1 && (
        <div className="px-5 py-3" style={{ width: "420px" }}>
          <div>
            <img
              src={Backicon}
              onClick={() => {
                handleBack(1);
              }}
            />
            <p
              className="text-center pt-4"
              style={{ fontWeight: 500, fontSize: "18px" }}
            >
              Want to send notification to?
            </p>
            <div className="d-flex justify-content-around pt-4">
              <Button name="Agent" width="30%" borderRadius="10px" />
              <Button name="Customer" width="30%" borderRadius="10px" />
            </div>
            <div
              className="mt-5 text-center"
              onClick={() => {
                handleSubmit(1);
              }}
            >
              <Button name="submit" width="40%" borderRadius="10px" />
            </div>
          </div>
        </div>
      )} */}
      {/* {showButtons == 2 && (
        <div className="px-5 py-3" style={{ width: "420px" }}>
          <img
            src={Backicon}
            onClick={() => {
              handleBack(2);
            }}
          />
          <div className="pt-5 text-center">
            <textarea
              placeholder="Write a description"
              style={{ border: "1px solid #000000", width: "80%" }}
            />
          </div>
          <div
            className="mt-5 text-center"
            onClick={() => {
              handleSubmit(2);
            }}
          >
            <Button name="submit" width="20%" borderRadius="10px" />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default NotificationPopup;
