import React, { useEffect, useState } from "react";
import Heading from "../../common/Heading";
import Card from "../../common/Card";

const TopOrderTable = (props) => {
  const data = [
    {
      name: "Rahul Sharma",
      value1: 1124,
      value2: "10/12/2022",
      value3: 4200,
      value4: 6,
      value5: 25850,
    },
    {
      name: "Rahul Sharma",
      value1: 1124,
      value2: "10/12/2022",
      value3: 4200,
      value4: 6,
      value5: 25850,
    },
    {
      name: "Rahul Sharma",
      value1: 1124,
      value2: "10/12/2022",
      value3: 4200,
      value4: 6,
      value5: 25850,
    },
    {
      name: "Rahul Sharma",
      value1: 1124,
      value2: "10/12/2022",
      value3: 4200,
      value4: 6,
      value5: 25850,
    },
    {
      name: "Rahul Sharma",
      value1: 1124,
      value2: "10/12/2022",
      value3: 4200,
      value4: 6,
      value5: 25850,
    },
    // Add more objects for each row...
  ];
  return (
    <div>
      <Card height={"29vh"}>
        <div>
          <div className="pt-3">
            <Heading name="Top orders" paddingLeft="4%" />
          </div>
          <div
            className="table-responsive pt-2 "
            style={{ overflowX: "auto", paddingLeft: "4%" }}
          >
            <table className="table table-borderless">
              <thead>
                <tr
                  style={
                    {
                      // backgroundColor: "#DEE9F8",
                    }
                  }
                >
                  <th
                    scope="col"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "80px",
                    }}
                  >
                    Product name
                  </th>
                  <th
                    scope="col"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "80px",
                    }}
                  >
                    Product id
                  </th>
                  <th
                    scope="col"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "80px",
                    }}
                  >
                    Date added
                  </th>
                  <th
                    scope="col"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "80px",
                    }}
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "80px",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "80px",
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.totalOrdersData.map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                      borderBottom: "1px solid #EBEBEB",
                    }}
                  >
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "80px",
                      }}
                    >
                      {row.products[0]?.name
                        ? row.products[0]?.name
                        : "no-name"}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "80px",
                      }}
                    >
                      {row.products[0]?._id ? row.products[0]?._id : "empty"}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "80px",
                      }}
                    >
                      {"empty"}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "80px",
                      }}
                    >
                      {row.products[0]?.costPerItem
                        ? row.products[0]?.costPerItem
                        : "empty"}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "80px",
                      }}
                    >
                      {row.products[0]?.quantity
                        ? row.products[0]?.quantity
                        : "empty"}
                    </td>
                    <td
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                      }}
                    >
                      {row?.amount ? row?.amount : 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TopOrderTable;
