import React, { useState, useEffect } from "react";
import Sidebar from "../../common/SideBar";
import { CustomLineChart } from "../../common/CustomLineChart";
import { CustomCircularChart } from "../../common/CircularChart";
import Card from "../../common/Card";
import Heading from "../../common/Heading";
import Styles from "./Shop.module.css";
import NavBarEx from "../../common/NavBarEx";
// import Button from "../../common/Button";
import ShopListTable from "../../components/Shop/ShopListTable";
import ShopForm from "../../components/Shop/ShopForm";
import DropDown from "../../common/DropDown";
import AllTable from "../../components/AllTable";
import dropimg from "../../Assets/dropimg.png";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// pop-up done
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import { BarChart } from "../../common/BarChart";
export default function Shop(props) {
  const [dataOfAllShops, setdataOfAllShops] = useState([]);
  const [topSalesData, setTopsalesdata] = useState([]);
  const getData = async () => {
    try {
      setLoading(true);
      topShopsForGraph();
      getFiveShops();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  function fetchShopVsSalesArray(data) {
    const sales = [];
    const shops = [];
    data = data.filter((item) => item.shop.name != null);

    for (const item of data) {
      const sale = item.totalSales;
      const shop = item.shop._id != null ? item.shop.name : "-";

      sales.push(sale);
      shops.push(shop);
    }

    return {
      x: shops,
      y: sales,
    };
  }

  const [amountsForGraph, setAmountsForGraph] = useState();
  const [shopNames, setShopNames] = useState([]);

  const topShopsForGraph = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.shopWiseSale
      );

      const recievedarray = fetchShopVsSalesArray(response.data.data);
      setAmountsForGraph(recievedarray);
      setShopNames(response.data.data);

      console.log(recievedarray);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const getFiveShops = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.allShops
      );
      setdataOfAllShops(response.data);
      // console.log("5 Shops api");
      // console.log(response.data.data);
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };

  const [open, setOpen] = useState(0);

  const showPopUp = (value) => {
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };
  const headerdata = [
    {
      header1: "Shop name",
      header2: "Owner ID",
      header3: "Owner Name",
      header4: "No. of agent",
      header5: "Total orders",
      header6: "Total sales",
      header7: "Edit",
      header8: "Delete",
      header9: "View",
    },
  ];
  const [loading, setLoading] = useState(true);
  const graphoptions = ["Yearly", "Monthly", "Weekly"];
  const [graphoption, setgraphoption] = useState("");
  const handleSelectgraphoptionChange = (event) => {
    const graphoptionValue = event.target.value;
    //  fetchDataFroGraph(graphoptionValue);
    setgraphoption(graphoptionValue);
    console.log("graphoption:", graphoptionValue);
  };
  return (
    <div>
      <div className="container-fluid  position-relative">
        <div className="row">
          {/* sidebar */}
          <div
            className={` col-lg-2 col-md-3 pe-0  ${Styles.left}`}
            style={open == 1 ? { filter: "blur(2px)" } : {}}
          >
            <Sidebar activeTabColor="Shops" />
          </div>
          <div
            className="col-lg-10 col-md-9 col-12 pb-4"
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#EAF0FA",
            }}
          >
            {loading && <PreLoader />}
            <div style={open == 1 ? { filter: "blur(2px)" } : {}}>
              {/* navbar */}
              <div className="py-3">
                <NavBarEx
                  search={true}
                  buttonName={"Add new Shop"}
                  buttonFlag={true}
                  popUp={showPopUp}
                  siderBarButton
                />
              </div>
              <div className="row pt-3">
                {/* line-chart */}
                <div className="col-lg-7 col-md-6 col-sm-6 col-12">
                  <Card width="100%" height="100%">
                    <div className="row align-items-center pt-3">
                      <div className="">
                        <Heading
                          paddingLeft={"4%"}
                          fontSize={"24px"}
                          fontWeight={600}
                          name="Shops Vs Sales"
                        />
                      </div>
                      {/* <div className="ps-3">
                        <DropDown
                          top="Last month"
                          items={item2}
                          bg={"#E4EEF8"}
                          img={dropimg}
                        />
                      </div> */}
                      {/* <div className="pe-3">
                        <select
                          value={graphoption}
                          onChange={handleSelectgraphoptionChange}
                          style={{
                            display: "block",
                            backgroundColor: "#F5F5F5",
                            width: "100%",
                            display: "block",
                            border: "none",
                            borderRadius: 10,
                          }}
                          className="ps-2 py-2 mt-1 add-new-agent"
                        >
                          <option value="" disabled className="d-none">
                            Weekly
                          </option>
                          {graphoptions.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                    {/* <CustomLineChart amountsForGraph={amountsForGraph} height={"84%"} /> */}
                    <div className="px-5">
                      <BarChart amountsForGraph={amountsForGraph} />
                    </div>
                  </Card>
                </div>
                {/* top-shop-maximum */}
                <div
                  className={`col-lg-5 col-md-6 col-sm-6 col-12 pt-3 pt-sm-0`}
                  style={{ boxSizing: "border-box" }}
                >
                  <Card
                    width="100%"
                    height={"100%"}
                    paddingTop={"3%"}
                    paddingBottom={"4%"}
                    paddingLeft={"3%"}
                    paddingRight={"3%"}
                  >
                    <Heading
                      paddingLeft={"4%"}
                      fontSize={"24px"}
                      fontWeight={600}
                      name="Top shops with maximum sales"
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "80%",
                        boxSizing: "border-box",
                        objectFit: "contain",
                      }}
                    >
                      <div style={{ width: "50%", height: "82%" }}>
                        <CustomCircularChart data={amountsForGraph?.y} />
                      </div>
                      <div style={{ width: "50%" }}>
                        {shopNames.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex align-items-center mt-1"
                            >
                              <div
                                className={`${Styles.circle} `}
                                style={{ backgroundColor: "#036DCF" }}
                              ></div>
                              <p
                                className="mb-0 mx-1 "
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                {item.shop._id != null
                                  ? item.shop.name
                                  : "others"}{" "}
                                :
                                <span style={{ marginLeft: "8px" }}>
                                  {item.orderCount} orders
                                </span>
                              </p>
                            </div>
                          );
                        })}
                        {/* <div className="d-flex align-items-center mt-1">
                          <div
                            className={`${Styles.circle} `}
                            style={{ backgroundColor: "#EC751F" }}
                          ></div>
                          <p
                            className="mb-0 mx-1"
                            style={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Shop mart
                            <span style={{ marginLeft: "8px" }}>50%</span>
                          </p>
                        </div>
                        <div className="d-flex align-items-center mt-1">
                          <div
                            className={`${Styles.circle} `}
                            style={{ backgroundColor: "#E4EEF8" }}
                          ></div>
                          <p
                            className="mb-0 mx-1"
                            style={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Shop mart
                            <span style={{ marginLeft: "8px" }}>50%</span>
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </Card>
                </div>
                {/* shop-list */}
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                  <ShopListTable header={headerdata} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.popcontainer} hidden={!open ? true : false}>
          {open && <ShopForm closePopUp={closePopUp} />}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
