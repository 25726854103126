import React, { useEffect, useState } from "react";
import Heading from "../../common/Heading";
import styles from "./TopAgent.module.css";
import "../../common/Fonts.css";
import CommonTable from "../../common/CommonTable";
import endpoints from "../../utils/endpoints";
import axiosInstance from "../../utils";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TopAgent = () => {
  const [dataFromApi, setdataFromApi] = useState([]);
  const fetchData = async () => {
    try {
      // Make a GET request to the API
      const response = await axiosInstance.get(
        endpoints.baseUrl + "admin/" + endpoints.topagents
      );
      setdataFromApi(response.data.data.filter((item)=>item?.agent?._id));
      // console.log(response.data.data)
    } catch (error) {
      // Handle errors here
      // console.error(error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const data = [
    {
      item1: "Rahul Sharma",
      item2: "Tradersxx",
      item3: "58",
      item4: "72000",
    },
    {
      item1: "Rahul Sharma",
      item2: "Tradersxx",
      item3: "58",
      item4: "72000",
    },
    {
      item1: "Rahul Sharma",
      item2: "Tradersxx",
      item3: "58",
      item4: "72000",
    },
    {
      item1: "Rahul Sharma",
      item2: "Tradersxx",
      item3: "58",
      item4: "72000",
    },
    {
      item1: "Rahul Sharma",
      item2: "Tradersxx",
      item3: "58",
      item4: "72000",
    },
  ];
  const headerdata = [
    {
      header1: "Name",
      header2: "Shop",
      header3: "Total Orders",
      header4: "Total sales",
    },
  ];
  return (
    <div>
      <div className="pb-2">
        <Heading
          fontSize="24px"
          fontWeight="600"
          name="Top Merchants"
          fontFamily={"InterSemiBold"}
        />
      </div>
      <div
        className={`table-responsive ${styles.tablecontainer}`}
        style={{ paddingTop: "15px" }}
      >
        <table className={`table table-borderless ${styles.table}`}>
          <thead>
            <tr>
              <th style={{ display: "table-cell", verticalAlign: "middle" }}>
                Name
              </th>
              <th style={{ display: "table-cell", verticalAlign: "middle" }}>
                Shop
              </th>
              <th
                style={{ display: "table-cell", verticalAlign: "middle" }}
                scope="col-4"
              >
                Total Orders
              </th>
              <th style={{ display: "table-cell", verticalAlign: "middle" }}>
                Total Sales
              </th>
            </tr>
          </thead>
          <tbody>
            {dataFromApi.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#F0F5FC",
                  borderBottom: "1px solid #EBEBEB",
                }}
              >
                <td style={{ display: "table-cell", verticalAlign: "middle" }}>
                  <div
                    title={item.item1}
                    style={{
                      maxWidth: "100px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.agent?.name ? item.agent?.name : "----"}
                  </div>
                </td>
                <td style={{ display: "table-cell", verticalAlign: "middle" }}>
                  <div
                    title={item.item2}
                    style={{
                      maxWidth: "100px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    className="mx-auto"
                  >
                    {item.agent?.shop?.name ? item.agent?.shop?.name : "----"}
                  </div>
                </td>
                <td style={{ display: "table-cell", verticalAlign: "middle" }}>
                  <div
                    title={item.item3}
                    style={{
                      maxWidth: "100px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    className="mx-auto"
                  >
                    {item.orderCount ? item.orderCount : "----"}
                  </div>
                </td>
                <td style={{ display: "table-cell", verticalAlign: "middle" }}>
                  <div
                    title={item.item4}
                    style={{
                      maxWidth: "100px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    className="mx-auto"
                  >
                    {item.totalSales ? item.totalSales?.toFixed(2) : "----"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div>
        <CommonTable data={data} header={headerdata}/>
      </div> */}
      <ToastContainer />
    </div>
  );
};

export default TopAgent;
