import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "./Button";
import uploadicon from "../Assets/Upload to the Cloud.svg";
import Styles from "./DragDrop.module.css";
import { MdDelete } from "react-icons/md";

export default function DragDrop({ value, onChange, multiple = true }) {
  // const [files, setfiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    let tmp = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    // let newfiles = multiple ? [...value, ...tmp] : tmp;
    let newfiles = value;
    if (multiple) {
      newfiles.push(...tmp);
    } else {
      newfiles = tmp;
    }
    console.log(newfiles);
    // setfiles(newfiles);
    onChange(newfiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    multiple,
  });

  const removeImage = (item) => {
    // let tmp = files;
    let tmp = value;
    tmp.splice(item, 1);
    // setfiles(tmp);
    onChange(tmp);
  };
  const removeAllImages = () => {
    // setfiles([]);
    onChange([]);
  };

  return (
    <section
      className="p-5"
      style={{
        width: "100%",
        textAlign: "center",
        borderWidth: "2px",
        borderStyle: "dashed",
        borderRadius: 25,
      }}
    >
      <div className="my-2" {...getRootProps()}>
        <input {...getInputProps()} />
        <div>
          <img src={uploadicon} height={100} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
      </div>
      {value.length > 0 && (
        <>
          <div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              {value.map((file, index) => (
                <div key={index} className={`${Styles.imagecontainer}`}>
                  <img
                    key={index}
                    className="m-1"
                    src={file.preview}
                    style={{
                      objectFit: "cover",
                      height: 100,
                      width: 100,
                      borderRadius: 25,
                    }}
                  />
                  <div
                    className={`${Styles.imageoverlay}`}
                    onClick={() => removeImage(index)}
                  >
                    <span className={`${Styles.deleteicon}`}>
                      <MdDelete />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button
            style={{
              backgroundColor: "#ea4335",
              padding: 10,
              borderRadius: 10,
              color: "white",
              fontWeight: "bold",
              // marginBottom: 10,
              border: "none",
              width: "25%",
            }}
            width="25%"
            borderRadius="5px"
            onClick={() => {
              removeAllImages();
            }}
            className="mt-3"
          >
            Clear
          </button>
        </>
      )}
    </section>
  );
}
