import React, { useEffect, useState } from "react";
import Sidebar from "../../common/SideBar";
import TableRow from "../../components/Table/TableRow";
import ProductStyles from "../agent/supplier/Product.module.css";
import dustbin from "../../Assets/icon_trash.png";
import SubAdminPopup from "../../components/SubAdminPopup";
import styles from "./SubAdmin.module.css";
import NavBarEx from "../../common/NavBarEx";
import WarningPop from "../../common/WarningPop";
import axiosInstance from "../../utils";
import endpoints from "../../utils/endpoints";
import PreLoader from "../../common/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SubAdmin() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 1000);
  }, []);
  const [open, setOpen] = useState(0);
  const [active, setActive] = useState(-1);
  const [warning, setWarning] = useState(false);
  const [data, setData] = useState([1, 20, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [getDataOfSubAdmin, setGetDataOfSubAdmin] = useState([]);

  const deleteSubAdmin = async (subAdminId) => {
    const url = endpoints.baseUrl+"admin/" + endpoints.deleteAdmin + subAdminId; // Adjust the endpoint for delete API
    try {
      const response = await axiosInstance.delete(url);
      console.log("Delete response:", response.data);
    } catch (error) {
      // console.log("Delete error:", err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const deleteHandler = async (val) => {
    console.log("from delete handler", val);
    try {
      // Get the _id of the subadmin to be deleted
      const subAdminId = getDataOfSubAdmin[val]._id;

      // Make the delete API call
      await deleteSubAdmin(subAdminId);

      // Remove the subadmin from the local state
      const newData = getDataOfSubAdmin.filter((item, index) => index !== val);
      setGetDataOfSubAdmin(newData);
    } catch (error) {
      // console.log("Delete Handler Error:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }

    setWarning(0);
  };

  useEffect(() => {
    getAllSubAdminFromApi();
  }, [open]);
  const getAllSubAdminFromApi = async () => {
    const url = endpoints.baseUrl + "admin/" + endpoints.allSubadmin;
    console.log(url + "get all subadmin from api");
    try {
      const response = await axiosInstance.get(url);
      setGetDataOfSubAdmin(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      // console.log(err);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Set the duration for how long the popup should be visible
      });
    }
  };
  const warningHandler = (val) => {
    console.log("hello from warnign handler");
    if (val == true) {
      setWarning(false);
    }
    if (warning == false) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  useEffect(() => {}, [data]);

  // const deleteElement = (index, ar) => {
  //   for (let i = 0; i < ar.length; i++) {
  //     if (i < index) {
  //       console.log("delete index ->", i);
  //       continue;
  //     }
  //     ar[i] = ar[i + 1];
  //   }
  //   ar.pop();
  //   // data=[...ar]
  //   console.log(data);
  //   return ar;
  // };
  // const deleteHandler = (val) => {
  //   console.log("from delete handler", val);
  //   let ar = deleteElement(val, data);

  //   setData([...ar]);
  //   setWarning(0);
  // };
  // const [x, setX] = useState(0);
  // const [y, setY] = useState(0);

  // const calculateXandY = (event) => {
  //   const clickX = event.clientX; // X coordinate of the click
  //   console.log(clickX, "click x value");
  //   console.log(window.innerWidth);
  //   setX(clickX);
  //   const clickY = event.clientY;
  //   setY(clickY);

  //   console.log("x coordinate", typeof clickX);
  // };

  const popcontainer = {
    width: "40%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // transform: translate (-50%, 50%),
    // borderColor: 'red'
    // border: "5px solid red",
    // backgroundColor:'#fff'
  };
  const image = {
    width: "17px",
    height: "17px",
    display: "flex",
    alignSelf: "center",
  };

  const showPopUp = (value) => {
    // console.log(value ,"Hello")
    setOpen(value);
  };
  const closePopUp = () => {
    setOpen(0);
  };

  return (
    <>
      {/* <NavBar buttonFlag={true} popUp={showPopUp} buttonName={"Create New admin"} />
       */}

      <div className="container-fluid position-relative">
        <div className="row">
          <div
            className={`col-lg-2 col-md-3 pe-0 ${styles.left}`}
            style={open == 1 ? { filter: "blur(2px)" } : {}}
          >
            {/* spacing-done */}
            <Sidebar activeTabColor="Add sub-admin" />
          </div>

          <div
            className={`col-lg-10 col-md-9 col-sm-12 col-12 pb-4 `}
            style={{
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#EAF0FA",
            }}
          >
            {loading && <PreLoader />}
            <div style={open == 1 ? { filter: "blur(2px)" } : {}}>
              <div className="py-3">
                <NavBarEx
                  buttonName={"Add new admin"}
                  buttonFlag={true}
                  popUp={showPopUp}
                  search={true}
                  siderBarButton
                />
              </div>
              {getDataOfSubAdmin.map((item, index) => {
                return (
                  <div className={`my-2 `} key={index}>
                    {/* <TableRow productName={"Jalaj Gupta"} productId={"#1243"} category={"Pop"}
                                        status={"ordered"} dateAdded={"12/05/2023"} price={"50,000"}
                                        quantity={"20"} /> */}
                    <TableRow height={60}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 14,
                              color: "black",
                              fontWeight: "500",
                              fontSize: "16px",
                              fontFamily: "InterMedium",
                            }}
                          >
                            {item.name}
                            {/* Rahul {index} */}
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 14,
                              color: "#9E8989",
                              fontWeight: "500",
                              fontSize: "14px",
                              fontFamily: "InterMedium",
                            }}
                          >
                            {item.email}
                          </p>
                        </div>

                        <div
                          style={image}
                          onClick={(event) => {
                            // calculateXandY(event);
                            setActive(index);
                            warningHandler();
                          }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            className={ProductStyles.iconSize}
                            src={dustbin}
                            alt="dustbin"
                          />
                        </div>
                      </div>
                      {/* implimentation in common */}
                    </TableRow>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={styles.popcontainer} hidden={!open ? true : false}>
          {open && <SubAdminPopup closePopUp={closePopUp} />}
        </div>
        {warning ? (
          <div
            style={{
              width: "35%",
              height: "50%",
              position: "absolute",
              left: "30%",
              top: "50%",
              // zIndex: 10,
            }}
          >
            <WarningPop
              delete={() => deleteHandler(active)}
              closePopUp={(val) => {
                warningHandler(val);
              }}
              message="Are you sure you want to accept the request?"
            />
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </>
  );
}
